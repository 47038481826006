import React from 'react';
import { useFormatTitleWithApplicantData } from '../../hooks/useFormatTitleWithApplicantData';
import * as styles from './styles.module.scss';

const FlowHeader = ({ data }) => {
  const { primary } = data;
  const { flow_header_title: title, flow_header_subtitle: subtitle, align_centre: alignCentre } = primary;

  const formattedTitle = useFormatTitleWithApplicantData({ title });
  const formattedSubtitle = useFormatTitleWithApplicantData({ title: subtitle });

  return (
    <header className={`${styles.container} flow-header`} style={{ textAlign: alignCentre ? 'center' : 'left' }}>
      {formattedTitle?.html && (
        <div dangerouslySetInnerHTML={{ __html: formattedTitle?.html }} className={styles.title} />
      )}
      {formattedSubtitle?.html && (
        <div dangerouslySetInnerHTML={{ __html: formattedSubtitle?.html }} className={styles.subtitle} />
      )}
    </header>
  );
};

export default FlowHeader;
