import React from 'react';
import { navigate } from 'gatsby';
import { CalculatorTemplate } from 'components';
import { fieldsDefault } from './fields';
import { SERVER } from '../../../utils/server';
import { isBrowser } from '../../../utils/isBrowser';

const LmiCalculator = (props) => {
  // CALCULATOR FORM DATA
  const calculatorEndpoint = `${SERVER}/calculators/lmi-calculator`;

  const defaultValues = {
    propertyValue: 950000,
    depositAmount: 100000,
    termOfLoan: '30 years',
    state: 'NSW',
    homeBuyingSituation: 'Yes',
    propertyUse: 'Primary residence',
  };

  const defaultCalculationData = {
    upfrontLmi: 0,
    estStateSDLmi: 0,
    lvr: 0,
  };

  // START APPLICATION
  const handleStartApplication = (data) => {
    if (isBrowser) {
      const storedLeadFlowData = JSON.parse(localStorage.getItem('yb-data'));

      const { state, termOfLoan, homeBuyingSituation, propertyUse, ...restOfData } = data;

      const formattedTermOfLoan = Number(termOfLoan?.slice(0, -6));

      const formattedHomeBuyingSituation =
        homeBuyingSituation === 'Yes' ? 'First time home buyer' : 'Experienced home buyer';

      const formattedPropertyUse = propertyUse === 'Primary residence' ? 'To live in' : "It's an investment";

      localStorage.setItem(
        'yb-data',
        JSON.stringify({
          ...storedLeadFlowData,
          ...restOfData,
          termOfLoan: formattedTermOfLoan,
          homeBuyingSituation: formattedHomeBuyingSituation,
          propertyUse: formattedPropertyUse,
        })
      );

      navigate('/get-started');
    }
  };

  // DISPLAY COUNT
  const controlsBlockConfigDefault = (data) => {
    const lvr = data?.lvr;

    const handleEndCount = (defaultValue, lookupKey) => {
      const lookup = {
        totalLmi: {
          default: defaultValue,
          under80: '$0',
          over95: 'N/A',
        },
        estStateSDLmi: {
          default: defaultValue,
          under80: 'N/A',
          over95: 'N/A',
        },
        lvr: {
          default: defaultValue,
          under80: '< 80.00%',
          over95: '> 95.00%',
        },
      };

      if (lvr <= 80) return lookup?.[lookupKey]?.under80;

      if (lvr >= 95) return lookup?.[lookupKey]?.over95;

      return lookup?.[lookupKey]?.default;
    };

    const handleDescription = () => {
      if (lvr >= 95) return 'LMI cover at 95% or over LVR will be approximately 1.8% - 2% of the entire loan amount.';

      return 'LMI is not usually payable for a loan to value ratio of 80% or below.';
    };

    return {
      displayCountConfig: [
        {
          displayType: 'primary',
          title: 'Upfront LMI',
          subtitle: 'inc. GST',
          endCount: handleEndCount(data?.totalLmi, 'totalLmi'),
        },
        [
          {
            displayType: 'secondary',
            countType: 'rate',
            title: 'Loan to value ratio',
            subtitle: '(LVR)',
            endCount: handleEndCount(data?.lvr, 'lvr'),
          },
          {
            displayType: 'secondary',
            title: 'Stamp Duty Payable',
            subtitle: '(On LMI)',
            endCount: handleEndCount(data?.estStateSDLmi, 'monthlyLmi'),
          },
        ],
      ],
      description: {
        html: `<p style="margin: 0 0 8px;">${handleDescription()}</p><p>Not all LMI calculations are the same. They vary between lenders based on a number of factors.</p>`,
      },
    };
  };

  const handleShowCaculationError = (responseData) => {
    if (responseData?.lvr >= 95) return 'LMI unavailable for your deposit amount. Please increase value.';

    return false;
  };

  return (
    <CalculatorTemplate
      {...props}
      defaultValues={defaultValues}
      onHandleStartApplication={handleStartApplication}
      controlsBlockConfigDefault={controlsBlockConfigDefault}
      fieldsDefault={fieldsDefault}
      defaultCalculationData={defaultCalculationData}
      calculatorEndpoint={calculatorEndpoint}
      showCalculationError={handleShowCaculationError}
    />
  );
};

export default LmiCalculator;
