import { formatToggleLabelWithAdditionalFields } from '../../../../../utils/misc';
import { formatCurrency } from '../../../../../utils/formatCurrency';
import { onSubmit } from './onSubmit';

const defaultCurrency = {
  type: 'currency',
  placeholder: 'Enter an amount',
};

const estValue = {
  ...defaultCurrency,
  name: 'estValue',
  label: 'Estimate Value',
  validationMessage: 'Please enter valid estimated value',
};

const value = {
  ...defaultCurrency,
  name: 'value',
  label: 'Estimate Value',
  validationMessage: 'Please enter valid value',
};

const monthlyRent = {
  ...defaultCurrency,
  name: 'monthlyRent',
  label: 'Monthly Rent',
  className: 'col-3',
  validationMessage: 'Please enter valid rent',
};

const ownership = {
  type: 'select',
  placeholder: 'Please select',
  options: ({ extraData }) => {
    const Users = extraData?.Users;
    return Users?.map((user) => user.name);
  },
  validation: { required: { value: true, message: 'Please select owner' } },
  name: 'assetOwnership',
  label: 'Ownership',
};

export default [
  {
    type: 'arrayMulti',
    name: 'assets',
    namePrismic: 'About - Assets',
    onSubmit,
    slug: 'about-assets',
    addButtonLabel: 'Add assets',
    preventEnterKeyListener: true,
    onMount: ({ extraData }) => {
      const primaryApplicantId = extraData?.Users?.find((user) => user?.primaryApplicant)?.id;
      localStorage.setItem('yb-current-user-id', primaryApplicantId);
    },
    subfields: [
      {
        type: 'arrayAccordion',
        name: 'otherAssetsHomeContentsList',
        hideAddButton: true,
        addButtonLabel: 'Home Contents',
        removeButtonLabel: 'Delete asset',
        removeFromDropdown: true,
        showGroupValidations: true,
        toggleLabel: ({ fieldData, index }) => 'Home Contents',
        validation: () => ({ required: true, min: 1, max: 1 }),
        subfields: [
          {
            ...estValue,
            defaultValue: (props) => {
              const numberOfApplicants = props?.extraData?.Users?.length || 1;
              return numberOfApplicants * 10000;
            },
          },
        ],
      },
      {
        type: 'arrayAccordion',
        hideAddButton: true,
        name: 'otherAssetsSuperList',
        addButtonLabel: 'Super',
        removeButtonLabel: 'Delete asset',
        removeFromDropdown: true,
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'Super' }),
        className: 'grid-cols-3',
        validation: ({ extraData }) => {
          const numberOfApplicants = extraData?.Users?.length;
          return { min: numberOfApplicants };
        },
        subfields: [
          {
            ...ownership,
            defaultValue: (props) => {
              const index = props?.index;
              const users = props?.extraData?.Users;
              return users?.[index]?.name;
            },
            className: 'grid-col-1',
          },
          {
            name: 'otherAssetsSuper',
            label: 'Fund',
            validationMessage: 'Please enter fund',
            type: 'text',
            className: 'grid-col-2',
            placeholder: 'Enter fund',
          },
          {
            ...estValue,
            label: 'Super Amount',
            className: 'grid-col-3',
          },
        ],
      },
      {
        type: 'arrayAccordion',
        hideAddButton: true,
        name: 'otherAssetsSavingsList',
        addButtonLabel: 'Savings',
        removeButtonLabel: 'Delete asset',
        className: 'grid-cols-3',
        validation: () => ({ min: 1 }),
        toggleLabel: ({ fieldData, index }) => 'Savings',
        appendedFields: [
          {
            estValue: null,
            bank: null,
            ownership: 'Please select',
          },
        ],
        subfields: [
          { ...ownership, className: 'grid-col-1' },
          {
            name: 'bank',
            type: 'text',
            label: 'Institution / Bank',
            placeholder: 'Enter institution',
            className: 'grid-col-2',
          },
          {
            ...estValue,
            label: 'Savings amount',
            className: 'grid-col-3',
            defaultValue: 30000,
          },
        ],
      },
      {
        type: 'arrayAccordion',
        hideAddButton: true,
        name: 'assetsHomeAddressList',
        addButtonLabel: 'Your Home',
        removeButtonLabel: 'Delete asset',
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'Your Home' }),
        subfields: [
          {
            name: 'assetsHomeAddress',
            label: 'Address',
            type: 'addressSearch',
            addressArray: true,
            hideManualAddress: true,
            hideDefaultLabel: true,
            placeholder: 'Enter an address',
            defaultValue: '',
            validationMessage: 'Please enter address',
            className: 'col-1',
          },
          {
            ...ownership,
            className: 'split-left',
          },
          {
            ...estValue,
            className: 'split-right',
          },
        ],
      },
      {
        type: 'arrayAccordion',
        hideAddButton: true,
        name: 'assetsInvestmentAddressList',
        addButtonLabel: 'Investment Property',
        removeButtonLabel: 'Delete asset',
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'Investment Address' }),
        className: 'grid-cols-3',
        subfields: [
          {
            name: 'assetsInvestmentAddress',
            label: 'Investment Address',
            type: 'addressSearch',
            addressArray: true,
            hideManualAddress: true,
            hideDefaultLabel: true,
            placeholder: 'Type here',
            defaultValue: '',
            validationMessage: 'Please enter valid address',
          },
          {
            ...ownership,
            className: 'grid-col-1',
          },
          {
            ...estValue,
            className: 'grid-col-2',
          },
          {
            ...monthlyRent,
            className: 'grid-col-3',
          },
        ],
      },
      {
        type: 'arrayAccordion',
        hideAddButton: true,
        name: 'otherAssetsVehicleList',
        addButtonLabel: 'Vehicle',
        removeButtonLabel: 'Delete asset',
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'Vehicle' }),
        className: 'grid-cols-3',
        subfields: [
          {
            ...ownership,
            className: 'grid-col-1',
          },
          {
            name: 'vehicleDetails',
            label: 'Make / Model / Year',
            type: 'text',
            placeholder: 'Type here',
            validationMessage: 'Please enter vehicle details',
            className: 'grid-col-2',
          },
          {
            ...estValue,
            className: 'grid-col-3',
          },
        ],
      },
      {
        type: 'arrayAccordion',
        hideAddButton: true,
        name: 'otherAssetsTotalSharesList',
        addButtonLabel: 'Shares',
        removeButtonLabel: 'Delete asset',
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'Shares' }),
        subfields: [
          {
            ...ownership,
            className: 'split-left',
          },
          {
            ...estValue,
            className: 'split-right',
          },
        ],
      },
      {
        type: 'arrayAccordion',
        name: 'assetsCryptoCurrency',
        hideAddButton: true,
        addButtonLabel: 'Cryptocurrency',
        removeButtonLabel: 'Delete asset',
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'Cryptocurrency' }),
        appendedFields: [
          {
            ownership: 'Select One',
            value: null,
          },
        ],
        subfields: [
          {
            ...ownership,
            className: 'split-left',
            name: 'ownership',
          },
          {
            ...value,
            className: 'split-right',
            label: 'Value',
          },
        ],
      },
      {
        type: 'arrayAccordion',
        hideAddButton: true,
        name: 'otherAssets',
        addButtonLabel: 'Other',
        removeButtonLabel: 'Delete asset',
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'Other' }),
        className: 'grid-cols-3',
        subfields: [
          {
            ...ownership,
            className: 'grid-col-1',
            name: 'ownership',
          },
          {
            name: 'assetType',
            label: 'Type of asset',
            validationMessage: 'Please enter details',
            type: 'text',
            placeholder: 'Enter details',
            className: 'grid-col-2',
          },
          {
            ...value,
            className: 'grid-col-3',
            label: 'Value',
          },
        ],
      },
      {
        type: 'total',
        name: 'about-assets-total',
        removeFromDropdown: true,
        helper: ({ formData }) => {
          const {
            assetsHomeAddressList,
            assetsInvestmentAddressList,
            otherAssetsVehicleList,
            otherAssetsSavingsList,
            otherAssetsHomeContentsList,
            otherAssetsSuperList,
            otherAssetsTotalSharesList,
            assetsCryptoCurrency,
            otherAssetsList,
          } = formData || {};
          const assets = [
            assetsHomeAddressList,
            assetsInvestmentAddressList,
            otherAssetsVehicleList,
            otherAssetsSavingsList,
            otherAssetsHomeContentsList,
            otherAssetsSuperList,
            otherAssetsTotalSharesList,
            assetsCryptoCurrency,
            otherAssetsList,
          ];
          const sum = assets.reduce((acc, val) => {
            if (!val) return acc;
            const subtotal = val.reduce((a, b) => a + (parseFloat(b.estValue) || 0), 0);
            return acc + subtotal;
          }, 0);
          const sumCurrency = formatCurrency(sum);
          return `
            <div class="inner-container flex-divider">
              💰 Total assets: <span>${sumCurrency}</span>
            </div>
          `;
        },
      },
    ],
  },
];
