import { defaultOnSubmit } from '../../../utils/defaultOnSubmit';

export const onSubmit = async ({ formData, apolloClient, extraData }) => {
  const hasDependants = formData?.otherDependants === 'Yes';

  const variables = {
    ...formData,
    otherDependants: hasDependants,
    otherDependantsList: hasDependants
      ? formData?.otherDependantsList?.map((data) => {
          delete data?.__typename;
          return {
            ...data,
            dependantAge: parseInt(data?.dependantAge, 10),
          };
        })
      : [],
  };

  return defaultOnSubmit({ formData: variables, apolloClient, extraData });
};
