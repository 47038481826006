const testCreds = [
  {
    ID: {
      Type: 'DRIVERS_LICENCE',
      Region: 'VIC',
      Country: 'AUS',
      'ID Number': 283229690,
    },
    'First Name': 'JAMES',
    'Other Name': 'A',
    'Family Name': 'TESTONE',
    DOB: '01-01-1950',
    Gender: 'M',
    Address: {
      Unit: 'U 1',
      'Street Number': 35,
      'Street Name': 'CONN STREET',
      'Town/City': 'FERNTREE GULLY',
      State: 'VIC',
      Postcode: '3156',
    },
  },
  // {
  //   ID: {
  //     Type: 'DRIVERS_LICENCE',
  //     Region: 'NSW',
  //     Country: 'AUS',
  //     'ID Number': 'N3446972',
  //   },
  //   'First Name': 'JUDY',
  //   'Other Name': 'B',
  //   'Family Name': 'TESTTWO',
  //   DOB: '01-01-1951',
  //   Gender: 'F',
  //   Address: {
  //     'Street Number': 17,
  //     'Street Name': 'HUME STREET',
  //     'Town/City': 'PARKES',
  //     State: 'NSW',
  //     Postcode: '2870',
  //   },
  // },
  // {
  //   ID: {
  //     Type: 'DRIVERS_LICENCE',
  //     Region: 'ACT',
  //     Country: 'AUS',
  //     'ID Number': '123201949',
  //   },
  //   'First Name': 'PETER',
  //   'Other Name': 'C',
  //   'Family Name': 'TESTTHREE',
  //   DOB: '01-01-1952',
  //   Gender: 'M',
  //   Address: {
  //     'Street Number': 42,
  //     'Street Name': 'SCRIVENER STREET',
  //     'Town/City': `O’CONNOR`,
  //     State: 'ACT',
  //     Postcode: '2602',
  //   },
  // },
  {
    ID: {
      Type: 'DRIVERS_LICENCE',
      Region: 'QLD',
      Country: 'AUS',
      'ID Number': '103188691',
    },
    'First Name': 'MARY',
    'Family Name': 'TESTFOUR',
    DOB: '01-01-1953',
    Gender: 'F',
    Address: {
      'Street Number': 17,
      'Street Name': 'WATTLE AVENUE',
      'Town/City': 'WARWICK',
      State: 'QLD',
      Postcode: '4370',
    },
  },
  {
    ID: {
      Type: 'DRIVERS_LICENCE',
      Region: 'NT',
      Country: 'AUS',
      'ID Number': '601903667',
    },
    'First Name': 'FRANK',
    'Other Name': '',
    'Family Name': 'TESTFIVE',
    DOB: '01-01-1954',
    Gender: 'M',
    Address: {
      'Street Number': 19,
      'Street Name': 'GURUNGU STREET',
      'Town/City': 'ELLIOT',
      State: 'NT',
      Postcode: '0862',
    },
  },
];

const getRandomIndex = () => Math.floor(Math.random() * testCreds.length);

const keyValueToString = (keyValue) =>
  `${keyValue[0]}: ${
    typeof keyValue[1] === 'object'
      ? Object.entries(keyValue[1])
        .map((nestedItem) => keyValueToString(nestedItem))
        .join('\n')
      : keyValue[1]
  }`;

export default () => {
  const testCredsIndex = getRandomIndex();
  const testCredsToUse = testCreds[testCredsIndex];
  return Object.entries(testCredsToUse)
    .map((keyValue) => keyValueToString(keyValue))
    .join('\n');
};
