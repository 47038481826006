import { formatCurrency } from '../../../../../utils/formatCurrency';
import { onSubmit } from './onSubmit';

export default [
  {
    type: 'group',
    name: 'additionalIncome',
    namePrismic: 'About - Additional Income',
    slug: 'about-additional-income',
    onSubmit,
    showGroupValidations: false,
    subfields: [
      {
        type: 'radio',
        name: 'otherIncome',
        options: [{ text: 'Yes' }, { text: 'No' }],
      },
      {
        type: 'arrayAccordion',
        name: 'OtherIncomeSources',
        addButtonLabel: 'Add income',
        showGroupValidations: true,
        conditional: ({ formData }) => formData.otherIncome === 'Yes',
        toggleLabel: ({ fieldData, index }) => {
          const incomeLabel = `Additional income #${index + 1}`;
          const incomeAmountValue = fieldData?.otherIncomeAmount;
          const incomeAmount = `- (${formatCurrency(incomeAmountValue)})`;
          return fieldData?.otherIncomeAmount > 0 ? `${incomeLabel} ${incomeAmount}` : incomeLabel;
        },
        validation: ({ formData }) => {
          if (formData.otherIncome === 'Yes') {
            return {
              min: 1,
            };
          }
        },
        subfields: [
          {
            type: 'select',
            name: 'otherIncomeType',
            placeholder: 'Select one',
            label: 'Type of income',
            options: ['Total Rent Received', 'Investment/Shares', 'Centrelink', 'DSP/Other'],
            className: 'split-left',
            validationMessage: 'Please select income type',
          },
          {
            type: 'currency',
            name: 'otherIncomeAmount',
            placeholder: 'Enter an amount',
            label: 'Annual income amount',
            validationMessage: 'Please enter valid income',
            className: 'split-right',
          },
        ],
      },
      {
        type: 'total',
        name: 'otherIncomeSources_total',
        label: '',
        conditional: ({ formData }) => formData.otherIncome === 'Yes',
        helper: ({ formData }) => {
          const otherIncomeSources = formData?.OtherIncomeSources;
          if (!otherIncomeSources && otherIncomeSources?.length === 0) return null;
          const sum = otherIncomeSources?.reduce((acc, item) => acc + item?.otherIncomeAmount, 0);
          if (!sum) return null;
          const sumCurrency = formatCurrency(sum);
          return `
          <div class="inner-container flex-divider">
              💰 Total other income: <span className="currency">${sumCurrency}</span>
          </div>`;
        },
      },
    ],
  },
];
