import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './styles.module.scss';

const FieldArrayLabel = (props) => {
  const { name, index, subfieldLabel, watch } = props;
  const formattedName = `${name}[${index}]`;
  const fieldData = watch(formattedName);
  return <div className={`${styles.label} field-label`}>{subfieldLabel(fieldData, index)}</div>;
};

FieldArrayLabel.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  subfieldLabel: PropTypes.func.isRequired,
};

export default FieldArrayLabel;
