import React, { useState, useRef } from 'react';
import { Tooltip, ProgressBar, FormFieldNavigation } from 'components';
import { useLazyQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import ApplicationFlowIllion from '../ApplicationFlowIllion';
import ApplicationFlowFrankieOne from '../ApplicationFlowFrankieOne';
import VerificationConfirmationModal from './VerificationConfirmationModal';
import { REACTIVE_VARS } from '../../../apollo/config/reactiveVars';
import { GET_VERIFICATION_STATUS } from '../../../apollo/queries/queries_misc';
import frankieOneTestCreds from '../../../utils/frankieOneTestCreds';
import { isDev } from '../../../utils/misc';
import { useDataLayer } from '../../../hooks/useDataLayer';
import * as styles from '../styles.module.scss';

const ApplicationFlowVerification = (props) => {
  const {
    progresses,
    applicationFlows,
    allApplicants,
    setCurrentUserId,
    currentApplicantIndex,
    currentApplicant,
    nextApplicant,
    prevApplicant,
    primaryApplicant,
    refetchApplicantCoreData,
    applicantCoreData,
  } = props;

  // Set applicant name in header
  REACTIVE_VARS.currentApplicantVar({
    ...REACTIVE_VARS.currentApplicantVar(),
    firstName: currentApplicant?.name,
  });

  // User Data
  const [
    fetchData,
    { data: fetchedVerificationData, loading, called, startPolling, stopPolling, client: apolloClient },
  ] = useLazyQuery(GET_VERIFICATION_STATUS, {
    variables: { email: Cookies.get('yb-email') },
    fetchPolicy: 'network-only',
  });

  const currentApplicantIdVerified = currentApplicant?.aboutUser?.idVerified;
  const currentApplicantBankstatementsReceived = currentApplicant?.aboutUser?.bankStatementsReceived;
  const userVerified = currentApplicantIdVerified && currentApplicantBankstatementsReceived;

  const [activeModal, setActiveModal] = useState(false);
  const [providerLoading, setProviderLoading] = useState(false);
  const [modalVerificationStage, setModalVerificationStage] = useState('userNotVerified');
  const currentFlowStep = applicantCoreData?.currentUser?.currentFlowStep;

  const dataLayer = useDataLayer();

  const handleSubmit = async ({ currentApplicantData }) => {
    if (!providerLoading && !userVerified) {
      setModalVerificationStage('userNotVerified');
      return setActiveModal(true);
    }

    if (providerLoading) {
      setModalVerificationStage('providerLoading');
      return setActiveModal(true);
    }

    if (called && !userVerified) {
      setModalVerificationStage('submittedAndUserNotVerified');
      return setActiveModal(true);
    }

    if (currentApplicantIndex === allApplicants?.length - 1 && userVerified) {
      // Once the data has originally been fetched post verification, no need to fetch
      // again
      if (currentFlowStep < 4) {
        await refetchApplicantCoreData();
        dataLayer?.track('User Verified');
      }
      return navigate('/fact-find');
    }

    if (userVerified) {
      return setCurrentUserId(nextApplicant?.id);
    }

    return null;
  };

  const handleBack = () => {
    if (currentApplicantIndex === 0) {
      if (allApplicants?.length === 1) {
        return navigate('/prioritise-your-shortlist');
      }

      return navigate('/invite-other-applicants');
    }

    return setCurrentUserId(prevApplicant?.id);
  };

  const hasPrimaryApplicantHadIdVerified = primaryApplicant?.aboutUser?.idVerified;

  const authApproval = useRef(false);

  const [resetFrankieOne, setResetFrankieOne] = useState(true);
  const [showFrankieOneModal, toggleFrankieOneModal] = useState(false);

  const frankieOneModalOnOpenHandler = (approval) => {
    if (!approval) {
      setModalVerificationStage('idVerificationAuth');
      return setActiveModal(true);
    }

    if (isDev) {
      const testCredsToUse = frankieOneTestCreds();
      console.log('%c******Test Credentials******\n\n', 'background: #3C38D6; color: #FFFFFF', testCredsToUse);
      prompt('Copy test credentials to use: Ctrl+C, Enter', testCredsToUse);
    }
    setResetFrankieOne(false);

    toggleFrankieOneModal(true);
  };

  const verificationConfirmationModalClickHandler = () => {
    if (!hasPrimaryApplicantHadIdVerified) {
      authApproval.current = true;
      setActiveModal(false);
      setTimeout(() => frankieOneModalOnOpenHandler(authApproval.current), 500);
    }
  };

  const commonVerificationFlowProps = {
    fetchData,
    fetchedVerificationData,
    startPolling,
    stopPolling,
    loading,
    setProviderLoading,
    activeModal,
    setActiveModal,
    applicantData: currentApplicant,
    hasPrimaryApplicantHadIdVerified,
    apolloClient,
  };

  const frankieOneProps = {
    testCreds: frankieOneTestCreds,
    resetFrankieOne,
    setResetFrankieOne,
    onOpenModalHandler: frankieOneModalOnOpenHandler,
    showFrankieOneModal,
    toggleFrankieOneModal,
    authApproval,
  };

  return (
    <div className={styles.section}>
      {applicationFlows.map((applicationFlow) => {
        const { title, subtitle, duration, link, tooltipLabel, tooltipContent } = applicationFlow;

        const applicationFlowIllion = title?.text?.toLowerCase()?.includes('bank statement verification');
        const applicationFrankieOne = title?.text?.toLowerCase()?.includes('id verification');

        return (
          <div className={`${styles.container} ${styles.verificationContainer}`} key={title?.text}>
            <div className={styles.topContainer}>
              <div>
                <div className={styles.title}>{title?.text}</div>
                <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle?.html }} />
              </div>
              <Tooltip label={tooltipLabel?.text} content={tooltipContent?.html} />
            </div>
            <div className={styles.duration}>{`${duration?.text}`}</div>
            {applicationFlowIllion && <ApplicationFlowIllion {...commonVerificationFlowProps} />}
            {applicationFrankieOne && (
              <ApplicationFlowFrankieOne {...commonVerificationFlowProps} {...frankieOneProps} />
            )}
          </div>
        );
      })}
      <FormFieldNavigation
        onBack={handleBack}
        submitButtonText="Next"
        handleSubmit={() => handleSubmit({ currentApplicantData: currentApplicant })}
      />
      <ProgressBar progress={progresses?.verificationProgress(currentApplicant)} />
      <div>{progresses?.verificationSteps(currentApplicant)}/2 Steps completed</div>
      <VerificationConfirmationModal
        activeModal={activeModal}
        setActiveModal={setActiveModal}
        verificationStage={modalVerificationStage}
        handleClick={verificationConfirmationModalClickHandler}
      />
    </div>
  );
};

export default ApplicationFlowVerification;
