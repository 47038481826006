import React from 'react';
import FormField from '../../../Form/FormFieldNew/index';

const FieldsBlock = (props) => {
  const { fields, methods, errors, showGroupValidations } = props;

  return (
    fields?.length > 0 &&
    fields.map((field) => {
      const { name } = field;

      return (
        <FormField
          key={name}
          {...methods}
          {...field}
          validationErrors={errors?.[name]}
          showGroupValidations={showGroupValidations}
        />
      );
    })
  );
};

export default FieldsBlock;
