import { gql } from '@apollo/client';

export const EXPENSES_FRAGMENTS = {
  expenses: {
    'expenses-monthly-expenses': gql`
      fragment ExpensesTotal on Expenses {
        id
        basicHousingAndPropertyExpenses
        clothingAndToiletries
        educationAndChildcare
        communicationsAndMedia
        insurance
        foodAndGroceries
        essentialTravel
        otherExpenses
        leisureExpenses
        medical
      }
    `,
    flowProgress: gql`
      fragment FlowProgressExpenses on Expenses {
        complete
        totalFields
        completedFields
      }
    `,
  },
};
