import { defaultOnSubmit } from '../../../utils/defaultOnSubmit';

export const onSubmit = async ({ formData, apolloClient, extraData }) => {
  const variables = {
    ...formData,
    addressHistory: formData?.addressHistory.map((data) => {
      delete data?.__typename;

      return data;
    }),
  };

  return defaultOnSubmit({ formData: variables, apolloClient, extraData });
};
