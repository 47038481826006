import React, { useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

const OnVisible = ({
  as,
  className,
  visibleClassName = 'visible',
  id,
  onClick = () => {},
  onChange = () => {},
  style,
  threshold,
  children,
  triggerMultiple,
  ...otherProps
}) => {
  const [visible, setVisible] = useState(false);

  const onChangeHandler = (isVisible) => {
    setVisible(isVisible);
    onChange(isVisible);
  };

  return (
    <InView
      as={as || 'div'}
      className={`${className || ''} ${visible ? visibleClassName : ''}`}
      style={style}
      id={id || null}
      triggerOnce={!triggerMultiple}
      onClick={onClick}
      onChange={onChangeHandler}
      threshold={threshold || 0.5}
      {...otherProps}
    >
      {children}
    </InView>
  );
};

export default OnVisible;
