export default [
  {
    type: 'fieldGroup',
    name: 'refinancePropertyAddress',
    namePrismic: 'Property - Refinance Address',
    slug: 'property-refinance-address',
    preventEnterKeyListener: true,
    subfields: [
      {
        type: 'addressSearch',
      },
    ],
  },
];
