import React, { useEffect, useState } from 'react';
import fetch from 'cross-fetch';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import Cookies from 'js-cookie';
import { setContext } from '@apollo/client/link/context';
import { typePolicies } from '../config/typePolicies';
import { IS_USER_LOGGED_IN } from '../queries/queries_misc';
import { SERVER } from '../../utils/server';

export const ApolloProviderWrapper = ({ children }) => {
  const [client, setClient] = useState(null);
  const [persistor, setPersistor] = useState(null);

  useEffect(async () => {
    async function init() {
      const cache = new InMemoryCache({
        typePolicies,
      });
      const newPersistor = new CachePersistor({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: true,
        key: 'yb-data-apollo-cache-persist',
        trigger: 'write',
      });

      const authCheckData = cache.readQuery({
        query: IS_USER_LOGGED_IN,
      });

      if (authCheckData?.isLoggedIn) {
        // Restore cache
        await newPersistor.restore();
      } else {
        // Purge / delete cache
        await newPersistor.purge();
      }

      const authLink = setContext((_, { headers }) => {
        const token = Cookies.get('yb-token');
        const applicationId = Cookies.get('yb-app');

        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            appId: applicationId || '',
          },
        };
      });

      setPersistor(newPersistor);
      setClient(
        new ApolloClient({
          link: authLink.concat(
            new HttpLink({
              uri: `${SERVER}/graphql`,
              fetch,
            })
          ),
          cache,
          defaultOptions: {
            persistor: newPersistor,
          },
        })
      );
    }

    init().catch(console.error);
  }, []);

  if (!client) return <div>...loading</div>;

  return <ApolloProvider client={client}>{React.cloneElement(children, { apolloClient: client })}</ApolloProvider>;
};
