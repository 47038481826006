import { gql } from '@apollo/client';

export const UPDATE_MORTGAGE_REFINANCE = gql`
  mutation UpdateMortgageRefinance($id: String, $lender: String, $applicationId: String) {
    updateMortgageRefinance(id: $id, lender: $lender, applicationId: $applicationId) {
      id
      lender
    }
  }
`;
