export default [
  {
    type: 'currency',
    name: 'salary',
    namePrismic: 'About - Income',
    slug: 'about-income',
    placeholder: 'Enter an amount',
    label: 'Annual salary before tax',
    validationMessage: 'Please enter annual salary',
    helper: (value) => value,
  },
];
