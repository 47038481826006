import { navigate } from 'gatsby';
import { handleSubmit } from './onSubmit';

export const fields = ({ currentInvitedApplicant }) => [
  {
    type: 'group',
    name: 'details',
    nextButtonLabel: 'Send invite',
    goPrev: () => navigate('/invite-other-applicants'),
    title: {
      html: `<h1>Let's invite ${currentInvitedApplicant?.name}</h1>`,
    },
    description: `Enter ${currentInvitedApplicant?.name}'s phone number below, they will use this number to access this application in future.`,
    onSubmit: handleSubmit,
    showGroupValidations: true,
    subfields: [
      {
        type: 'email',
        name: 'inviteEmail',
        placeholder: 'Enter a valid email',
        validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
        validationMessage: 'Please enter a valid email',
      },
      {
        type: 'string',
        validation: {
          required: { value: true, message: 'Phone number is required' },
          pattern: {
            value: /^04(\s?[0-9]{2}\s?)([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})$/i,
            message: 'Please enter valid phone number',
          },
        },
        placeholder: 'Enter a valid phone number',
        validationMessage: 'Please enter a valid phone number',
        name: 'invitePhoneNumber',
      },
    ],
  },
];
