import React from 'react';
import { Image, Section, Link, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const TwoColumns = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, content } = primary;

  // content checks

  const titleHtml = title?.html;
  const contentHtml = content?.html;

  return (
    <Section className={styles.section} containerClassName={styles.container}>
      <OnVisible className={styles.titleContainer} visibleClassName={styles.titleContainerVisible}>
        {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {contentHtml && <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
      </OnVisible>
      <div className={styles.rows}>
        {items.map((item) => {
          const { title: rowTitle, content: rowContent, cta_link: ctaLink, cta_text: cta, image } = item;
          const rowTitleHtml = rowTitle?.html;
          const rowTitleText = rowTitle?.text;
          const rowContentHtml = rowContent?.html;
          const ctaText = cta?.text;
          const ctaLinkUrl = ctaLink?.url;
          const hasCta = ctaText && ctaLinkUrl;

          return (
            <div className={styles.row} key={rowTitleText}>
              <OnVisible className={styles.rowImage} visibleClassName={styles.rowImageVisible}>
                <Image image={image} alt={rowTitleText} />
              </OnVisible>
              <OnVisible className={styles.rowText} visibleClassName={styles.rowTextVisible}>
                {rowTitleHtml && <div className={styles.rowTitle} dangerouslySetInnerHTML={{ __html: rowTitleHtml }} />}
                {rowContentHtml && (
                  <div className={styles.rowContent} dangerouslySetInnerHTML={{ __html: rowContentHtml }} />
                )}
                {hasCta && (
                  <Link to={ctaLinkUrl} withChevron className={styles.cta} arrowClassName={styles.ctaArrow}>
                    {ctaText}
                  </Link>
                )}
              </OnVisible>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default TwoColumns;
