import React from 'react';
import { Section, Image, OnVisible, GetStarted } from 'components';
import './styles.scss';

const IconsTextGrid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { breakout_text: breakout, content, image, title } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const breakoutText = breakout?.text;
  const hasImage = image?.url;

  const hasBreakout = hasImage && breakoutText;

  return (
    <Section className={`icons-text-grid ${hasBreakout ? 'with-breakout' : ''}`}>
      <div className="icons-text-grid-images">
        {/* IMAGE AND BREAKOUT BOX */}
        {hasImage && (
          <OnVisible className="icons-text-grid-image">
            <Image image={image} aspectRatio={4 / 3} />
          </OnVisible>
        )}
        {hasBreakout && (
          <OnVisible className="icons-text-grid-breakout-container">
            <div className="icons-text-grid-breakout">
              <p>{breakoutText}</p>
              <GetStarted />
            </div>
          </OnVisible>
        )}
      </div>
      {/* GRID */}
      <div className="icons-text-grid-text">
        {titleHtml && <OnVisible className="icons-text-grid-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {contentHtml && (
          <OnVisible className="icons-text-grid-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
        )}
        {items.length > 0 && (
          <div className="icons-text-grid-container">
            {items.map((item) => {
              const { content: cardContent, icon, title: cardTitle } = item;
              // GRID ITEM
              return (
                <OnVisible className="icons-text-grid-card" key={cardTitle?.text}>
                  {icon?.url && (
                    <div className="icons-text-grid-card-image">
                      <Image image={icon} />
                    </div>
                  )}
                  <div className="icons-text-grid-card-text">
                    {cardTitle?.text && (
                      <div
                        className="icons-text-grid-card-title"
                        dangerouslySetInnerHTML={{ __html: cardTitle?.html }}
                      />
                    )}
                    {cardContent?.text && (
                      <div
                        className="icons-text-grid-card-content"
                        dangerouslySetInnerHTML={{ __html: cardContent?.html }}
                      />
                    )}
                  </div>
                </OnVisible>
              );
            })}
          </div>
        )}
      </div>
    </Section>
  );
};

export default IconsTextGrid;
