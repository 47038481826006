import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormField } from 'components';
import { defaultFields } from './defaultFields';
import * as styles from './styles.module.scss';

const Form = ({ submitButtonRef, fields, submitButtonText, className, onSubmit }) => {
  const { register, handleSubmit, formState, getValues, setValue } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const isSubmitting = formState?.isSubmitting;
  const [submissionError, setSubmissionError] = useState(false);

  const { errors, dirtyFields } = formState;

  const defaultOnSubmit = (values) => console.log('VALUES: ', values);

  const formFieldProps = {
    getValues,
    setValue,
    register,
  };

  const SubmitButton = () => (
    <button
      ref={submitButtonRef}
      type="submit"
      className={`button secondary submit-button ${styles.formButton}`}
      disabled={isSubmitting}
    >
      {isSubmitting ? <span className={styles.loader}>&nbsp;</span> : submitButtonText || 'Submit'}
    </button>
  );

  return (
    <section className={className || undefined}>
      {submissionError && <p className={styles.submissionError}>{submissionError}</p>}
      <form onSubmit={handleSubmit(onSubmit || defaultOnSubmit)} className={styles.form}>
        <button type="submit" disabled aria-hidden="true" style={{ display: 'none' }} />
        {(fields || defaultFields).map((field) => {
          const hasError = dirtyFields[field.name] && errors[field.name];

          return (
            <div key={field.name} className={`${styles.field} ${field.className || ''} form-field`}>
              {field.label && <span className={`${styles.label} form-label`}>{field.label}</span>}
              <FormField {...field} {...formFieldProps} />
              <div className={`${styles.fieldError} ${hasError ? 'active' : 'inactive'}`}>
                {hasError && <span>{field.validationMessage || 'This field is required'}</span>}
              </div>
            </div>
          );
        })}
        <SubmitButton />
      </form>
    </section>
  );
};
export default Form;
