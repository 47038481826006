import React from 'react';
import { navigate } from 'gatsby';
import { CalculatorTemplate } from 'components';
import { fieldsDefault } from './fields';
import { SERVER } from '../../../utils/server';
import { isBrowser } from '../../../utils/isBrowser';

const Chart = React.lazy(() => import('./Chart'));

const HomeLoanOffsetCalculator = (props) => {
  // CALCULATOR FORM DATA
  const calculatorEndpoint = `${SERVER}/calculators/offset-account`;

  const defaultValues = {
    balance: 600000,
    interestRate: 3.2,
    remainingYears: '30 years',
    repaymentFrequency: 'Monthly',
    offsetAccountBalance: 100000,
  };

  const defaultCalculationData = {
    interestSaved: 0,
    revisedTimeWithOffset: 0,
    timeSavedWithOffset: 0,
  };

  // START APPLICATION
  const handleStartApplication = (data) => {
    if (isBrowser) {
      const storedLeadFlowData = JSON.parse(localStorage.getItem('yb-data'));

      const { remainingYears, interestRate, balance } = data;

      localStorage.setItem(
        'yb-data',
        JSON.stringify({
          ...storedLeadFlowData,
          interestRate,
          loanAmount: balance,
          termOfLoan: Number(remainingYears?.slice(0, -6)),
        })
      );

      navigate('/get-started');
    }
  };

  // DISPLAY COUNT
  const controlsBlockConfigDefault = (data) => ({
    displayCountConfig: [
      {
        displayType: 'primary',
        title: `${data?.main?.repaymentFrequency || 'Monthly'} interest saved`,
        endCount: Math?.floor(data?.main?.interestSaved / data?.main?.totalOffsetPayments),
      },
      {
        displayType: 'secondary',
        title: 'Interest saved',
        endCount: data?.main?.interestSaved,
      },
      [
        {
          displayType: 'tertiary',
          title: 'Time saved',
          endCount: data?.main?.timeSavedWithOffset,
        },
        {
          displayType: 'tertiary',
          title: 'Revised time',
          endCount: data?.main?.revisedTimeWithOffset,
        },
      ],
    ],
  });

  const isSSR = typeof window === 'undefined';

  // ADDITIONAL BLOCK - GRAPH
  const additionalBlockConfigDefault = (data) => ({
    title: 'Loan balance chart',
    content: !isSSR && (
      <React.Suspense fallback={<div>...loading</div>}>
        <Chart {...data} />
      </React.Suspense>
    ),
  });

  return (
    <CalculatorTemplate
      {...props}
      defaultValues={defaultValues}
      onHandleStartApplication={handleStartApplication}
      controlsBlockConfigDefault={controlsBlockConfigDefault}
      fieldsDefault={fieldsDefault}
      defaultCalculationData={defaultCalculationData}
      calculatorEndpoint={calculatorEndpoint}
      additionalBlockConfigDefault={additionalBlockConfigDefault}
    />
  );
};

export default HomeLoanOffsetCalculator;
