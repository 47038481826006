import { gql } from '@apollo/client';

export const PROPERTY_FRAGMENTS = {
  property: {
    'property-address': gql`
      fragment Address on Property {
        addressNotKnown
        addressOne
        addressTwo
        city
        state
        postCode
        country
      }
    `,
    'property-purchase-price': gql`
      fragment PurchasePrice on Property {
        purchasePrice
      }
    `,
    'property-deposit-amount': gql`
      fragment DepositAmount on Property {
        depositAmount
      }
    `,
    'property-use': gql`
      fragment PropertyUse on Property {
        investmentOrLiveIn
      }
    `,
    'property-type': gql`
      fragment PropertyType on Property {
        propertyType
      }
    `,
    'property-first-home-buyers-grant': gql`
      fragment FirstHomeBuyersGrant on Property {
        firstHomeBuyers
      }
    `,
    'property-refinance': gql`
      fragment RefinanceCurrentAddress on Application {
        refinanceCurrentAddress
      }
    `,
    'property-refinance-address': gql`
      fragment RefinanceAddress on PropertyRefinance {
        addressOne
        addressTwo
        city
        state
        postCode
        country
      }
    `,
    'property-usage-details': gql`
      fragment UsageDetails on PropertyRefinance {
        propertyValue
        currentUse
        usageChange
        intendedUse
      }
    `,
    flowProgress: gql`
      fragment FlowProgressProperty on Property {
        complete
        totalFields
        completedFields
      }
    `,
  },
};
