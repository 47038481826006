import { gql } from '@apollo/client';

export const EFFI_PRODUCTS = gql`
  query getEffiRates(
    $loanRateType: String
    $loanRepaymentType: String
    $loanPurpose: String
    $skip: Int
    $orderBy: String
    $fixedTermLength: String
    $lvr: Float
    $promotion: [String]
    $isRefinance: Boolean
  ) {
    getEffiRates(
      loanRateType: $loanRateType
      loanRepaymentType: $loanRepaymentType
      loanPurpose: $loanPurpose
      orderBy: $orderBy
      skip: $skip
      fixedTermLength: $fixedTermLength
      lvr: $lvr
      promotion: $promotion
      isRefinance: $isRefinance
    ) {
      EffiRate {
        productId
        bank
        lender
        lenderLogoUrl
        displayName
        featureSummary
        feeSummary
        feeSummaryComponents
        interestRate
        rateType
        comparisonRate
        additionalInfo
        purpose
        promotionSummary
        repaymentType
        lvrMin
        lvrMax
        description
      }
    }
  }
`;
