// extracted by mini-css-extract-plugin
export var section = "n2GcrW8O";
export var progressBarContainer = "+beoX5kP";
export var container = "iFXzzkD5";
export var locked = "IM1q2m+D";
export var verificationContainer = "YFzFCZhV";
export var topContainer = "_94Eub4FO";
export var duration = "hE-yqDUB";
export var topContainerDefault = "QzkpolRz";
export var title = "FtLmkSvB";
export var subtitle = "hrKavh4D";
export var progress = "_6wCW5yPo";
export var bottomContainer = "YaM-tKG1";
export var resendInvite = "jWgXRBEM";