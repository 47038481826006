import { gql } from '@apollo/client';
import { FACT_FIND_FRAGMENTS } from '../fragments/fragments_ff';

const { property: Property } = FACT_FIND_FRAGMENTS;

export const UPDATE_PROPERTY = gql`
  mutation updateProperty(
    $id: String
    $addressOne: String
    $addressTwo: String
    $city: String
    $state: String
    $postCode: String
    $country: String
    $purchasePrice: Int
    $investmentOrLiveIn: String
    $depositAmount: Int
    $propertyType: String
    $firstHomeBuyers: String
    $addressNotKnown: Boolean
  ) {
    updateProperty(
      id: $id
      addressOne: $addressOne
      addressTwo: $addressTwo
      city: $city
      state: $state
      postCode: $postCode
      country: $country
      purchasePrice: $purchasePrice
      investmentOrLiveIn: $investmentOrLiveIn
      depositAmount: $depositAmount
      propertyType: $propertyType
      firstHomeBuyers: $firstHomeBuyers
      addressNotKnown: $addressNotKnown
    ) {
      id
      ...Address
      ...PurchasePrice
      ...DepositAmount
      ...PropertyUse
      ...PropertyType
      ...FirstHomeBuyersGrant
      ...FlowProgressProperty
    }
  }
  ${Property?.['property-address']}
  ${Property?.['property-purchase-price']}
  ${Property?.['property-deposit-amount']}
  ${Property?.['property-use']}
  ${Property?.['property-type']}
  ${Property?.['property-first-home-buyers-grant']}
  ${Property?.flowProgress}
`;
