import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import './styles.scss';

const Image = (props) => {
  const { image, className = '', ...otherProps } = props;

  if (!image) return <div className={`gatsby-image placeholder ${className}`} />;

  if (image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.gatsbyImageData) {
    return (
      <GatsbyImage
        image={image.localFile.childImageSharp.gatsbyImageData}
        className={`gatsby-image ${className}`}
        alt={image.alt || ''}
        {...otherProps}
      />
    );
  }

  if (image.url) {
    return <img className={`gatsby-image ${className}`} src={image.url} alt={image.alt || ''} />;
  }

  return <div className={`gatsby-image placeholder ${className}`} />;
};

export default Image;
