import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { Logo, Link, Section, OnVisible } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './styles.module.scss';

const colOneLinks = {
  title: 'Get in touch',
  links: [
    { text: 'Contact', url: '/contact-us/' },
    { text: 'Email Us', url: 'mailto:support@youbroker.com.au' },
    // { text: 'Buying a home', url: '#' },
    // { text: 'Refinance', url: '#' },
    // { text: 'Careers', url: '#' },
    // { text: 'Blog', url: '/articles/' },
  ],
};

const colTwoLinks = {
  title: 'Company',
  links: [
    { text: 'About', url: '/about-us/' },
    { text: 'Articles', url: '/articles/' },
    { text: 'Careers', url: '/careers/' },
    // { text: 'Refinance my loan', url: '#' },
    // { text: 'Investment home loans', url: '#' },
    // { text: 'Debt consolidation', url: '#' },
    // { text: 'Home loan guides', url: '#' },
  ],
};

const colThreeLinks = {
  title: 'Legal',
  links: [
    { text: 'Business Details', url: '/business-details/' },
    { text: 'Privacy Policy', url: '/privacy/' },
    // { text: 'Terms', url: '#' },
    // { text: 'Credit guide', url: '#' },
    // { text: 'Site map', url: '#' },
  ],
};

// const colFourLinks = [
//   { text: 'Privacy Policy', url: '#' },
//   { text: 'Terms', url: '#' },
//   { text: 'Credit guide', url: '#' },
//   { text: 'Site map', url: '#' },
// ];

const allLinks = [colOneLinks, colTwoLinks, colThreeLinks];

// footerText on settings query
const footerTextQuery = graphql`
  query {
    prismicSettings {
      data {
        footer_text {
          html
          text
        }
      }
    }
  }
`;

const Footer = () => {
  const { prismicSettings } = useStaticQuery(footerTextQuery);
  const { data: footerData } = prismicSettings;
  const { footer_text: footerText } = footerData;
  return (
    <>
      <Section as="footer" className={styles.footer} containerClassName={styles.container}>
        <div className={styles.footerTop}>
          <OnVisible className={styles.logoContainer} visibleClassName={styles.logoContainerVisible}>
            <Logo white />
            <div className={styles.about}>
              <p>
                YouBroker.com.au provides home loan advice you can trust from a expert, ASIC licensed and fully insured
                team.
              </p>
            </div>
          </OnVisible>
          <div className={styles.linkColumns}>
            {allLinks.map((column) => (
              <OnVisible
                className={styles.linkColumn}
                visibleClassName={styles.linkColumnVisible}
                key={`${column?.title}`}
              >
                <div className={styles.linkColumnTitle}>{column?.title}</div>
                {column?.links.map((link) => {
                  const { text, url } = link;
                  return (
                    <Fragment key={text}>
                      <Link className={styles.link} to={url}>
                        {text}
                      </Link>
                    </Fragment>
                  );
                })}
              </OnVisible>
            ))}
          </div>
        </div>
        {footerText?.html && (
          <OnVisible className={styles.footerBottom} visibleClassName={styles.footerBottomVisible}>
            <div dangerouslySetInnerHTML={{ __html: footerText.html }} />
            <p>
              © YouBroker {dayjs().format('YYYY')} - All Rights Reserved -{' '}
              <a href="https://mammoth.tech" title="Made by Mammoth" target="_blank">
                Made by Mammoths
              </a>
            </p>
          </OnVisible>
        )}
      </Section>
    </>
  );
};

export default Footer;
