import React from 'react';
import { Modal } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import './styles.scss';

const VerificationConfirmationModal = (props) => {
  const { activeModal, setActiveModal, verificationStage, handleClick } = props;

  const {
    verificationModalSettings: { data: settingsData },
  } = useStaticQuery(verificationModalSettingsQuery);

  const { verification_authorisation_modal_title: title, verification_authorisation_modal_subtitle: subtitle } =
    settingsData;

  const closeModal = (e) => {
    e.preventDefault();
    setActiveModal(false);
  };

  const verificationStages = {
    userNotVerified: {
      title: "Looks like you haven't completed all of the verfication steps.",
      subtitle: 'Please try again',
      className: 'user-not-verified',
      onClick: closeModal,
    },
    providerLoading: {
      title: "Looks like we're still awaiting for a response from our third parties.",
      altTitle: 'This may take a couple of minutes.',
      subtitle: 'Go back to verification steps',
      className: 'user-not-verified',
      onClick: closeModal,
    },
    submittedAndUserNotVerified: {
      title:
        "We're pre-populating your application with data from our providers. This will make your application process faster and require less data entry. Winner winner 🐔🍽️",
      subtitle: 'Please try again',
      className: 'user-not-verified',
      onClick: closeModal,
    },
    idVerificationAuth: {
      title:
        'YouBroker will do a no impact credit check free of charge. Best of all, we will pull your information back and smartfill your application, saving you time.',
      subtitle: subtitle?.text,
    },
  };

  const onClickHandler = (e) => {
    if (!verificationStages?.[verificationStage]?.onClick) return handleClick();

    return verificationStages?.[verificationStage]?.onClick(e);
  };

  return (
    title && (
      <Modal
        active={activeModal}
        closeModal={closeModal}
        title={verificationStages[verificationStage]?.title}
        altTitle={verificationStages[verificationStage]?.altTitle}
        subtitle={verificationStages[verificationStage]?.subtitle}
        className={`verification-confirmation-modal ${verificationStages[verificationStage]?.className || ''}`}
        onClick={onClickHandler}
      />
    )
  );
};

export default VerificationConfirmationModal;

export const verificationModalSettingsQuery = graphql`
  query verificationModalSettingsQuery {
    verificationModalSettings: prismicSettings {
      data {
        verification_authorisation_modal_title {
          html
          text
        }
        verification_authorisation_modal_subtitle {
          html
          text
        }
      }
    }
  }
`;
