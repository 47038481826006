import React from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql } from 'gatsby';
import { FormFlow, AppLayout } from 'components';
import { useQuery } from '@apollo/client';
import Cookies from 'js-cookie';
import { IS_USER_LOGGED_IN } from '../apollo/queries/queries_misc';
import { GET_APPLICANT_CORE_DATA } from '../apollo/queries/queries_ff';
import populateFields from '../slices/Questions/utils/populateFields';
import './lead-flow.scss';

const LeadFlow = (props) => {
  const { data, apolloClient, location } = props;
  const { prismicLeadFlow } = data;
  const {
    data: leadFlowData,
    first_publication_date: publishedDate,
    last_publication_date: updatedDate,
  } = prismicLeadFlow;
  const {
    questions,
    two_factor_auth: twoFactor,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
  } = leadFlowData;

  const seo = {
    title: metaTitle?.text || 'YouBroker: get started comparing 3,129 mortgage products',
    desc: metaDesc?.text || '',
    banner: ogImage?.url,
    publishedDate,
    updatedDate,
  };

  const { data: authCheckData } = useQuery(IS_USER_LOGGED_IN);

  const handleAppplicantCoreData = () => {
    if (authCheckData?.isLoggedIn) {
      const response = apolloClient.cache.readQuery({
        query: GET_APPLICANT_CORE_DATA,
        variables: { email: Cookies.get('yb-email') },
      });
      return response;
    }
    return {};
  };

  const extraData = {
    isLoggedIn: authCheckData?.isLoggedIn,
    applicantCoreData: handleAppplicantCoreData(),
  };

  // Load fields
  const fields = populateFields({ questions });

  return (
    <AppLayout showTrustFactors stepIndex="Basic information" seo={seo} location={location}>
      <section className="lead-flow-page">
        <FormFlow fields={fields} hasTwoFactor={twoFactor} apolloClient={apolloClient} extraData={extraData} />
      </section>
    </AppLayout>
  );
};

export default withPrismicPreview(LeadFlow);

export const pageQuery = graphql`
  query LeadFlowBySlug($uid: String) {
    prismicLeadFlow(uid: { eq: $uid }) {
      _previewable
      id
      uid
      prismicId
      first_publication_date
      last_publication_date
      data {
        meta_description {
          text
          html
        }
        meta_title {
          text
          html
        }
        open_graph_image {
          url
        }
        two_factor_auth
        questions {
          question
          description {
            html
            text
          }
          title {
            html
            text
          }
        }
        lead_flow_image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
