import { defaultOnSubmit } from '../../../utils/defaultOnSubmit';

export const onSubmit = async ({ formData, apolloClient, extraData, dataLayer }) => {
  const allUsers = extraData?.Users;
  const primaryApplicant = allUsers?.find((user) => user?.primaryApplicant);

  if (primaryApplicant?.currentFlowStep === 4) {
    // To get to this point user must have completed all previous fact find steps
    dataLayer?.track('Fact Find Completed');
  }

  return defaultOnSubmit({ formData, apolloClient, extraData });
};
