export default [
  {
    type: 'group',
    name: 'whatsImportantLoan',
    namePrismic: 'Mortgage - Whats Important Loan',
    slug: 'mortgage-whats-important-loan',
    showGroupValidations: false,
    subfields: [
      {
        type: 'checkbox',
        name: 'whatsImportantLoan',
        placeholder: 'Select one',
        label: '',
        defaultValue: 'Best overall mix of value',
        options: [
          { text: 'Best overall mix of value' },
          { text: 'Lowest overall cost' },
          { text: 'Lowest rate variable loan' },
          { text: 'Lowest rate fixed loan' },
          { text: 'Other' },
        ],
      },
      {
        type: 'text', // conditional field
        name: 'whatsImportantLoanOther',
        placeholder: "What's important in your loan selection?",
        label: 'Please specify',
        conditional: ({ formData }) => {
          if (formData?.whatsImportantLoan?.includes('Other')) return true;

          return false;
        },
        validationMessage: 'Please enter details',
      },
    ],
  },
];
