// extracted by mini-css-extract-plugin
export var container = "GJzkDHLI";
export var titleContainer = "_0bJkyWt1";
export var icon = "r4G79Kcn";
export var iconVisible = "_2KeRSZJI";
export var title = "kN6u+sqx";
export var titleVisible = "_1CX2MPF8";
export var content = "h3dDvu3Q";
export var contentVisible = "mkPSgi4b";
export var cta = "vz2Ab2TS";
export var ctaVisible = "nYIQEnnT";
export var section = "GTBrz3BP";
export var lightBlueBg = "fpaQzU2V";
export var darkBlueBg = "_3m-vbdai";
export var narrow = "leFz1mDH";