import React from 'react';
import { Section, Image, OnVisible } from 'components';
import './styles.scss';

const TwoColsStepsImage = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { image, title } = primary;

  // content checks
  const titleHtml = title?.html;
  const hasImage = image?.url;

  return (
    <Section className="two-cols-steps-image">
      {hasImage && (
        <div className="two-cols-steps-image-image">
          <Image image={image} />
        </div>
      )}
      <div className="two-cols-steps-image-text">
        {titleHtml && <div className="two-cols-steps-image-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {/* IMAGE */}
        {/* STEPS */}
        {items.length > 0 && (
          <div className="two-cols-steps-image-step-container">
            {items.map((item, index) => {
              const { step_content: stepContent, step_title: stepTitle } = item;
              return (
                <OnVisible className="two-cols-steps-image-step" key={stepTitle?.text}>
                  {/* STEP NUMBER */}
                  <div className="two-cols-steps-image-step-number">
                    <span>{index + 1}.</span>
                  </div>
                  {/* STEP TEXT */}
                  <div className="two-cols-steps-image-step-text">
                    {stepTitle?.text && (
                      <div
                        className="two-cols-steps-image-step-title"
                        dangerouslySetInnerHTML={{ __html: stepTitle?.html }}
                      />
                    )}
                    {stepContent?.text && (
                      <div
                        className="two-cols-steps-image-step-content"
                        dangerouslySetInnerHTML={{ __html: stepContent?.html }}
                      />
                    )}
                  </div>
                </OnVisible>
              );
            })}
          </div>
        )}
      </div>
    </Section>
  );
};

export default TwoColsStepsImage;
