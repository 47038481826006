import React from 'react';
import * as styles from './styles.module.scss';

const CtaArrow = ({ className, fill }) => (
  <span className={`${styles.arrow} ${className}`}>
    <svg
      className={styles.arrowSvg}
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill={fill || '#FFFFFF'}
    >
      <path d="M85 277.375h259.704L225.002 397.077 256 427l171-171L256 85l-29.922 29.924 118.626 119.701H85v42.75z" />
    </svg>
  </span>
);
export default CtaArrow;
