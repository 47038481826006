import React, { useState, createContext } from 'react';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [leadFlowStepProgress, setLeadFlowStepProgress] = useState(null);
  const [createShortlistCount, setCreateShortlistCount] = useState(0);
  const [inviteAdditionalApplicantProgress, setInviteAdditionalApplicantProgress] = useState(0);

  return (
    <GlobalContext.Provider
      value={{
        leadFlowStepProgress,
        setLeadFlowStepProgress,
        createShortlistCount,
        setCreateShortlistCount,
        inviteAdditionalApplicantProgress,
        setInviteAdditionalApplicantProgress,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
