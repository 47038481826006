import React from 'react';
import * as styles from './styles.module.scss';

const LoadingSpinner = (props) => {
  const { className } = props;
  const classes = `${styles.spinner} ${className}`;
  return  <span className={classes}>&nbsp;</span>;
}

export default LoadingSpinner;
