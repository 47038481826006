import React from 'react';
import { PrismicPreviewProvider } from 'components';
import { FactFindProvider } from '../src/contexts/FactFindContext';
import { GlobalProvider } from '../src/contexts/GlobalContext';
import { ApolloProviderWrapper } from '../src/apollo/ApolloProviderWrapper/index';

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>
    <FactFindProvider>
      <PrismicPreviewProvider element={element} />
    </FactFindProvider>
  </GlobalProvider>
);

export const wrapPageElement = ({ element, props }) => {
  if (props?.pageContext?.apolloWrapperRequired) {
    return <ApolloProviderWrapper>{element}</ApolloProviderWrapper>;
  }

  return element;
};
