export const approvedLenders = [
  {
    label: '86 400',
    value: '86400',
  },
  {
    label: 'AMP Bank [AU]',
    value: 'amp-bank',
  },
  {
    label: 'ANZ',
    value: 'anz',
  },
  {
    label: 'Australian Military Bank',
    value: 'australian-military-bank',
  },
  {
    label: 'Auswide Bank',
    value: 'auswide-bank',
  },
  {
    label: 'Bank of China',
    value: 'bank-of-china',
  },
  {
    label: 'Bank of Melbourne',
    value: 'bank-of-melbourne',
  },
  {
    label: 'Bank of Queensland',
    value: 'bank-of-queensland',
  },
  {
    label: 'BankSA',
    value: 'banksa',
  },
  {
    label: 'Bankwest',
    value: 'bankwest',
  },
  {
    label: 'Citibank AU',
    value: 'citibank',
  },
  {
    label: 'Commonwealth Bank',
    value: 'commonwealth-bank',
  },
  {
    label: 'Firefighters Mutual Bank',
    value: 'firefighters-mutual-bank',
  },
  {
    label: 'Firstmac',
    value: 'firstmac',
  },
  {
    label: 'Gateway Bank',
    value: 'gateway-bank',
  },
  {
    label: 'Health Professionals Bank',
    value: 'health-professionals-bank',
  },
  {
    label: 'Heritage Bank',
    value: 'heritage-bank',
  },
  {
    label: 'Homestar Finance',
    value: 'homestar-finance',
  },
  {
    label: 'ING',
    value: 'ing',
  },
  {
    label: 'La Trobe Financial',
    value: 'la-trobe-financial',
  },
  {
    label: 'Macquarie Bank',
    value: 'macquarie-bank',
  },
  {
    label: 'ME Bank',
    value: 'me-bank',
  },
  {
    label: 'MyState Bank',
    value: 'mystate-bank',
  },
  {
    label: 'NAB',
    value: 'nab',
  },
  {
    label: 'Newcastle Permanent Building Society',
    value: 'newcastle-permanent',
  },
  {
    label: 'P&N Bank',
    value: 'p-n-bank',
  },
  {
    label: 'Pepper',
    value: 'pepper',
  },
  {
    label: 'Resimac',
    value: 'resimac',
  },
  {
    label: 'St. George Bank',
    value: 'st-george-bank',
  },
  {
    label: 'Suncorp',
    value: 'suncorp-bank',
  },
  {
    label: 'Teachers Mutual Bank',
    value: 'teachers-mutual-bank',
  },
  {
    label: 'UniBank',
    value: 'unibank',
  },
  {
    label: 'Virgin Money Australia',
    value: 'virgin-money',
  },
  {
    label: 'Westpac',
    value: 'westpac',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
