import React from 'react';
import { Link, OnVisible, BlogPostCard } from 'components';

import './styles.scss';

const PostGrid = (props) => {
  const { posts, className, title, showExcerpt, hasFeaturedPost, titleLink } = props;
  if (!posts || posts.length < 1) return null;

  return (
    <section className={`post-grid ${className || ''}`} key={title}>
      <div className="wrapper">
        {title && (
          <OnVisible className="post-grid-header-container">
            <Link to={titleLink} className="post-grid-title">
              {title}
            </Link>
            {titleLink && (
              <Link
                to={titleLink}
                className="post-grid-header-link"
                arrowClassName="post-grid-header-link-arrow"
                withChevron
              >
                View all posts
              </Link>
            )}
          </OnVisible>
        )}
        <div className="post-grid-container">
          {posts.length > 0 &&
            posts.map((post, index) => (
              <BlogPostCard
                key={post?.uid}
                data={post?.data}
                uid={post?.uid}
                className={className}
                index={index}
                showExcerpt={showExcerpt || (hasFeaturedPost && index === 0)}
                isMainFeaturedPost={hasFeaturedPost && index === 0}
                dataString={JSON.stringify(post?.dataRaw || {})}
              />
            ))}
        </div>
      </div>
    </section>
  );
};
export default PostGrid;
