import React, { useEffect } from 'react';
import { animate } from 'motion';
import CountUp from 'react-countup';
import * as styles from './styles.module.scss';
import { useDoughnutProgressCalculator } from './useDoughnutProgressCalculator';

const ProgressDoughnut = (props) => {
  const { radius = 64, stroke = 8, applicantCoreData } = props;

  const calculatedProgress = useDoughnutProgressCalculator(applicantCoreData) || 0;
  const startCalculatedProgress = calculatedProgress <= 30 ? 0 : calculatedProgress - 30;

  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffsetOuterRing = circumference - 1 * circumference;

  const startStrokeDashoffset = 302;
  const strokeDashoffset = circumference - (calculatedProgress / 100) * circumference;

  useEffect(() => {
    animate(
      '.inner-ring circle',
      {
        strokeDashoffset: [startStrokeDashoffset, strokeDashoffset],
      },
      {
        strokeDashoffset: {
          duration: 0.5,
        },
        easing: 'linear',
      }
    );
  }, [strokeDashoffset]);

  return (
    <div className={`${styles.container}`} id="progress-doughnut">
      <div className={styles.ringContainer}>
        <CountUp
          start={startCalculatedProgress}
          end={calculatedProgress}
          duration={0.5}
          className={styles.progressCount}
          suffix="%"
        />
        <svg height={radius * 2} width={radius * 2} style={{ opacity: 0.1 }}>
          <circle
            stroke="#5BEFDF"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            style={{ strokeDashoffset: strokeDashoffsetOuterRing }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
        <svg height={radius * 2} width={radius * 2} style={{ position: 'absolute' }} className="inner-ring">
          <circle
            stroke="#5BEFDF"
            strokeLinecap="round"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
      </div>
      <div className={styles.progressCta}>Profile strength</div>
    </div>
  );
};

export default ProgressDoughnut;
