import React, { useState } from 'react';
import SecondaryButton from './SecondaryButton';
import './styles.scss';

const FormFieldNavigation = (props) => {
  const {
    onBack,
    submitting,
    submitButtonRef,
    submitButtonText,
    handleSubmit,
    secondaryButtonConfig,
    disabled = false,
    hideNavigation,
  } = props;

  const [loading, setLoading] = useState(false);

  const onSubmitHandler = async () => {
    if (handleSubmit) {
      setLoading(true);
      await handleSubmit();
      setLoading(false);
    }
  };

  if (hideNavigation) return null;

  const isDisabled = loading || submitting || disabled;

  return (
    <div className="form-field-navigation" style={{ marginTop: secondaryButtonConfig ? '0' : '40px' }}>
      <div className="navigation-buttons-container">
        <button type="button" className="form-go-back button outline" onClick={onBack} disabled={loading}>
          Back
        </button>
        <button
          type="submit"
          className="form-next button"
          disabled={isDisabled}
          ref={submitButtonRef}
          onClick={onSubmitHandler}
        >
          {submitting || loading ? <span className="loader">&nbsp;</span> : submitButtonText || 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default FormFieldNavigation;
