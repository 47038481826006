import React from 'react';
import './styles.scss';

const Section = props => {
  const { className, children, noWrapper, containerClassName, scrollRef, as } = props;
  const Tag = as || 'section';
  return (
    <Tag className={`section ${className || ''}`} ref={scrollRef}>
      {noWrapper ? children : <div className={`wrapper ${containerClassName || ''}`}>{children}</div>}
    </Tag>
  );
};

export default Section;
