import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import useWindowSize from '../../../hooks/useWindowSize';
import FieldContainer from '../Fields/_FieldCommon/FieldContainer';
import FieldLabel from '../Fields/_FieldCommon/FieldLabel';
import FieldError from '../Fields/_FieldCommon/FieldError';
import Fields from '../Fields';
import { commonValidations } from '../helpers/validations';

const FormField = (props) => {
  const {
    type,
    name,
    label,
    altLabel,
    validationErrors,
    validationMessage,
    validation,
    conditional,
    className,
    watch,
    index,
    extraData,
    onMount,
    autoFocus: defaultAutofocus = true,
    defaultValue,
    showGroupValidations,
    clearErrors,
  } = props;

  // Set autofocus paramter
  const size = useWindowSize();
  const autoFocus = defaultAutofocus && size.width > 1024;

  useEffect(() => {
    if (onMount) {
      onMount({ extraData });
    }
  }, []);

  const isLoggedIn = !!Cookies.get('yb-token');

  // Conditional Parameters
  let conditionalDisplay;
  let conditionalValidation = validation || commonValidations({ fieldName: name, fieldType: type, validationMessage });
  let conditionalClassName = className;
  let conditionalDefaultValue = defaultValue;
  let conditionalShowGroupValidations = showGroupValidations;

  // Conditional Paramters
  if (
    typeof validation === 'function' ||
    typeof className === 'function' ||
    conditional ||
    typeof defaultValue === 'function'
  ) {
    const formData = watch && watch(); // Call watch once to avoid re-renders
    if (typeof conditional === 'function')
      conditionalDisplay = conditional({ formData, extraData, index, clearErrors, validationErrors, isLoggedIn });
    if (typeof validation === 'function')
      conditionalValidation = validation({ formData, extraData, index, clearErrors, validationErrors, isLoggedIn });
    if (typeof className === 'function')
      conditionalClassName = className({ formData, extraData, index, clearErrors, validationErrors, isLoggedIn });
    if (typeof defaultValue === 'function')
      conditionalDefaultValue = defaultValue({ formData, extraData, index, clearErrors, validationErrors, isLoggedIn });
    if (typeof showGroupValidations === 'function')
      conditionalShowGroupValidations = showGroupValidations({
        formData,
        extraData,
        index,
        clearErrors,
        validationErrors,
      });
  }

  if (conditional && !conditionalDisplay) return null;

  // Load field component as per 'type' value
  const FieldTypeComponent = Fields[type];
  if (!FieldTypeComponent) return null;
  const hasErrors = validationErrors && Object.keys(validationErrors).length > 0;

  return (
    <FieldContainer
      name={name}
      type={type}
      className={`${conditionalClassName || ''} ${hasErrors ? 'hasError' : ''} field-container`}
    >
      <FieldLabel name={name} label={label} />
      <FieldLabel name={name} altLabel={altLabel} />
      <FieldTypeComponent
        {...props}
        className={conditionalClassName}
        autoFocus={autoFocus}
        validation={conditionalValidation}
        defaultValue={conditionalDefaultValue}
        showGroupValidations={conditionalShowGroupValidations}
      />
      {!showGroupValidations && (
        <FieldError validationErrors={validationErrors} validationMessage={validationMessage} />
      )}
    </FieldContainer>
  );
};

FormField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  validation: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  validationMessage: PropTypes.string,
  hasError: PropTypes.bool,
  onSubmit: PropTypes.func,
  register: PropTypes.func.isRequired,
  goNext: PropTypes.func,
  goPrev: PropTypes.func,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
};

FormField.defaultProps = {
  type: 'text',
  label: null,
  className: '',
  validationMessage: null,
  hasError: false,
  onSubmit: null,
  validation: null,
  goNext: null,
  goPrev: null,
};

export default FormField;
