import { navigate } from 'gatsby';
import { REACTIVE_VARS } from '../../../../../apollo/config/reactiveVars';
import { FACT_FIND_FRAGMENTS } from '../../../../../apollo/fragments/fragments_ff';
import { onSubmit } from './onSubmit';

const { currentApplicantVar } = REACTIVE_VARS;
const { 'about-you': aboutUser } = FACT_FIND_FRAGMENTS;
const { 'about-address': addressHistory } = aboutUser;

export default [
  {
    type: 'radio',
    name: 'refinanceCurrentAddress',
    namePrismic: 'Property - Refinance',
    slug: 'property-refinance',
    goNext: ({ formData }) => {
      if (formData?.refinanceCurrentAddress === 'No') {
        return navigate('?question=property-refinance-address');
      }

      return navigate('?question=property-usage-details');
    },
    options: [{ text: 'Yes' }, { text: 'No' }],
    onSubmit,
    onMount: ({ extraData }) => {
      const { apolloConfig, currentApplicant } = extraData;
      const { apolloClient } = apolloConfig;
      const aboutUserId = currentApplicant?.aboutUser?.id;

      const storedCachedData = apolloClient?.cache?.readFragment({
        id: `AboutUser:${aboutUserId}`,
        fragment: addressHistory,
      });

      const currentAddress = storedCachedData?.addressHistory?.[0];

      const { addressOne, addressTwo, city, postCode, country, state } = currentAddress;

      currentApplicantVar({
        ...currentApplicantVar(),
        addressOne,
        addressTwo,
        city,
        postCode,
        country,
        state,
      });
    },
  },
];
