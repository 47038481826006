import React from 'react';
import classNames from 'classnames';
import { useFormatTitleWithApplicantData } from './useFormatTitleWithApplicantData';
import { useFormatDescriptionWithApplicantData } from '../../../hooks/useFormatDescriptionWithApplicantData';
import * as styles from './styles.module.scss';

const FormFieldHeader = (props) => {
  const { title, description, className, alignCenter, nextApplicant } = props;
  const classes = classNames(styles.header, { [styles.alignCenter]: alignCenter }, className);

  const formattedTitle = useFormatTitleWithApplicantData({ title });
  const formattedDescription = useFormatDescriptionWithApplicantData({ description, nextApplicant });

  return (
    <div className={classes}>
      {formattedTitle?.html && (
        <span className={styles.title} dangerouslySetInnerHTML={{ __html: formattedTitle?.html }} />
      )}
      {formattedDescription && <span className={styles.description}>{formattedDescription}</span>}
    </div>
  );
};

export default FormFieldHeader;
