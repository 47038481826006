import { isBrowser } from '../../../../../utils/isBrowser';
import { CREATE_PROPERTY_REFINANCE } from '../../../../../apollo/mutations/mutations_property_refinance_ff';

export const onSubmit = async ({ formData, apolloClient, extraData }) => {
  const { applicantCoreData } = extraData;
  const applicationId = applicantCoreData?.currentUser?.application?.id;

  if (formData?.ownOtherProperties === 'Yes') {
    const response = await apolloClient.mutate({
      mutation: CREATE_PROPERTY_REFINANCE,
      variables: {
        propertyRefinance: [
          {
            applicationId,
          },
        ],
      },
      update: (cache, { data }) => {
        const dbRefinancedProperties = data?.createPropertyRefinance?.RefinancedProperties;
        const newRefinancedProperty = dbRefinancedProperties?.[dbRefinancedProperties?.length - 1];
        const newRefinancedPropertyCacheRef = { __ref: `PropertyRefinance:${newRefinancedProperty?.id}` };

        const cacheObjToModify = cache.identify({ id: applicationId, __typename: 'Application' });
        cache.modify({
          id: cacheObjToModify,
          fields: {
            PropertyRefinance(cachedData) {
              return [...cachedData, newRefinancedPropertyCacheRef];
            },
          },
        });
      },
    });

    const refinancedProperties = response?.data?.createPropertyRefinance?.RefinancedProperties;
    const newProperty = refinancedProperties?.[refinancedProperties?.length - 1];

    if (isBrowser) {
      localStorage.setItem('yb-current-property-id', newProperty?.id);
    }
  }
};
