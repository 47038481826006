import React from 'react';
import { ReactComponent as Plus } from '../../../../../images/add-row-field.svg';
import { ReactComponent as Dash } from '../../../../../images/dash.svg';
import * as styles from './styles.module.scss';

const FieldArrayButtonAdd = (props) => {
  const { label = 'Add', hide, onClick, expanded = false, disabled = false } = props;

  const onClickHandler = () => {
    if (disabled) return null;

    if (onClick) onClick();
  };

  if (hide) return null;

  return (
    <button
      type="button"
      onClick={onClickHandler}
      className={`${styles.button} ${expanded ? styles.expanded : ''} field-array-button-add`}
    >
      {label}
      {expanded ? <Dash className={styles.icon} /> : <Plus className={styles.icon} />}
    </button>
  );
};

export default FieldArrayButtonAdd;
