import { useState, useEffect, useContext } from 'react';
import GlobalContext from '../../../contexts/GlobalContext';

export const useDoughnutProgressCalculator = (applicantCoreData) => {
  const [progress, setProgress] = useState(0);

  const additionalUsers = applicantCoreData?.currentUser?.leadApplication?.additionalUsers;
  const hasAdditionalApplicants = additionalUsers?.length > 0;
  const invitedAllAdditionalUsers = !additionalUsers?.find((additionalUser) => additionalUser?.inviteSent === false);

  const users = applicantCoreData?.currentUser?.application?.Users || [];
  const usersSorted = [...users]?.sort((a, b) => b?.primaryApplicant - a?.primaryApplicant);
  const primaryApplicant = usersSorted?.[0];
  const application = applicantCoreData?.currentUser?.application;
  const userIsVerified = applicantCoreData?.currentUser?.userVerified;
  const isRefinancing = applicantCoreData?.currentUser?.application?.isRefinance;

  const { leadFlowStepProgress, createShortlistCount, inviteAdditionalApplicantProgress } =
    useContext(GlobalContext) || {};

  const sectionWeights = {
    'get-started': 16,
    'create-your-shortlist': 16,
    'invite-other-applicants': hasAdditionalApplicants ? 8 : 0,
    'verify-your-details': hasAdditionalApplicants ? 36 : 40,
    'tell-us-more-about-you': hasAdditionalApplicants ? 24 : 28,
    'get-approved': 0,
  };

  const maxSectionProgress = (section) => sectionWeights?.[section];

  const sectionProgress = (val, section) => Math.ceil((val / 100) * maxSectionProgress(section));

  const applicationSections = {
    'get-started': (() => {
      // User has already completed lead flow
      if (applicantCoreData) return maxSectionProgress('get-started');

      return sectionProgress(leadFlowStepProgress, 'get-started');
    })(),
    'create-your-shortlist': (() => {
      const applicantHasMortgageShortlist =
        applicantCoreData?.currentUser?.application?.MortgageShortlist?.length === 3;

      if (applicantHasMortgageShortlist) return maxSectionProgress('create-your-shortlist');

      const percentageOfCreateShortlistComplete = (createShortlistCount / 3) * 100;

      return sectionProgress(percentageOfCreateShortlistComplete);
    })(),
    'invite-other-applicants': (() => {
      if (!hasAdditionalApplicants) return 0;

      if (invitedAllAdditionalUsers) return maxSectionProgress('invite-other-applicants');

      return sectionProgress(inviteAdditionalApplicantProgress, 'invite-other-applicants');
    })(),
    'verify-your-details': (() => {
      if (!applicantCoreData) return 0;

      if (userIsVerified) return maxSectionProgress('verify-your-details');

      const totalUsers = users?.length;

      const usersVerificationCount = users?.reduce((prev, current, index, arr) => {
        const applicantHasIdVerified = arr[index]?.aboutUser?.idVerified ? 0.5 : 0;
        const applicantHasSubmittedBankStatments = arr[index]?.aboutUser?.bankStatementsReceived ? 0.5 : 0;

        return prev + applicantHasIdVerified + applicantHasSubmittedBankStatments;
      }, 0);

      const percentageOfUsersVerified = (usersVerificationCount / totalUsers) * 100;

      return sectionProgress(percentageOfUsersVerified, 'verify-your-details');
    })(),
    'tell-us-more-about-you': (() => {
      if (!userIsVerified) return 0;

      const expensesComplete = primaryApplicant?.Expenses?.complete;

      if (expensesComplete) return maxSectionProgress('tell-us-more-about-you');

      const abouUserFieldsTotalFields = primaryApplicant?.aboutUser?.totalFields;
      const expensesFieldsTotalFields = primaryApplicant?.Expenses?.totalFields;
      const propertyFieldsTotalFields = application?.Property?.totalFields;
      const propertyRefinanceFieldsTotalFields = 4;
      const mortgageFieldsTotalFields = application?.MortgagePreference?.totalFields;

      const factFindFieldsTotalFields = [
        abouUserFieldsTotalFields,
        expensesFieldsTotalFields,
        isRefinancing ? propertyRefinanceFieldsTotalFields : propertyFieldsTotalFields,
        mortgageFieldsTotalFields,
      ]?.reduce((a, b) => a + b, 0);

      const abouUserFieldsCount = primaryApplicant?.aboutUser?.completedFields;
      const expensesFieldsCount = primaryApplicant?.Expenses?.completedFields;
      const mortgageFieldsCount = application?.MortgagePreference?.completedFields;

      const PropertyRefinance = application?.PropertyRefinance?.[0];
      const hasPropertyValue = !!PropertyRefinance?.propertyValue;
      const hasAddress = !!PropertyRefinance?.addressOne;
      const hasCurrentUse = !!PropertyRefinance?.currentUse;
      const hasUsageChange = !!PropertyRefinance?.usageChange;
      const requiredFields = [hasPropertyValue, hasAddress, hasCurrentUse, hasUsageChange];
      const propertyRefinanceFieldsCount = requiredFields?.filter((val) => val)?.length;

      const propertyFieldsCount = isRefinancing ? propertyRefinanceFieldsCount : application?.Property?.completedFields;

      const factFindFieldsCount = [
        abouUserFieldsCount,
        expensesFieldsCount,
        propertyFieldsCount,
        mortgageFieldsCount,
      ]?.reduce((a, b) => a + b, 0);

      const percentageOfFactFindComplete = (factFindFieldsCount / factFindFieldsTotalFields) * 100;

      return sectionProgress(percentageOfFactFindComplete, 'tell-us-more-about-you');
    })(),
    'get-approved': (() => {
      const getApprovedSubmitted = applicantCoreData?.currentUser?.application?.Approval?.termsConditionsAccepted;

      if (getApprovedSubmitted) return maxSectionProgress('get-approved');

      return 0;
    })(),
  };

  useEffect(() => {
    setProgress(Object?.values(applicationSections)?.reduce((a, b) => a + b, 0));
  }, [applicationSections]);

  return progress;
};
