const idScanVerification = process.env.GATSBY_FRANKIE_ONE_BIOMETRICS;

export const FRANKIE_URL =
  process.env.GATSBY_YOUBROKER_SERVER === 'production'
    ? process.env.GATSBY_FRANKIE_SMART_WIDGET_PROD_URL
    : process.env.GATSBY_FRANKIE_SMART_WIDGET_DEMO_URL;

export const FRANKIE_ENCODED_CREDENTIALS =
  process.env.GATSBY_YOUBROKER_SERVER === 'production'
    ? process.env.GATSBY_FRANKIE_ENCODED_CREDENTIALS_PROD
    : process.env.GATSBY_FRANKIE_ENCODED_CREDENTIALS_DEMO;

export const FRANKIE_HEADERS = { authorization: `machine ${FRANKIE_ENCODED_CREDENTIALS}` };

export const FRANKIE_CONFIG = {
  // mode: "demo", // remove the mode when you are testing with the API, demo mode wont make API calls to the service
  frankieBackendUrl: FRANKIE_URL,
  documentTypes: ['PASSPORT', 'DRIVERS_LICENCE', 'NATIONAL_HEALTH_ID'],
  idScanVerification: typeof idScanVerification !== 'undefined',
  checkProfile: 'auto',
  maxAttemptCount: 5,
  googleAPIKey: false,
  acceptedCountries: ['AUS', 'NZL'],
  requestAddress: true,
  injectedCss: `
    #ff-onboarding-widget {
      border-radius: 16px;
      padding: 32px !important;
    }

    #ff-onboarding-widget .ff-form .ff-form-overlay {
      z-index: 0 !important;
    }

    .ff-basic-button, .ff-submit-button, .ff-cancel {
      background-color: #3C38D6 !important;
      border-color: #3C38D6 !important;
      color: #FFFFFF !important;
    }

    .ff-submit-button, .ff-basic-button {
      border-radius: 66px !important;
    }

    .ff-text-link {
      color: #10156B !important;
    }

    .ff-scrollable::-webkit-scrollbar {
      display: none;
    }

    .ff-scrollable {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .ff-buttton-wrapper {
      box-shadow: none !important;
    }

    .ff-consent-check:hover {
      background-color: transparent !important;
    }
  `,
  consentText: "I agree with the terms described in the Consent section of the Company's webpage",
};
