import React from 'react';
import { Image, Section, OnVisible, Link, GetStarted } from 'components';
import * as styles from './styles.module.scss';

const TwoColsImageText = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    content,
    image,
    primary_title: primaryTitle,
    secondary_title: SecondaryTitle,
    contain_image: containImage,
    cta_option: ctaOption,
  } = primary;

  // content checks

  const titleOneHtml = primaryTitle?.html;
  const titleTwoHtml = SecondaryTitle?.html;
  const contentHtml = content?.html;
  const hasImage = image?.url;

  return (
    <Section className={styles.section}>
      <OnVisible className={styles.container} visibleClassName={styles.containerVisible}>
        {/* TEXT */}
        <div className={styles.left}>
          {/* TITLES */}
          <div>
            {titleOneHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleOneHtml }} />}
            {titleTwoHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleTwoHtml }} />}
          </div>
          {/* CONTENT */}
          {contentHtml && <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
          <div className={styles.cta}>
            {ctaOption === 'Refinance or Purchase' && <GetStarted />}
            {ctaOption === 'Email Us' && (
              <Link to="mailto:office@youbroker.com.au" className="button">
                Email us
              </Link>
            )}
          </div>
        </div>
        {/* IMAGE */}
        <div className={styles.right}>
          {hasImage && <Image className={`${styles.image} ${containImage ? styles.containImage : ''}`} image={image} />}
        </div>
      </OnVisible>
    </Section>
  );
};

export default TwoColsImageText;
