import FieldArray from './FieldArray';
import FieldCheckbox from './FieldCheckbox';
import FieldCurrency from './FieldCurrency';
import FieldDate from './FieldDate';
import FieldEmail from './FieldEmail';
import FieldGoogleMap from './FieldGoogleMap';
import FieldHidden from './FieldHidden';
import FieldModal from './FieldModal';
import FieldNumber from './FieldNumber';
import FieldRadio from './FieldRadio';
import FieldRange from './FieldRange';
import FieldSelect from './FieldSelect';
import FieldText from './FieldText';
import FieldTextArea from './FieldTextarea';
import FieldGroup from './FieldGroup';
import FieldTwoFactors from './FieldTwoFactors';
import FieldArrayAccordion from './FieldArrayAccordion';
import FieldTotal from './FieldTotal';
import FieldMessage from './FieldMessage';
import FieldDraggableMortgageShortlist from './FieldDraggableMortgageShortlist';
import FieldDraggable from './FieldDraggable';
import FieldArrayMulti from './FieldArrayMulti';
import FieldPropertiesReview from './FieldPropertiesReview';
import FieldQuickMessage from './FieldQuickMessage';
import FieldToggleButtons from './FieldToggleButtons';
import './_FieldCommon/stylesCommon.scss';

export default {
  array: FieldArray,
  arrayMulti: FieldArrayMulti,
  additionalApplicants: FieldArray,
  addressSearch: FieldGoogleMap,
  checkbox: FieldCheckbox,
  currency: FieldCurrency,
  date: FieldDate,
  hidden: FieldHidden,
  modal: FieldModal,
  number: FieldNumber,
  email: FieldEmail,
  radio: FieldRadio,
  radios: FieldRadio,
  range: FieldRange,
  select: FieldSelect,
  text: FieldText,
  textarea: FieldTextArea,
  toggleButtons: FieldToggleButtons,
  twoFactorModal: FieldTwoFactors,
  group: FieldGroup,
  fieldGroup: FieldGroup,
  arrayAccordion: FieldArrayAccordion,
  total: FieldTotal,
  message: FieldMessage,
  draggableMortgageShortlist: FieldDraggableMortgageShortlist,
  draggableField: FieldDraggable,
  propertiesReviewFields: FieldPropertiesReview,
  quickMessage: FieldQuickMessage,

  // @TODO Change input undefined/string types to 'text'
  string: FieldText,
};
