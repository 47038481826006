import { UPDATE_APPLICATION } from '../../../../../apollo/mutations/mutations_misc';
import { UPDATE_PROPERTY_REFINANCE } from '../../../../../apollo/mutations/mutations_property_refinance_ff';
import { isBrowser } from '../../../../../utils/isBrowser';

export const onSubmit = async ({ formData, apolloClient, extraData }) => {
  const { applicantCoreData } = extraData;
  const applicationId = applicantCoreData?.currentUser?.application?.id;
  const users = applicantCoreData?.currentUser?.application?.Users;
  const primaryApplicant = users?.find((user) => user?.primaryApplicant);
  const currentAddress = primaryApplicant?.aboutUser?.addressHistory?.[0];
  const { addressOne, addressTwo, city, postCode, state, country } = currentAddress;

  await apolloClient.mutate({
    mutation: UPDATE_APPLICATION,
    variables: {
      id: applicationId,
      refinanceCurrentAddress: formData?.refinanceCurrentAddress === 'Yes',
    },
    update: (cache, { data }) => {
      const cacheObjToModify = cache.identify({ id: applicationId, __typename: 'Application' });
      cache.modify({
        id: cacheObjToModify,
        fields: {
          refinanceCurrentAddress() {
            return data?.updateApplication?.refinanceCurrentAddress;
          },
        },
      });
    },
  });

  if (formData?.refinanceCurrentAddress === 'Yes') {
    await apolloClient.mutate({
      mutation: UPDATE_PROPERTY_REFINANCE,
      variables: {
        id: applicantCoreData?.currentUser?.application?.PropertyRefinance?.[0]?.id,
        addressOne,
        addressTwo,
        city,
        postCode,
        state,
        country,
      },
    });
  } else {
    await apolloClient.mutate({
      mutation: UPDATE_PROPERTY_REFINANCE,
      variables: {
        id: applicantCoreData?.currentUser?.application?.PropertyRefinance?.[0]?.id,
        addressOne: null,
        addressTwo: null,
        city: null,
        postCode: null,
        state: null,
        country: null,
      },
    });
  }

  if (isBrowser) {
    localStorage.setItem(
      'yb-current-property-id',
      applicantCoreData?.currentUser?.application?.PropertyRefinance?.[0]?.id
    );
  }
};
