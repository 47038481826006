import React, { useRef, useEffect } from 'react';
import { Link, ProgressDoughnut } from 'components';
import { ReactComponent as TickCompleteAlt } from '../../../images/tick-complete-alt.svg';
import * as styles from './styles.module.scss';

const AppSidebar = ({ stepIndex, extra }) => {
  const sidebarRef = useRef(null);

  const isLoggedIn = extra?.isLoggedIn;
  const applicantCoreData = extra?.applicantCoreData;

  const isLeadFlow = extra?.isLeadFlow;
  const isRatesFlow = extra?.isRatesFlow;
  const isInviteFlow = extra?.isInviteFlow;
  const showProgressDoughnut = !isLeadFlow && !isRatesFlow && !isInviteFlow;

  const currentFlowStep = applicantCoreData?.currentUser?.currentFlowStep || 0;
  const hasAdditionalApplicants = applicantCoreData?.currentUser?.leadApplication?.additionalUsers?.length > 0;

  const handleStepClick = (event, index) => {
    if (currentFlowStep < index) {
      return event.preventDefault();
    }

    return event;
  };

  useEffect(() => {
    const sidebarEl = document.getElementById('sidebar');
    const activeStepEl = document.getElementById('sidebar-active');
    const windowWidth = window.innerWidth;

    if (sidebarEl && activeStepEl) {
      const scrollToLeft = activeStepEl.getBoundingClientRect().left - (windowWidth / 2 - activeStepEl.clientWidth / 2);
      sidebarEl.scrollTo({
        left: scrollToLeft,
      });
    }
  }, [stepIndex]);

  return (
    <div className={styles.sidebar} id="sidebar" ref={sidebarRef}>
      {defaultSteps({ hasAdditionalApplicants, isLoggedIn }).map((step, index) => {
        const isActive = step?.title === stepIndex;
        const isComplete = index < currentFlowStep;
        const currentStep = currentFlowStep === index;
        const hideStep = index === 2 && !hasAdditionalApplicants;
        const disableStep = index > currentFlowStep;

        if (hideStep) return null;

        return (
          <Link
            className={`${styles.sidebarItem} ${currentStep ? styles.currentStep : ''} ${
              isActive ? styles.activeItem : ''
            } ${isComplete ? styles.completeItem : ''}
            ${index > currentStep ? styles.inactiveLink : ''} `}
            id={isActive ? 'sidebar-active' : null}
            onClick={(event) => handleStepClick(event, index)}
            key={step?.title}
            disable={disableStep}
            to={step?.link}
          >
            <span className={styles.sidebarItemInner}>
              {isComplete && <TickCompleteAlt className={styles.tickComplete} />}
              <span className={styles.sidebarItemText}>{step?.title}</span>
            </span>
          </Link>
        );
      })}
      {showProgressDoughnut && (
        <div className={styles.doughnutContainer}>
          <ProgressDoughnut applicantCoreData={applicantCoreData} />
        </div>
      )}
    </div>
  );
};

export default AppSidebar;

const defaultSteps = ({ hasAdditionalApplicants, isLoggedIn }) => [
  { title: 'Basic information', link: '/get-started' },
  {
    title: 'Create your shortlist',
    link: isLoggedIn ? '/prioritise-your-shortlist' : '/create-your-shortlist',
  },
  {
    title: 'Invite other applicants',
    link: '/invite-other-applicants',
  },
  { title: 'Verify your details', link: '/verify-your-details' },
  { title: 'Tell us more about you', link: '/fact-find' },
  { title: 'Get approved', link: '/get-approved' },
];
