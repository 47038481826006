import React, { useEffect, useState } from 'react';
import ApplicationFlowButton from '../ApplicationFlowCommon/ApplicationFlowButton';
import ApplicationFlowModal from '../ApplicationFlowCommon/ApplicationFlowModal';
import FrankieOneWidget from './FrankieOneWidget';
import { useDataLayer } from '../../../hooks/useDataLayer';

const FrankieOneVerifyId = (props) => {
  const {
    applicantData,
    fetchedVerificationData,
    startPolling,
    stopPolling,
    setProviderLoading,
    apolloClient,
    primaryApplicant,
    setActiveModal: setActiveVerificationModal,
    hasPrimaryApplicantHadIdVerified,
    onOpenModalHandler,
    showFrankieOneModal: showModal,
    toggleFrankieOneModal: toggleModal,
    resetFrankieOne,
    setResetFrankieOne,
    authApproval,
  } = props;

  const [status, setStatus] = useState(null);

  useEffect(() => {
    const currentStatus = applicantData?.aboutUser?.idVerified ? 'complete' : null;
    setStatus(currentStatus);
  }, [applicantData]);

  const hasVerifiedId = fetchedVerificationData?.currentUser?.application?.Users?.find(
    (fetchedUserData) => fetchedUserData?.id === applicantData?.id
  )?.aboutUser?.idVerified;

  // Status Tracking
  const handleIdVerification = async (e) => {
    console.log('Results from handleIdVerification listener', e?.detail);
    const result = e?.detail?.resultSlug;
    if (result === 'no-match') {
      toggleModal(false);
      setProviderLoading(false);
      return setStatus('no-match');
    }
    if (result === 'success' || result === 'pending-success') {
      toggleModal(false);
      setStatus('loading');
      setProviderLoading(true);
      return startPolling(3000);
    }
    console.error('Error during ID verification (handleIdVerification)', e?.detail);
    toggleModal(false);
    setProviderLoading(false);
    return setStatus('no-match');
  };

  const dataLayer = useDataLayer();

  useEffect(() => {
    if (hasVerifiedId) {
      const cacheObjToModify = apolloClient.cache.identify({
        id: applicantData?.aboutUser?.id,
        __typename: 'AboutUser',
      });

      apolloClient.cache.modify({
        id: cacheObjToModify,
        fields: {
          idVerified() {
            return true;
          },
        },
      });

      toggleModal(false);
      setResetFrankieOne(true);
      setStatus('complete');
      setProviderLoading(false);
      stopPolling();

      dataLayer?.track('ID Verified | Credit Check Complete');
    }
  }, [hasVerifiedId]);

  const onCloseModalHandler = () => {
    toggleModal(false);
    setResetFrankieOne(true);
    if (!status) {
      setStatus('incomplete');
      setProviderLoading(false);
    }
  };

  return (
    <>
      <ApplicationFlowButton status={status} onClick={() => onOpenModalHandler(authApproval.current)} isVerification />
      <ApplicationFlowModal title="ID verification and credit check" active={showModal} onClose={onCloseModalHandler}>
        <FrankieOneWidget applicantData={applicantData} onChange={handleIdVerification} reset={resetFrankieOne} />
      </ApplicationFlowModal>
    </>
  );
};

export default FrankieOneVerifyId;
