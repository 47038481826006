export const sendEmail = (url, formData = {}) => {
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(formData),
  };
  return fetch(url, config);
};
