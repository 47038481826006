import { gql } from '@apollo/client';
import { FACT_FIND_FRAGMENTS } from '../fragments/fragments_ff';

const { 'about-you': aboutUser, expenses, property: Property, mortgage: MortgagePreference } = FACT_FIND_FRAGMENTS;

export const CURRENT_PROPERTY = gql`
  query CurrentProperty {
    currentProperty @client
  }
`;

export const IS_USER_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const CURRENT_FLOW_STEP = gql`
  query GetCurrentFlowStep($email: String) {
    currentUser(email: $email) {
      currentFlowStep
    }
  }
`;

export const GET_CURRENT_APPLICANT = gql`
  query GetCurrentApplicant {
    currentApplicant @client
    nextApplicant @client
  }
`;

export const GET_VERIFICATION_STATUS = gql`
  query GetVerificationStatus($email: String) {
    currentUser(email: $email) {
      id
      application {
        id
        Users {
          id
          primaryApplicant
          userVerified
          aboutUser {
            id
            idVerified
            bankStatementsReceived
          }
        }
      }
    }
  }
`;

export const GET_FF_FLOW_PROGRESS = gql`
  query GetFlowProgress($email: String) {
    currentUser(email: $email) {
      aboutUser {
        ...FlowProgressAbout
      }
      Expenses {
        ...FlowProgressExpenses
      }
      application {
        Property {
          ...FlowProgressProperty
        }
        MortgagePreference {
          ...FlowProgressMortgagePreference
        }
      }
    }
  }
  ${aboutUser?.flowProgress}
  ${expenses?.flowProgress}
  ${Property?.flowProgress}
  ${MortgagePreference?.flowProgress}
`;

export const GET_ADDITIONAL_APPLICANTS = gql`
  query GetAdditionalApplicants($email: String) {
    currentUser(email: $email) {
      application {
        Users {
          id
          name
          email
          userVerified
          currentFlowStep
          primaryApplicant
          aboutUser {
            id
            idVerified
            bankStatementsReceived
            ...Details
            ...Citizenship
            ...AddressHistory
            ...Work
            ...Income
            ...AdditionalIncome
            ...Assets
            ...Liabilities
            ...Dependants
          }
        }
      }
    }
  }

  # About user fragments
  ${aboutUser?.['about-details']}
  ${aboutUser?.['about-citizenship']}
  ${aboutUser?.['about-address']}
  ${aboutUser?.['about-work']}
  ${aboutUser?.['about-income']}
  ${aboutUser?.['about-additional-income']}
  ${aboutUser?.['about-assets']}
  ${aboutUser?.['about-liabilities']}
  ${aboutUser?.['about-dependants']}
`;
