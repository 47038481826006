import React, { useRef } from 'react';
import { OnVisible } from 'components';
import { FormProvider } from 'react-hook-form';
import FormBlock from '../_common/FormBlock';
import ControlsBlock from '../_common/ControlsBlock';
import AdditionalBlock from '../_common/AdditionalBlock';
import { useCalculator } from '../_common/utils/useCalculator';
import * as styles from './styles.module.scss';

const CalculatorTemplate = (props) => {
  const {
    onHandleStartApplication,
    controlsBlockConfigDefault,
    defaultValues,
    fieldsDefault,
    defaultCalculationData,
    calculatorEndpoint,
    className = '',
    showGroupValidations = false,
    isBlog,
    additionalBlockConfigDefault,
    allowNegativeResults,
    showCalculationError,
  } = props;

  const submitRef = useRef();

  const {
    calculationData,
    setCalculationData,
    submitHandler,
    formMethods: methods,
    bottomOfCalculatorIsInView,
    setBottomOfCalculatorIsInView,
  } = useCalculator({
    defaultCalculationData,
    calculatorEndpoint,
    defaultValues,
    allowNegativeResults,
    showCalculationError,
  });

  const fields = fieldsDefault(submitHandler);
  const additionalBlockConfig =
    typeof additionalBlockConfigDefault === 'function' &&
    additionalBlockConfigDefault(calculationData, methods?.watch());

  const commonProps = {
    methods,
    submitRef,
    onSubmit: submitHandler,
  };

  return (
    <FormProvider methods={methods}>
      <div className={`${styles.calculatorContainer} ${className} ${isBlog ? styles.blogCalculator : ''}`}>
        <div className={styles.formBlockContainer}>
          <FormBlock {...commonProps} fieldsets={fields} showGroupValidations={showGroupValidations} />
          <AdditionalBlock additionalBlockConfig={additionalBlockConfig} className="hide-desktop" />
        </div>
        {additionalBlockConfig ? (
          <AdditionalBlockContainer
            commonProps={commonProps}
            additionalBlockConfig={additionalBlockConfig}
            controlsBlockConfigDefault={controlsBlockConfigDefault}
            bottomOfCalculatorIsInView={bottomOfCalculatorIsInView}
            onHandleStartApplication={onHandleStartApplication}
            calculationData={calculationData}
            setCalculationData={setCalculationData}
          />
        ) : (
          <ControlsBlock
            {...commonProps}
            controlsBlockConfigDefault={controlsBlockConfigDefault}
            bottomOfCalculatorIsInView={bottomOfCalculatorIsInView}
            onHandleStartApplication={onHandleStartApplication}
            calculationData={calculationData}
            setCalculationData={setCalculationData}
          />
        )}
        <OnVisible onChange={(inView) => setBottomOfCalculatorIsInView(inView)} triggerMultiple />
      </div>
    </FormProvider>
  );
};

export default CalculatorTemplate;

const AdditionalBlockContainer = (props) => {
  const {
    commonProps,
    controlsBlockConfigDefault,
    bottomOfCalculatorIsInView,
    onHandleStartApplication,
    calculationData,
    setCalculationData,
    additionalBlockConfig,
  } = props;

  return (
    <div className={styles.additionalBlockContainer}>
      <ControlsBlock
        {...commonProps}
        controlsBlockConfigDefault={controlsBlockConfigDefault}
        bottomOfCalculatorIsInView={bottomOfCalculatorIsInView}
        onHandleStartApplication={onHandleStartApplication}
        calculationData={calculationData}
        setCalculationData={setCalculationData}
      />
      <AdditionalBlock additionalBlockConfig={additionalBlockConfig} className="hide-mobile" />
    </div>
  );
};
