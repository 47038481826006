import React, { useState } from 'react';
import FieldAddressSubfields from './FieldAddressSubfields';
import FieldAddressSearch from './FieldAddressSearch';
import { formatAddress } from '../../../../utils/formatAddress';

const FieldGoogleMap = (props) => {
  const { defaultValues, setValue, className, getValues, addressArray, name, ...fieldProps } = props;

  const [showSearch, setShowSearch] = useState(true);

  const addressArrayNameComponents = addressArray && name?.split('.')?.slice(0, 2);
  const addressArrayNamePrefix = addressArrayNameComponents?.join('.');

  const onChangeHandler = (data) => {
    const addressComponents = data?.addressComponents?.customAddressComponents;
    const hasSearchedForSuburb = data?.searchBySuburb;

    Object.keys(addressComponents).forEach((addressComponentKey) => {
      const value = addressComponents[addressComponentKey];
      const fieldName = addressArrayNamePrefix
        ? `${addressArrayNamePrefix}.${addressComponentKey}`
        : addressComponentKey;
      setValue(fieldName, value);
    });

    if (!addressArray && !hasSearchedForSuburb) {
      setShowSearch(false);
    }
  };

  const searchInitialValue = () => {
    const defaultValue = addressArray
      ? defaultValues?.[addressArrayNameComponents[0]][addressArrayNameComponents[1]]
      : defaultValues;

    const addressOne = defaultValue?.addressOne;
    const city = defaultValue?.city;
    const state = defaultValue?.state;
    const postCode = defaultValue?.postCode;
    const country = defaultValue?.country;

    if (addressArray && addressOne) {
      return `${formatAddress({ addressData: defaultValue })}`;
    }

    if (city && state && postCode && country && !addressOne) {
      return `${city} ${state} ${postCode}, ${country}`;
    }

    return '';
  };

  const fieldsResetConfig = {
    addressSearch: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    postCode: '',
    state: '',
    country: '',
    addressNotKnown: 'Yes',
  };

  return (
    <div className={className}>
      <FieldAddressSearch
        {...fieldProps}
        addressArray={addressArray}
        addressArrayNamePrefix={addressArrayNamePrefix}
        addressArrayNameComponents={addressArrayNameComponents}
        onChange={onChangeHandler}
        showSearch={showSearch}
        setShowSearch={setShowSearch}
        setValue={setValue}
        getValues={getValues}
        initialValue={searchInitialValue()}
        fieldsResetConfig={fieldsResetConfig}
        name={name}
      />
      <FieldAddressSubfields
        {...fieldProps}
        addressArray={addressArray}
        addressArrayNamePrefix={addressArrayNamePrefix}
        addressArrayNameComponents={addressArrayNameComponents}
        showSearch={showSearch}
        setShowSearch={setShowSearch}
        setValue={setValue}
        getValues={getValues}
        fieldsResetConfig={fieldsResetConfig}
        name={name}
      />
    </div>
  );
};

export default FieldGoogleMap;
