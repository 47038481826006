import React, { useState, useEffect, useRef } from 'react';
import ApplicationFlowButton from '../ApplicationFlowCommon/ApplicationFlowButton';
import ApplicationFlowModal from '../ApplicationFlowCommon/ApplicationFlowModal';
import * as styles from './styles.module.scss';
import testCreds from '../../../utils/illionTestCreds';
import { isDev } from '../../../utils/misc';
import { useDataLayer } from '../../../hooks/useDataLayer';

const ILLION_IFRAME_URL =
  process.env.GATSBY_YOUBROKER_SERVER === 'production'
    ? process.env.GATSBY_ILLION_PROD_URL
    : process.env.GATSBY_ILLION_DEMO_URL;

const POLLING_INTERVAL = 4000; // 3 seconds

const IllionVerifyBankStatements = (props) => {
  const { applicantData, setProviderLoading, fetchedVerificationData, startPolling, stopPolling, apolloClient } = props;
  const [showModal, toggleModal] = useState(false);
  const [status, setStatus] = useState(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (applicantData?.aboutUser?.bankStatementsReceived) {
      setStatus('complete');
    }

    if (!applicantData?.aboutUser?.bankStatementsReceived && status === 'complete') {
      setStatus(null);
    }
  }, [applicantData]);

  // User Data
  const currentUserId = applicantData?.id;
  const urlParameter = currentUserId ? `-${currentUserId}` : '';

  const iframeUrl = `${ILLION_IFRAME_URL}${urlParameter}`;

  // Bank Statement Verification Status
  const serverBankStatementsReceived = fetchedVerificationData?.currentUser?.application?.Users?.find(
    (fetchedUserData) => fetchedUserData?.id === applicantData?.id
  )?.aboutUser?.bankStatementsReceived;

  const dataLayer = useDataLayer();

  useEffect(() => {
    if (serverBankStatementsReceived) {
      const cacheObjToModify = apolloClient.cache.identify({
        id: applicantData?.aboutUser?.id,
        __typename: 'AboutUser',
      });

      apolloClient.cache.modify({
        id: cacheObjToModify,
        fields: {
          bankStatementsReceived() {
            return true;
          },
        },
      });
      toggleModal(false);
      stopPolling();
      setStatus('complete');
      setProviderLoading(false);

      dataLayer?.track('Bank Statements Submitted');
    }
  }, [serverBankStatementsReceived]);

  // Modal Handlers
  const onOpenModalHandler = () => {
    if (isDev) {
      const testCredsToUse = testCreds();
      console.log('%c******Test Credentials******\n\n', 'background: #3C38D6; color: #FFFFFF', testCredsToUse);
      prompt('Copy test credentials to use: Ctrl+C, Enter', testCredsToUse);
    }
    toggleModal(true);
  };

  useEffect(() => {
    if (status === 'loading') {
      startPolling(POLLING_INTERVAL);
      timeoutRef.current = setTimeout(() => {
        setStatus('no-statements');
        setProviderLoading(false);
        stopPolling();
      }, POLLING_INTERVAL * 12);
    }

    if ((status === 'complete' || status === 'no-statements') && timeoutRef.current) {
      setProviderLoading(false);
      clearTimeout(timeoutRef.current);
    }
  }, [status]);

  const onCloseModalHandler = () => {
    toggleModal(false);
    setStatus('loading');
    setProviderLoading(true);
  };

  return (
    <>
      <ApplicationFlowButton status={status} onClick={onOpenModalHandler} isVerification />
      <ApplicationFlowModal title="Bank Statement Verification" active={showModal} onClose={onCloseModalHandler}>
        <iframe
          className={styles.iframe}
          src={iframeUrl}
          title="Bank Statement Verification - Illion"
          seamless
          referrerPolicy="no-referrer"
        />
      </ApplicationFlowModal>
    </>
  );
};

export default IllionVerifyBankStatements;
