export const formatAddress = ({ addressData }) => {
  const addressNotKnown = addressData?.addressNotKnown;

  if (addressNotKnown) return 'Address not known';

  const addressOne = addressData?.addressOne;
  const addressTwo = addressData?.addressTwo;
  const city = addressData?.city;
  const state = addressData?.state;
  const postCode = addressData?.postCode;
  const country = addressData?.country;

  if (!addressOne && !city) return 'Please enter address';

  return `${addressTwo ? `${addressTwo},` : ''}${addressOne}, ${city} ${state} ${postCode}, ${country}`;
};
