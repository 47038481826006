import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getItemStyle, getListStyle, onDragEnd } from '../../../../utils/draggable';
import { isBrowser } from '../../../../utils/isBrowser';
import * as styles from './styles.module.scss';

const FieldDraggable = (props) => {
  const {
    extraData,
    dragDirection = 'vertical',
    className,
    options: defaultOptions,
    iconOptions: defaultIcons,
    register,
    name,
    setValue,
    defaultValues,
  } = props;

  const isRefinancing = extraData?.isRefinancing;

  const options = defaultOptions({ isRefinancing, defaultValues });
  const iconOptions = defaultIcons({ isRefinancing });

  const [listOptions, setListOptions] = useState(options);

  register(name);

  const onChange = (items) => {
    const itemsOrdered = items.reduce((acc, item, index) => {
      acc.push({ ...item, order: index });
      return acc;
    }, []);

    setListOptions(itemsOrdered);
    setValue(name, itemsOrdered);
  };

  return (
    <div className={iconOptions ? styles.containerWithIcons : styles.container}>
      {isBrowser && (
        <DragDropContext onDragEnd={(result) => onDragEnd({ result, draggableList: listOptions, onChange })}>
          <Droppable droppableId="droppable" direction={dragDirection}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver)}
                className={className || ''}
              >
                {listOptions?.map((option, index) => (
                  <div className={styles.dragContainer} key={option?.order}>
                    <div key={iconOptions[index]} className={snapshot.isDraggingOver ? styles.iconDrag : styles.icon}>
                      {iconOptions[index]}
                    </div>
                    <div className={styles.dragBackground}>
                      <Draggable key={option?.text} draggableId={option?.text} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle({
                              isDragging: snapshot.isDragging,
                              draggableStyle: provided.draggableProps.style,
                              lockY: true,
                              background: 'none',
                            })}
                            className={styles.draggableFieldContainer}
                          >
                            <div className={styles.draggableFieldText}>{option?.text}</div>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  </div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default FieldDraggable;
