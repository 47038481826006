import React, { useEffect, useState, useContext } from 'react';
import { handleCurrentProgress } from '../Questions/utils/handleCurrentProgress';
import ApplicationFlowVerification from './ApplicationFlowVerification';
import ApplicationFlowInviteOtherApplicants from './ApplicationFlowInviteOtherApplicants';
import ApplicationFlowDefault from './ApplicationFlowDefault';
import GlobalContext from '../../contexts/GlobalContext';

const ApplicationFlowList = (props) => {
  const { data, extra } = props;

  const applicationFlows = data?.items;
  const flowType = data?.primary?.flow_type;
  const { applicantCoreData, showLoading, setShowLoading, apolloClient, refetchApplicantCoreData } = extra;

  const allApplicantsRaw = applicantCoreData?.currentUser?.application?.Users;
  const allApplicants =
    (allApplicantsRaw?.length > 0 &&
      [...allApplicantsRaw]?.sort((a, b) => b?.primaryApplicant - a?.primaryApplicant)) ||
    [];
  const primaryApplicant = allApplicants?.[0];

  // Get Applicant Ids and Data
  const [currentUserId, setCurrentUserId] = useState(allApplicants?.[0]?.id);

  const currentUserIdKey = 'yb-current-user-id';

  // Keep track of current applicant in local storage
  useEffect(() => {
    if (!currentUserId && allApplicants?.length > 0) {
      setCurrentUserId(allApplicants?.[0]?.id);
    }
  }, [currentUserId, allApplicants]);

  useEffect(() => {
    if (currentUserId) {
      localStorage.setItem(currentUserIdKey, currentUserId);
    }
  }, [currentUserId]);

  const currentApplicantIndex = allApplicants?.findIndex((applicant, index) => applicant?.id === currentUserId);
  const currentApplicant = allApplicants?.[currentApplicantIndex];
  const nextApplicant = allApplicants?.[currentApplicantIndex + 1];
  const prevApplicant = currentApplicantIndex > 0 ? allApplicants?.[currentApplicantIndex - 1] : null;

  const invitedAdditionalApplicants = applicantCoreData?.currentUser?.leadApplication?.additionalUsers || [];

  const [progresses, setProgresses] = useState(null);

  useEffect(() => {
    setProgresses(
      handleCurrentProgress({
        currentApplicant,
        applicantCoreData,
      })
    );
  }, [currentApplicant]);

  const applicationFlowProps = {
    progresses,
    applicantCoreData,
    applicationFlows,
    allApplicants,
    invitedAdditionalApplicants,
    apolloClient,
    showLoading,
    setShowLoading,
    currentApplicantIndex,
    currentApplicant,
    nextApplicant,
    prevApplicant,
    primaryApplicant,
    currentUserId,
    setCurrentUserId,
    refetchApplicantCoreData,
  };

  const { setInviteAdditionalApplicantProgress } = useContext(GlobalContext) || {};
  const allInviteProgress = progresses?.['enter-number'](invitedAdditionalApplicants)?.stepsCompleteProgress || 0;
  useEffect(() => {
    setInviteAdditionalApplicantProgress(allInviteProgress);
  }, [allInviteProgress]);

  let allApplicantsHaveBeenInvited;

  if (progresses?.['enter-number']) {
    allApplicantsHaveBeenInvited =
      progresses?.['enter-number'](invitedAdditionalApplicants)?.progressBar === 100 || false;
  }

  if (flowType === 'Invite Other Applicants')
    return (
      <ApplicationFlowInviteOtherApplicants
        {...applicationFlowProps}
        allApplicantsHaveBeenInvited={allApplicantsHaveBeenInvited}
      />
    );

  if (flowType === 'Verification') return <ApplicationFlowVerification {...applicationFlowProps} />;

  return <ApplicationFlowDefault {...applicationFlowProps} />;
};

export default ApplicationFlowList;
