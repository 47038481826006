import { gql } from '@apollo/client';
import { FACT_FIND_FRAGMENTS } from '../fragments/fragments_ff';

const { property: PropertyRefinance } = FACT_FIND_FRAGMENTS;

export const UPDATE_PROPERTY_REFINANCE = gql`
  mutation Mutation(
    $id: String
    $city: String
    $addressOne: String
    $addressTwo: String
    $postCode: String
    $state: String
    $country: String
    $currentUse: String
    $usageChange: String
    $intendedUse: String
    $applicationId: String
    $propertyValue: Int
  ) {
    updatePropertyRefinance(
      id: $id
      city: $city
      addressOne: $addressOne
      addressTwo: $addressTwo
      postCode: $postCode
      state: $state
      country: $country
      currentUse: $currentUse
      usageChange: $usageChange
      intendedUse: $intendedUse
      applicationId: $applicationId
      propertyValue: $propertyValue
    ) {
      id
      ...RefinanceAddress
      ...UsageDetails
    }
  }

  ${PropertyRefinance?.['property-refinance-address']}
  ${PropertyRefinance?.['property-usage-details']}
`;

export const CREATE_PROPERTY_REFINANCE = gql`
  mutation CreatePropertyRefinance($propertyRefinance: [PropertyRefinanceInput]) {
    createPropertyRefinance(PropertyRefinance: $propertyRefinance) {
      RefinancedProperties {
        id
        applicationId
        propertyValue
      }
    }
  }
`;
