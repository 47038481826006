import React from 'react';
import PostGrid from '../PostGrid/index.jsx';
import './styles.scss';

const FeaturedPosts = (props) => {
  const { posts, title, className } = props;

  return (
    <section className={`featured-posts ${className || ''}`}>
      <PostGrid posts={posts} title={title} hasFeaturedPost />
    </section>
  );
};

export default FeaturedPosts;
