import React from 'react';
import { Link } from 'components';
import { isBrowser } from '../../../../utils/isBrowser';
import * as styles from './styles.module.scss';

const FieldPropertiesReview = (props) => {
  const { extraData, question } = props;
  const { applicantCoreData } = extraData;
  const properties = applicantCoreData?.currentUser?.application?.PropertyRefinance;
  const { subfields } = question;

  // SET THE PROPERTY TO BE EDITED
  const handleEditLink = ({ propertyData }) => {
    if (isBrowser) {
      localStorage.setItem('yb-current-property-id', propertyData?.id);
    }
  };

  const linkText = 'Edit';

  return (
    <div className={styles.container}>
      {properties?.map((property) => (
        <div key={property?.id} className={styles.borderContainer}>
          {subfields.map((reviewFields) => {
            const title = reviewFields?.title;
            const link = reviewFields?.link;
            const fields = reviewFields?.subfields(property);

            return (
              <div key={reviewFields?.name} className={styles.fieldContainer}>
                <div className={styles.flexDivider}>
                  {title && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
                  {link?.slug && (
                    <Link
                      className={styles.link}
                      to={`/fact-find/property?question=${link.slug}`}
                      onClick={() => handleEditLink({ propertyData: property })}
                    >
                      {linkText}
                    </Link>
                  )}
                </div>
                {fields?.length > 0 &&
                  fields.map((field) => {
                    const data = field?.data;

                    return <div key={field.name}>{data && <div className={styles.data}>{data}</div>}</div>;
                  })}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default FieldPropertiesReview;
