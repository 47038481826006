import React from 'react';
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';
import { Link } from 'components';

import linkResolver from '../../../../config/linkResolver';
import Page from '../../../templates/page';
import BlogPost from '../../../templates/blog_post';
import LeadFlow from '../../../templates/lead-flow';
import BlogCategory from '../../../templates/category';

const PrismicPreviewProviderWrapper = (props) => {
  const { element } = props;
  return (
    <PrismicPreviewProvider
      internalLinkComponent={({ href, ...linkProps }) => <Link to={href} {...linkProps} />}
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            page: Page,
            blog_post: BlogPost,
            category: BlogCategory,
            lead_flow: LeadFlow,
          }),
        },
      ]}
    >
      {element}
    </PrismicPreviewProvider>
  );
};

export default PrismicPreviewProviderWrapper;
