import { gql } from '@apollo/client';
import { FACT_FIND_FRAGMENTS } from '../fragments/fragments_ff';

const { mortgage: MortgagePreference } = FACT_FIND_FRAGMENTS;

export const UPDATE_MORTGAGE = gql`
  mutation updateMortgage(
    $id: String
    $mortgageQuickOffer: String
    $settlementDate: String
    $whatsImportantLoan: [String]
    $whatsImportantLoanOther: String
    $whatsImportantToYou: [String]
    $whatsImportantToYouOther: String
    $keyPriorities: [KeyPrioritiesInput]
    $keyPrioritiesMissed: String
  ) {
    updateMortgage(
      id: $id
      mortgageQuickOffer: $mortgageQuickOffer
      settlementDate: $settlementDate
      whatsImportantLoan: $whatsImportantLoan
      whatsImportantLoanOther: $whatsImportantLoanOther
      whatsImportantToYou: $whatsImportantToYou
      whatsImportantToYouOther: $whatsImportantToYouOther
      keyPriorities: $keyPriorities
      keyPrioritiesMissed: $keyPrioritiesMissed
    ) {
      id
      ...ApprovalSpeed
      ...WhatsImportantLoan
      ...WhatsImportantToYou
      ...KeyPriorities
      ...FlowProgressMortgagePreference
    }
  }
  ${MortgagePreference?.['mortgage-approval-speed']}
  ${MortgagePreference?.['mortgage-whats-important-loan']}
  ${MortgagePreference?.['mortgage-whats-important-to-you']}
  ${MortgagePreference?.['mortgage-key-priorities']}
  ${MortgagePreference?.flowProgress}
`;
