import React from 'react';
import { Link } from 'components';
import * as styles from './styles.module.scss';

const GetStarted = ({ text, showRefinance, highlightRefinance = false }) => {
  const purchaseLink = {
    to: '/purchase/',
    text: 'I want to purchase',
  };

  const refinanceLink = {
    to: '/refinance/',
    text: 'I want to refinance',
  };

  const links = highlightRefinance ? [refinanceLink, purchaseLink] : [purchaseLink, refinanceLink];

  if (showRefinance) {
    return (
      <>
        <div className={styles.buttons}>
          {links?.map((link, index) => (
            <Link to={link?.to} key={link?.to} className={`button ${index === 1 ? 'outline' : ''}`}>
              {link?.text}
            </Link>
          ))}
        </div>
        <div className={styles.tabletDown}>
          <Link className="button" to="/get-started/">
            {text || 'Get Started'}
          </Link>
        </div>
      </>
    );
  }
  return (
    <div className={styles.button}>
      <Link className="button" to="/get-started/">
        {text || 'Get Started'}
      </Link>
    </div>
  );
};

export default GetStarted;
