import React from 'react';
import { Section, OnVisible, ContactForm, Image } from 'components';

import { ReactComponent as Logo } from '../../images/logo-icon.svg';
import './styles.scss';

const Form = (props) => {
  const { data } = props;
  const { primary } = data;
  const { content, title } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;

  return (
    <Section className="contact-form-block">
      <OnVisible className="contact-form-block-container" id="form">
        <div className="contact-form-block-header">
          <Logo className="contact-form-block-icon" />
          {titleHtml && <div className="contact-form-block-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          {contentHtml && (
            <div className="contact-form-block-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
          )}
        </div>
        <ContactForm />
      </OnVisible>
    </Section>
  );
};

export default Form;
