import React from 'react';
import { Link } from 'components';

const Navigation = (props) => {
  const { classNamePrefix = 'main', links } = props;
  const className = classNamePrefix ? `${classNamePrefix}-navigation` : 'navigation';
  return (
    <nav className={className}>
      {links &&
        links.map((link) => (
          <Link
            key={link.text}
            activeClassName="active"
            className={`${className}-${link.button ? 'button' : 'link'} ${link.className || ''}`}
            to={link.to}
          >
            {link.text}
          </Link>
        ))}
    </nav>
  );
};

export default Navigation;
