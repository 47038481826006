import { formatAddress } from '../../../../../utils/formatAddress';
import { formatCurrency } from '../../../../../components/Form/Fields/FieldCurrency/formatCurrency';

const formatMissingData = (requiredData, validationMessage) => {
  if (!requiredData) return `Please enter ${validationMessage}`;
  return false;
};

export default [
  {
    type: 'propertiesReviewFields',
    name: 'propertiesReviewFields',
    namePrismic: 'Property - Review Details',
    slug: 'property-review-details',
    onSubmit: () => null,
    subfields: [
      {
        name: 'propertyAddress',
        title: {
          html: '<h4>Address of property</h4>',
        },
        link: {
          slug: 'property-refinance-address',
        },
        subfields: (propertyData) => [
          {
            name: 'address',
            data: formatAddress({
              addressData: {
                addressOne: propertyData?.addressOne,
                addressTwo: propertyData?.addressTwo,
                city: propertyData?.city,
                postCode: propertyData?.postCode,
                state: propertyData?.state,
                country: propertyData?.country,
              },
            }),
          },
        ],
      },
      {
        name: 'aboutProperty',
        title: {
          html: '<h4>About your property</h4>',
        },
        link: {
          slug: 'property-usage-details',
        },
        subfields: (propertyData) => [
          {
            name: 'propertyValue',
            data:
              formatMissingData(propertyData?.propertyValue, 'property value') ||
              `Property value of ${formatCurrency(propertyData?.propertyValue)}`,
          },
          {
            name: 'currentUse',
            data:
              formatMissingData(propertyData?.currentUse, 'current use') || `Current use - ${propertyData?.currentUse}`,
          },
          {
            name: 'usageChange',
            data:
              formatMissingData(propertyData?.usageChange, 'if changing usage') ||
              `${propertyData?.usageChange !== 'No' ? `Planning` : 'Not planning'} on changing use`,
          },
          {
            name: 'intendedUse',
            data: (() => {
              if (propertyData?.usageChange === 'No') return null;

              return (
                formatMissingData(propertyData?.intendedUse, 'intended use') ||
                `Intended use - ${propertyData?.intendedUse}`
              );
            })(),
          },
        ],
      },
    ],
  },
];
