import React, { useState, useEffect } from 'react';
import { use100vh } from 'react-div-100vh';
import { Logo, Link, Navigation, Hamburger } from 'components';
import { useLogout } from '../../../hooks/useLogout';
import { useCurrentFlowStepRedirect } from '../../../hooks/useCurrentFlowStepRedirect';
import { isBrowser } from '../../../utils/isBrowser';
import './styles.scss';

const navigationLinks = [
  { to: '/', text: 'Home', className: '' },
  { to: '/about-us/', text: 'About', className: '' },
  // { to: '#', text: 'Resources', className: '' },
  // { to: '#', text: 'Buying a home', className: '' },
  // { to: '#', text: 'Refinance', className: '' },
  // { to: '/careers', text: 'Careers', className: '' },
  // { to: '#', text: 'News', className: '' },
  { to: '/contact-us/', text: 'Contact', className: '' },
  { to: '/articles/', text: 'Articles', className: '' },
  { to: '/login/', text: 'Login', className: 'header-login-mobile' },
  { to: '/get-started/', text: 'Get Started', className: 'button white outline nav-button', button: true },
];

const Header = ({ location, appUi, extra, noEscapeRoutes }) => {
  const [showNavBurger, setShowNavBurger] = useState(false);
  const [scrollTop, setScrollTop] = useState(0); // set pixels to - from top when shownav = false
  const [hasScrolled, setHasScrolled] = useState(false); // set pixels to - from top when shownav = false
  const [stickyHeader, setStickyHeader] = useState(false); // set pixels to - from top when shownav = false
  const [bodyLocked, setBodyLock] = useState(false); // lock body while show nav = true

  const handleScroll = () => {
    const { scrollY, requestAnimationFrame } = window;
    requestAnimationFrame(() => {
      if (scrollY > 0 && !hasScrolled) setHasScrolled(true);
      if (scrollY >= 800 && !stickyHeader) setStickyHeader(true);
      if (scrollY === 0 && hasScrolled) setHasScrolled(false);
      if (scrollY < 800 && stickyHeader) setStickyHeader(false);
    });
  };

  // H A M B U R G E R   O N   M O B I L E

  // toggle main nav links for mobile
  const toggleNav = (event) => {
    event.preventDefault();
    setShowNavBurger(!showNavBurger);
  };

  // C L O S E   O N   E S C   A N D   B O D Y   L O C K

  const handleEscKey = (event) => {
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setShowNavBurger(false);
    }
  };

  const lockBody = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  // use effect exit menu on sec keydown
  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      unlockBody();
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  // use effect to listen to scroll
  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled, stickyHeader]);

  // use effect when shownav updated
  useEffect(() => {
    if (showNavBurger && !bodyLocked) {
      document.querySelector('meta[name="theme-color"]').setAttribute('content', '#10156B');
      lockBody();
    }
    if (!showNavBurger && bodyLocked) {
      document.querySelector('meta[name="theme-color"]').setAttribute('content', '#FFFFFF');
      unlockBody();
    }
  }, [showNavBurger]);

  // toggle visibility for mobile
  const hamburgerOpenClass = showNavBurger ? 'hamburger-open' : '';
  const scrolledClassName = hasScrolled ? 'header-has-scrolled' : '';
  const stickyClassName = stickyHeader ? 'header-is-sticky' : '';
  const appClassName = appUi ? 'header-is-app' : '';
  const height = use100vh();

  const classNames = `header ${scrolledClassName} ${stickyClassName} ${hamburgerOpenClass} ${appClassName}`;

  const isLoggedIn = extra?.isLoggedIn;
  const logout = useLogout();
  const currentFlowStep = (isBrowser && localStorage.getItem('yb-current-flow-step')) || 0;
  const { getCurrentFlowStepPath } = useCurrentFlowStepRedirect(currentFlowStep);

  return (
    <header className={classNames} style={showNavBurger ? { height } : {}}>
      <div className="wrapper">
        <div className="nav-top">
          {!appUi && <Hamburger onClick={toggleNav} active={showNavBurger} />}
          <Logo white={showNavBurger} disable={noEscapeRoutes} />
        </div>
        {!noEscapeRoutes && !appUi && (
          <Navigation classNamePrefix="header" links={navigationLinks} location={location} />
        )}
        {!noEscapeRoutes && !appUi && (
          <div className="header-actions">
            <Link
              className="header-login-desktop"
              to={isLoggedIn ? '/' : '/login/'}
              onClick={isLoggedIn && (() => logout())}
            >
              {isLoggedIn ? 'Logout' : 'Login'}
            </Link>
            <Link className="button outline" to={isLoggedIn ? getCurrentFlowStepPath : '/get-started/'}>
              {isLoggedIn ? 'Continue' : 'Get Started'}
            </Link>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
