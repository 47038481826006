import React, { useEffect } from 'react';
import { animate } from 'motion';
import './styles.scss';

const MobileDropShadow = (props) => {
  const { whenToFadeOut } = props;

  useEffect(() => {
    animate(
      '.mobile-drop-shadow',
      {
        opacity: whenToFadeOut ? 0 : 1,
      },
      {
        opacity: {
          duration: 0.5,
        },
        easing: 'linear',
      }
    );
  }, [whenToFadeOut]);

  return <span className="mobile-drop-shadow" />;
};

export default MobileDropShadow;
