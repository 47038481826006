import React from 'react';
import { Link } from 'components';
import { siteName } from '../../../../config/website';
import './styles.scss';

const Logo = ({ white, icon, darkLogoWhiteText, className, disable }) => (
  <Link
    to="/"
    className={`logo ${white ? 'white' : ''} ${icon ? 'icon' : ''} ${darkLogoWhiteText ? 'dark-logo-white-text' : ''} ${
      className || undefined
    } ${disable ? 'disable' : ''}`}
  >
    {siteName}
  </Link>
);

export default Logo;
