import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FieldArrayButtonAdd from '../_FieldCommon/FieldArrayButtonAdd';
import FieldArrayButtonRemove from '../_FieldCommon/FieldArrayButtonRemove';
import FieldArrayLabel from './FieldArrayLabel';
import FormFieldNew from '../../FormFieldNew';
import * as styles from './styles.module.scss';

const FieldArray = (props) => {
  const {
    name,
    addButtonLabel,
    removeButtonLabel,
    removeButtonIcon,
    hideAddButton,
    onRemove,
    validation,
    subfieldLabel,
    subfields,
    register,
    getValues,
    setValue,
    className,
    defaultValue,
    goNext,
    goPrev,
    validationErrors,
    apolloClient,
    watch,
    submitThenRemoveFromArray = true,
    showGroupValidations,
  } = props;

  const { methods } = useFormContext();

  const { fields, append, remove } = useFieldArray({ control: methods?.control, name, keyName: 'reactHookFormId' });
  const fieldsCount = fields?.length;

  const onAppendHandler = () => {
    const defaultValues = subfields?.reduce(
      (acc, subfield) => ({ ...acc, [subfield.name]: subfield.defaultValue || '' }),
      {}
    );
    append(defaultValues, { shouldFocus: true, focusIndex: 1 });
  };

  const onRemoveHandler = async ({ field, index }) => {
    if (submitThenRemoveFromArray) {
      if (onRemove) await onRemove({ field, apolloClient, table: name, formData: getValues() });
      return remove(index);
    }

    remove(index);
    if (onRemove) await onRemove({ field, apolloClient, table: name, formData: getValues() });
  };

  useEffect(() => {
    if (fieldsCount < validation.min) onAppendHandler();
    if (fieldsCount > validation.max) remove(fieldsCount - 1);
  }, [fieldsCount]);

  return (
    <div className={styles.container}>
      {fields?.map((field, index) => (
        <div key={field.reactHookFormId}>
          <div className={`${styles.label} field-array-label-container`}>
            {subfieldLabel && <FieldArrayLabel name={name} index={index} subfieldLabel={subfieldLabel} watch={watch} />}
            {removeButtonIcon && (
              <FieldArrayButtonRemove
                isIcon
                hide={index < validation?.min}
                onClick={() => onRemoveHandler({ field, index })}
              />
            )}
          </div>
          <div
            id={`${name}.${index}`}
            className={`${styles.subfieldsContainer} ${className} field-array-subfields-container`}
          >
            {subfields.map((subfield) => {
              const { name: subfieldName } = subfield;
              const fieldName = `${name}.${index}.${subfieldName}`;
              const subfieldValidationErrors = validationErrors?.[index]?.[subfieldName];
              return (
                <Fragment key={fieldName}>
                  <FormFieldNew
                    {...subfield}
                    name={fieldName}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    goNext={goNext}
                    goPrev={goPrev}
                    validationErrors={subfieldValidationErrors}
                    watch={watch}
                    showGroupValidations={showGroupValidations}
                  />
                  {!removeButtonIcon && (
                    <FieldArrayButtonRemove
                      hide={index < validation?.min}
                      label={removeButtonLabel}
                      onClick={() => onRemoveHandler({ field, index })}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      ))}
      <FieldArrayButtonAdd
        hide={hideAddButton || fields.length >= validation.max}
        label={addButtonLabel}
        onClick={onAppendHandler}
      />
    </div>
  );
};

FieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  addButtonLabel: PropTypes.string,
  removeButtonLabel: PropTypes.string,
  removeButtonIcon: PropTypes.bool,
  onRemove: PropTypes.func,
  className: PropTypes.string,
  subfieldLabel: PropTypes.func,
  validation: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
};

FieldArray.defaultProps = {
  className: '',
  addButtonLabel: 'Add an item',
  removeButtonLabel: 'Remove an item',
  removeButtonIcon: true,
  onRemove: null,
  subfieldLabel: null,
  validation: {
    min: 0,
    max: 50,
  },
};

export default FieldArray;
