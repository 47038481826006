import React from 'react';
import { FormError } from 'components';
import PropTypes from 'prop-types';
import FormFieldNew from '../../FormFieldNew';
import * as styles from './styles.module.scss';

const FieldGroup = (props) => {
  const { name, subfields, validationErrors, showGroupValidations, ...extraProps } = props;

  return (
    <div className={`${styles.container} field-group-container`}>
      {subfields.map((subfield) => {
        const { name: subfieldName, type } = subfield;
        const validationErrorsObject =
          type === 'group' || type === 'arrayMulti' ? validationErrors : validationErrors?.[subfieldName];
        return (
          <FormFieldNew
            showGroupValidations={showGroupValidations}
            {...extraProps}
            {...subfield}
            key={`${name}_${subfieldName}`}
            name={subfieldName}
            validationErrors={validationErrorsObject}
          />
        );
      })}
      <FormError errors={validationErrors} showGroupValidations={showGroupValidations} />
    </div>
  );
};

FieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  subfields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  showGroupValidations: PropTypes.bool,
};

FieldGroup.defaultProps = {
  showGroupValidations: true,
};

export default FieldGroup;
