import React from 'react';
import { ReactComponent as RemoveFieldIcon } from '../../../../../images/dash.svg';
import * as styles from './styles.module.scss';

const FieldArrayButtonRemove = (props) => {
  const { label = 'Remove', onClick, hide, isIcon, disabled = false } = props;

  if (hide) return null;

  const onClickHandler = () => {
    if (disabled) return null;

    if (onClick) onClick();
  };

  return (
    <button
      type="button"
      aria-hidden="true"
      onClick={onClickHandler}
      className={`${styles.button} ${isIcon ? styles.icon : ''}`}
    >
      {isIcon ? <RemoveFieldIcon /> : label}
    </button>
  );
};

export default FieldArrayButtonRemove;
