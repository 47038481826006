import { gql } from '@apollo/client';
import { FACT_FIND_FRAGMENTS } from '../fragments/fragments_ff';

const { MortgageShortlist, approval } = FACT_FIND_FRAGMENTS;

export const UPDATE_LEAD_APPLICATION = gql`
  mutation updateApplication($id: String, $firstName: String, $emailAddress: String) {
    updateApplication(id: $id, firstName: $firstName, emailAddress: $emailAddress) {
      firstName
      id
      emailAddress
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($email: String, $id: String, $name: String, $currentFlowStep: Int, $targetId: String) {
    updateUser(email: $email, id: $id, name: $name, currentFlowStep: $currentFlowStep, targetId: $targetId) {
      email
      id
      name
      currentFlowStep
    }
  }
`;

export const UPDATE_BANKSTATEMENTS_RECEIVED = gql`
  mutation updateAboutUser($id: String, $bankStatementsReceived: Boolean) {
    updateAboutUser(id: $id, bankStatementsReceived: $bankStatementsReceived) {
      id
      # totalFields
      # complete
      # completedFields
      bankStatementsReceived
      idVerified
    }
  }
`;

export const UPDATE_ID_VERIFIED = gql`
  mutation updateAboutUser($id: String, $idVerified: Boolean) {
    updateAboutUser(id: $id, idVerified: $idVerified) {
      id
      totalFields
      complete
      completedFields
      idVerified
    }
  }
`;

// TODO - delete
export const CREATE_ADDITIONAL_APPLICANTS = gql`
  mutation Mutation($additionalApplicant: [UserInput]) {
    createAdditionalApplicant(AdditionalApplicant: $additionalApplicant) {
      id
      firstName
      email
      phoneNumber
      applicationId
    }
  }
`;

export const CREATE_APPROVAL = gql`
  mutation createApproval(
    $id: String
    $applicationId: String
    $additionalQuestions: String
    $termsConditionsAccepted: Boolean
    $extraOptions: [String]
    $primaryUserId: String
  ) {
    createApproval(
      id: $id
      applicationId: $applicationId
      additionalQuestions: $additionalQuestions
      termsConditionsAccepted: $termsConditionsAccepted
      extraOptions: $extraOptions
      primaryUserId: $primaryUserId
    ) {
      ...GetApproved
    }
  }
  ${approval}
`;

export const CREATE_APPLICATION = gql`
  mutation createApplication(
    $userId: String
    $creditHistory: String
    $currentLender: String
    $currentLenderOther: String
    $depositAmount: Int
    $emailAddress: String!
    $employment: String
    $expectedPurchasePrice: Int
    $firstName: String
    $grantEligibility: String
    $homeBuyingSituation: String
    $importantValues: [String]
    $currentInterestRate: Float
    $interestRate: Float
    $loanAmount: Int
    $numberOfApplicants: String
    $numberOfProperties: String
    $phoneNumber: String
    $propertyKind: String
    $propertyUse: String
    $propertyValue: Int
    $termOfLoan: Int
    $whatStage: String
    $whyRefinancing: [String]
    $isRefinance: Boolean
    $additionalUsers: [UserInviteInput]
    $isGcs: Boolean
    $householdIncome: Int
  ) {
    createApplication(
      userId: $userId
      creditHistory: $creditHistory
      currentLender: $currentLender
      currentLenderOther: $currentLenderOther
      depositAmount: $depositAmount
      emailAddress: $emailAddress
      employment: $employment
      expectedPurchasePrice: $expectedPurchasePrice
      firstName: $firstName
      grantEligibility: $grantEligibility
      homeBuyingSituation: $homeBuyingSituation
      importantValues: $importantValues
      currentInterestRate: $currentInterestRate
      interestRate: $interestRate
      loanAmount: $loanAmount
      numberOfApplicants: $numberOfApplicants
      numberOfProperties: $numberOfProperties
      phoneNumber: $phoneNumber
      propertyKind: $propertyKind
      propertyUse: $propertyUse
      propertyValue: $propertyValue
      termOfLoan: $termOfLoan
      whatStage: $whatStage
      whyRefinancing: $whyRefinancing
      isRefinance: $isRefinance
      additionalUsers: $additionalUsers
      isGcs: $isGcs
      householdIncome: $householdIncome
    ) {
      application {
        id
      }
      additionalUsers {
        id
        name
        inviteSent
        leadApplicationId
      }
    }
  }
`;

export const UPDATE_APPLICATION = gql`
  mutation UpdateApplication(
    $id: String
    $refinanceCurrentAddress: Boolean
    $mortgageShortlist: [MortgageShortlistInput]
    $additionalUsers: [UserInviteInput]
    $creditHistory: String
    $currentLender: String
    $currentLenderOther: String
    $depositAmount: Int
    $emailAddress: String
    $employment: String
    $expectedPurchasePrice: Int
    $grantEligibility: String
    $homeBuyingSituation: String
    $importantValues: [String]
    $currentInterestRate: Float
    $interestRate: Float
    $loanAmount: Int
    $numberOfProperties: String
    $phoneNumber: String
    $propertyKind: String
    $propertyUse: String
    $propertyValue: Int
    $termOfLoan: Int
    $whatStage: String
    $whyRefinancing: [String]
    $householdIncome: Int
  ) {
    updateApplication(
      id: $id
      refinanceCurrentAddress: $refinanceCurrentAddress
      MortgageShortlist: $mortgageShortlist
      additionalUsers: $additionalUsers
      creditHistory: $creditHistory
      currentLender: $currentLender
      currentLenderOther: $currentLenderOther
      depositAmount: $depositAmount
      emailAddress: $emailAddress
      employment: $employment
      expectedPurchasePrice: $expectedPurchasePrice
      grantEligibility: $grantEligibility
      homeBuyingSituation: $homeBuyingSituation
      importantValues: $importantValues
      currentInterestRate: $currentInterestRate
      interestRate: $interestRate
      loanAmount: $loanAmount
      numberOfProperties: $numberOfProperties
      phoneNumber: $phoneNumber
      propertyKind: $propertyKind
      propertyUse: $propertyUse
      propertyValue: $propertyValue
      termOfLoan: $termOfLoan
      whatStage: $whatStage
      whyRefinancing: $whyRefinancing
      householdIncome: $householdIncome
    ) {
      id
      refinanceCurrentAddress
      MortgageShortlist {
        ...ProductData
      }
      additionalUsers {
        id
        inviteSent
        name
        leadApplicationId
      }
      creditHistory
      currentLender
      depositAmount
      emailAddress
      employment
      expectedPurchasePrice
      grantEligibility
      homeBuyingSituation
      importantValues
      currentInterestRate
      interestRate
      loanAmount
      numberOfProperties
      phoneNumber
      propertyKind
      propertyUse
      propertyValue
      termOfLoan
      whatStage
      whyRefinancing
      isRefinance
      householdIncome
    }
  }

  ${MortgageShortlist}
`;

export const DELETE_RECORD = gql`
  mutation deleteRecord($table: String, $id: String, $userId: String, $targetId: String) {
    deleteRecord(table: $table, id: $id, userId: $userId, targetId: $targetId) {
      id
    }
  }
`;
