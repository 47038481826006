// extracted by mini-css-extract-plugin
export var section = "_2VQwFo44";
export var lightBlueBg = "lG9SDGP7";
export var titleContentContainer = "nRq0NE+5";
export var title = "qIzgShz1";
export var content = "OG7dBWAR";
export var cardsContainer = "X0kvTGYv";
export var card = "wZQzKnpc";
export var cardIcon = "_5pB1RkvI";
export var cardTitle = "f748uM00";
export var cardContent = "T3Kqn6pS";
export var cardCtaLink = "ZcoGz-3v";