import React from 'react';
import * as styles from './styles.module.scss';

const FieldNumber = (props) => {
  const {
    name,
    placeholder,
    defaultValue,
    autoFocus,
    register,
    validation,
    step,
    isSubmitting,
    onBlur,
    watch,
    min,
    max,
  } = props;

  const field = register(name, { ...validation, valueAsNumber: true });

  const onBlurHandler = (e) => {
    if (onBlur) onBlur(watch());

    return field.onBlur(e);
  };

  return (
    <input
      {...field}
      onBlur={onBlurHandler}
      type="number"
      step={step}
      min={min}
      max={max}
      className={styles.number}
      name={name}
      id={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      readOnly={isSubmitting}
      onKeyDown={(e) => (e?.key === 'e' || e?.key === 'E' ? e?.preventDefault() : e)}
    />
  );
};

export default FieldNumber;
