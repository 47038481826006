import React from 'react';
import * as styles from './styles.module.scss';

const Spinner = ({ className }) => (
  <svg className={`${styles.spinner} ${className || ''}`} viewBox="0 0 50 50">
    <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
  </svg>
);

export default Spinner;
