import React from 'react';
import { Section } from 'components';
import { EmailCapture } from 'slices';
import './styles.scss';

const FooterCta = (props) => (
  <EmailCapture
    data={{
      primary: {
        title: { html: '<p>Compare, save and settle faster online with YouBroker</p>' },
        description: {
          html:
            '<p>Get qualified in minutes. YouBroker makes it fast and easy for your mortgage pre-approval.</p>',
        },
      },
    }}
  />
);

export default FooterCta;
