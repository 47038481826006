import React from 'react';
import { ReactComponent as Star } from '../../../images/iconography/star.svg';
import { ReactComponent as DollarCoin } from '../../../images/iconography/dollar-coin.svg';

export const fieldsDefault = (submitHandler) => [
  {
    title: 'Your home loan details',
    titleIcon: <Star className="title-icon" />,
    fields: [
      {
        type: 'currency',
        name: 'balance', // loanAmount
        label: 'Loan amount',
        onBlur: (data) => submitHandler(data),
        validation: {
          required: true,
          pattern: /^([5-9]\d{3,}|\d{4,})$/i,
          min: {
            value: 150000,
            message: 'Please enter an amount over $150,000',
          },
          max: {
            value: 999999999,
            message: 'Please enter an amount under $999,999,999',
          },
        },
        validationMessage: 'Please enter loan amount',
      },
      {
        type: 'number',
        name: 'interestRate',
        label: 'Interest rate',
        placeholder: '%',
        onBlur: (data) => submitHandler(data),
        validation: {
          required: true,
          max: { value: 18, message: 'Max interest rate 18.00%' },
          min: { value: 0.01, message: 'Min interest rate 0.01%' },
          validate: (v) => v?.toString()?.length <= 4 || 'Rate limited to 2 decimal places',
        },
      },
      {
        name: 'remainingYears', // termOfLoan
        label: 'Term of loan',
        type: 'select',
        options: [...Array(31).keys()]?.slice(5)?.map((number) => `${number} year${number === 1 ? '' : 's'}`),
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'repaymentFrequency',
        type: 'select',
        options: ['Weekly', 'Fortnightly', 'Monthly'],
        label: 'Repayment frequency',
        onChange: (data) => submitHandler(data),
      },
    ],
  },
  {
    title: 'Your offset account details',
    titleIcon: <DollarCoin className="title-icon" />,
    fields: [
      {
        type: 'currency',
        name: 'offsetAccountBalance',
        label: 'Offset account balance',
        onBlur: (data) => submitHandler(data),
        validation: {
          required: true,
          pattern: /^([5-9]\d{3,}|\d{4,})$/i,
          max: {
            value: 999999999,
            message: 'Please enter an amount under $999,999,999',
          },
        },
        validationMessage: 'Please enter offset account balance',
      },
    ],
  },
];
