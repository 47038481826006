import { makeVar } from '@apollo/client';

const commonApplicantFields = {
  userId: '',
  firstName: '',
  userVerified: false,
  idVerified: false,
  bankStatementsReceived: false,
  applicationId: '',
  customTitle: '',
};

export const currentApplicantVar = makeVar(commonApplicantFields);

export const nextApplicantVar = makeVar(commonApplicantFields);

export const REACTIVE_VARS = {
  currentApplicantVar,
  nextApplicantVar,
};
