import { camalise } from '../../../../../utils/camalise';

const withShortName = ['administrativeAreaLevel1', 'administrativeAreaLevel2'];

export const formatAddress = (addressComponents, searchBySuburb) => {
  const components = addressComponents?.reduce((acc, item) => {
    const { types, short_name: shortName, long_name: longName } = item;
    const typeId = types?.[0];
    const type = camalise(typeId);
    const isShortName = withShortName?.includes(type);
    const name = isShortName ? shortName : longName;
    return { ...acc, [type]: name };
  }, {});

  // Custom components naming
  const addressOne = !searchBySuburb ? `${components?.streetNumber} ${components?.route}` : '';
  const addressTwo = components?.subpremise ? `U${components?.subpremise}` : '';
  const city = !searchBySuburb ? components?.administrativeAreaLevel2 : components?.locality;
  const postCode = components?.postalCode;
  const state = components?.administrativeAreaLevel1;
  const country = components?.country;
  return {
    defaultAddressComponents: components,
    customAddressComponents: {
      addressOne,
      addressTwo,
      city,
      postCode,
      state,
      country,
    },
  };
};
