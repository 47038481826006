export default [
  {
    type: 'currency',
    name: 'depositAmount',
    label: 'Deposit amount',
    namePrismic: 'Property - Deposit Amount',
    slug: 'property-deposit-amount',
    placeholder: 'Enter an amount',
    validationMessage: 'Please enter deposit amount',
    helper: (value) => `${value} deposit amount`,
  },
];
