import { onSubmit } from './onSubmit';

export default [
  {
    type: 'group',
    name: 'dependants',
    namePrismic: 'About - Dependants',
    slug: 'about-dependants',
    onSubmit,
    showGroupValidations: false,
    subfields: [
      {
        type: 'radio',
        name: 'otherDependants',
        options: [{ text: 'Yes' }, { text: 'No' }],
      },
      {
        type: 'arrayAccordion',
        name: 'otherDependantsList',
        addButtonLabel: 'Add dependent',
        showGroupValidations: true,
        toggleLabel: ({ index }) => `Dependent ${index + 1}`,
        conditional: ({ formData }) => formData?.otherDependants === 'Yes',
        validation: ({ formData }) => {
          if (formData?.otherDependants === 'Yes') {
            return {
              min: 1,
            };
          }
        },
        subfields: [
          {
            type: 'select',
            name: 'dependantType',
            placeholder: 'Select one',
            label: 'Type of dependant',
            options: ['Child', 'Spouse', 'Partner', 'Other'],
            className: 'split-left',
          },
          {
            type: 'number',
            name: 'dependantAge',
            label: 'Age',
            placeholder: 'Enter their age',
            validation: ({ formData }) => {
              if (formData.otherDependants === 'Yes') {
                return {
                  min: {
                    value: 0,
                    message: 'Please enter valid age',
                  },
                  max: {
                    value: 120,
                    message: 'Please enter valid age',
                  },
                  required: {
                    value: true,
                    message: 'Please enter valid age',
                  },
                };
              }
            },
            className: `split-right`,
          },
        ],
      },
      {
        type: 'total',
        name: 'otherDependantsTotal',
        conditional: ({ formData }) => formData?.otherDependants === 'Yes',
        helper: ({ formData }) => {
          const dependantsCount = formData?.otherDependantsList?.length;
          if (!dependantsCount) return null;
          return `
          <div class="inner-container flex-divider">
            👨‍👩‍👦 Total dependants: <span>${dependantsCount}</span>
          </div>
          `;
        },
      },
    ],
  },
];
