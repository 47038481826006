import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import { onSubmit } from './onSubmit';

// Init to caclulate date from now
dayjs.extend(relativeTime);
dayjs.extend(duration);

export default [
  {
    type: 'group',
    name: 'approvalSpeed',
    namePrismic: 'Mortgage - Approval Speed',
    onSubmit,
    slug: 'mortgage-approval-speed',
    showGroupValidations: false,
    subfields: [
      {
        type: 'radio',
        name: 'mortgageQuickOffer',
        placeholder: 'Select one',
        label: '',
        options: [
          { text: 'Yesterday' },
          { text: 'Quick! I need approval in the next 7 days' },
          { text: 'Standard approval and settlement timeframes are OK' },
          { text: 'Need to settle by an exact date' },
        ],
      },
      {
        type: 'date',
        name: 'settlementDate',
        placeholder: 'DD/MM/YYYY',
        label: 'Preferred settlement date',
        validation: ({ formData }) => {
          const currentDate = dayjs();
          const dateSelected = dayjs(formData?.settlementDate);
          const dateSelectedValidated = dateSelected?.isValid();
          const dateDifference = dateSelected?.diff(currentDate, 'day');
          const isValidDate = dateDifference < 150 && dateDifference > 0;

          return {
            validate: (v) => (dateSelectedValidated && isValidDate) || 'Please select date less than 5 months from now',
          };
        },
        validationMessage: 'Please enter date',
        conditional: ({ formData }) => {
          if (formData?.mortgageQuickOffer === 'Need to settle by an exact date') return true;

          return false;
        },
      },
    ],
  },
];
