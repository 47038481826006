import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as styles from './styles.module.scss';

const FieldLabel = (props) => {
  const { label, altLabel, name, className } = props;
  if (!label && !altLabel) return null;

  const classes = classNames(altLabel ? styles.altLabel : styles.label, className, 'field-label');

  return (
    <label htmlFor={name} className={classes}>
      {altLabel || label}
    </label>
  );
};

FieldLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

FieldLabel.defaultProps = {
  className: null,
  label: null,
};

export default FieldLabel;
