import { gql } from '@apollo/client';

export const ABOUT_USER_FRAGMENTS = {
  'about-you': {
    'about-details': gql`
      fragment Details on AboutUser {
        firstName
        title
        middleName
        surname
        birthDate
        phoneNumber
        maritalStatus
        retirementAge
      }
    `,
    'about-citizenship': gql`
      fragment Citizenship on AboutUser {
        citizen
        visaType
      }
    `,
    'about-address': gql`
      fragment AddressHistory on AboutUser {
        addressHistory {
          id
          order
          addressOne
          addressTwo
          city
          postCode
          state
          country
          moveInDate
          moveOutDate
          rentOwnStatus
        }
      }
    `,
    'about-work': gql`
      fragment Work on AboutUser {
        employmentHistory {
          id
          order
          employmentType
          jobTitle
          company
          startDate
          endDate
        }
      }
    `,
    'about-income': gql`
      fragment Income on AboutUser {
        salary
      }
    `,
    'about-additional-income': gql`
      fragment AdditionalIncome on AboutUser {
        otherIncome
        OtherIncomeSources {
          id
          order
          otherIncomeAmount
          otherIncomeType
        }
      }
    `,
    'about-assets': gql`
      fragment Assets on AboutUser {
        otherAssetsHomeContentsList {
          id
          order
          estValue
        }
        otherAssetsSuperList {
          id
          order
          assetOwnership
          estValue
          otherAssetsSuper
        }
        otherAssetsSavingsList {
          id
          order
          estValue
          assetOwnership
          bank
        }
        assetsHomeAddressList {
          id
          order
          assetOwnership
          estValue
          addressOne
          addressTwo
          city
          postCode
          state
          country
        }
        assetsInvestmentAddressList {
          id
          order
          assetOwnership
          estValue
          monthlyRent
          addressOne
          addressTwo
          city
          postCode
          state
          country
        }
        otherAssetsVehicleList {
          id
          order
          assetOwnership
          estValue
          vehicleDetails
        }
        otherAssetsTotalSharesList {
          id
          order
          assetOwnership
          estValue
        }
        assetsCryptoCurrency {
          id
          order
          ownership
          value
        }
        otherAssets {
          id
          order
          ownership
          value
          assetType
        }
      }
    `,
    'about-liabilities': gql`
      fragment Liabilities on AboutUser {
        liabilitiesCreditCardLenderList {
          id
          order
          lender
          ownership
          limit
          balance
          refinance
        }
        liabilitiesHomeLoanLenderList {
          id
          order
          lender
          ownership
          repayment
          balance
          rate
          refinance
        }
        liabilitiesVehicleLoanLenderList {
          id
          order
          lender
          ownership
          repayment
          balance
          rate
          refinance
        }
        storeCards {
          id
          order
          balance
          limit
          ownership
          lender
        }
        liabilitiesPersonalLenderList {
          id
          order
          lender
          repayment
          balance
          ownership
          refinance
        }
        liabilitiesSmsfLoanLenderList {
          id
          order
          lender
          ownership
          repayment
          balance
          rate
          refinance
        }
        liabilitiesStudentDebtList {
          id
          order
          lender
          repayment
          balance
        }
      }
    `,
    'about-dependants': gql`
      fragment Dependants on AboutUser {
        otherDependants
        otherDependantsList {
          id
          order
          dependantType
          dependantAge
        }
      }
    `,
    flowProgress: gql`
      fragment FlowProgressAbout on AboutUser {
        complete
        totalFields
        completedFields
      }
    `,
  },
};
