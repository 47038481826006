import React from 'react';
import { Image } from 'components';
import './styles.scss';

const ImageSlice = (props) => {
  const { data } = props;
  const { primary } = data;
  const { image } = primary;

  return (
    <div className="image-slice">
      <Image image={image} />
    </div>
  );
};

export default ImageSlice;
