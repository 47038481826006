import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

// const portalRoot = document.getElementById('portal-footer');

const Portal = ({ children }) => {
  const portalRoot = typeof document !== `undefined` && document.querySelector(`#portal-footer`);

  const el = useMemo(() => document.createElement('div'), []);
  useEffect(() => {
    portalRoot?.appendChild(el);
    return () => {
      portalRoot?.removeChild(el);
    };
  }, [portalRoot]);

  return createPortal(children, el);
};

export default Portal;
