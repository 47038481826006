import { onSubmit } from './onSubmit';

export default [
  {
    type: 'group',
    name: 'citizenship',
    namePrismic: 'About - Citizenship',
    slug: 'about-citizenship',
    onSubmit,
    subfields: [
      {
        type: 'radios',
        name: 'citizen',
        placeholder: 'Select one',
        label: '',
        options: [
          { text: 'Aus Citizen 🇦🇺', value: 'AU Citizen' },
          { text: 'NZ Citizen 🇳🇿', value: 'NZ Citizen' },
          { text: 'Permanent Resident' },
          { text: 'Working Visa' },
        ],
      },
      {
        type: 'text',
        name: 'visaType',
        placeholder: 'Enter visa subclass',
        label: 'What Visa?',
        // defaultValue: storedCachedData?.visaType || '',
        conditional: ({ formData }) => {
          const value = formData?.citizen;
          if (!value || value === 'Permanent Resident' || value.includes('Aus') || value.includes('NZ')) return false;
          if (value === 'Working Visa') return true;
          return false;
        },
        validationMessage: 'Please enter current visa',
      },
    ],
  },
];
