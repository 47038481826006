import { gql } from '@apollo/client';

export const MISC_FRAGMENTS = {
  approval: gql`
    fragment GetApproved on Approval {
      id
      additionalQuestions
      termsConditionsAccepted
      extraOptions
      applicationId
      __typename
    }
  `,
};
