import { defaultOnSubmit } from '../../../utils/defaultOnSubmit';

const defaultSubmitDataHandler = ({ isRefinancing }) => {
  const defaultOptions = [
    {
      value: 'best mix',
      order: 0,
    },
    {
      value: 'pay more',
      order: 1,
    },
    {
      value: 'fastest response',
      order: 2,
    },
    {
      value: 'borrowing power',
      order: isRefinancing ? 4 : 3,
    },
  ];

  if (!isRefinancing) return defaultOptions;

  const refinancingOption = {
    value: 'home value',
    order: 3,
  };

  if (isRefinancing) {
    defaultOptions?.splice(3, 0, refinancingOption);
    return defaultOptions;
  }
};

export const onSubmit = async ({ formData, apolloClient, extraData }) => {
  const isRefinancing = extraData?.isRefinancing;

  const variables = {
    ...formData,
    keyPriorities:
      formData?.keyPriorities?.length === 0
        ? defaultSubmitDataHandler({ isRefinancing })
        : formData?.keyPriorities?.map((keyPriority) => ({
            id: keyPriority?.id || null,
            order: keyPriority?.order,
            value: keyPriority?.value,
          })),
  };

  return defaultOnSubmit({ formData: variables, apolloClient, extraData });
};
