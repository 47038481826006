export const commonValidations = ({ fieldName = '', fieldType = '', validationMessage = '' }) => {
  let nameToCheck = fieldName;

  // Check if react hook form field array name - structure foo.index.bar
  if (/(.)\1+/?.test(nameToCheck)) {
    nameToCheck = fieldName?.split('.')?.[fieldName?.split('.').length - 1];
  }

  const defaultValidationMessage = 'Please enter valid';
  const validationMessageLookup = ({ messageType = '' }) => {
    if (validationMessage) return validationMessage;

    const messageLookup = {
      text: 'Please enter value',
      select: 'Please select option',
      currency: `${defaultValidationMessage} currency`,
      address: `${defaultValidationMessage} address`,
      city: `${defaultValidationMessage} city`,
      state: `Please select state or territory`,
      postCode: `${defaultValidationMessage} postcode`,
      country: `${defaultValidationMessage} country`,
      phoneNumber: `${defaultValidationMessage} phone number`,
      name: `${defaultValidationMessage} name`,
    };

    return messageLookup?.[messageType];
  };

  const validationLookup = {
    // default validations
    text: {
      required: {
        value: !!validationMessage,
        message: validationMessageLookup({ messageType: 'text' }),
      },
    },
    select: {
      required: {
        value: true,
        message: validationMessageLookup({ messageType: 'select' }),
      },
    },
    currency: {
      required: {
        value: true,
        message: validationMessageLookup({ messageType: 'currency' }),
      },
      min: {
        value: 0,
        message: validationMessageLookup({ messageType: 'currency' }),
      },
      max: {
        value: 247483600,
        message: validationMessageLookup({ messageType: 'currency' }),
      },
      pattern: {
        value: /^[0-9]+$/,
        message: validationMessageLookup({ messageType: 'currency' }),
      },
      validate: (v) => typeof v === 'number' || validationMessageLookup({ messageType: 'currency' }),
    },

    // common validations
    name: {
      required: {
        value: true,
        message: validationMessageLookup({ messageType: 'name' }),
      },
      pattern: {
        value: /^[A-Za-z\s\-]+$/,
        message: validationMessageLookup({ messageType: 'name' }),
      },
    },
    addressOne: {
      required: {
        value: true,
        message: validationMessageLookup({ messageType: 'address' }),
      },
      pattern: {
        value: /^[0-9A-Za-z\s\-]+$/,
        message: validationMessageLookup({ messageType: 'address' }),
      },
    },
    addressTwo: {
      pattern: { value: /^[0-9A-Za-z\s\-]+$/, message: validationMessageLookup({ messageType: 'address' }) },
    },
    city: {
      required: { value: true, message: validationMessageLookup({ messageType: 'city' }) },
      pattern: { value: /^[A-Za-z0-9'\.\-\s\,]+$/, message: validationMessageLookup({ messageType: 'city' }) },
    },
    state: {
      required: {
        value: true,
        message: validationMessageLookup({ messageType: 'state' }),
      },
    },
    postCode: {
      required: {
        value: true,
        message: validationMessageLookup({ messageType: 'postCode' }),
      },
      pattern: { value: /^[A-Za-z0-9]+$/i, message: validationMessageLookup({ messageType: 'postCode' }) },
    },
    country: {
      required: {
        value: true,
        message: validationMessageLookup({ messageType: 'country' }),
      },
      pattern: { value: /^[A-Za-z\s\-]+$/, message: validationMessageLookup({ messageType: 'country' }) },
    },
    phoneNumber: {
      required: {
        value: true,
        message: validationMessageLookup({ messageType: 'phoneNumber' }),
      },
      minLength: {
        value: 8,
        message: validationMessageLookup({ messageType: 'phoneNumber' }),
      },
      maxLength: {
        value: 12,
        message: validationMessageLookup({ messageType: 'phoneNumber' }),
      },
      pattern: {
        value: /^\S*04(\s?[0-9]{2})([0-9]{3}[0-9]{3}|[0-9]{2}[0-9]{2}[0-9]{2})$/i,
        message: validationMessageLookup({ messageType: 'phoneNumber' }),
      },
    },
  };

  validationLookup.radio = validationLookup?.select;
  validationLookup.checkbox = validationLookup?.select;

  validationLookup.firstName = validationLookup?.name;
  validationLookup.middleName = validationLookup?.name;
  validationLookup.surname = validationLookup?.name;

  validationLookup.invitePhoneNumber = validationLookup?.phoneNumber;

  return validationLookup?.[nameToCheck] || validationLookup?.[fieldType];
};
