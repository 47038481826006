import React from 'react';
import { CalculatorTemplate } from 'components';
import { navigate } from 'gatsby';
import { ReactComponent as MoneyBag } from '../../../images/iconography/money-bag.svg';
import { fieldsDefault, controlsBlockFieldsDefault } from './fields';
import { isBrowser } from '../../../utils/isBrowser';
import { SERVER } from '../../../utils/server';
import * as styles from './styles.module.scss';

const BorrowingHomeLoanCalculator = (props) => {
  // CALCULATOR FORM DATA
  const calculatorEndpoint = `${SERVER}/calculators/borrowing-home-loan`;

  const defaultValues = {
    termOfLoan: '30 years',
    numberOfApplicants: 'Just me',
    loanPurpose: 'To live in',
    purchaseOrRefinance: 'Purchase',
    dependants: '0',
    combinedIncome: 100000,
    incomePeriod: '/year',
    otherIncome: [],
    rentalIncome: [],
    creditCardLimits: 20000,
    vehicleLoanRepayments: 1200,
    periodVehicleLoanRepayments: '/month',
    householdExpenses: 2000,
    periodHouseholdExpenses: '/month',
    otherMortgages: [],
  };

  const defaultCalculationData = 0;

  // START APPLICATION
  const handleStartApplication = (data) => {
    if (isBrowser) {
      const { termOfLoan, numberOfApplicants, loanPurpose, purchaseOrRefinance } = data;

      const storedLeadFlowData = JSON.parse(localStorage.getItem('yb-data'));

      const formattedTermOfLoan = Number(termOfLoan?.slice(0, -6));

      const numberOfApplicantsLookup = {
        'Just me': 'One',
        'My partner and I': 'Two',
        'A group of investors': 'Three+',
      };

      const formattedNumberOfApplicants = numberOfApplicantsLookup?.[numberOfApplicants];

      localStorage.setItem(
        'yb-data',
        JSON.stringify({
          ...storedLeadFlowData,
          termOfLoan: formattedTermOfLoan,
          propertyUse: loanPurpose,
          purchaseOrRefinance,
          numberOfApplicants: formattedNumberOfApplicants,
        })
      );

      navigate('/get-started');
    }
  };

  // DISPLAY COUNT
  const controlsBlockConfigDefault = (data) => ({
    displayCountConfig: [
      {
        icon: <MoneyBag className="top-icon" />,
        displayType: 'secondaryAlt',
        title: 'Your borrowing power is up to:',
        endCount: Number(data),
        className: styles.primaryDisplayCount,
      },
    ],
    controlsBlockFieldsDefault,
  });

  return (
    <CalculatorTemplate
      {...props}
      defaultValues={defaultValues}
      onHandleStartApplication={handleStartApplication}
      controlsBlockConfigDefault={controlsBlockConfigDefault}
      fieldsDefault={fieldsDefault}
      defaultCalculationData={defaultCalculationData}
      calculatorEndpoint={calculatorEndpoint}
      className={styles.borrowingPowerCalculator}
      showGroupValidations
    />
  );
};

export default BorrowingHomeLoanCalculator;
