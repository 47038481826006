import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';

const FieldOrder = (props) => {
  const { name, order = 0 } = props;
  const { field } = useController({ name, defaultValue: 0 });
  useEffect(() => {
    field?.onChange(order);
  }, [order]);
  const value = field?.value || 0;
  return <input {...field} type="hidden" value={value} />;
};

export default FieldOrder;
