import React, { useState, useEffect } from 'react';
import { Image, Link, OnVisible } from 'components';
import './styles.scss';

const BlogCard = (props) => {
  const { data, className, uid: slug, showExcerpt, isMainFeaturedPost, dataString } = props;
  // time to read
  const [minsToRead, setMinsToRead] = useState(0);

  useEffect(() => {
    const wordsPerMinute = 200;
    const textLength = dataString && dataString.split(' ').length; // Split by words
    if (textLength > 0) {
      setMinsToRead(Math.ceil(textLength / wordsPerMinute));
    }
  }, [dataString]);

  // data
  if (!data) return null;

  const { title, hero_image: heroImage, category, excerpt, timestamp, author } = data;

  const categoryUid = category?.uid;
  const categoryName = category?.document?.data?.category_name?.text;

  // content checks
  const titleText = title?.text;
  const hasImage = heroImage?.url;
  const excerptText = excerpt?.text;
  const authorText = author?.text;

  return (
    <OnVisible className={`blog-post-card ${className || ''}`}>
      {hasImage && (
        <Link to={`/articles/${slug}`} className="blog-post-card-image-link">
          <Image image={heroImage} className="blog-post-card-image" />
        </Link>
      )}
      <div className="blog-post-card-text-container">
        {categoryName && (
          <Link to={`/articles/${categoryUid}`} className="blog-post-card-category">
            {categoryName}
          </Link>
        )}
        {titleText && (
          <Link to={`/articles/${slug}`} className="blog-post-card-title">
            <span>{titleText}</span>
          </Link>
        )}
        {excerptText && showExcerpt && (
          <div dangerouslySetInnerHTML={{ __html: excerpt?.html }} className="blog-post-card-excerpt" />
        )}
        {(timestamp || authorText) && (
          <div className="blog-post-card-time-author">
            <span>{timestamp}</span>
            <span> &nbsp; | &nbsp; </span>
            <span className="blog-post-card-mins-text">{minsToRead} min read</span>
          </div>
        )}
      </div>
    </OnVisible>
  );
};

export default BlogCard;
