import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { BlogHeader, PostGrid, SEO, Layout, FooterCta } from 'components';
import './category.scss';

const Category = ({ data, location, pageContext }) => {
  const { category, categoryPosts, featuredCategoryPosts } = data;
  const { data: pageData, first_publication_date: publishedDate, last_publication_date: updatedDate } = category;
  const { nodes: posts = [] } = categoryPosts;
  const { nodes: featuredPosts = [] } = featuredCategoryPosts;

  const {
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    category_name: categoryName,
  } = pageData;

  // seo data
  const seo = {
    title: metaTitle?.text || `${categoryName?.text}`,
    desc: metaDesc?.text || `Read insightful articles on ${categoryName?.text}`,
    banner: ogImage?.url,
    publishedDate,
    updatedDate,
  };

  return (
    <Layout>
      <SEO {...seo} location={location} />
      <div className="category">
        <BlogHeader data={{ blog_title: categoryName, blog_secondary_title: { html: '<h1>Posts</h1>' } }} />
        {/* <CategoryHeader category={categoryName} description={categoryDescription} /> */}
        <div className="category-posts-container">
          {featuredPosts.length === 2 && <PostGrid posts={featuredPosts} title={`Featured ${categoryName.text}`} />}
          {posts.length > 0 && <PostGrid posts={posts} title={`All ${categoryName.text}`} />}
          {!posts || (posts.length < 1 && <span className="category-down-state">No posts found</span>)}
        </div>
        <FooterCta />
      </div>
    </Layout>
  );
};

export default withPrismicPreview(Category);

export const categoryQuery = graphql`
  query CategoryBySlug($uid: String!) {
    category: prismicCategory(uid: { eq: $uid }) {
      _previewable
      id
      uid
      prismicId
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        category_name {
          html
          text
        }
      }
    }
    categoryPosts: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: $uid } } } }
      sort: { order: DESC, fields: data___timestamp }
    ) {
      nodes {
        dataRaw
        uid
        id
        data {
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
          timestamp(formatString: "MMMM D, YYYY")
          title {
            html
            text
          }
        }
      }
    }
    featuredCategoryPosts: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: $uid } } } }
      sort: { order: DESC, fields: data___timestamp }
      limit: 2
    ) {
      nodes {
        dataRaw
        uid
        id
        data {
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
          timestamp(formatString: "MMMM D, YYYY")
          title {
            html
            text
          }
        }
      }
    }
  }
`;
