import React from 'react';

const FieldHidden = (props) => {
  const { name, className, register, validation, defaultValue } = props;

  return (
    <input
      {...register(name, validation)}
      type="hidden"
      className={className}
      name={name}
      defaultValue={defaultValue}
    />
  );
};

export default FieldHidden;
