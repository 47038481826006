import React, { useEffect, useState } from 'react';
import { FormFlow } from 'components';
import { fields } from './fields';

const InviteApplicant = (props) => {
  const { extra } = props;

  const { applicantCoreData } = extra;
  const invitedApplicants = applicantCoreData?.currentUser?.leadApplication?.additionalUsers;
  const primaryApplicant = applicantCoreData?.currentUser;

  const currentInvitedApplicantIdKey = 'yb-invited-applicant-id';
  const storedInvitedApplicant = localStorage.getItem(currentInvitedApplicantIdKey);
  const currentInvitedApplicantFormValsKey = `yb-invited-applicant-form-vals-${storedInvitedApplicant}`;

  // Get Applicant Ids and Data
  const [currentInvitedApplicantId, setCurrentInvitedApplicantId] = useState(
    storedInvitedApplicant || invitedApplicants?.[0]?.id
  );

  const currentInvitedApplicant = invitedApplicants?.find(
    (applicant, index) => applicant?.id === currentInvitedApplicantId
  );

  useEffect(() => {
    if (!currentInvitedApplicant) {
      setCurrentInvitedApplicantId(storedInvitedApplicant || invitedApplicants?.[0]?.id);
    }
  }, [invitedApplicants]);

  const extraData = {
    applicantCoreData,
    primaryApplicant,
    currentInvitedApplicant,
  };

  // Load fields

  return (
    <FormFlow
      fields={fields({ currentInvitedApplicant })}
      hasTwoFactor={false}
      hideProgressBar
      extraData={extraData}
      localStorageKey={currentInvitedApplicantFormValsKey}
      formHeaderAlignCenter
    />
  );
};
export default InviteApplicant;
