import React from 'react';
import { FormSubmitButton, OnVisible } from 'components';
import MobileDropShadow from '../MobileDropShadow';
import DisplayCountContainer from '../DisplayCountContainer';
import FieldsBlock from '../FieldsBlock';
import ControlsBlockFormError from '../ControlsBlockFormError';
import * as styles from './styles.module.scss';

const ControlsBlock = (props) => {
  const {
    controlsBlockConfigDefault,
    methods,
    calculationData,
    setCalculationData,
    onSubmit,
    bottomOfCalculatorIsInView,
    onHandleStartApplication,
  } = props;

  const { formState, watch } = methods;
  const { errors } = formState;

  const controlsBlockConfig = controlsBlockConfigDefault(calculationData, watch());

  const { displayCountConfig, controlsBlockFieldsDefault = null, description } = controlsBlockConfig;

  const controlsBlockFields = typeof controlsBlockFieldsDefault === 'function' && controlsBlockFieldsDefault(onSubmit, calculationData, setCalculationData);

  return (
    <div className={`${styles.controlsBlockContainer} controls-block-container`}>
      <OnVisible
        className={`${styles.controlsBlock} controls-block`}
        visibleClassName="controls-block-container-visible"
        triggerMultiple
      >
        <MobileDropShadow whenToFadeOut={bottomOfCalculatorIsInView} />
        {displayCountConfig && <DisplayCountContainer displayCountConfig={displayCountConfig} />}
        {controlsBlockFields?.length > 0 && (
          <div className={styles.hideMobile}>
            <FieldsBlock fields={controlsBlockFields} methods={methods} errors={errors} />
          </div>
        )}
        {description?.html && (
          <div dangerouslySetInnerHTML={{ __html: description?.html }} className={styles.description} />
        )}
        <ControlsBlockFormError errors={errors} />
        <FormSubmitButton
          label="Create your profile"
          className={styles.formSubmitButton}
          onClick={() => onHandleStartApplication(watch())}
        />
      </OnVisible>
    </div>
  );
};

export default ControlsBlock;
