import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as styles from './styles.module.scss';

const FormError = (props) => {
  const { className, message, errors = {}, showGroupValidations = false } = props;

  if (!showGroupValidations) {
    const classes = classNames(styles.container, message ? styles.active : '', className);
    return <div className={classes}>{message}</div>;
  }

  const errorList = Object.keys(errors);
  const hasWarning = !!Object?.values(errors)?.find((vals) => vals?.type === 'warning');
  const hasErrors = errorList?.length > 0;
  if (!hasErrors) return null;

  const classes = classNames(styles.container, message || hasErrors ? styles.active : '', className);

  return (
    <div className={classes}>
      <div className={`${styles.wrapper} ${hasWarning ? styles.warning : ''}`}>
        <ul>
          {errorList?.map((errorKey) => {
            const error = errors[errorKey];
            if (!error?.message) return null;
            return <li key={error?.message}>{error?.message}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

FormError.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

FormError.defaultProps = {
  className: null,
  message: null,
};

export default FormError;
