import { gql } from '@apollo/client';
import { FACT_FIND_FRAGMENTS } from '../fragments/fragments_ff';

const {
  leadApplication,
  'about-you': aboutUser,
  expenses,
  property: Property,
  mortgage: MortgagePreference,
  MortgageShortlist,
  approval,
} = FACT_FIND_FRAGMENTS;

export const GET_USER_LOGIN_DATA = gql`
  query GetUserLoginData($email: String) {
    currentUser(email: $email) {
      name
      currentFlowStep
      applicationId
    }
  }
`;

export const GET_APPLICANT_CORE_DATA = gql`
  query GetApplicantCoreData($email: String) {
    currentUser(email: $email) {
      id
      email
      name
      userVerified
      currentFlowStep
      leadApplication {
        additionalUsers {
          id
          name
          inviteSent
          leadApplicationId
        }
      }
      application {
        id
        isRefinance
        refinanceCurrentAddress
        Users {
          id
          applicationId
          primaryApplicant
          email
          name
          userVerified
          aboutUser {
            id
            firstName
            surname
            idVerified
            bankStatementsReceived
            ...AddressHistory
            complete
            totalFields
            completedFields
          }
          Expenses {
            id
            complete
            totalFields
            completedFields
          }
        }
        Property {
          id
          depositAmount
          complete
          totalFields
          completedFields
        }
        PropertyRefinance {
          id
          ...RefinanceAddress
          ...UsageDetails
        }
        MortgagePreference {
          id
          loanAmount
          complete
          totalFields
          completedFields
        }
        MortgageShortlist {
          ...ProductData
        }
      }
    }
  }
  # Mortgage shortlist fragment
  ${MortgageShortlist}
  # About user fragments
  ${aboutUser?.['about-address']}
  # Property refinance fragments
  ${Property?.['property-refinance-address']}
  ${Property?.['property-usage-details']}
`;

export const GET_USER = gql`
  query GetAllApplicantData($email: String) {
    currentUser(email: $email) {
      email
      id
      applicationId
      userVerified
      currentFlowStep
      name
      ...LeadApplication
      application {
        id
        isRefinance
        refinanceCurrentAddress
        Approval {
          ...GetApproved
        }
        Users {
          id
          name
          email
          phoneNumber
          userVerified
          applicationId
          leadApplicationId
          primaryApplicant
          currentFlowStep
          aboutUser {
            id
            idVerified
            bankStatementsReceived
            ...Details
            ...Citizenship
            ...AddressHistory
            ...Work
            ...Income
            ...AdditionalIncome
            ...Assets
            ...Liabilities
            ...Dependants
            ...FlowProgressAbout
            __typename
          }
          Expenses {
            ...ExpensesTotal
            ...FlowProgressExpenses
            __typename
          }
          __typename
        }
        MortgagePreference {
          id
          loanAmount
          refinanceLender
          currentLenderOther
          ...ApprovalSpeed
          ...WhatsImportantLoan
          ...WhatsImportantToYou
          ...KeyPriorities
          ...FlowProgressMortgagePreference
          __typename
        }
        Property {
          id
          ...Address
          ...PurchasePrice
          ...DepositAmount
          ...PropertyUse
          ...PropertyType
          ...FirstHomeBuyersGrant
          ...FlowProgressProperty
          __typename
        }
        PropertyRefinance {
          id
          applicationId
          ...RefinanceAddress
          ...UsageDetails
          __typename
        }
        MortgageShortlist {
          ...ProductData
        }
        __typename
      }
      __typename
    }
  }
  # Mortgage shortlist fragment
  ${MortgageShortlist}
  # Lead application fragments
  ${leadApplication}
  # About user fragments
  ${aboutUser?.['about-details']}
  ${aboutUser?.['about-citizenship']}
  ${aboutUser?.['about-address']}
  ${aboutUser?.['about-work']}
  ${aboutUser?.['about-income']}
  ${aboutUser?.['about-additional-income']}
  ${aboutUser?.['about-assets']}
  ${aboutUser?.['about-liabilities']}
  ${aboutUser?.['about-dependants']}
  ${aboutUser?.flowProgress}
  # Expenses fragments
  ${expenses?.['expenses-monthly-expenses']}
  ${expenses?.flowProgress}
  # Property fragments
  ${Property?.['property-address']}
  ${Property?.['property-purchase-price']}
  ${Property?.['property-deposit-amount']}
  ${Property?.['property-use']}
  ${Property?.['property-type']}
  ${Property?.['property-first-home-buyers-grant']}
  ${Property?.flowProgress}
  # Property refinance fragments
  ${Property?.['property-refinance-address']}
  ${Property?.['property-usage-details']}
  # Mortgage fragments
  ${MortgagePreference?.['mortgage-approval-speed']}
  ${MortgagePreference?.['mortgage-whats-important-loan']}
  ${MortgagePreference?.['mortgage-whats-important-to-you']}
  ${MortgagePreference?.['mortgage-key-priorities']}
  ${MortgagePreference?.flowProgress}
  # Approval
  ${approval}
`;
