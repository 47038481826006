import { navigate } from 'gatsby';

export const useCurrentFlowStepRedirect = (currentFlowStep = 0) => {
  const currentFlowStepRedirect = () => {
    if (currentFlowStep === 0) {
      return navigate('/get-started');
    }

    if (currentFlowStep === 1) {
      return navigate('/create-your-shortlist');
    }

    if (currentFlowStep === 2) {
      return navigate('/invite-other-applicants');
    }

    if (currentFlowStep === 3) {
      return navigate('/verify-your-details');
    }

    if (currentFlowStep === 4) {
      return navigate('/fact-find');
    }

    if (currentFlowStep === 5 || currentFlowStep === 6) {
      return navigate('/get-approved');
    }
  };

  const flowStepPaths = {
    0: '/get-started',
    1: '/create-your-shortlist',
    2: '/invite-other-applicants',
    3: '/verify-your-details',
    4: '/fact-find',
    5: '/get-approved',
    6: '/get-approved',
  };

  const getCurrentFlowStepPath = flowStepPaths?.[currentFlowStep];

  return { currentFlowStepRedirect, getCurrentFlowStepPath };
};
