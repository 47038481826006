import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { RateBar } from 'components';
import { getItemStyle, getListStyle, onDragEnd } from '../../../utils/draggable';
import { isBrowser } from '../../../utils/isBrowser';
import * as styles from './styles.module.scss';

const DraggableRateCards = (props) => {
  const { shortlistedProducts, setShortlistedProducts, disabled = false } = props;

  const handleChoices = (choice) => {
    const choiceLoan = 'choice loan';

    const choiceLookup = {
      1: `🥇 First ${choiceLoan}`,
      2: `🥈 Second ${choiceLoan}`,
      3: `🥉 Third ${choiceLoan}`,
    };

    return choiceLookup[choice];
  };

  const rateBarProps = (productData) => ({
    shortlistedProducts,
    setShortlistedProducts,
    productData,
  });

  return (
    <>
      <h4 className={styles.title}>Drag and drop your choice of loan in order of preference</h4>
      {isBrowser && (
        <DragDropContext
          onDragEnd={(result) =>
            onDragEnd({ result, draggableList: shortlistedProducts, onChange: setShortlistedProducts })
          }
        >
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle({ isDraggingOver: snapshot.isDraggingOver })}
                className={styles.shortlistContainer}
              >
                <div className={styles.dragBackground}>
                  {shortlistedProducts?.map(
                    (shortlistedProduct, index) =>
                      shortlistedProduct?.productId && (
                        <Draggable
                          key={shortlistedProduct?.productId}
                          draggableId={shortlistedProduct?.productId}
                          index={index}
                          isDragDisabled={disabled}
                        >
                          {(provided, snapshot) => (
                            <div
                              key={shortlistedProduct?.productId}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle({
                                isDragging: snapshot.isDragging,
                                draggableStyle: provided.draggableProps.style,
                                lockY: true,
                              })}
                            >
                              <div className={styles.choiceRank}>{handleChoices(index + 1)}</div>
                              <RateBar {...rateBarProps(shortlistedProduct)} provided={provided} hideShortlistButton />
                            </div>
                          )}
                        </Draggable>
                      )
                  )}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default DraggableRateCards;
