export default [
  {
    namePrismic: 'Property - Type',
    slug: 'property-type',
    type: 'radio',
    name: 'propertyType',
    goNextOnClick: true,
    options: [
      { text: 'Established property' },
      { text: 'Newly built property' },
      { text: 'Vacant land or construction' },
    ],
  },
];
