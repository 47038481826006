// extracted by mini-css-extract-plugin
export var section = "rXCD6YlF";
export var title = "ZUM3iDyU";
export var carouselContainer = "uUs3L+iN";
export var arrowContainer = "VjGPy3Fi";
export var arrow = "-Hke3B8m";
export var hideArrow = "OVVcWOBz";
export var testimonialContainer = "iKxkBldO";
export var testimonial = "_99-HwLCs";
export var testimonialText = "ycqjHqPA";
export var testimonialImage = "r7DdEw0H";
export var quoteMarks = "jTprdSAg";
export var quote = "aFnHg58F";
export var starsContainer = "vXF+vwZQ";
export var stars = "ml+efRCp";
export var name = "ndfQPTE8";
export var controls = "DRLRIeeH";
export var controlsActive = "_1SxULNCj";