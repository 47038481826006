import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { CtaArrow } from 'components';
import * as styles from './styles.module.scss';

const Link = (props) => {
  const {
    children,
    activeClassName = '',
    className = '',
    arrowClassName = '',
    id = null,
    to,
    target = '_blank',
    title = null,
    ariaLabel = null,
    tabIndex,
    onClick = () => {},
    onMouseOver = () => {},
    onMouseOut = () => {},
    withChevron,
    disable,
    state,
    style,
  } = props;

  const sharedProps = {
    id,
    title,
    'aria-label': ariaLabel,
    tabIndex,
    onClick,
    onMouseOver,
    onMouseOut,
    // withChevron,
  };

  if (!to)
    return (
      <span className={className || ''} {...sharedProps}>
        {children}
      </span>
    );

  const isExternal = to?.indexOf('http') !== -1 || to === '#' || to?.indexOf('mailto:') !== -1;

  if (isExternal) {
    return (
      <a
        {...sharedProps}
        href={to}
        className={`${styles.link} ${className || ''}`}
        target={target}
        rel="nofollow noopener noreferrer"
        style={disable ? { pointerEvents: 'none' } : { ...style }}
      >
        {children}
        {withChevron && <CtaArrow className={`${arrowClassName || ''} ${styles.arrow}`} />}
      </a>
    );
  }

  return (
    <GatsbyLink
      {...sharedProps}
      to={to}
      activeClassName={activeClassName || 'active'}
      className={`${className || ''} ${styles.link}`}
      style={disable ? { pointerEvents: 'none' } : { ...style }}
      state={state}
    >
      {children}
      {withChevron && <CtaArrow className={`${arrowClassName || ''} ${styles.arrow}`} />}
    </GatsbyLink>
  );
};

export default Link;
