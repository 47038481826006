import { navigate } from 'gatsby';
import { formatCurrency } from '../../../../../utils/formatCurrency';
import { onSubmit } from './onSubmit';

const validationMessage = 'Please enter valid expenditure';

export default [
  {
    type: 'group',
    name: 'expensesMonthlyExpenses',
    namePrismic: 'Expenses - Monthly Expenses',
    slug: 'expenses-monthly-expenses',
    goNext: () => navigate('/get-approved'),
    showGroupValidations: false,
    onSubmit,
    subfields: [
      {
        type: 'currency',
        name: 'basicHousingAndPropertyExpenses',
        label: 'Basic Housing & Property Expenses (inc. Utilities)',
        altLabel:
          'Includes home maintenance and repairs, electricity, gas, water, garden maintenance, council rates, housekeeper.',
        validationMessage,
      },
      {
        type: 'currency',
        name: 'educationAndChildcare',
        label: 'Education & Childcare',
        altLabel: 'Includes childcare, babysitting.',
        validation: ({ extraData }) => {
          const currentApplicant = extraData?.currentApplicant;
          const applicantChildrenNumber = currentApplicant?.aboutUser?.otherDependantsList?.filter(
            (dependant) => dependant?.dependantType === 'Child'
          )?.length;

          return {
            min: {
              value: applicantChildrenNumber > 0 ? applicantChildrenNumber * 50 : 0,
              message: 'Please enter valid expenditure',
            },
          };
        },
        validationMessage,
        defaultValue: (props) => {
          const currentApplicant = props?.extraData?.currentApplicant;
          const applicantChildrenNumber = currentApplicant?.aboutUser?.otherDependantsList?.filter(
            (dependant) => dependant?.dependantType === 'Child'
          )?.length;

          return applicantChildrenNumber > 0 ? applicantChildrenNumber * 50 : '';
        },
      },
      {
        type: 'currency',
        name: 'communicationsAndMedia',
        label: 'Communications & Media',
        altLabel:
          'Includes landline, internet, mobile phone, subscription services (e.g. Foxtel, Netflix, Stan, Spotify).',
        validationMessage,
      },
      {
        type: 'currency',
        name: 'insurance',
        label: 'Insurance',
        altLabel:
          'Includes income protection insurance, home & contents insurance, vehicle insurance, travel insurance.',
        validationMessage,
      },
      {
        type: 'currency',
        name: 'foodAndGroceries',
        label: 'Food & Groceries',
        altLabel: 'Includes grocery shopping covering cigarettes, alcohol, restaurants and cafes, takeaway, delivery.',
        validationMessage,
      },
      {
        type: 'currency',
        name: 'clothingAndToiletries',
        label: 'Clothing & Toiletries',
        altLabel: 'Includes clothes & shoes, hair & beauty.',
        validationMessage,
      },
      {
        type: 'currency',
        name: 'essentialTravel',
        label: 'Essential Travel',
        altLabel:
          'Includes registration, fuel, maintenance, roadside assist, parking & tolls, public transport, taxi, rentals.',
        validationMessage,
      },
      {
        type: 'currency',
        name: 'leisureExpenses',
        label: 'Recreation & Holidays',
        altLabel:
          'Includes lifestyle and culture, newspapers, magazines, books, sports, hobbies, memberships (e.g. gym), gifts, holidays & airfares, donations.',
        validationMessage,
      },
      {
        type: 'currency',
        name: 'medical',
        label: 'Medical & Health',
        altLabel: 'Includes doctor, pharmacy, dentist, optical, physio, remedial, chiro, alternative therapies.',
        validationMessage,
      },
      {
        type: 'currency',
        name: 'otherExpenses',
        label: 'Other',
        altLabel: 'Other hobbies, furnishings and household items',
        validationMessage,
      },
      {
        type: 'total',
        name: 'total',
        helper: ({ formData, extraData }) => {
          const {
            basicHousingAndPropertyExpenses,
            educationAndChildcare,
            communicationsAndMedia,
            insurance,
            foodAndGroceries,
            clothingAndToiletries,
            essentialTravel,
            leisureExpenses,
            medical,
            otherExpenses,
          } = formData || {};

          const expenses = {
            basicHousingAndPropertyExpenses,
            educationAndChildcare,
            communicationsAndMedia,
            insurance,
            foodAndGroceries,
            clothingAndToiletries,
            essentialTravel,
            leisureExpenses,
            medical,
            otherExpenses,
          };

          const { currentApplicant } = extraData;
          const estMonthlySalary = parseInt(currentApplicant?.aboutUser?.salary / 12, 10);
          const estMonthlySalaryCurrency = formatCurrency(estMonthlySalary);

          const sum = Object.values(expenses).reduce((acc, val) => {
            if (!val) return acc;
            return acc + parseFloat(val);
          }, 0);
          const sumCurrency = formatCurrency(sum);

          const monthlySurplus = formatCurrency(estMonthlySalary - sum);

          const monthlySurplusNumber = estMonthlySalary - sum;

          const highlightColor = () => {
            if (monthlySurplusNumber > 200) return 'highlight-surplus';

            if (monthlySurplusNumber <= 0) return 'highlight-defecit';

            return 'highlight-narrow-surplus';
          };

          return `
            <div class="inner-container">
              <div class="flex-divider subtotal">
                Total expenses: <span>${sumCurrency}</span>
              </div>
              <div class="flex-divider subtotal">
                Total monthly income: <span>${estMonthlySalaryCurrency}</span>
              </div>
              <div class="flex-divider align-center total">
                💰 Total monthly surplus: <span class="${highlightColor()}">${monthlySurplus}</span>
              </div>
              <div class="${monthlySurplusNumber < 0 ? 'surplus-disclaimer' : 'hide-fields'}">
                Hey we've noted the expenses have gone negative, please check over the expenses for accuracy.
              </div>
            </div>
            `;
        },
      },
    ],
  },
];
