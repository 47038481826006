import React from 'react';
import DatePicker from 'react-date-picker';
import { useController } from 'react-hook-form';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as styles from './styles.module.scss';

dayjs.extend(customParseFormat);

const FieldDate = (props) => {
  const { name, className, validation, placeholder, autoFocus, isSubmitting } = props;

  const {
    field: { value, onChange },
  } = useController({
    name,
    rules: validation,
  });

  const onChangeHandler = (date) => {
    const dateParsed = dayjs(date)?.format('YYYY-MM-DD');
    onChange(dateParsed);
  };

  const dateParsed = !value || value === 'Invalid Date' || typeof value !== 'string' ? null : new Date(value);

  return (
    <DatePicker
      id={name}
      calendarClassName={styles.calendar}
      tileClassName={styles.tile}
      className={styles.date}
      name={name}
      value={dateParsed}
      onChange={onChangeHandler}
      clearIcon={null}
      calendarIcon={null}
      prev2Label={null}
      next2Label={null}
      minDetail="decade"
      defaultView="decade"
      format="d/M/y"
      minDate={new Date('01-01-1920')}
      maxDate={new Date('01-01-2100')}
      disabled={isSubmitting}
    />
  );
};

export default FieldDate;
