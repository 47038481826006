import React, { useState } from 'react';
import { ReactComponent as Plus } from '../../../../../images/add-row-field.svg';
import FieldArrayButtonAdd from '../../_FieldCommon/FieldArrayButtonAdd';
import * as styles from './styles.module.scss';

const FieldArrayMultiDropDown = (props) => {
  const { subfields, appendControls, addButtonLabel } = props;
  const [isVisible, toggleIsVisible] = useState(false);

  const filteredSubfields = subfields?.filter((subfield) => !subfield?.removeFromDropdown);

  const onClickHandler = () => {
    toggleIsVisible((state) => !state);
  };

  return (
    <div className={styles.container}>
      <FieldArrayButtonAdd onClick={onClickHandler} label={addButtonLabel} expanded={isVisible} />
      <div className={`${styles.dropdown} ${isVisible ? styles.active : ''}`}>
        {filteredSubfields.map((subfield) => {
          const { name: subfieldName, addButtonLabel: appendLabel } = subfield;
          const append = appendControls?.[subfieldName];
          const label = appendLabel || subfieldName;
          return (
            <button
              key={subfieldName}
              className={styles.button}
              type="button"
              onClick={() => {
                if (append) append();
                onClickHandler();
              }}
            >
              {label}
              <Plus className={styles.icon} />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default FieldArrayMultiDropDown;
