import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import * as styles from './styles.module.scss';

const FieldRadio = (props) => {
  const {
    watch,
    name,
    validation,
    options,
    goNext,
    goNextOnClick,
    reverseYesNoDefault,
    getValues,
    isSubmitting,
    onChange,
  } = props;

  // We are already passing down applicantCoreData in getValues()
  const radioOptions = typeof options === 'function' ? options(getValues()) : options;

  const methods = useFormContext();
  const control = methods?.methods?.control;

  const { field } = useController({
    name,
    rules: validation,
    control,
  });

  const onChangeHandler = (event) => {
    const inputValue = event.target.value;
    field.onChange(inputValue);
    if (onChange) onChange(watch());
    if (goNextOnClick && goNext) setTimeout(() => goNext(), 300);
  };

  // Reset boolean to 'Yes'/'No' values
  useEffect(() => {
    let value = field?.value;
    if (typeof value === 'boolean') {
      if (value) {
        value = reverseYesNoDefault ? 'No' : 'Yes';
      } else {
        value = reverseYesNoDefault ? 'Yes' : 'No';
      }

      field.onChange(value);
    }
  }, [field]);

  return (
    <div className="radio-buttons">
      {radioOptions.map((option) => {
        const { text, value, IconComponent, disabled, className = '' } = option;
        const hasImage = IconComponent;
        const id = slugify(text, { lower: true }) + name;
        const optionValue = value || text;

        return (
          <div role="img" key={optionValue} className={`radio-checkbox ${hasImage ? 'has-image' : ''} ${className}`}>
            <input
              {...field}
              type="radio"
              id={id}
              name={name}
              onChange={onChangeHandler}
              value={optionValue}
              checked={optionValue === field.value}
              disabled={isSubmitting || !!disabled}
            />
            <label htmlFor={id}>
              {hasImage && <IconComponent className="option-image" />}
              <span className="radio-buttons-checkbox" />
              <span className="button-text">{text}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

FieldRadio.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  register: PropTypes.func.isRequired,
  message: PropTypes.string,
  // validation: PropTypes.shape({
  //   required: PropTypes.bool,
  // }),
  goNext: PropTypes.func,
  goNextOnClick: PropTypes.bool,
};

FieldRadio.defaultProps = {
  className: null,
  message: null,
  goNext: null,
  defaultValue: '',
  goNextOnClick: false,
  // validation: {
  //   required: false,
  // },
};

export default FieldRadio;
