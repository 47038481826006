import React from 'react';
import DisplayCount from '../DisplayCount';
import * as styles from './styles.module.scss';

const DisplayCountContainer = (props) => {
  const { displayCountConfig } = props;

  if (!displayCountConfig) return null;

  return displayCountConfig?.map((config, index) => {
    if (!Array?.isArray(config))
      return (
        <div
          className={`${index === 0 ? styles.primaryTotalisationFieldContainer : ''} ${config?.displayType}`}
          key={config?.title}
        >
          <DisplayCount {...config} />
        </div>
      );

    return (
      <div className={`${styles.totalisationFieldsContainer}`}>
        {config?.map((configData) => (
          <DisplayCount key={configData?.title} {...configData} />
        ))}
      </div>
    );
  });
};

export default DisplayCountContainer;
