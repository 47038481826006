import React, { useState, useEffect, useContext } from 'react';
import { FilterBar, RateBar, DotsToCheck, Placeholder, ShortlistBar, Portal } from 'components';
import { useInView } from 'react-intersection-observer';
import { useForm, FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useGetEffiData } from '../../../hooks/useGetEffiData';
import { isDev } from '../../../utils/misc';
import GlobalContext from '../../../contexts/GlobalContext';
import { calculateLvr } from '../_common/utils';
import { isBrowser } from '../../../utils/isBrowser';
import './styles.scss';

const CreateShortlist = (props) => {
  const { extra } = props;
  const applicantCoreData = extra?.applicantCoreData;
  const application = applicantCoreData?.currentUser?.application;
  const isRefinance = application?.isRefinance;
  const Property = application?.Property;
  const prefilledLoanAmount =
    application?.MortgagePreference?.loanAmount || Property?.purchasePrice - Property?.depositAmount;
  const prefilledPropertyValue = application?.PropertyRefinance?.[0]?.propertyValue || Property?.purchasePrice;
  const prefilledLoanPurpose =
    application?.Property?.investmentOrLiveIn === 'To live in' ? 'Owner Occupied' : 'Investment';

  const { setCreateShortlistCount } = useContext(GlobalContext) || {};

  const localStorageData = isBrowser ? JSON?.parse(localStorage?.getItem('yb-data')) : {};

  const handleLocalStorageUpdate = (prevData = localStorageData, newData) =>
    isBrowser &&
    localStorage?.setItem(
      'yb-data',
      JSON?.stringify({
        ...prevData,
        newData,
      })
    );

  const handleDefaultFormValues = (defaultData, dataKey) => {
    if (!localStorageData?.[dataKey]) return defaultData;

    if (dataKey === 'termOfLoan' && typeof localStorageData?.[dataKey] === 'number')
      return `${localStorageData?.[dataKey]} years`;

    return localStorageData?.[dataKey];
  };

  const defaultFormValues = {
    loanAmount: handleDefaultFormValues(prefilledLoanAmount || 600000, 'loanAmount'),
    termOfLoan: handleDefaultFormValues('30 years', 'termOfLoan'),
    propertyValue: handleDefaultFormValues(prefilledPropertyValue || 1200000, 'propertyValue'),
    loanRateType: handleDefaultFormValues('Any', 'loanRateType'),
    fixedTermLength: handleDefaultFormValues('Any', 'fixedTermLength'),
    loanRepaymentType: handleDefaultFormValues('Any', 'loanRepaymentType'),
    loanPurpose: handleDefaultFormValues(prefilledLoanPurpose, 'loanPurpose'),
    sortBy: handleDefaultFormValues('Interest rate', 'sortBy'),
    promotion: handleDefaultFormValues([], 'promotion'),
    lvr: calculateLvr({ loanAmount: prefilledLoanAmount, propertyValue: prefilledPropertyValue }),
  };

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
  });

  const {
    control,
    reset,
    watch,
    trigger,
    setError,
    handleSubmit: rhfHandleSubmit,
    formState: { errors },
  } = formMethods;

  const formData = watch();

  const filterOptions = {
    ...formData,
    isRefinance,
  };

  const watchedLvr = watch('lvr');

  const [calculateRepayments, setCalulateRepayments] = useState(true);

  const handleSubmit = (data, e) => {
    if (isBrowser) {
      handleLocalStorageUpdate({
        loanAmount: data?.loanAmount,
        propertyValue: data?.propertyValue,
        termOfLoan: data?.termOfLoan,
      });
    }

    if (watch('lvr') > 80 && watch('lvr') < 100) {
      setError('lvr', { type: 'warning', message: 'Your LVR is over 80% - some results may be excluded.' });
    }
    if (watch('lvr') >= 100) {
      setError('lvr', { type: 'custom', message: 'Your LVR is over 100% - No results found' });
    }
    setCalulateRepayments(!calculateRepayments);
  };

  const handleErrors = (submissionErrors, e) => {
    console.log('submission errors: ', submissionErrors, 'FORM STATE ERRORS: ', errors);
  };

  const handleReset = () =>
    reset({
      ...defaultFormValues,
      loanRateType: 'Any',
      fixedTermLength: 'Any',
      loanRepaymentType: 'Any',
      loanPurpose: 'Any',
      sortBy: 'Interest rate',
      promotion: [],
    });

  const [shortlistedProducts, setShortlistedProducts] = useState([]);

  useEffect(() => {
    const storedShortlistedProducts = localStorageData?.shortlistedProducts;

    // Set any saved shortlisted products on mount
    if (storedShortlistedProducts?.length > 0) return setShortlistedProducts(storedShortlistedProducts);
  }, []);

  useEffect(() => {
    setCreateShortlistCount(shortlistedProducts?.length);
  }, [shortlistedProducts]);

  const {
    showLoadingDots,
    showShimmer,
    setShowShimmer,
    pagesDisplayed,
    setPagesDisplayed,
    effiData,
    setEffiData,
    effiQueryLoading,
  } = useGetEffiData({
    options: filterOptions,
    calculateRepayments,
    setShortlistedProducts,
    shortlistedProducts,
  });

  useEffect(() => {
    // Keep in LS for prioritise shortlist page
    handleLocalStorageUpdate({
      ...filterOptions,
      shortlistedProducts,
    });
  }, [shortlistedProducts, calculateRepayments]);

  // ***** Load additional rates as you scroll to the bottom
  const {
    ref: inViewRef,
    inView,
    entry,
  } = useInView({
    /* Optional options */
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      setPagesDisplayed((prevState) => ({
        prev: prevState.current,
        current: prevState.current + 1,
      }));
    }
  }, [inView]);
  // *******

  const filterBarProps = {
    setCalulateRepayments,
    calculateRepayments,
    showShimmer,
    setEffiData,
    setShowShimmer,
    setPagesDisplayed,
    shortlistedProducts,
    setShortlistedProducts,
    handleReset,
  };

  const rateBarProps = (productData, index) => ({
    active: index === 0,
    shortlistedProducts,
    setShortlistedProducts,
    showShimmer,
    productData,
  });

  return (
    <>
      <FormProvider {...formMethods}>
        {isDev && <DevTool control={control} />}
        <FilterBar
          {...filterBarProps}
          formMethods={formMethods}
          onSubmit={handleSubmit}
          onError={handleErrors}
          errors={errors}
        />
      </FormProvider>
      <div>
        <div className="mortgage-products-compare-bar-container">
          {/* // When effi is loading show 3 empty compare bars with a shimmer effect */}
          {(effiData?.length === 0 && showLoadingDots) || effiQueryLoading
            ? [{ slot: 1 }, { slot: 2 }, { slot: 3 }]?.map((data, index) => (
                <RateBar key={data?.slot} {...rateBarProps(data, index)} />
              ))
            : effiData?.length === 0 &&
              !effiQueryLoading && (
                <Placeholder
                  lvr={watchedLvr}
                  label="No results found"
                  content="Please Adjust the Property Value or Loan Amount"
                  className="mortgage-products-placeholder"
                />
              )}
          {/* // When effi is loaded show comparison bars with mortgage product data */}
          {effiData?.length > 0 &&
            effiData?.map((data, index) => {
              if (index === effiData?.length - 1) {
                return <RateBar key={data?.productId} {...rateBarProps(data, index)} inViewRef={inViewRef} />;
              }

              return <RateBar key={data?.productId} {...rateBarProps(data, index)} />;
            })}
          {showLoadingDots && <DotsToCheck className="mortgage-products-loading-dots" />}
        </div>
        <Portal>
          <ShortlistBar shortlistCount={shortlistedProducts?.length} />
        </Portal>
      </div>
    </>
  );
};

export default CreateShortlist;
