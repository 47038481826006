import React from 'react';
import { Footer, Header, SEO, WmAscii } from 'components';
import '@fontsource/inter';
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';
import '../../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const { children, customSEO, seo, className, location, noEscapeRoutes, extra } = props;

  return (
    <div className={className}>
      <WmAscii />
      <Header location={location} extra={extra} noEscapeRoutes={noEscapeRoutes} />
      <main>
        {!customSEO && <SEO {...seo} location={location} />}
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
