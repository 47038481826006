import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './styles.module.scss';

const FormSubmitButton = (props) => {
  const { isSubmitting, label, onClick, isLogin, className = '' } = props;

  const onClickHandler = () => {
    if (onClick) onClick();
  };

  return (
    <button
      type="submit"
      className={`${styles.button} button ${!isLogin ? '' : styles.login} ${className}`}
      disabled={isSubmitting}
      onClick={onClickHandler}
    >
      {isSubmitting ? <span className={styles.loader}>&nbsp;</span> : label}
    </button>
  );
};

FormSubmitButton.propTypes = {
  isSubmitting: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

FormSubmitButton.defaultProps = {
  isSubmitting: false,
  label: 'Submit',
  onClick: null,
};

export default FormSubmitButton;
