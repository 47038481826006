import { defaultOnSubmit } from '../../../utils/defaultOnSubmit';

export const onSubmit = async ({ formData, apolloClient, extraData }) => {
  const variables = {
    ...formData,
    employmentHistory: formData?.employmentHistory.map((result) => {
      delete result?.__typename;
      return result;
    }),
  };

  return defaultOnSubmit({ formData: variables, apolloClient, extraData });
};
