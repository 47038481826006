import React from 'react';
import CalcCountUp from '../CalcCountUp';
import * as styles from './styles.module.scss';

const DisplayCount = (props) => {
  const { countType, endCount, title, subtitle, displayType = 'secondary', className = '', icon } = props;

  const classNameLookup = {
    '': '',
    primary: styles.primary,
    secondary: styles.secondary,
    secondaryAlt: styles.secondaryAlt,
    tertiary: styles.tertiary,
  };

  return (
    <div className={`${styles.container} ${classNameLookup?.[displayType]} ${className}`}>
      <div className={styles.titleContainer}>
        <div className={styles.titleIconContainer}>
          {icon}
          {title && <div className={`${styles.title} display-count-title`}>{title}</div>}
        </div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
      <CalcCountUp endCount={endCount} countType={countType} />
    </div>
  );
};

export default DisplayCount;
