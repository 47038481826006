import React from 'react';
import FormError from '../../../Form/FormError';
import * as styles from './styles.module.scss';

const FormBlockFormError = (props) => {
  const { errors: defaultErrors, showGroupValidations } = props;

  if (!defaultErrors) return null;

  const errors = {};

  Object?.keys(defaultErrors)?.forEach((error) => {
    if (Array?.isArray(defaultErrors?.[error])) {
      const arrayErrorKeyLookup = {
        otherIncome: 'Other income',
        rentalIncome: 'Rental income',
        otherMortgages: 'Mortgage repayments',
      };

      errors[error] = { type: 'custom', message: `${arrayErrorKeyLookup?.[error]} - please enter valid amount` };
    } else {
      errors[error] = defaultErrors[error];
    }
  });

  return (
    <FormError errors={errors} showGroupValidations={showGroupValidations} className={styles.formBlockFormError} />
  );
};

export default FormBlockFormError;
