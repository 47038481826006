import React from 'react';
import { Section, Link } from 'components';
import './styles.scss';

const Title = (props) => {
  const { data } = props;
  const { primary } = data;
  const { colour, title, cta_link: ctaLink, cta_text: ctaText } = primary;

  // content checks
  const titleHtml = title?.html;
  const hasCta = ctaLink?.url && ctaText?.text;
  const colourClass = colour ? colour.toLowerCase() : '';

  return (
    <Section className={`title ${colourClass}`}>
      {titleHtml && <div className="title-container" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
      {hasCta && (
        <Link className="button title-cta" to={ctaLink?.url} withChevron>
          {ctaText?.text}
        </Link>
      )}
    </Section>
  );
};

export default Title;
