import { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import { EFFI_PRODUCTS } from '../apollo/queries/queries_effi';
import { calculateLvr } from '../components/Rates/_common/utils';
import { SERVER } from '../utils/server';
import { isBrowser } from '../utils/isBrowser';

export const useGetEffiData = ({ calculateRepayments, options, shortlistedProducts, setShortlistedProducts }) => {
  const {
    loanRateType,
    loanRepaymentType,
    loanPurpose,
    loanAmount,
    termOfLoan,
    sortBy,
    fixedTermLength,
    promotion,
    isRefinance,
    propertyValue,
  } = options;

  const [effiData, setEffiData] = useState([]);
  const [showShimmer, setShowShimmer] = useState(false);
  const [showLoadingDots, setShowLoadingDots] = useState(false);
  const [pagesDisplayed, setPagesDisplayed] = useState({
    prev: 0,
    current: 0,
  });

  const apolloClient = useApolloClient();

  const lvr = calculateLvr({ loanAmount, propertyValue });

  const handleDefault = (option) => {
    if (option === 'Any') return '';

    return option;
  };

  const handleFixedTermLength = (value) => {
    if (value === 'Fixed (any)') return '';
    return value?.match(/[0-9]/g)?.join('');
  };

  const getEffiProducts = async (pages) => {
    const { data } = await apolloClient.query({
      query: EFFI_PRODUCTS,
      variables: {
        loanRateType: handleDefault(loanRateType),
        loanRepaymentType: handleDefault(loanRepaymentType),
        loanPurpose: handleDefault(loanPurpose),
        orderBy: sortBy === 'Interest rate' ? 'RATE' : 'LENDER',
        lvr,
        promotion,
        skip: pages * 25,
        fixedTermLength: handleFixedTermLength(fixedTermLength),
        isRefinance,
      },
      fetchPolicy: 'no-cache',
    });

    return data?.getEffiRates?.EffiRate;
  };

  const monthlyFeeAmount = (feeSummaryComponents) =>
    feeSummaryComponents
      ?.filter((feeType) => feeType?.includes('Monthly Fee'))
      .join()
      .trim()
      .slice(13) || 0;

  const handleLoading = (loadingState, onlyDots = false) => {
    if (onlyDots) {
      return setShowLoadingDots(loadingState);
    }

    setShowShimmer(loadingState);
    setShowLoadingDots(loadingState);
  };

  const calculateRates = async (data) => {
    let effiDataClone = [...data];
    effiDataClone = await Promise.all(
      (effiDataClone || [])?.map(async (node, index) => {
        const response = await axios.post(`${SERVER}/rates`, {
          loanAmount,
          termOfLoan: Number(termOfLoan?.match(/[0-9]/g)?.join('')),
          interestRate: node?.interestRate,
          monthlyFee: monthlyFeeAmount(node.feeSummaryComponents),
        });

        const updatedData = {
          ...node,
          repaymentRate: parseInt(response?.data?.amount, 10),
          interestRate: parseFloat(node?.interestRate),
        };

        return updatedData;
      })
    );

    return effiDataClone;
  };

  const handleShortlistRepaymentRateUpdate = async () => {
    const updatedShortlistedProducts = await calculateRates(shortlistedProducts);

    if (isBrowser) {
      const storedLeadFlowData = JSON.parse(localStorage.getItem('yb-data'));

      localStorage.setItem(
        'yb-data',
        JSON.stringify({
          ...storedLeadFlowData,
          shortlistedProducts: updatedShortlistedProducts,
        })
      );
    }
  };

  //* **************************************************** */

  const handleUniqueProducts = (products) => {
    const productsClone = [...products];
    return productsClone?.filter((v, i, a) => a?.findIndex((t) => t?.productId === v?.productId) === i);
  };

  const fetchEffiProductsWithRates = async ({ data = [], onlyDots = false, pages }) => {
    handleLoading(true, onlyDots);
    const effiProducts = await getEffiProducts(pages);
    let effiProductsWithRates = [];

    if (pages > 0 && effiProducts?.length === 0) {
      return handleLoading(false, onlyDots);
    }

    if (effiProducts?.length > 0) {
      effiProductsWithRates = await calculateRates(
        data?.length > 0 ? handleUniqueProducts([...data, ...effiProducts]) : effiProducts
      );
    }
    setEffiData(effiProductsWithRates);
    handleShortlistRepaymentRateUpdate();
    return handleLoading(false, onlyDots);
  };

  useEffect(() => {
    setPagesDisplayed({
      prev: 0,
      current: 0,
    });
  }, [calculateRepayments]);

  useEffect(() => {
    setEffiData([]);
    return fetchEffiProductsWithRates({ pages: 0 });
  }, [calculateRepayments, loanRateType, loanRepaymentType, loanPurpose, sortBy, promotion, fixedTermLength]);

  useEffect(() => {
    if (pagesDisplayed?.current > 0) {
      return fetchEffiProductsWithRates({
        data: effiData,
        onlyDots: true,
        pages: pagesDisplayed?.current,
      });
    }
  }, [pagesDisplayed?.current]);

  return {
    effiData,
    setEffiData,
    showShimmer,
    setShowShimmer,
    pagesDisplayed,
    setPagesDisplayed,
    showLoadingDots,
  };
};
