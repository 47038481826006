import React, { Fragment } from 'react';
import { DevTool } from '@hookform/devtools';
import FieldsBlock from '../FieldsBlock';
import FormBlockFormError from '../FormBlockFormError';
import { isDev } from '../../../../utils/misc';
import * as styles from './styles.module.scss';

const FormBlock = (props) => {
  const { fieldsets, methods, submitRef, onSubmit, onErrors, showGroupValidations, className = '' } = props;

  const { handleSubmit: rhfHandleSubmit, formState } = methods;
  const { errors } = formState;

  const defaultOnSubmit = (data, e) => {
    if (onSubmit) return onSubmit(data, e);
    console.log('Data: ', data, 'E: ', e);
  };

  const defaultOnError = (e) => {
    if (onErrors) return onErrors(errors, e);
    console.log('Errors: ', errors, 'E: ', e);
  };

  return (
    <form
      onSubmit={rhfHandleSubmit(defaultOnSubmit, defaultOnError)}
      className={`${styles.form} form-block ${className}`}
    >
      {fieldsets?.length > 0 &&
        fieldsets.map((fieldset, index) => {
          const { title, titleIcon, fields } = fieldset;

          const fieldNames = fields?.map((field) => field?.name);

          const errorFieldNamesInFieldset = Object?.keys(errors)?.filter((errorFieldName) =>
            fieldNames?.includes(errorFieldName)
          );

          const fieldsetErrors = {};

          errorFieldNamesInFieldset?.forEach((errorFieldName) => {
            fieldsetErrors[errorFieldName] = errors?.[errorFieldName];
          });

          return (
            <Fragment key={title}>
              <div className={styles.formTitleContainer}>
                {titleIcon}
                <h3>{title}</h3>
              </div>
              <FieldsBlock
                fields={fields}
                methods={methods}
                errors={errors}
                showGroupValidations={showGroupValidations}
              />
              <FormBlockFormError errors={fieldsetErrors} showGroupValidations={showGroupValidations} />
              {index < fieldsets.length - 1 && <hr className={`${styles.borderDivider} border-divider`} />}
            </Fragment>
          );
        })}
      <button type="submit" aria-hidden="true" style={{ display: 'none' }} ref={submitRef} />
      {/* {isDev && <DevTool control={methods?.control} />} */}
    </form>
  );
};

export default FormBlock;
