import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import { onSubmit } from './onSubmit';

// Init to caclulate date from now
dayjs.extend(relativeTime);
dayjs.extend(duration);

export default [
  {
    onSubmit,
    type: 'arrayAccordion',
    name: 'addressHistory',
    namePrismic: 'About - Address',
    slug: 'about-address',
    addButtonLabel: 'Add address',
    removeButtonLabel: 'Delete address',
    toggleLabel: ({ fieldData, index }) => {
      const formatDate = () => {
        const pluralize = (count, noun) => `${count} ${noun}${count !== 1 ? 's' : ''}`;
        if (!fieldData?.moveInDate || (!fieldData?.moveOutDate && index > 0)) return null;
        const moveInDate = dayjs(fieldData?.moveInDate);
        const moveOutDate = fieldData?.moveOutDate ? dayjs(fieldData?.moveOutDate) : dayjs(); // Default to today
        const years = moveOutDate?.diff(moveInDate, 'y');
        const months = moveOutDate?.diff(moveInDate, 'M');
        const yearsLabel = years > 0 && years < 100 ? pluralize(years, 'year') : null;
        const monthsLabel = years < 100 && months % 12 > 0 ? pluralize(months % 12, 'month') : null;
        const dateLabel = yearsLabel || monthsLabel ? [` - (`, yearsLabel, monthsLabel, ')'].join(' ') : null;
        return dateLabel;
      };
      return [index > 0 ? `Address #${index + 1}` : `Current Address`, formatDate()].join(' ');
    },
    subfields: [
      {
        type: 'text',
        name: 'addressOne',
        label: 'Address Line 1',
      },
      {
        type: 'text',
        name: 'addressTwo',
        label: 'Address Line 2',
      },
      {
        type: 'text',
        name: 'city',
        label: 'City',
        className: 'split-left',
      },
      {
        type: 'text',
        name: 'postCode',
        label: 'Postcode',
        className: `grid-col-3`,
      },
      {
        type: 'select',
        name: 'state',
        placeholder: 'Select',
        label: 'State',
        options: ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'NT', 'ACT'],
        className: `grid-col-4`,
      },
      {
        type: 'text',
        name: 'country',
        label: 'Country',
      },
      {
        type: 'select',
        name: 'rentOwnStatus',
        placeholder: 'Select one',
        label: 'Living situation',
        options: ['Select One', 'Own', 'Mortgaged', 'Renting', 'Boarding', 'Other'],
        validationMessage: 'Please select living situation',
      },
      {
        type: 'date',
        name: 'moveInDate',
        placeholder: 'MM/YYYY',
        label: 'When did you move in?',
        className: ({ index }) => (index > 0 ? 'split-left' : ''),
        validation: ({ formData, index }) => ({
          required: {
            value: true,
            message: 'Please enter job start date',
          },
          validate: (v) => {
            const threeYearsDifference = dayjs.duration({ years: 3 }).$ms;
            const addressHistory = formData?.addressHistory;

            const allMoveInDates = addressHistory?.map((addressHistoryData) => addressHistoryData?.moveInDate);
            const allMoveInDatesSorted = allMoveInDates?.sort((a, b) => {
              const aDate = dayjs(a);
              const bDate = dayjs(b);
              if (aDate && bDate) {
                return aDate?.diff(bDate) - bDate?.diff(aDate);
              }
              return a - b;
            });
            const oldestMoveInDate = dayjs(allMoveInDatesSorted?.[0]);
            const TODAY = dayjs();

            const oldestMoveInDateProvidedGreaterThanThreeYears = TODAY.diff(oldestMoveInDate) > threeYearsDifference;

            if (!oldestMoveInDateProvidedGreaterThanThreeYears) {
              return 'Please include at least three years address history';
            }

            const selectedMoveInDate = dayjs(v);

            return TODAY.diff(selectedMoveInDate) > 0 || 'Please enter valid move in date';
          },
        }),
      },
      {
        type: 'date',
        name: 'moveOutDate',
        placeholder: 'MM/YYYY',
        label: 'When did you move out?',
        validation: ({ formData, index }) => ({
          required: {
            value: true,
            message: 'Please enter move out date',
          },
          validate: (v) => {
            const selectedMoveInDate = dayjs(formData?.addressHistory?.[index]?.moveInDate);
            const selectedMoveOutDate = dayjs(v);
            const TODAY = dayjs();

            if (selectedMoveOutDate.diff(selectedMoveInDate) < 0 || TODAY.diff(selectedMoveOutDate) < 0)
              return 'Please select valid move out date';

            return true;
          },
        }),
        conditional: ({ index }) => index !== 0,
        className: 'split-right',
      },
    ],
  },
];
