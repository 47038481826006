import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Cookies from 'js-cookie';
import { Layout, Slice } from 'components';
import './page.scss';

const Page = ({ location, data }) => {
  const { prismicPage } = data;
  const {
    data: pageData,
    first_publication_date: publishedDate,
    last_publication_date: updatedDate,
    uid,
  } = prismicPage;
  const {
    breadcrumbs_current: breadcrumbsCurrent,
    breadcrumbs,
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
    page_class: pageClass,
    no_escape_routes: noEscapeRoutes,
  } = pageData;

  const seo = {
    title: metaTitle?.text,
    desc: metaDesc?.text,
    banner: ogImage?.url,
    schema: schema?.text,
    publishedDate,
    updatedDate,
  };

  const pageClassName = pageClass ? pageClass?.text.replace(/\s+/g, '-').toLowerCase() : '';

  const isLoggedIn = !!Cookies.get('yb-token');

  const extra = {
    isLoggedIn,
  };

  const isTwoColsHeader = (slice) => slice?.slice_type === 'two_cols_header';

  return (
    <Layout location={location} seo={seo} className={pageClassName} extra={extra} noEscapeRoutes={noEscapeRoutes}>
      {sliceData.map((slice, index) => (
        <Slice
          key={slice.id}
          data={slice}
          uid={uid}
          breadcrumbs={isTwoColsHeader(slice) && index === 0 && breadcrumbs}
          currentPage={isTwoColsHeader(slice) && index === 0 && breadcrumbsCurrent?.text}
        />
      ))}
    </Layout>
  );
};

export default withPrismicPreview(Page);

export const pageQuery = graphql`
  query PageBySlug($uid: String) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      id
      uid
      prismicId
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        page_class {
          text
        }
        no_escape_routes
        breadcrumbs {
          link {
            url
          }
          title {
            text
          }
        }
        breadcrumbs_current {
          text
        }
        body {
          ...TitlePageFragment
          # ...ImagePageFragment
          # ...LoanCardPageFragment
          ...WysiwygPageFragment
          ...TwoColsHeaderPageFragment
          ...IconsTextGridPageFragment
          ...TwoColsStepsImagePageFragment
          ...PartnersPageFragment
          ...PageTestimonialsFragment
          ...TwoColsImageTextPageFragment
          ...TwoColsAccordionPageFragment
          ...TwoColsFeatureListPageFragment
          ...FeaturesGridPageFragment
          ...EmailCapturePageFragment
          ...TwoColsTextPageFragment
          ...ContactFormBlockPageFragment
          ...TwoColumnsPageFragment
          ...CtaPageFragment
          ...StatsPageFragment
          ...CalculatorPageFragment
          ...UspCardsPageFragment
          ...TextBlockPageFragment
          ...AccordionPageFragment
          ...BlogFeedPageFragment
        }
      }
    }
  }
`;
