import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { submitHandlerDefault } from '.';

export const useCalculator = (props) => {
  const { defaultCalculationData, calculatorEndpoint, defaultValues, allowNegativeResults, showCalculationError } = props;
  const [calculationData, setCalculationData] = useState(defaultCalculationData);
  const [bottomOfCalculatorIsInView, setBottomOfCalculatorIsInView] = useState(false);

  // -----------------------------
  // Initialise Form
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { formState, setError, clearErrors } = methods;
  const { errors } = formState;
  const submitHandler = (data) =>
    submitHandlerDefault(
      data,
      errors,
      setCalculationData,
      calculatorEndpoint,
      defaultCalculationData,
      setError,
      clearErrors,
      allowNegativeResults,
      showCalculationError
    );

  // Trigger default values on mount
  useEffect(() => {
    submitHandler(defaultValues);
  }, []);

  return {
    calculationData,
    setCalculationData,
    submitHandler,
    formMethods: methods,
    bottomOfCalculatorIsInView,
    setBottomOfCalculatorIsInView,
  };
};
