import React, { useState, useEffect } from 'react';
import { DotsToCheck } from 'components';
import { ReactComponent as LogoIcon } from '../../../images/logo-icon.svg';
import * as styles from './styles.module.scss';

const LoadingCard = (props) => {
  const { currentApplicant } = props;

  const title = `Almost ${
    currentApplicant ? `there ${currentApplicant?.name}` : 'there'
  }, we're setting up your application`;
  const subtitle = "We can't wait to help you get underway with Australia's best home loan experience!";

  // -----------------------------
  // Animate mounting
  const [isMounting, setIsMounting] = useState(false);
  const containerClasses = `${styles.container} ${isMounting ? styles.hidden : styles.visible}`;

  useEffect(() => {
    const appLayout = document.getElementById('app-layout');
    appLayout.classList.add('loading');
    const appSidebar = document.getElementById('sidebar');
    appSidebar.classList.add('loading');
    return () => {
      appLayout.classList.remove('loading');
      appSidebar.classList.remove('loading');
    };
  }, []);

  useEffect(
    () => () => {
      setTimeout(() => {
        setIsMounting(false);
      }, 200);
    },
    [isMounting]
  );

  return (
    <div className={containerClasses}>
      <LogoIcon className={styles.icon} />
      <div className={styles.textContainer}>
        <h3>{title}</h3>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
      <DotsToCheck />
    </div>
  );
};

export default LoadingCard;
