import React, { useEffect, useState } from 'react';
import { Section, FormError, FormFieldNew, Tooltip } from 'components';
import { animate } from 'motion';
import { ReactComponent as ChevronDown } from '../../../images/chevron-down.svg';
import useWindowSize from '../../../hooks/useWindowSize';
import { calculateLvr } from '../_common/utils';
import './styles.scss';

const FilterBar = (props) => {
  const {
    showShimmer,
    shortlistedProducts,
    setShortlistedProducts,
    formMethods,
    handleReset,
    onSubmit,
    onError,
    errors,
  } = props;

  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);

  const windowSize = useWindowSize();

  const { watch, handleSubmit: rhfHandleSubmit, setValue, clearErrors } = formMethods;

  const loanAmount = watch('loanAmount');
  const propertyValue = watch('propertyValue');
  const loanRateType = watch('loanRateType');
  const termOfLoan = watch('termOfLoan');

  const defaultCalculatedLvr = calculateLvr({ loanAmount, propertyValue });

  useEffect(() => {
    setValue('lvr', defaultCalculatedLvr);
  }, [loanAmount, propertyValue]);

  const handleLvrError = () => {
    if (errors?.lvr) clearErrors('lvr');
  };

  const handleMaxHeight = (windowWidth) => {
    if (!showAdditionalOptions) return 0;

    if (windowWidth > 769) return '350px';

    if (windowWidth > 500 && windowWidth <= 769) return '520px';

    if (windowWidth <= 500) return '826px';
  };

  useEffect(() => {
    animate(
      '.filter-bar-additional-options',
      {
        opacity: showAdditionalOptions ? 1 : 0,
        maxHeight: handleMaxHeight(windowSize?.width),
        overflow: showAdditionalOptions ? 'visible' : 'hidden',
        margin: showAdditionalOptions ? '32px 0 0' : 0,
      },
      {
        opacity: {
          duration: showAdditionalOptions ? 0.5 : 0.5,
        },
        maxHeight: {
          duration: showAdditionalOptions ? 0.5 : 0.5,
        },
        margin: {
          duration: showAdditionalOptions ? 0.5 : 0.5,
        },
        easing: 'linear',
      }
    );

    animate(
      '.chevron-down',
      {
        transform: showAdditionalOptions ? 'rotate(-180deg)' : 'rotate(0deg)',
      },
      {
        transform: {
          duration: 0.5,
        },
        easing: 'linear',
      }
    );
  }, [showAdditionalOptions]);

  const UpdateFormButton = () => (
    <button className="button calculate-repayments" type="submit">
      {showShimmer ? <span className="loading-spinner">&nbsp;</span> : 'Calculate'}
    </button>
  );

  const filterBarOptionsTopRow = [
    {
      label: 'Loan amount',
      type: 'currency',
      name: 'loanAmount',
      onChange: handleLvrError,
      validation: {
        min: {
          value: 150000,
          message: 'Please enter loan amount over $150,000',
        },
        max: {
          value: 999999999,
          message: 'Please loan amount under $999,999,999',
        },
      },
    },
    {
      label: 'Est. property value',
      type: 'currency',
      name: 'propertyValue',
      onChange: handleLvrError,
      validation: {
        min: {
          value: 50000,
          message: 'Please enter property value over $50,000',
        },
        max: {
          value: 999999999,
          message: 'Please enter property value under $999,999,999',
        },
      },
    },
    {
      label: 'Loan term',
      type: 'select',
      name: 'termOfLoan',
      defaultValue: `${termOfLoan} ${termOfLoan === 1 ? 'year' : 'years'}`,
      options: [...Array(31).keys()]?.slice(10)?.map((number) => `${number} years`),
    },
    {
      type: 'hidden',
      className: 'hide-fields',
      name: 'lvr',
    },
  ];

  const additionalOptions = [
    {
      label: 'Loan purpose',
      type: 'radio',
      name: 'loanPurpose',
      options: [{ text: 'Any' }, { text: 'Owner Occupied' }, { text: 'Investment' }],
    },
    {
      type: 'group',
      name: 'loanTypeGroup',
      showGroupValidations: false,
      className: 'loan-type-group',
      subfields: [
        {
          label: 'Loan type',
          type: 'radio',
          name: 'loanRateType',
          options: [
            { text: 'Any' },
            { text: 'Variable' },
            { text: 'Fixed', className: loanRateType !== 'Fixed' ? '' : 'hide-fixed-radio' },
          ],
        },
        {
          name: 'fixedTermLength',
          type: 'select',
          className: loanRateType === 'Fixed' ? 'fixed-select' : 'hide-fixed-select',
          options: [...Array(11).keys()]?.map((year) => {
            const handleYear = () => {
              if (year === 0) return '(any)';
              if (year === 1) return `${year} year`;
              if (year > 1) return `${year} years`;
            };

            return `Fixed ${handleYear()}`;
          }),
        },
      ],
    },
    {
      label: 'Repayment type',
      name: 'loanRepaymentType',
      type: 'radio',
      options: [{ text: 'Any' }, { text: 'Principal and interest' }, { text: 'Interest only' }],
    },
    {
      label: 'Sort by',
      name: 'sortBy',
      type: 'radio',
      options: [{ text: 'Interest rate' }, { text: 'Lender' }],
    },
    {
      label: 'Promotions',
      name: 'promotion',
      type: 'checkbox',
      options: [{ text: 'Discounts' }, { text: 'Cashback' }],
      validation: {
        required: {
          value: false,
        },
      },
    },
  ];

  const handleCalculateLvr = () => {
    if (defaultCalculatedLvr < 0) return '< 0';

    if (defaultCalculatedLvr > 100) return '> 100';

    if (Number?.isNaN(defaultCalculatedLvr)) return '-';

    return defaultCalculatedLvr;
  };

  const calculatedLvr = handleCalculateLvr();

  const lvrWarnings = {
    default: {
      message: null,
      className: null,
      badge: 'Good',
      tooltip: 'Great, you have a low LVR and will be eligible for most deals.',
    },
    over80: {
      message: 'LVR over 80% - some results may be excluded',
      className: 'lvr-warning-80',
      badge: 'Limited Loans',
      tooltip: 'FYI: we are now showing products over 80% LVR',
    },
    over100: {
      message: 'LVR 100% - no results found',
      className: 'lvr-warning-100',
      badge: 'No Loans',
      tooltip:
        'LVR is the "Loan To Value Ratio" and lets you understand how much percentage of the property is being borrowed',
    },
  };

  const handleLvrWarnings = () => {
    if (defaultCalculatedLvr >= 80 && defaultCalculatedLvr < 100) return lvrWarnings?.over80;

    if (defaultCalculatedLvr >= 100) return lvrWarnings?.over100;

    return lvrWarnings?.default;
  };

  return (
    <Section className="filter-bar-section" noWrapper>
      <div>
        <div className="filter-bar-options-container">
          <form onSubmit={rhfHandleSubmit(onSubmit, onError)} className="filter-bar-form">
            {filterBarOptionsTopRow?.map((filterOption) => {
              const fieldHasError = Object?.keys(errors)?.includes(filterOption?.name);

              return (
                <FormFieldNew
                  key={filterOption?.name}
                  {...filterOption}
                  {...formMethods}
                  validationErrors={fieldHasError ? errors : null}
                />
              );
            })}
            <UpdateFormButton />
          </form>

          <div className="filter-bar-subbar">
            <div className="filter-bar-lvr">
              <div>
                LVR <span>{calculatedLvr}%</span>
              </div>
              <div className="badge-tooltip-container">
                <Badge content={handleLvrWarnings()?.badge} />
                <Tooltip label={handleLvrWarnings()?.tooltip} />
              </div>
            </div>
            <div className="shortlist-count">
              <span>{shortlistedProducts?.length} of 3</span> loans selected
            </div>
          </div>
          <FormError
            errors={errors}
            message="TEST MESSAGE"
            className={`form-error ${handleLvrWarnings?.className}`}
            showGroupValidations
          />
          <div className="filter-bar-additional-options">
            {additionalOptions?.map((additionalOption) => (
              <FormFieldNew key={additionalOption?.name} {...additionalOption} {...formMethods} />
            ))}
          </div>
        </div>
        <div className="toggle-additional-options-container">
          <h4>Your results</h4>
          <div className="additional-options-container">
            <button
              type="button"
              className="toggle-additional-options clear-button"
              onClick={() => setShowAdditionalOptions(!showAdditionalOptions)}
            >
              {showAdditionalOptions ? 'Hide ' : 'Show '}advanced filters
              <ChevronDown className="chevron-down" />
            </button>
            <button
              type="button"
              className="clear-button clear-shortlist-button"
              onClick={() => {
                handleReset();
                setShortlistedProducts([]);
              }}
            >
              Clear shortlist
            </button>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default FilterBar;

const Badge = (props) => {
  const { content } = props;

  return content && <div className="badge">{content}</div>;
};
