import React from 'react';
import { Section, Image, OnVisible } from 'components';
import './styles.scss';

const FeaturesGrid = (props) => {
  const { data } = props;
  const { items } = data;

  return (
    <Section className="features-grid">
      {items.length > 0 && (
        <div className="features-grid-container">
          {items.map((item, index) => {
            const { content: cardContent, icon, title: cardTitle } = item;
            return (
              <OnVisible
                className="features-grid-card"
                key={cardTitle?.text}
                style={{ transitionDelay: `${0.15 * index}s` }}
              >
                {icon?.url && <Image className="features-grid-card-image" image={icon} />}
                {cardTitle?.text && (
                  <div className="features-grid-card-title" dangerouslySetInnerHTML={{ __html: cardTitle?.html }} />
                )}
                {cardContent?.text && (
                  <div className="features-grid-card-content" dangerouslySetInnerHTML={{ __html: cardContent?.html }} />
                )}
              </OnVisible>
            );
          })}
        </div>
      )}
    </Section>
  );
};

export default FeaturesGrid;
