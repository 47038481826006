import { gql } from '@apollo/client';
import { FACT_FIND_FRAGMENTS } from '../fragments/fragments_ff';

const { 'about-you': aboutUser } = FACT_FIND_FRAGMENTS;

export const UPDATE_ABOUT_USER = gql`
  mutation updateAboutUser(
    $id: String
    $title: String
    $firstName: String
    $middleName: String
    $surname: String
    $birthDate: String
    $phoneNumber: String
    $maritalStatus: String
    $retirementAge: String
    $citizen: String
    $visaType: String
    $addressHistory: [AddressHistoryInput]
    $employmentHistory: [EmploymentHistoryInput]
    $salary: Int
    $otherIncome: Boolean
    $OtherIncomeSources: [OtherIncomeInput]
    $otherAssetsHomeContentsList: [HomeContentsInput]
    $otherAssetsSuperList: [SuperannuationInput]
    $otherAssetsSavingsList: [SavingsInput]
    $assetsHomeAddressList: [HomeInput]
    $assetsInvestmentAddressList: [InvestmentHomeInput]
    $otherAssetsVehicleList: [VehiclesInput]
    $otherAssetsTotalSharesList: [InvestmentInput]
    $otherAssets: [OtherAssetsInput]
    $assetsCryptoCurrency: [CryptoCurrencyInput]
    $liabilitiesCreditCardLenderList: [CreditCardInput]
    $liabilitiesHomeLoanLenderList: [HomeLoanInput]
    $liabilitiesVehicleLoanLenderList: [VehicleLoanInput]
    $storeCards: [StoreCardInput]
    $liabilitiesPersonalLenderList: [PersonalLendersInput]
    $liabilitiesSmsfLoanLenderList: [SmsfLoansInput]
    $liabilitiesStudentDebtList: [StudentDebtInput]
    $otherDependants: Boolean
    $otherDependantsList: [OtherDependantDetailsInput]
  ) {
    updateAboutUser(
      id: $id
      title: $title
      firstName: $firstName
      middleName: $middleName
      surname: $surname
      birthDate: $birthDate
      phoneNumber: $phoneNumber
      maritalStatus: $maritalStatus
      retirementAge: $retirementAge
      citizen: $citizen
      visaType: $visaType
      addressHistory: $addressHistory
      employmentHistory: $employmentHistory
      salary: $salary
      otherIncome: $otherIncome
      OtherIncomeSources: $OtherIncomeSources
      otherAssetsHomeContentsList: $otherAssetsHomeContentsList
      otherAssetsSuperList: $otherAssetsSuperList
      otherAssetsSavingsList: $otherAssetsSavingsList
      assetsHomeAddressList: $assetsHomeAddressList
      assetsInvestmentAddressList: $assetsInvestmentAddressList
      otherAssetsVehicleList: $otherAssetsVehicleList
      otherAssetsTotalSharesList: $otherAssetsTotalSharesList
      otherAssets: $otherAssets
      assetsCryptoCurrency: $assetsCryptoCurrency
      liabilitiesCreditCardLenderList: $liabilitiesCreditCardLenderList
      liabilitiesHomeLoanLenderList: $liabilitiesHomeLoanLenderList
      liabilitiesVehicleLoanLenderList: $liabilitiesVehicleLoanLenderList
      storeCards: $storeCards
      liabilitiesPersonalLenderList: $liabilitiesPersonalLenderList
      liabilitiesSmsfLoanLenderList: $liabilitiesSmsfLoanLenderList
      liabilitiesStudentDebtList: $liabilitiesStudentDebtList
      otherDependants: $otherDependants
      otherDependantsList: $otherDependantsList
    ) {
      id
      idVerified
      bankStatementsReceived
      ...Details
      ...Citizenship
      ...AddressHistory
      ...Work
      ...Income
      ...AdditionalIncome
      ...Assets
      ...Liabilities
      ...Dependants
      ...FlowProgressAbout
    }
  }
  ${aboutUser?.['about-details']}
  ${aboutUser?.['about-citizenship']}
  ${aboutUser?.['about-address']}
  ${aboutUser?.['about-work']}
  ${aboutUser?.['about-income']}
  ${aboutUser?.['about-additional-income']}
  ${aboutUser?.['about-assets']}
  ${aboutUser?.['about-liabilities']}
  ${aboutUser?.['about-dependants']}
  ${aboutUser?.flowProgress}
`;
