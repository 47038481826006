import React from 'react';
import { TwoColsHeader } from 'slices';
import './styles.scss';

const BlogHeader = ({ data }) => {
  const { blog_description: description, blog_title: title } = data;

  return <TwoColsHeader data={{ primary: { primary_title: title, content: description } }} />;
};

export default BlogHeader;
