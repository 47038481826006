import React from 'react';
import { Link } from 'components';
import * as styles from './styles.module.scss';

const ShortlistBar = ({ shortlistCount }) => {
  const shortlistInitiated = shortlistCount > 0;
  const shortlistComplete = shortlistCount >= 3;

  return (
    <div className={`${styles.container} ${shortlistInitiated ? styles.containerVisible : null}`}>
      <div className={styles.text}>
        <span className={styles.shortlistCount}>{shortlistCount} of 3</span> loans shortlisted
        {shortlistComplete ? ` 🎉 ` : ` 🏡 `}
        {shortlistComplete ? (
          <Link to="/prioritise-your-shortlist" className={styles.confirmationLink}>
            Proceed
          </Link>
        ) : (
          `Almost there...`
        )}
      </div>
    </div>
  );
};

export default ShortlistBar;
