import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Image, Section, OnVisible, GetStarted, Breadcrumbs, GetCreditScoreDataInput } from 'components';
import { ReactComponent as Crown } from '../../images/crown.svg';
import { ReactComponent as GetCreditScoreLogo } from '../../images/getcreditscore-logo-black.svg';
import { ReactComponent as FiveStars } from '../../images/5-stars.svg';
import { isBrowser } from '../../utils/isBrowser';
import * as styles from './styles.module.scss';

const TwoColsHeader = (props) => {
  const { data, uid, breadcrumbs, currentPage } = props;
  const { primary } = data;
  const {
    content,
    image,
    visible_on_mobile: showImageOnMobile,
    lead_flow_field: leadFlow,
    primary_title: primaryTitle,
    secondary_title: SecondaryTitle,
    highlight_refinance: highlightRefinance,
  } = primary;

  // content checks

  const localStorageData = isBrowser && JSON.parse(localStorage.getItem('yb-data'));
  const applicantName = localStorageData?.additionalApplicants?.[0]?.firstName;
  const applicantNamePhrase = applicantName ? `Hi ${applicantName}, ` : '';
  const titleOneHtml = primaryTitle?.html?.replace(/Hi APPLICANT,/g, applicantNamePhrase);
  const titleTwoHtml = SecondaryTitle?.html;
  const contentHtml = content?.html;
  const hasImage = image?.url;

  const compactVersion = !contentHtml && !hasImage;

  const isGetCreditScore = uid === 'get-credit-score';

  return (
    <Section
      className={`${styles.section} ${compactVersion ? styles.compact : ''} ${
        isGetCreditScore ? styles.getCreditScore : ''
      }`}
      containerClassName={styles.container}
    >
      <OnVisible className={styles.left} visibleClassName={styles.leftVisible}>
        <div className={styles.text}>
          <div className={styles.titleContainer}>
            <Breadcrumbs breadcrumbs={breadcrumbs} currentPage={currentPage} />
            {titleOneHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleOneHtml }} />}
            {titleTwoHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleTwoHtml }} />}
          </div>
          {contentHtml && <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        </div>
        {leadFlow && (
          <div className={styles.leadFlow}>
            {!isGetCreditScore && <GetStarted showRefinance highlightRefinance={highlightRefinance} />}
            <div className={`${styles.prompt} ${isGetCreditScore ? styles.isGetCreditScore : ''}`}>
              <Prompt isGetCreditScore={isGetCreditScore} />
            </div>
            <div className={styles.trustfactors}>
              <StaticImage
                className={styles.faces}
                src="../../images/trusted-by.png"
                placeholder="NONE"
                alt="Trusted by"
                objectFit="contain"
              />
              <div className={styles.rating}>
                <FiveStars />
                <span>Rated 5/5 from 100’s of users</span>
              </div>
            </div>
          </div>
        )}
      </OnVisible>
      <OnVisible
        className={`${styles.right} ${showImageOnMobile ? styles.showOnMobile : styles.hideOnMobile}`}
        visibleClassName={styles.rightVisible}
      >
        {hasImage && !isGetCreditScore && <Image className={`${styles.image} `} image={image} />}
        {isGetCreditScore && <GetCreditScoreDataInput />}
      </OnVisible>
    </Section>
  );
};

const Prompt = (props) => {
  const { isGetCreditScore } = props;

  if (isGetCreditScore) {
    return (
      <>
        <span className={styles.promptText}>We've fetched your data from </span>
        <GetCreditScoreLogo className={styles.gcsLogo} />
      </>
    );
  }

  return (
    <>
      <Crown />
      <span className={styles.promptText}>
        Get qualified in <strong>3 minutes</strong>
      </span>
    </>
  );
};

export default TwoColsHeader;
