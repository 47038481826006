import React from 'react';
import './styles.scss';

const RichText = (props) => {
  const { html, className = '' } = props;

  if (!html || html?.length < 8) return null;

  return <div className={`rich-text ${className}`} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default RichText;
