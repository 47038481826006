import React from 'react';
import { FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { FormFieldNew } from 'components';
import { isDev } from '../../../utils/misc';
import * as styles from './styles.module.scss';

const FormStarter = (props) => {
  const { methods, fields, className: formClassName = '', submitRef, submitHandler, errorHandler } = props;

  const { handleSubmit, control, formState } = methods;

  const onSubmit = (data, e) => {
    if (submitHandler) return submitHandler(data, e);
    console.log('Data: ', data, 'E: ', e);
  };

  const onError = (errors, e) => {
    if (errorHandler) return errorHandler(errors, e);
    console.log('Errors: ', errors, 'E: ', e);
  };

  return (
    <FormProvider {...methods}>
      <form className={`${styles.form} ${formClassName}`} onSubmit={handleSubmit(onSubmit, onError)}>
        {fields.map((field) => {
          const { name } = field;

          return <FormFieldNew key={name} {...methods} {...field} validationErrors={formState?.errors?.[name]} />;
        })}
        {/* {isDev && <DevTool control={control} />} */}
        <button type="submit" aria-hidden="true" style={{ display: 'none' }} ref={submitRef} />
      </form>
    </FormProvider>
  );
};

export default FormStarter;
