import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Image, Carousel, Section, OnVisible, CtaArrow } from 'components';
import { ReactComponent as FiveStars } from '../../images/5-stars.svg';
import * as styles from './styles.module.scss';

// import { useStaticQuery, graphql } from 'gatsby';

// testimonials on settings query
// const testimonialsQuery = graphql`
//   query {
//     ...TestimonialsData
//   }
// `;

const Testimonials = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, useGlobalTestimonials } = primary;
  // const { prismicSettings } = useStaticQuery(testimonialsQuery);
  // const { data: testimonialData } = prismicSettings;

  // content checks
  const titleHtml = title?.html;

  const [portalRef, setPortalRef] = useState(null);
  const [sliderRef, setSliderRef] = useState(null);
  const [sliderEngagement, setSlideEngagement] = useState(false);
  const controlsContainerRef = useRef();

  useEffect(() => {
    setPortalRef(controlsContainerRef?.current);
  }, [controlsContainerRef]);

  const handleSlideChange = () => {
    // setSlideEngagement(true);
  };

  const handleArrowClick = () => {
    sliderRef?.current?.slickNext();
  };

  const settings = {
    dots: false,
    arrows: true,
    variableWidth: true,
    autoplay: false,
    infinite: true,
    swipeToSlide: true,
    touchThreshold: 100,
    beforeChange: handleSlideChange,
    nextArrow: <Arrow portalRef={portalRef} />,
    prevArrow: <Arrow portalRef={portalRef} />,
  };

  // const { testimonials: globalTestimonials = [] } = testimonialData;
  // const testimonials = useGlobalTestimonials === 'No' ? items : globalTestimonials;

  return (
    <Section className={styles.section} noWrapper>
      {/* TITLE */}
      {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
      <OnVisible className={styles.carouselContainer} onChange={setSlideEngagement} threshold={1}>
        <OnVisible
          as="button"
          className={`${styles.arrowContainer} ${sliderEngagement ? styles.hideArrow : ''}`}
          type="button"
          onClick={handleArrowClick}
        >
          <CtaArrow className={styles.arrow} />
        </OnVisible>
        <div>
          <Carousel settings={settings}>
            {items.map((item) => {
              const { name, testimonial, image } = item;
              const hasImage = image?.url;
              const testimonialHtml = testimonial?.text;
              const nameText = name?.text;
              return (
                // TESTIMONIAL
                <div className={styles.testimonialContainer} key={nameText}>
                  <div className={styles.testimonial}>
                    {hasImage && <Image image={image} className={styles.testimonialImage} />}
                    <div className={styles.testimonialText}>
                      <div className={styles.quoteMarks}>“</div>
                      <div className={styles.quote} dangerouslySetInnerHTML={{ __html: testimonialHtml }} />
                      <div className={styles.starsContainer}>
                        <FiveStars className={styles.stars} />
                        <p className={styles.name}>{nameText}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </OnVisible>
      <div
        className={`${styles.controls} ${sliderEngagement ? styles.controlsActive : ''}`}
        ref={controlsContainerRef}
      />
    </Section>
  );
};

const Arrow = (props) => {
  const { className, style, onClick, portalRef } = props;
  if (!portalRef) return null;
  return ReactDOM.createPortal(
    <button type="button" style={style} className={className} onClick={onClick} aria-label="Change Slide" />,
    portalRef
  );
};

export default Testimonials;
