const testCreds = [
  {
    Name: 'Test User',
    Bank: 'Bank of Statements',
    Username: '12345678',
    Password: 'TestMyMoney',
  },
];

const getRandomIndex = () => Math.floor(Math.random() * testCreds.length);

const keyValueToString = (keyValue) =>
  `${keyValue[0]}: ${
    typeof keyValue[1] === 'object'
      ? Object.entries(keyValue[1])
        .map((nestedItem) => keyValueToString(nestedItem))
        .join('\n')
      : keyValue[1]
  }`;

export default () => {
  const testCredsIndex = getRandomIndex();
  const testCredsToUse = testCreds[testCredsIndex];
  return Object.entries(testCredsToUse)
    .map((keyValue) => keyValueToString(keyValue))
    .join('\n');
};
