import React, { Fragment, useState, useRef, useEffect } from 'react';
import { range } from '../../../../../utils/misc';
import * as styles from '../styles.module.scss';

const CodeInput = ({ length, onChange, authKey, disabled }) => {
  const [characters, setCharacters] = useState({});
  const inputRefs = useRef({});

  useEffect(() => {
    setCharacters({});
  }, [authKey]);

  const setCharacter = (position, value) => {
    const n = {
      ...characters,
      [position]: value,
    };
    setCharacters(n);
    onChange(Object.values(n).join('').trim());
  };

  return (
    <>
      <div className={styles.twoFactorContainer}>
        {range(length).map((i) => (
          <Fragment key={i}>
            <input
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              className={styles.twoFactorInput}
              ref={(el) => (inputRefs.current[i] = el)}
              onChange={(e) => {
                setCharacter(i, e.target.value);

                if (!e.target.value) {
                  return;
                }

                if (inputRefs.current[i + 1]) {
                  inputRefs.current[i + 1].focus();
                }
              }}
              onFocus={(e) => e.target.select()}
              onClick={(e) => e.target.select()}
              autoFocus={i === 0}
              maxLength={1}
              onPaste={(e) => {
                if (i !== 0) {
                  return;
                }
                const paste = (e.clipboardData || window.clipboardData).getData('text');
                if (paste && paste.length === length) {
                  setCharacters(paste.split(''));
                  range(length).forEach((i) => {
                    inputRefs.current[i].value = paste[i];
                  });
                  onChange(paste.trim());
                }
              }}
              onKeyDown={(e) => {
                if (e.key === characters[i]) {
                  e.preventDefault();
                  if (inputRefs.current[i + 1]) {
                    inputRefs.current[i + 1].focus();
                  }
                }

                if (e.key === 'Backspace') {
                  e.preventDefault();
                  setCharacter(i, '');
                  inputRefs.current[i].value = '';
                  if (inputRefs.current[i - 1]) {
                    inputRefs.current[i - 1].focus();
                  }
                }
              }}
              disabled={disabled}
            />
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default CodeInput;
