// extracted by mini-css-extract-plugin
export var container = "KLM1+Msx";
export var button = "q35nsgD1";
export var error = "_8HtVgwFi";
export var complete = "+LlRbUk2";
export var verification = "JxBjoUNY";
export var loading = "YWeL70er";
export var iconComplete = "TF5ujNCr";
export var iconPadlock = "XzKul1d7";
export var iconLoading = "HHgL4IFD";
export var message = "asWMa-Zj";
export var locked = "-iRuLuGu";