import React, { useState, createContext, useEffect } from 'react';

const FactFindContext = createContext();

export const FactFindProvider = ({ children }) => {
  const [factFindData, setFactFindData] = useState({});
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [lastFormStep, setLastFormStep] = useState(null);
  const [appendControl, setAppendControl] = useState('');

  return (
    <FactFindContext.Provider
      value={{
        factFindData,
        setFactFindData,
        triggerSubmit,
        setTriggerSubmit,
        refetchLoading,
        setRefetchLoading,
        lastFormStep,
        setLastFormStep,
        appendControl,
        setAppendControl,
      }}
    >
      {children}
    </FactFindContext.Provider>
  );
};

export default FactFindContext;
