import React from 'react';
import CountUp from 'react-countup';

const CalcCountUp = (props) => {
  const { endCount, countType = 'currency' } = props;

  const handleCountUpStart = () => (endCount - 20 > 0 ? endCount - 20 : 0);

  const configLookup = {
    currency: {
      prefix: '$',
      separator: ',',
    },
    rate: {
      suffix: '%',
      decimals: 2,
    },
  };

  const config = configLookup?.[countType];

  if (endCount === 'N/A') return <span>N/A</span>;

  if (typeof endCount === 'string') return <span>{endCount}</span>;

  return <CountUp start={handleCountUpStart(endCount)} end={endCount} duration={0.5} {...config} />;
};

export default CalcCountUp;
