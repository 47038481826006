import React, { useEffect, useState, useRef } from 'react';
import { Section } from 'components';
import { animate } from 'motion';
import { ReactComponent as Chevron } from '../../images/chevron-accordion-slice.svg';
import * as styles from './styles.module.scss';

const Accordion = (props) => {
  const { data } = props;
  const primary = data?.primary;
  const items = data?.items;

  const hasLightBlueBg = primary?.variant === 'Light Blue Background';
  const title = primary?.title;

  const backgroundClass = hasLightBlueBg ? styles.lightBlueBg : '';

  const [activeAccordionItems, setActiveAccordionItems] = useState([]);

  const handleAccordionActive = (accordionIndex) =>
    setActiveAccordionItems((prevState) => {
      if (prevState?.includes(accordionIndex))
        return prevState?.filter((prevStateIndex) => prevStateIndex !== accordionIndex);

      return [...prevState, accordionIndex];
    });

  return (
    <Section className={`${styles.section} ${backgroundClass}`} containerClassName={styles.container}>
      {title?.html && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
      {items?.length > 0 && (
        <div className={styles.accordionContainer}>
          {items?.map((item, index) => {
            const accordionTitle = item?.accordion_title;
            const accordionContent = item?.accordion_content;
            const accordionActive = activeAccordionItems?.includes(index) ? styles.active : '';

            return (
              <div key={accordionTitle?.text} className={`${styles.accordionItemContainer} ${accordionActive}`}>
                {accordionTitle?.html && (
                  <div
                    dangerouslySetInnerHTML={{ __html: accordionTitle?.html }}
                    className={styles.accordionTitle}
                    onClick={() => handleAccordionActive(index)}
                    role="button"
                    tabIndex={index}
                    aria-label="Toggle accordion"
                  />
                )}
                <Chevron className={styles.chevron} onClick={() => handleAccordionActive(index)} />
                <AccordionItem accordionContent={accordionContent} isActive={!!accordionActive} index={index} />
              </div>
            );
          })}
        </div>
      )}
    </Section>
  );
};

export default Accordion;

const AccordionItem = (props) => {
  const { accordionContent, isActive, index } = props;

  const accordionItemRef = useRef();

  useEffect(() => {
    animate(
      `.accordion-content-${index}`,
      {
        visibility: isActive ? 'visible' : 'hidden',
      },
      {
        visibility: {
          delay: isActive ? 0 : 0.3,
        },
        easing: 'linear',
      }
    );
  }, [isActive]);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: accordionContent?.html }}
      className={`accordion-content-${index} ${styles.accordionContent}`}
      ref={accordionItemRef}
      style={{
        maxHeight: isActive ? `${accordionItemRef?.current?.scrollHeight}px` : '0px',
      }}
    />
  );
};
