export const reorder = ({ list, startIndex, endIndex }) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const onDragEnd = ({ result, draggableList, onChange }) => {
  const listClone = [...draggableList];

  // dropped outside the list
  if (!result.destination) {
    return;
  }

  const items = reorder({ list: listClone, startIndex: result.source.index, endIndex: result.destination.index });

  if (onChange) {
    return onChange(items);
  }

  return items;
};

export const getItemStyle = ({ isDragging, draggableStyle, lockY, background, index }) => {
  const transform = draggableStyle?.transform;
  const axisLockY = lockY && transform ? `translate(0px, ${transform.split(',').pop()}` : transform;

  const gridRow = ({ index }) => {
    if (index) return index;

    return 'initial';
  };

  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    background: background || 'white',
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
    transform: axisLockY,
    gridRow: gridRow({ index }),
  };
};

export const getListStyle = ({ isDraggingOver }) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  // background: 'white',
  // padding: grid,
  // width: 1012,
});
