import { defaultOnSubmit } from '../../../utils/defaultOnSubmit';

export const onSubmit = async ({ formData, apolloClient, extraData }) => {
  const formatData = ({ data }) => {
    if (data?.length === 0) return [];

    return data?.map((dataObj) => {
      delete dataObj?.__typename;
      return dataObj;
    });
  };

  const formattedAssetsHomeAddressList = formatData({ data: formData?.assetsHomeAddressList })?.map((fieldData) => {
    const { assetsHomeAddress, hiddenAddressSearch, ...fieldDataToKeep } = fieldData;

    return fieldDataToKeep;
  });

  const formattedAssetsInvestmentAddressList = formatData({ data: formData?.assetsInvestmentAddressList })?.map(
    (fieldData) => {
      const { assetsInvestmentAddress, hiddenAddressSearch, ...fieldDataToKeep } = fieldData;

      return fieldDataToKeep;
    }
  );

  const variables = {
    ...formData,
    otherAssetsHomeContentsList: formatData({ data: formData?.otherAssetsHomeContentsList }),
    otherAssetsSuperList: formatData({ data: formData?.otherAssetsSuperList }),
    otherAssetsSavingsList: formatData({ data: formData?.otherAssetsSavingsList }),
    otherAssetsVehicleList: formatData({ data: formData?.otherAssetsVehicleList }),
    assetsHomeAddressList: formattedAssetsHomeAddressList,
    assetsInvestmentAddressList: formattedAssetsInvestmentAddressList,
    otherAssetsTotalSharesList: formatData({ data: formData?.otherAssetsTotalSharesList }),
    assetsCryptoCurrency: formatData({ data: formData?.assetsCryptoCurrency }),
    otherAssets: formatData({ data: formData?.otherAssets }),
  };

  return defaultOnSubmit({ formData: variables, apolloClient, extraData });
};
