import React from 'react';
import { navigate } from 'gatsby';
import { CalculatorTemplate } from 'components';
import { fieldsDefault } from './fields';
import { SERVER } from '../../../utils/server';
import { isBrowser } from '../../../utils/isBrowser';

const StampDuty = (props) => {
  // CALCULATOR FORM DATA
  const calculatorEndpoint = `${SERVER}/calculators/stamp-duty`;

  const defaultValues = {
    state: 'NSW',
    propertyValue: 950000,
    firstHomeBuyer: 'Yes',
    foreignPurchaser: 'No',
    propertyKind: 'Established property',
    propertyUse: 'Primary residence',
    pensioner: 'No',
    vicEligiblePensioner: 'First home buyer duty exemption, concession or reduction',
    paymentMethod: 'Electronic transaction',
    numDependents: 0,
  };

  const defaultCalculationData = {
    totalFees: 0,
    stampDuty: 0,
    mortgageRegistration: 0,
    transferFee: 0,
  };

  // START APPLICATION
  const handleStartApplication = (data) => {
    if (isBrowser) {
      const storedLeadFlowData = JSON.parse(localStorage.getItem('yb-data'));

      const { homeBuyingSituation, propertyUse, ...restOfData } = data;

      const formattedHomeBuyingSituation =
        homeBuyingSituation === 'Yes' ? 'First time home buyer' : 'Experienced home buyer';

      const formattedPropertyUse = propertyUse === 'Primary residence' ? 'To live in' : "It's an investment";

      localStorage.setItem(
        'yb-data',
        JSON.stringify({
          ...storedLeadFlowData,
          ...restOfData,
          homeBuyingSituation: formattedHomeBuyingSituation,
          propertyUse: formattedPropertyUse,
          expectedPurchasePrice: data?.properyValue,
        })
      );

      navigate('/get-started');
    }
  };

  // DISPLAY COUNT
  const controlsBlockConfigDefault = (data) => ({
    displayCountConfig: [
      {
        displayType: 'primary',
        title: 'Total fees',
        endCount: data?.totalFees,
      },
      {
        displayType: 'secondary',
        title: 'Stamp duty value',
        endCount: data?.stampDuty,
      },
      [
        {
          displayType: 'tertiary',
          title: 'Registration fees',
          endCount: data?.mortgageRegistration,
        },
        {
          displayType: 'tertiary',
          title: 'Transfer fees',
          endCount: data?.transferFee,
        },
      ],
    ],
  });

  return (
    <CalculatorTemplate
      {...props}
      defaultValues={defaultValues}
      onHandleStartApplication={handleStartApplication}
      controlsBlockConfigDefault={controlsBlockConfigDefault}
      fieldsDefault={fieldsDefault}
      defaultCalculationData={defaultCalculationData}
      calculatorEndpoint={calculatorEndpoint}
    />
  );
};

export default StampDuty;
