// extracted by mini-css-extract-plugin
export var borrowingPowerCalculator = "_2-dTPPpG";
export var splitLeft = "FloZjtM4";
export var splitRight = "dw98Dg9J";
export var col1Span4 = "+atvBoC0";
export var col5Span2 = "wde29OwQ";
export var incomePeriod = "H62EoPXq";
export var total = "r3mUjkJF";
export var otherIncome = "JtPAvtGv";
export var termOfLoanMobile = "ZeQdvkKB";
export var termOfLoan = "_9vbAmpeE";
export var primaryDisplayCount = "xJoVcIlJ";