import React from 'react';

import FieldLabel from '../../_FieldCommon/FieldLabel';
import FormField from '../../../FormFieldNew';
import * as styles from './styles.module.scss';

const FieldAddressSubfieldsDefault = (props) => {
  const {
    register,
    getValues,
    setValue,
    validationErrors,
    showSearch,
    setShowSearch,
    reset,
    fieldsResetConfig,
    addressArrayNamePrefix,
  } = props;

  const onClickToggleSearchHander = () => {
    reset(fieldsResetConfig, { keepValues: false, keepErrors: false, keepDefaultValues: false });
    setShowSearch(true);
  };

  return (
    <div className={`${styles.container} ${!showSearch ? styles.active : ''}`}>
      <div className={styles.address}>
        <div className={styles.label}>
          <FieldLabel name="addressOne" label="Address" />
          <button className={styles.button} onClick={onClickToggleSearchHander} type="button">
            Search new address
          </button>
        </div>
        <FormField
          type="text"
          name={addressArrayNamePrefix ? `${addressArrayNamePrefix}.addressOne` : 'addressOne'}
          register={register}
          getValues={getValues}
          setValue={setValue}
          validationErrors={validationErrors?.addressOne}
          conditional={showSearch}
        />
      </div>
      <FormField
        type="text"
        name={addressArrayNamePrefix ? `${addressArrayNamePrefix}.addressTwo` : 'addressTwo'}
        register={register}
        getValues={getValues}
        setValue={setValue}
        label="Apartment / Suite / Other"
        validationErrors={validationErrors?.addressTwo}
        conditional={showSearch}
        autoFocus={false}
      />
      <FormField
        type="text"
        name={addressArrayNamePrefix ? `${addressArrayNamePrefix}.city` : 'city'}
        register={register}
        getValues={getValues}
        setValue={setValue}
        label="City"
        className="split-left"
        validation={{
          required: { value: !showSearch, message: 'Please enter city' },
          pattern: { value: /^[A-Za-z0-9'\.\-\s\,]+$/, message: 'Please enter city' },
        }}
        validationErrors={validationErrors?.city}
      />
      <FormField
        type="text"
        name={addressArrayNamePrefix ? `${addressArrayNamePrefix}.postCode` : 'postCode'}
        register={register}
        getValues={getValues}
        setValue={setValue}
        label="Postcode"
        className="grid-col-3"
        validation={{
          required: { value: !showSearch, message: 'Please enter postcode' },
          pattern: { value: /^[A-Za-z0-9]+$/i, message: 'Please enter postcode' },
        }}
        validationErrors={validationErrors?.postCode}
      />
      <FormField
        type="select"
        name={addressArrayNamePrefix ? `${addressArrayNamePrefix}.state` : 'state'}
        register={register}
        getValues={getValues}
        setValue={setValue}
        className="grid-col-4"
        label="State"
        placeholder="Select"
        options={['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'NT', 'ACT']}
        validation={{ required: { value: !showSearch, message: 'Please enter state or territory' } }}
        validationErrors={validationErrors?.state}
      />
      <FormField
        type="text"
        name={addressArrayNamePrefix ? `${addressArrayNamePrefix}.country` : 'country'}
        register={register}
        getValues={getValues}
        setValue={setValue}
        label="Country"
        validation={{
          required: { value: !showSearch, message: 'Please enter country' },
          pattern: { value: /^[A-Za-z\s\-]+$/, message: 'Please enter country' },
        }}
        validationErrors={validationErrors?.country}
      />
    </div>
  );
};

export default FieldAddressSubfieldsDefault;
