import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import mixpanel from 'mixpanel-browser';
import { isDev } from '../utils/misc';

export const useDataLayer = (props) => {
  const isProduction = !isDev && !process.env.GATSBY_IS_STAGING;

  const init = () => {
    if (isProduction) {
      Sentry?.init({
        dsn: 'https://b4c5432e037e41ab8840a735204800c5@o1085377.ingest.sentry.io/6096034',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
      });
      LogRocket?.init('v1pua6/youbroker');
      mixpanel?.init('8fa72bc8b152e9fc24227183a5671b42', { debug: isDev });
    }
  };

  const setup = () =>
    isProduction &&
    LogRocket?.getSessionURL((sessionURL) => {
      Sentry?.configureScope((scope) => {
        scope?.setExtra('sessionURL', sessionURL);
      });
    });

  const identify = (identifier, data) => {
    if (isProduction) {
      LogRocket?.identify(identifier, data?.LogRocket || data);
      mixpanel?.identify(identifier);
      mixpanel?.people?.set(data?.mixpanel || data);
    }
  };

  const track = (event, data, trackType) => {
    if (isProduction) {
      if (trackType === 'track_forms') return mixpanel?.track_forms(event, data);

      LogRocket?.track(event, data?.LogRocket || data);
      mixpanel?.track(event, data?.mixpanel || data);
    }
  };

  return {
    init,
    setup,
    identify,
    track,
  };
};
