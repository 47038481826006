import React from 'react';
import { ReactComponent as MoreInfo } from 'images/tooltip-icon.svg';
import * as styles from './styles.module.scss';
import 'microtip/microtip.css';

const Tooltip = (props) => {
  const { label, content, position = 'top', className = '' } = props;

  if (!label) return null;

  return (
    <div
      className={`${styles.container} tooltip ${className}`}
      aria-label={label}
      data-microtip-position={position}
      role="tooltip"
    >
      {content && <div className={`${styles.label} tooltip-label`} dangerouslySetInnerHTML={{ __html: content }} />}
      <MoreInfo className={`${styles.icon} tooltip-icon`} />
    </div>
  );
};

export default Tooltip;
