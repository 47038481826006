import React from 'react';
import { Section, Link, Image } from 'components';
import * as styles from './styles.module.scss';

const UspCards = (props) => {
  const { data } = props;
  const primary = data?.primary;
  const items = data?.items;

  const hasLightBlueBg = primary?.light_blue_background;
  const title = primary?.title;
  const content = primary?.content;

  const backgroundClass = hasLightBlueBg ? styles.lightBlueBg : '';

  return (
    <Section className={`${styles.section} ${backgroundClass}`} containerClassName={styles.container}>
      <div className={styles.titleContentContainer}>
        {title?.html && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
        {content?.html && <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.content} />}
      </div>
      {items?.length > 0 && (
        <div className={styles.cardsContainer}>
          {items?.map((item) => {
            const cardIcon = item?.card_icon;
            const cardTitle = item?.card_title;
            const cardContent = item?.card_content;
            const cardCtaText = item?.card_cta_text;
            const cardCtaLink = item?.card_cta_link;

            return (
              <div key={cardTitle?.text} className={styles.card}>
                {cardIcon?.url && <Image image={cardIcon} className={styles.cardIcon} />}
                {cardTitle?.html && (
                  <div dangerouslySetInnerHTML={{ __html: cardTitle?.html }} className={styles.cardTitle} />
                )}
                {cardContent?.html && (
                  <div dangerouslySetInnerHTML={{ __html: cardContent?.html }} className={styles.cardContent} />
                )}
                {cardCtaText?.text && (
                  <Link to={cardCtaLink?.url} className={styles.cardCtaLink}>
                    {cardCtaText?.text}
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      )}
    </Section>
  );
};

export default UspCards;
