import React from 'react';
import {
  Section,
  Pattern,
  HomeLoanRepaymentCalculator,
  BorrowingHomeLoanCalculator,
  LmiCalculator,
  StampDutyCalculator,
  HomeLoanOffsetCalculator,
  ScenarioCalculator,
} from 'components';
import slugify from 'slugify';
import * as styles from './styles.module.scss';

const Calculator = (props) => {
  const { data, isBlog } = props;
  const { primary } = data;
  const { title, content, calculator_selection: calculatorSelection } = primary;

  const CalculatorChoice = (extraProps) => {
    const calculatorOptions = {
      'Home Loan Repayment Calculator': <HomeLoanRepaymentCalculator {...extraProps} />,
      'Borrowing Home Loan Calculator': <BorrowingHomeLoanCalculator {...extraProps} />,
      'LMI Calculator': <LmiCalculator {...extraProps} />,
      'Stamp Duty Calculator': <StampDutyCalculator {...extraProps} />,
      'Home Loan Offset Calculator': <HomeLoanOffsetCalculator {...extraProps} />,
      'Scenario Calculator': <ScenarioCalculator {...extraProps} />,
    };

    return calculatorOptions?.[calculatorSelection] || null;
  };

  return (
    <Section
      className={`${styles.section} ${isBlog ? styles.blogCalculator : ''} calculator ${slugify(calculatorSelection, {
        lower: true,
      })}`}
      containerClassName={styles.container}
      noWrapper={isBlog}
    >
      {!isBlog && <Pattern />}
      <div className={styles.headerContainer}>
        {title?.html && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.primaryTitle} />}
        {content?.html && <div dangerouslySetInnerHTML={{ __html: content?.html }} />}
      </div>
      <CalculatorChoice isBlog={isBlog} />
    </Section>
  );
};

export default Calculator;
