import React, { useState } from 'react';
import { formatCurrency } from '../../../utils/formatCurrency';
import DownChevron from '../../../images/chevron-down.svg';
import { ReactComponent as TickCompleteAlt } from '../../../images/tick-complete-alt.svg';
import { ReactComponent as PlusAlt } from '../../../images/plus-alt.svg';
import './styles.scss';

const RateBar = (props) => {
  const {
    active,
    shortlistedProducts,
    setShortlistedProducts,
    showShimmer,
    productData,
    inViewRef,
    hideShortlistButton,
    hideBottomRow,
    hideMonthlyRepayment,
    hideDisplayName,
    minVersion,
  } = props;

  const {
    productId,
    promotionSummary,
    lenderLogoUrl: logo,
    displayName,
    feeSummaryComponents,
    interestRate,
    rateType: interestRateType,
    comparisonRate,
    repaymentRate: defaultRepaymentRate,
    additionalInfo,
    lvrMin,
    lvrMax,
    featureSummary,
    description,
    purpose,
  } = productData;

  const repaymentRate = formatCurrency(defaultRepaymentRate);

  const [productsListSeeMoreInfo, setProductsListSeeMoreInfo] = useState([]);
  const isShortlisted = !!shortlistedProducts?.find((product) => product?.productId === productId);

  const handleAddToShortlist = (product) => {
    const productAlreadyShortlisted =
      shortlistedProducts?.filter((shortlistedProduct) => shortlistedProduct?.productId === product?.productId)
        ?.length > 0;

    // Max 3 shortlisted products
    if (!productAlreadyShortlisted && shortlistedProducts?.length === 3) return;

    if (productAlreadyShortlisted) {
      return setShortlistedProducts((prevState) => prevState.filter((data) => data?.productId !== product.productId));
    }

    return setShortlistedProducts((prevState) => [...prevState, product]);
  };

  const handleRateBarSeeMoreInfo = (id) => {
    if (!productsListSeeMoreInfo.includes(id)) {
      setProductsListSeeMoreInfo((prevState) => [...prevState, id]);
    } else {
      setProductsListSeeMoreInfo((prevState) => prevState.filter((state) => state !== id));
    }
  };

  const applicationFee = () => {
    const applicationFeeRaw = feeSummaryComponents?.find((ele) => ele?.includes('Application Fee'));

    if (!applicationFeeRaw) return null;

    return `Application Fee: ${applicationFeeRaw?.slice(16)}`;
  };

  const ongoingFees = () => {
    const ongoingFeesRaw = feeSummaryComponents?.find((ele) => ele?.includes('Ongoing Fees'));

    if (!ongoingFeesRaw) return null;

    return `Ongoing Fees: ${ongoingFeesRaw?.slice(13)}.00`;
  };

  const formatList = ({ stringList }) => stringList?.split(',');

  formatList({ stringList: featureSummary });

  const listItems = ({ list, appendedListItemText = '', icon }) => (
    <div>
      {list.map((listItem) => (
        <div key={listItem} className="more-info-subtitle">
          {icon}
          {listItem.trim()}
          {appendedListItemText}
        </div>
      ))}
    </div>
  );

  return (
    <div className={`compare-bar-container ${active ? 'compare-bar-active' : null}`} ref={inViewRef}>
      {active && <span className="compare-bar-container-label">Best mortgage for you</span>}
      <div
        className={`compare-bar-top-row-container ${hideBottomRow ? 'hide-bottom-row' : ''} ${
          minVersion ? 'min-version' : ''
        }`}
      >
        <div className={`${showShimmer ? 'shimmer' : ''} compare-bar-logo-container stat-application`}>
          <img src={logo} className="compare-bar-logo" alt="lender logo" />
          {!hideDisplayName && (
            <div className={`${showShimmer ? 'shimmer' : ''}`}>
              <div className="compare-bar-top-row-col-container compare-bar-package-container">
                <span className="compare-bar-package-title">{displayName}</span>
                <span className="compare-bar-application-fee">{applicationFee()}</span>
                <span className="compare-bar-ongoing-fee">{ongoingFees()}</span>
              </div>
            </div>
          )}
        </div>

        <div className={`${showShimmer ? 'shimmer' : ''} compare-bar-top-row-col-stat stat-interest`}>
          <div className="compare-bar-top-row-col-container compare-bar-rate-container">
            <span className="compare-bar-top-row-col-title">Interest Rate</span>
            <span className="compare-bar-top-row-col-data">{Number((interestRate * 100).toFixed(2))}%</span>
            <span className="compare-bar-top-row-col-variable">{interestRateType}</span>
          </div>
        </div>
        <div className={`${showShimmer ? 'shimmer' : ''} compare-bar-top-row-col-stat stat-comparison`}>
          <div className="compare-bar-top-row-col-container compare-bar-rate-container">
            <span className="compare-bar-top-row-col-title">Comparison Rate</span>
            <span className="compare-bar-top-row-col-data">
              {comparisonRate ? `${Number(comparisonRate * 100).toFixed(2)}%` : ''}
            </span>
          </div>
        </div>
        {!hideMonthlyRepayment && (
          <div className={`${showShimmer ? 'shimmer' : ''} compare-bar-top-row-col-stat`}>
            <div className="compare-bar-top-row-col-container compare-bar-rate-container">
              <span className="compare-bar-top-row-col-title">Repayment</span>
              <span className="compare-bar-top-row-col-data">{repaymentRate}</span>
              <span className="compare-bar-top-row-col-variable">Monthly</span>
            </div>
          </div>
        )}
      </div>
      {!hideBottomRow && (
        <div className="compare-bar-bottom-row-container">
          <div className={`${showShimmer ? 'shimmer' : ''}`}>
            <div
              className="compare-bar-more-info-container"
              onClick={() => handleRateBarSeeMoreInfo(productId)}
              aria-hidden="true"
            >
              More info{' '}
              <img
                src={DownChevron}
                alt="down chevron"
                className={`compare-bar-more-info-down-chevron ${
                  productsListSeeMoreInfo.includes(productId) ? 'compare-bar-more-info-down-chevron-active' : null
                }`}
              />
            </div>
          </div>
          <div className={`${showShimmer ? 'shimmer' : ''} compare-bar-button-container`}>
            <ShortlistButton
              shortlisted={isShortlisted}
              hideShortlistButton={hideShortlistButton}
              onClick={() => handleAddToShortlist(productData)}
              hasFullShortlist={shortlistedProducts?.length === 3}
            />
          </div>
        </div>
      )}
      <div
        className={`compare-bar-hidden-info-container ${
          productsListSeeMoreInfo.includes(productId) ? 'compare-bar-show-hidden-info' : 'compare-bar-hide-info'
        }`}
      >
        <div className="compare-bar-hidden-info-middle-row-container">
          <div>
            <div className="more-info-title">Key Features</div>
            {formatList({ stringList: featureSummary })?.length > 0 && (
              <div>
                {listItems({
                  icon: <TickCompleteAlt className="list-icon" />,
                  appendedListItemText: ' Available',
                  list: formatList({ stringList: featureSummary }),
                })}
              </div>
            )}
          </div>
          <div>
            <div className="more-info-title">{promotionSummary ? `Discounts Available` : 'No Discounts Available'}</div>
            {formatList({ stringList: promotionSummary })?.length > 0 && (
              <div>{listItems({ list: formatList({ stringList: promotionSummary }) })}</div>
            )}
          </div>
          <div>
            <div className="more-info-title">Purpose</div>
            {purpose && <div className="more-info-subtitle">{purpose}</div>}
          </div>
          <div>
            <div className="more-info-title">Description</div>
            {description && <div className="more-info-subtitle">{description}</div>}
            <div>
              {lvrMin !== null && lvrMax !== null && (
                <div className="more-info-title lvr">
                  LVR {lvrMin}% to {lvrMax}%
                </div>
              )}
            </div>
          </div>
        </div>
        {additionalInfo && (
          <div>
            <div className="more-info-title">Additional Info Summary</div>
            <div className="more-info-subtitle">{additionalInfo}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RateBar;

const ShortlistButton = ({ shortlisted, hideShortlistButton, onClick, hasFullShortlist }) => {
  const innerButtonStyles = () => {
    if (shortlisted)
      return (
        <>
          Added to Shortlist
          <TickCompleteAlt className="compare-bar-added-button-icon" alt="added icon" />{' '}
        </>
      );

    return (
      <>
        Shortlist
        <PlusAlt className="compare-bar-added-button-icon" alt="Add to shortlist icon" />
      </>
    );
  };

  return (
    <button
      type="button"
      className={`button compare-bar-button ${shortlisted ? 'secondary' : 'button white'} ${
        hasFullShortlist && !shortlisted ? 'full-shortlist' : ''
      }`}
      style={{ display: hideShortlistButton ? 'none' : 'flex' }}
      onClick={onClick}
    >
      {innerButtonStyles(shortlisted)}
    </button>
  );
};
