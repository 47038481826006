import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import * as styles from './styles.module.scss';

const FieldSelect = (props) => {
  const {
    name,
    className,
    placeholder = 'Select',
    validation,
    defaultValue,
    options,
    getValues,
    question,
    reactSelect: reactSelectDefault,
    isSubmitting,
    onChange,
    watch,
    selectLabel = false,
  } = props;

  const { methods } = useFormContext();

  const reactSelect = question?.reactSelect || reactSelectDefault;

  // We are already passing down applicantCoreData in getValues()
  const selectOptions = typeof options === 'function' ? options(getValues()) : options;

  const { field } = useController({
    name,
    rules: validation,
    control: methods?.control,
  });

  const onChangeHandler = (e) => {
    if (reactSelect) field.onChange(selectLabel ? e?.label : e?.value);
    if (!reactSelect) field.onChange(e);
    if (onChange) onChange(watch());
  };

  // Reset boolean to 'Yes'/'No' values
  useEffect(() => {
    let value = field?.value;
    if (typeof value === 'boolean') {
      value = value ? 'Yes' : 'No';
      field.onChange(value);
    }
  }, [field]);

  if (reactSelect) {
    return (
      <Select
        {...field}
        options={selectOptions}
        classNamePrefix="custom-react-select"
        placeholder="Select"
        value={selectOptions?.find((option) => option?.value === field?.value)}
        onChange={onChangeHandler}
        isDisabled={isSubmitting}
      />
    );
  }

  const handleOption = (option, key) => {
    if (typeof option === 'string') return option;

    return option?.[key];
  };

  return (
    <select
      {...field}
      onChange={onChangeHandler}
      className={className}
      name={name}
      placeholder={placeholder}
      disabled={isSubmitting}
    >
      {placeholder && (
        <option value="" disabled selected>
          {placeholder}
        </option>
      )}
      {selectOptions?.map((option) => (
        <option value={handleOption(option, 'value')} defaultValue={defaultValue}>
          {handleOption(option, 'label')}
        </option>
      ))}
    </select>
  );
};

export default FieldSelect;
