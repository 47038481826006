import React from 'react';
import './styles.scss';

const ProgressBar = ({ progress, className, hide }) =>
  !hide && (
    <div className={`progress-bar-container ${className || ''}`}>
      <div className="progress-bar" style={{ width: `${progress || 2}%` }} />
    </div>
  );

export default ProgressBar;
