// extracted by mini-css-extract-plugin
export var container = "NTr76Kkv";
export var containerWithIcons = "X6AhcXaL";
export var draggableFieldText = "_30vsDSu3";
export var dragContainer = "LbjBKR18";
export var dragBackground = "gWLVy-S+";
export var draggableFieldContainer = "pAGl0-a+";
export var icon = "nO3FcZSi";
export var fadeIn = "yMeTRr00";
export var iconDrag = "_4dVYuAZ3";
export var fadeOut = "YHNxOGSX";