import React from 'react';
import { OnVisible } from 'components';
import './styles.scss';

const BlockQuote = (props) => {
  const { data } = props;
  const { primary } = data;
  const { quote, credit } = primary;

  const quoteHtml = quote?.html;
  const creditText = credit?.text;

  return (
    <OnVisible className="blockquote-slice">
      {quoteHtml && <div className="blockquote-slice-quote" dangerouslySetInnerHTML={{ __html: quoteHtml }} />}
      {creditText && <span className="blockquote-slice-credit">{creditText}</span>}
    </OnVisible>
  );
};

export default BlockQuote;
