// extracted by mini-css-extract-plugin
export var modal = "C0ibr-qT";
export var modalOpen = "g9CQ8hrm";
export var container = "+md2+0lu";
export var openModal = "o3-JgF-m";
export var closeButton = "_9LqEMz4s";
export var showClose = "uro8iHdT";
export var content = "d3uvFpC3";
export var header = "vIBP71CX";
export var title = "sg4S4TMz";
export var closeIcon = "+uVCudbR";