import { useQuery } from '@apollo/client';
import { formatAddress } from '../utils/formatAddress';
import { GET_CURRENT_APPLICANT } from '../apollo/queries/queries_misc';

export const useFormatTitleWithApplicantData = ({ title }) => {
  const { data: currentApplicantData } = useQuery(GET_CURRENT_APPLICANT);
  const titleClone = JSON.parse(JSON.stringify(title));

  if (title?.html?.includes('APPLICANT') || title?.html?.includes('ADDRESS')) {
    // Simple deep clone to remove reference
    const address = formatAddress({ addressData: currentApplicantData?.currentApplicant });

    titleClone.html = titleClone?.html.replace(/APPLICANT/g, currentApplicantData?.currentApplicant?.firstName);
    titleClone.html = titleClone?.html.replace(/ADDRESS/g, `${address}`);
    return titleClone;
  }

  return title;
};
