import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

export const formatMortgageApprovalSpeed = (selectedOption, settlementDate) => {
  const optionLookup = {
    Yesterday: 'getting approval as soon as possible',
    'Quick! I need approval in the next 7 days': 'getting approval in the next 7 days',
    'Standard approval and settlement timeframes are OK': 'getting approval within the standard timeframes',
    'Need to settle by an exact date': `getting approval by ${dayjs(settlementDate).format('ll')}`,
  };

  return optionLookup?.[selectedOption];
};

export const formatChosenBanks = (chosenbanks) => {
  const returnUniqueVals = (arr) => arr?.filter((val, index) => arr?.indexOf(val) === index);
  const uniqueBanks = returnUniqueVals(chosenbanks);
  const numberOfUniqueBanks = uniqueBanks?.length;

  const optionLookup = {
    1: `${uniqueBanks?.[0]}`,
    2: `${uniqueBanks?.[0]} and ${uniqueBanks?.[1]}`,
    3: `${uniqueBanks?.[0]}, ${uniqueBanks?.[1]} and ${uniqueBanks?.[2]}`,
  };

  return optionLookup?.[numberOfUniqueBanks];
};

export const formatLoanPreferences = (priority, preferenceOrder) => {
  const optionLookup = {
    1: {
      'best mix': 'with the best mix of value, borrowing power, and approval speed',
      'pay more': 'giving you the ability to pay more into your loan or offset',
      'fastest response': 'which will give the fastest response time',
      'borrowing power': 'which will maximise your borrowing power',
      'home value': 'which will get the highest value on your home',
    },
    2: {
      'best mix': 'the best mix of value, borrowing power, and approval speed',
      'pay more': 'the ability to pay more into your loan or offset',
      'fastest response': 'the fastest response time',
      'borrowing power': 'maximising your borrowing power',
      'home value': 'getting the highest value on your home',
    },
    3: {
      'best mix': 'the best mix of value, borrowing power, and approval speed',
      'pay more': 'having the ability to pay more into your loan or offset',
      'fastest response': 'getting the fastest response time',
      'borrowing power': 'maximising your borrowing power',
      'home value': 'getting the highest value on your home',
    },
  };

  return optionLookup?.[preferenceOrder]?.[priority];
};

export const formatPropertyAddress = (address) => {
  if (address === 'Address not known') return 'You have not selected a property address';

  return `The property address we have got is: ${address}`;
};
