import { defaultOnSubmit } from '../../../utils/defaultOnSubmit';

export const onSubmit = async ({ formData, apolloClient, extraData }) => {
  const hasOtherIncome = formData?.otherIncome === 'Yes';

  const variables = {
    ...formData,
    otherIncome: hasOtherIncome,
    OtherIncomeSources: hasOtherIncome
      ? formData?.OtherIncomeSources.map((result) => {
          delete result?.__typename;
          return result;
        })
      : [],
  };

  return defaultOnSubmit({ formData: variables, apolloClient, extraData });
};
