import React from 'react';
import { Section, Button, RichText } from 'components';
import * as styles from './styles.module.scss';

const TextBlock = (props) => {
  const { data } = props;
  const primary = data?.primary;

  const hasLightBlueBg = primary?.variant === 'Light Blue Background';
  const title = primary?.title;
  const content = primary?.content;
  const ctaText = primary?.cta_text;
  const ctaLink = primary?.cta_link;

  const backgroundClass = hasLightBlueBg ? styles.lightBlueBg : '';

  return (
    <Section className={`${styles.section} ${backgroundClass}`} containerClassName={styles.container}>
      {title?.html && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
      <div>
        <RichText html={content?.html} className={styles.content} />
        {ctaText?.text && (
          <Button to={ctaLink?.url} theme="secondary" className={styles.cta}>
            {ctaText?.text}
          </Button>
        )}
      </div>
    </Section>
  );
};

export default TextBlock;
