import React from 'react';
import { ProgressBar, FormFieldNavigation, Link } from 'components';
import { navigate } from 'gatsby';
import ApplicationFlowButton from '../ApplicationFlowCommon/ApplicationFlowButton';
import { useSetFlowStep } from '../../../hooks/useSetFlowStep';
import * as styles from '../styles.module.scss';

const ApplicationFlowInviteOtherApplicants = (props) => {
  const {
    progresses,
    invitedAdditionalApplicants,
    applicationFlows,
    allApplicantsHaveBeenInvited,
    allApplicants,
    applicantCoreData,
    refetchApplicantCoreData,
  } = props;

  useSetFlowStep({
    newFlowStep: 3,
    userId: applicantCoreData?.currentUser?.id,
    condition: allApplicantsHaveBeenInvited,
  });

  const handleSubmit = async () => {
    if (!allApplicantsHaveBeenInvited) return null;

    // First time after inviting fetch additional applicant data
    if (allApplicantsHaveBeenInvited && allApplicants?.length === 1) {
      await refetchApplicantCoreData();
    }

    return navigate('/verify-your-details/');
  };

  const handleInvite = ({ data }) => {
    const currentInvitedApplicantIdKey = 'yb-invited-applicant-id';
    localStorage.setItem(currentInvitedApplicantIdKey, data?.id);
    return navigate('/invite-other-applicants/enter-number');
  };

  const handleBack = () => navigate('/prioritise-your-shortlist');

  const formatTitle = ({ applicantData, title }) => {
    // Create shallow clone
    const titleClone = JSON.parse(JSON.stringify(title));

    titleClone.html = titleClone?.html.replace(/APPLICANT/g, applicantData?.name);
    titleClone.html = titleClone?.html.replace(/enter-number/g, 'invite-other-applicants/enter-number');
    return titleClone;
  };

  let stepsComplete;
  let progressBar;

  if (progresses?.['enter-number']) {
    stepsComplete = progresses?.['enter-number'](invitedAdditionalApplicants)?.stepsComplete;
    progressBar = progresses?.['enter-number'](invitedAdditionalApplicants)?.progressBar;
  }

  return (
    <div className={styles.section}>
      {invitedAdditionalApplicants.map((applicantData) => (
        <div key={Math.random()}>
          {applicationFlows.map((applicationFlow) => {
            const { title, subtitle, duration, link } = applicationFlow;
            const { uid } = link;

            const progress = applicantData?.inviteSent;

            const progressStatus = () => {
              if (progress) return 'invite sent';
              if (!progress) return '';
              return null;
            };

            return (
              <div className={styles.container} key={title?.text}>
                <div className={styles.topContainer}>
                  <div>
                    <div
                      className={styles.title}
                      dangerouslySetInnerHTML={{
                        __html: formatTitle({ applicantData, title })?.html,
                      }}
                    />
                    <div className={styles.duration}>{duration?.text}</div>
                  </div>
                  <div>
                    <ApplicationFlowButton
                      onClick={() => handleInvite({ data: applicantData })}
                      status={progressStatus()}
                    />
                  </div>
                </div>
                {applicantData?.inviteSent && (
                  <div className={`${styles.resendInvite}`}>
                    {`Invite sent - waiting for ${applicantData?.name} to complete signing up. `}
                    <Link onClick={() => handleInvite({ data: applicantData })}>Need to resend?</Link>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
      <FormFieldNavigation
        handleSubmit={handleSubmit}
        onBack={handleBack}
        submitButtonText="Next"
        disabled={!allApplicantsHaveBeenInvited}
      />
      <ProgressBar progress={progressBar} />
      <div>
        {stepsComplete}/{invitedAdditionalApplicants?.length} Steps completed
      </div>
    </div>
  );
};

export default ApplicationFlowInviteOtherApplicants;
