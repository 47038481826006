export const onSubmit = async ({ formData, apolloClient, extraData }) => {
  const { apolloConfig } = extraData;
  const { fragment, id, responseDataName, updateMutation: mutation } = apolloConfig;

  const variables = {
    id,
    mortgageShortlist: formData?.mortgageShortlist?.map((productData, index) => {
      const { __typename, ...restOfProductData } = productData;
      restOfProductData.favourite = index + 1;
      return restOfProductData;
    }),
  };

  await apolloClient.mutate({
    mutation,
    variables,
  });
};
