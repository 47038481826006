import React from 'react';
import FormError from '../../../Form/FormError';
import * as styles from './styles.module.scss';

const ControlsBlockFormError = (props) => {
  const { errors } = props;
  const controlsBlockError = errors?.controlsBlock;

  if (!controlsBlockError) return null;

  return (
    <FormError
      errors={{ controlsBlock: controlsBlockError }}
      showGroupValidations
      className={`${styles.formError} controls-block-form-error`}
    />
  );
};

export default ControlsBlockFormError;
