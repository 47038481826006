import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormSubmitButton from '../FormSubmitButton';
import * as styles from './styles.module.scss';

const FormNav = (props) => {
  const {
    goPrev,
    goNext,
    hideNavigation,
    prevButtonLabel,
    nextButtonLabel,
    isSubmitting,
    preventEnterKeyListener,
    isLogin,
  } = props;
  const [loading, setLoading] = useState(false);

  const onClickNextHandler = async () => {
    if (goNext) {
      setLoading(true);
      await goNext();
      setLoading(false);
    }
  };

  const onClickPrevHandler = () => {
    if (goPrev) goPrev();
  };

  // GoNext when 'Enter' key is pressed
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();

        if (preventEnterKeyListener) {
          return false;
        }

        onClickNextHandler();
      }
    };
    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, []);

  if (hideNavigation) return null;
  return (
    <div className={`${styles.container} ${!isLogin || styles.login}`}>
      <div className={styles.wrapper}>
        <button
          type="button"
          className={`button outline prev-button ${styles.prev}`}
          onClick={onClickPrevHandler}
          disabled={isSubmitting}
        >
          {prevButtonLabel}
        </button>
        <FormSubmitButton
          isSubmitting={isSubmitting || loading}
          label={nextButtonLabel}
          onClick={onClickNextHandler}
          isLogin={isLogin}
        />
      </div>
    </div>
  );
};

FormNav.propTypes = {
  hideNavigation: PropTypes.bool,
  goNext: PropTypes.func.isRequired,
  goPrev: PropTypes.func.isRequired,
  prevButtonLabel: PropTypes.string,
  nextButtonLabel: PropTypes.string,
};

FormNav.defaultProps = {
  prevButtonLabel: 'Back',
  nextButtonLabel: 'Next',
  hideNavigation: false,
};

export default FormNav;
