import React from 'react';
import * as styles from './styles.module.scss';

const FieldError = (props) => {
  const { validationErrors, validationMessage } = props;

  if (Array.isArray(validationErrors) || (validationErrors && Object.keys(validationErrors).length === 0)) return null; // FieldArray error
  const manualErrorMessage = validationErrors?.message;
  const hasHtmlInMessage = manualErrorMessage?.includes('http');
  const checkedManualErrorMessage = hasHtmlInMessage ? (
    <span dangerouslySetInnerHTML={{ __html: manualErrorMessage }} />
  ) : (
    manualErrorMessage
  );

  if (!validationMessage && !manualErrorMessage) return null;
  return (
    <div className={`${styles.error} ${validationErrors ? styles.active : ''} field-error`}>
      <div>{checkedManualErrorMessage || validationMessage}</div>
    </div>
  );
};

export default FieldError;
