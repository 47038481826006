import React from 'react';
import slugify from 'slugify';
import * as styles from './styles.module.scss';

const FieldCheckbox = (props) => {
  const { name, options, defaultValue, goNextOnClick, goNext, register, validation, isSubmitting } = props;

  return (
    <div className={`radio-buttons ${slugify(name, { lower: true })}-screen`}>
      {options.map((option) => {
        const { text, IconComponent, linkTo } = option;
        const hasImage = IconComponent;
        const id = slugify(text, { lower: true });
        return (
          <div role="img" key={text} className={`${styles.checkbox} ${hasImage ? 'has-image' : ''} field-checkbox`}>
            <input
              {...register(name, validation)}
              type="checkbox"
              id={id}
              name={name}
              value={text}
              defaultChecked={defaultValue === text}
              disabled={isSubmitting}
            />
            <label htmlFor={id}>
              {hasImage && <IconComponent className="option-image" />}
              <span className="radio-buttons-checkbox" />
              {linkTo ? (
                <a href={linkTo} className="button-text checkbox-link" target="_blank" rel="noreferrer">
                  {text}
                </a>
              ) : (
                <span className="button-text">{text}</span>
              )}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default FieldCheckbox;
