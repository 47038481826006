import { navigate } from 'gatsby';
import slugify from 'slugify';
import { onSubmitNewApplicant, onSubmitExistingApplicant } from './onSubmit';
import { approvedLenders } from './approvedLenders';

const hideIfUserLoggedIn = ({ extraData }) => {
  const isLoggedIn = extraData?.isLoggedIn;

  return !isLoggedIn;
};

export default [
  {
    type: 'radio',
    name: 'purchaseOrRefinance',
    namePrismic: 'LF Get Started - Purchase or Refinance',
    options: [
      { text: 'Purchase', linkTo: '/purchase/' },
      { text: 'Refinance', linkTo: '/refinance/' },
    ],
    validationMessage: 'Please select an option',
    goNextOnClick: true,
    goNext: ({ formData, dataLayer }) => {
      dataLayer?.track('LF - Purchase Or Refinance - Complete');

      const nextPath = slugify(formData?.purchaseOrRefinance, { lower: true });
      navigate(`/${nextPath}/`);
    },
    goPrev: () => navigate(`/`),
  },
  {
    type: 'currency',
    name: 'expectedPurchasePrice',
    namePrismic: 'LF Purchase - Expected Purchase Price',
    validation: {
      required: {
        value: true,
        message: 'Please enter purchase price',
      },
      min: {
        value: 50000,
        message: 'Please enter an amount over $50,000',
      },
      max: {
        value: 999999999,
        message: 'Please enter an amount under $999,999,999',
      },
    },
    autoFocus: true,
    placeholder: 'Enter an expected purchase price',
    helper: (value) => `${value} purchase price`,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Expected Purchase Price - Complete'),
    goPrev: () => navigate(`/get-started/`),
  },
  {
    type: 'currency',
    name: 'loanAmount',
    namePrismic: 'LF Refinance - Loan Amount',
    validation: {
      required: true,
      pattern: /^([5-9]\d{3,}|\d{4,})$/i,
      min: {
        value: 150000,
        message: 'Please enter an amount over $150,000',
      },
      max: {
        value: 999999999,
        message: 'Please enter an amount under $999,999,999',
      },
    },
    validationMessage: 'Please enter loan amount',
    autoFocus: true,
    placeholder: 'Enter an amount',
    helper: (value) => `${value} loan amount`,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Loan Amount - Complete'),
    goPrev: () => navigate(`/get-started/`),
  },
  {
    type: 'currency',
    name: 'depositAmount',
    namePrismic: 'LF Purchase - Deposit Amount',
    placeholder: 'Enter an amount',
    validation: ({ formData }) => ({
      required: {
        value: true,
        message: 'Please enter an amount',
      },
      min: {
        value: 0,
        message: 'Please enter an amount',
      },
      validate: (v) => {
        const { expectedPurchasePrice } = formData;
        if (!expectedPurchasePrice || !v) return true;
        const depositPercentage = Math.round((v / expectedPurchasePrice) * 100);
        return depositPercentage < 90 || `Max deposit percantage is 90%`;
      },
    }),
    autoFocus: true,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Deposit Amount - Complete'),
    helper: (value, formData) => {
      const { expectedPurchasePrice, depositAmount } = formData;
      if (!expectedPurchasePrice || !depositAmount) return null;
      const depositPercentage = Math.round((depositAmount / expectedPurchasePrice) * 100);
      return `${value} (${Math.floor(depositPercentage)}% deposit)`;
    },
  },
  {
    type: 'radio',
    name: 'propertyUse',
    namePrismic: 'LF - Property Use',
    options: [{ text: 'To live in' }, { text: "It's an investment" }],
    goNextOnClick: true,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Property Use - Complete'),
  },
  {
    type: 'radio',
    name: 'propertyKind',
    namePrismic: 'LF Purchase - Property Kind',
    options: [
      { text: 'Established property' },
      { text: 'Newly built property' },
      { text: 'Vacant land or construction' },
    ],
    goNextOnClick: true,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Property Kind - Complete'),
  },
  {
    type: 'text',
    name: 'suburbs',
    namePrismic: 'Suburbs',
    validationMessage: "Please enter the suburbs you're looking at",
    autoFocus: true,
  },
  {
    type: 'radio',
    name: 'whatStage',
    namePrismic: 'LF Purchase - What Stage',
    options: [
      { text: 'I want to make an offer' },
      { text: 'I want to buy within 3 months' },
      { text: 'I want to buy within 6 months' },
      { text: `I'm not sure` },
    ],
    goNextOnClick: true,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - What Stage - Complete'),
  },
  {
    type: 'radio',
    name: 'employment',
    namePrismic: 'LF - Employment',
    options: [{ text: 'Employee' }, { text: 'Contractor' }, { text: 'Casual' }, { text: `Self Employed` }],
    goNextOnClick: true,
    skipSubmit: ({ extraData }) => !extraData?.isLoggedIn || !extraData?.isRefinancing,
    onSubmit: onSubmitExistingApplicant,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Employment - Complete'),
  },
  {
    type: 'currency',
    name: 'householdIncome',
    namePrismic: 'LF - Household Income',
    validation: {
      required: true,
      pattern: /^([5-9]\d{3,}|\d{4,})$/i,
      max: {
        value: 999999999,
        message: 'Please enter an amount under $999,999,999',
      },
    },
    validationMessage: 'Please enter household income',
    autoFocus: true,
    placeholder: 'Enter annual household income',
    helper: (value) => `${value} annual household income`,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Household Income - Complete'),
  },
  {
    type: 'text',
    name: 'firstName',
    namePrismic: 'First name',
    conditional: ({ extraData }) => hideIfUserLoggedIn({ extraData }),
    autoFocus: true,
    placeholder: 'Enter your first name',
    validationMessage: 'Please enter your first name',
    goNext: ({ dataLayer }) => dataLayer?.track('LF - First Name - Complete'),
  },
  {
    type: 'email',
    name: 'emailAddress',
    namePrismic: 'LF - Email Address',
    validation: {
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      required: true,
    },
    conditional: ({ extraData }) => hideIfUserLoggedIn({ extraData }),
    validationMessage: 'Please enter your email address',
    autoFocus: true,
    placeholder: 'Enter your email address',
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Email Address - Complete'),
  },
  {
    type: 'number',
    name: 'postcode',
    namePrismic: 'Postcode',
    autoFocus: true,
    validationMessage: 'Please enter a four-digit postcode',
  },
  {
    type: 'number',
    name: 'interestRate',
    namePrismic: 'LF Refinance - Interest Rate',
    placeholder: 'Enter an estimated interest rate (eg. 3.4%)',
    validation: {
      required: true,
      max: { value: 18, message: 'Max interest rate 18.00%' },
      min: { value: 0.01, message: 'Min interest rate 0.01%' },
      validate: (v) => v?.toString()?.length <= 4 || 'Rate limited to 2 decimal places',
    },
    autoFocus: true,
    validationMessage: 'Interest rate is required',
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Interest Rate - Complete'),
  },
  {
    name: 'currentLenderOptions',
    namePrismic: 'LF Refinance - Current Lender',
    type: 'group',
    preventEnterKeyListener: true,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Current Lender - Complete'),
    subfields: [
      {
        type: 'select',
        name: 'currentLender',
        reactSelect: true,
        validationMessage: 'Please enter your current lender',
        options: approvedLenders,
        autoFocus: true,
      },
      {
        type: 'text',
        name: 'currentLenderOther',
        conditional: ({ formData, clearErrors, validationErrors }) => {
          if (formData?.currentLender === 'other') {
            return true;
          }

          if (validationErrors) clearErrors('currentLenderOther');
          return false;
        },
        label: 'Lender',
        validation: ({ formData }) => ({
          required: {
            value: formData?.currentLender === 'other',
            message: 'Please enter name of current lender',
          },
        }),
      },
    ],
  },
  {
    type: 'currency',
    name: 'propertyValue',
    namePrismic: 'LF Refinance - Property Value',
    placeholder: 'Enter an amount',
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Property Value - Complete'),
    validation: {
      required: {
        value: true,
        message: 'Please enter property value',
      },
      min: {
        value: 100000,
        message: 'Please enter an amount over $100,000',
      },
      max: {
        value: 999999999,
        message: 'Please enter an amount under $999,999,999',
      },
      pattern: /^([1-9]\d{5,})$/i,
    },
    autoFocus: true,
    validationMessage: 'Please enter property value',
    helper: (value, formData) =>
      `${value} estimated property value (LVR ${Math.floor((formData?.loanAmount / formData?.propertyValue) * 100)}%)`,
  },
  {
    type: 'checkbox',
    name: 'whyRefinancing',
    namePrismic: 'LF Refinance - Why Refinancing',
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Why Refinancing - Complete'),
    options: [
      { text: 'Decrease payments' },
      { text: 'Pay off loan faster' },
      { text: 'Consolidate debt/ Get cash out' },
      { text: 'Get cash out to buy second property' },
    ],
  },
  {
    type: 'radio',
    name: 'RepaymentType',
    namePrismic: 'Repayment type',
    options: [{ text: 'Weekly' }, { text: 'Monthly' }, { text: 'Quartarly' }, { text: 'Annually' }],
    goNextOnClick: true,
  },
  {
    type: 'checkbox',
    name: 'importantValues',
    namePrismic: 'LF Purchase - Important Values',
    options: [
      { text: 'Knowing my borrowing power' },
      { text: 'Knowing the buying process' },
      { text: 'Knowing my rate' },
      { text: `Understanding any grants` },
      { text: `Fast approval` },
    ],
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Important Values - Complete'),
  },
  {
    type: 'radio',
    name: 'creditHistory',
    namePrismic: 'LF - Credit History',
    options: [{ text: 'Excellent' }, { text: 'Average' }, { text: 'Fair' }, { text: "I'm not sure" }],
    goNextOnClick: true,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Credit History - Complete'),
  },
  {
    type: 'radio',
    name: 'numberOfApplicants',
    namePrismic: 'LF - Number Of Applicants',
    options: [{ text: 'One' }, { text: 'Two' }, { text: 'Three+' }],
    conditional: ({ extraData }) => hideIfUserLoggedIn({ extraData }),
    goNextOnClick: true,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Number Of Applicants - Complete'),
  },
  {
    type: 'radio',
    name: 'numberOfProperties',
    namePrismic: 'LF Purchase - Number Of Properties',
    options: [{ text: 'One' }, { text: 'Two' }, { text: 'Three+' }],
    goNextOnClick: true,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Number Of Properties - Complete'),
  },
  {
    type: 'additionalApplicants',
    name: 'additionalApplicants',
    namePrismic: 'LF - Additional Applicants',
    conditional: ({ extraData }) => hideIfUserLoggedIn({ extraData }),
    addButtonLabel: 'Add another applicant',
    removeButtonLabel: 'Remove applicant',
    subfieldLabel: (fieldData, index) => `Applicant ${index + 1}${!index ? ' (You)' : ''}`,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Additional Applicants - Complete'),
    validation: ({ formData }) => {
      const numberOfApplicants = {
        One: { min: 1, max: 1 },
        Two: { min: 2, max: 2 },
        'Three+': { min: 3, max: 10 },
      };
      return numberOfApplicants[formData?.numberOfApplicants];
    },
    subfields: [
      {
        type: 'text',
        name: 'firstName',
        placeholder: 'First name',
        validationMessage: 'A first name is required',
      },
    ],
  },
  {
    type: 'radio',
    name: 'homeBuyingSituation',
    namePrismic: 'LF Purchase - Home Buying Situation',
    options: [
      { text: 'First time home buyer' },
      { text: 'First investment property' },
      { text: 'Experienced home buyer' },
    ],
    goNextOnClick: true,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Home Buying Situation - Complete'),
    skipSubmit: ({ formData, extraData }) => {
      const firstTimeBuyer = formData?.homeBuyingSituation === 'First time home buyer';

      return !extraData?.isLoggedIn || extraData?.isRefinancing || firstTimeBuyer;
    },
    onSubmit: onSubmitExistingApplicant,
  },
  {
    type: 'radio',
    name: 'grantEligibility',
    namePrismic: 'LF Purchase - Grant Eligibility',
    options: [{ text: 'Yes' }, { text: 'No' }, { text: "I'm not sure" }],
    conditional: ({ formData }) => formData?.homeBuyingSituation === 'First time home buyer',
    goNextOnClick: true,
    skipSubmit: ({ extraData }) => !extraData?.isLoggedIn || extraData?.isRefinancing,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Grant Eligibility - Complete'),
    onSubmit: onSubmitExistingApplicant,
  },
  {
    type: 'string',
    name: 'phoneNumber',
    namePrismic: 'LF - Phone Number',
    conditional: ({ extraData }) => hideIfUserLoggedIn({ extraData }),
    placeholder: 'Enter your phone number',
    autoFocus: true,
    validationMessage: 'Please enter a valid phone number',
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Phone Number - Complete'),
  },
  {
    type: 'twoFactorModal',
    name: 'twoFactor',
    namePrismic: 'LF - Two Factor',
    conditional: ({ extraData }) => hideIfUserLoggedIn({ extraData }),
    validation: {
      required: true,
      pattern: /^[0-9]{4}$/i,
    },
    validationMessage: 'Please enter the code you received via SMS',
    onSubmit: onSubmitNewApplicant,
    goNext: ({ dataLayer }) => dataLayer?.track('LF - Two Factor - Complete'),
  },
];
