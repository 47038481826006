import React from 'react';
import { Logo } from 'components';
import * as styles from './styles.module.scss';

const PreviewBody = () => (
  <div className={`wrapper ${styles.container}`}>
    <Logo className={styles.logo} icon />
    <div className={styles.content}>
      <h2>Loading preview…</h2>
    </div>
  </div>
);

export default PreviewBody;
