import React, { useRef, useState, useEffect } from 'react';

const FieldRange = (props) => {
  const {
    name,
    register,
    validation,
    step,
    min,
    max,
    placeholder,
    defaultValue,
    autoFocus,
    rangeLabelOne,
    rangeLabelTwo,
    formattedPopupValue,
  } = props;

  const popupRef = useRef();
  const [popupPosition, setPopupPosition] = useState(defaultValue || 50);

  const handlePopupMinMax = () => {
    if (min && max) return (Math.ceil(popupPosition - min / max - min) * 100) / (max - min);
    return popupPosition;
  };

  useEffect(() => {
    const rangePopup = popupRef?.current;
    const thumbSizeHalf = 26 / 2;
    if (rangePopup) {
      rangePopup.style.left = `calc(${handlePopupMinMax()}% - ${Number((rangePopup.offsetWidth / 2).toFixed(2))}px - ${
        handlePopupMinMax() <= 50
          ? thumbSizeHalf * ((100 - handlePopupMinMax() * 2) / 100) * -1
          : thumbSizeHalf * (handlePopupMinMax() / 100)
      }px)`;
    }
  }, [popupPosition]);

  const onInputHandler = (e) => {
    const value = e?.target?.value;
    setPopupPosition(value);
  };

  const displayedValue = formattedPopupValue ? formattedPopupValue(popupPosition) : popupPosition;

  return (
    <div className="fact-find-range-container">
      {rangeLabelOne && <div className="range-label">{rangeLabelOne}</div>}
      <div className="fact-find-range-inner-container">
        <div className="range-slider-popup" ref={popupRef}>
          {displayedValue}
        </div>
        <input
          {...register(name, validation)}
          name={name}
          type="range"
          id={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          autoFocus={autoFocus}
          onInput={onInputHandler}
          step={step}
          min={min}
          max={max}
        />
      </div>
      {rangeLabelTwo && <div className="range-label">{rangeLabelTwo}</div>}
    </div>
  );
};

export default FieldRange;
