import React, { useState } from 'react';
import FormFieldNew from '../../FormFieldNew';
import FieldArrayMultiDropdown from './FieldArrayMultiDropdown';

const FiedArrayMulti = (props) => {
  const { subfields, className, addButtonLabel, validationErrors } = props;

  // Append Controls
  const [appendControls, setAppendControls] = useState({});
  const setAppendControlsHandler = (appendName, appendFunc) => {
    setAppendControls((state) => ({ ...state, [appendName]: appendFunc }));
  };

  return (
    <div className={className}>
      {subfields?.map((subfield) => (
        <FormFieldNew
          {...props}
          {...subfield}
          key={subfield.name}
          allowMultipleExpand
          setAppendControls={setAppendControlsHandler}
          validationErrors={validationErrors?.[subfield?.name] || []}
        />
      ))}
      <FieldArrayMultiDropdown subfields={subfields} addButtonLabel={addButtonLabel} appendControls={appendControls} />
    </div>
  );
};

export default FiedArrayMulti;
