import React from 'react';
import { Link, LoadingSpinner } from 'components';
import { ReactComponent as CompletedIcon } from 'images/tick-complete.svg';
import { ReactComponent as PadlockIcon } from 'images/padlock.svg';
import * as styles from './styles.module.scss';

const ApplicationFlowButton = (props) => {
  const { status, onClick, link, isVerification } = props;

  let statusObject;
  switch (status) {
  case 'invite sent':
    statusObject = {
      label: 'Invite sent',
      className: `black ${styles.complete}`,
    };
    break;
  case 'complete':
    statusObject = {
      label: 'Completed',
      className: `black ${styles.complete} ${isVerification ? styles.verification : ''}`,
    };
    break;
  case 'loading':
    statusObject = {
      label: 'Verifying',
      className: styles.loading,
    };
    break;
  case 'no-match':
    statusObject = {
      message: 'We could not verify your ID.',
      label: 'Try again',
      className: styles.error,
    };
    break;
  case 'no-statements':
    statusObject = {
      message: 'We could not verify your bank statements.',
      label: 'Try again',
      className: styles.error,
    };
    break;
  case 'error':
    statusObject = {
      label: 'Try again',
      className: styles.error,
    };
    break;
  case 'incomplete':
    statusObject = {
      label: 'Finish verification',
      className: styles.incomplete,
    };
    break;
  case 'to-complete':
    statusObject = {
      label: 'Complete',
      className: styles.toComplete,
    };
    break;
  case 'locked':
    statusObject = {
      label: 'Locked',
      className: styles.locked,
    };
    break;
  default:
    statusObject = {
      label: 'Get Started',
    };
  }

  const { label, message, className } = statusObject;
  const buttonClassName = `button ${styles.button} ${className}`;
  const isLoading = status === 'loading';
  const isComplete = status === 'complete' || status === 'invite sent';
  const isLocked = status === 'locked';

  // Link or Button (button created if onClick provided)
  const NavComponent = ({ children }) => {
    const componentType = onClick ? 'button' : Link;
    const componentProps = onClick ? { onClick, type: 'button' } : { to: link?.to, state: link?.state };
    return React.createElement(componentType, { className: buttonClassName, ...componentProps }, children);
  };

  return (
    <div className={`${styles.container} application-flow-btn-container`}>
      {message && <div className={`${styles.message} application-flow-btn-messsage`}>{message}</div>}
      <NavComponent>
        {isLoading && <LoadingSpinner className={styles.iconLoading} />}
        <span>{label}</span>
        {isComplete && <CompletedIcon className={styles.iconComplete} />}
        {isLocked && <PadlockIcon className={styles.iconPadlock} />}
      </NavComponent>
    </div>
  );
};

export default ApplicationFlowButton;
