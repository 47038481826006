import React from 'react';
import { Link, OnVisible } from 'components';
import './styles.scss';

const BreakoutBox = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    breakout_content: content,
    breakout_cta_link: ctaLink,
    breakout_title: title,
    breakout_cta_text: ctaText,
  } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const ctaTextText = ctaText?.text;

  return (
    <OnVisible className="breakout-box">
      <div className="breakout-box-container">
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: title.html }} className="breakout-box-title" />}
        {contentHtml && <div dangerouslySetInnerHTML={{ __html: content.html }} />}
        {ctaTextText && (
          <Link to={ctaLink.url} className="button">
            {ctaTextText && <div dangerouslySetInnerHTML={{ __html: ctaText.text }} />}
          </Link>
        )}
      </div>
    </OnVisible>
  );
};

export default BreakoutBox;
