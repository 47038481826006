import React from 'react';
import * as styles from './styles.module.scss';

const ApplicationFlowModal = (props) => {
  const { children, onClose, active, title } = props;

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <div className={`${styles.modal} ${active ? styles.modalOpen : ''}`}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <div>
            <button className={styles.closeButton} type="button" tabIndex={0} onClick={onCloseHandler}>
              <span className={styles.closeIcon} />
            </button>
          </div>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default ApplicationFlowModal;
