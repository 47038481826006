import React, { useState } from 'react';
import CountUp from 'react-countup';
import { Section, Image, OnVisible, Link } from 'components';
import * as styles from './styles.module.scss';

const Stats = (props) => {
  const { data } = props;
  const { items } = data;

  const defaultVisibleStats = {};

  items.forEach((item, index) => {
    defaultVisibleStats[index] = false;
  });

  const [visibleStats, setVisibleStats] = useState(defaultVisibleStats);

  const handleStatVisibility = (isVisible, index) => {
    const clonedVisibleStates = { ...visibleStats };
    clonedVisibleStates[index] = isVisible;
    setVisibleStats(clonedVisibleStates);
  };

  return (
    <Section className={styles.section} containerClassName={styles.container}>
      <div className={styles.stats}>
        {items.map((item, index) => {
          const { title, prefix, suffix, number } = item;
          const titleText = title?.text;
          const prefixText = prefix?.text;
          const suffixText = suffix?.text;
          return (
            <OnVisible
              key={titleText}
              className={styles.stat}
              visibleClassName={styles.statVisible}
              onChange={(isVisible) => handleStatVisibility(isVisible, index)}
            >
              {visibleStats[index] && (
                <div className={styles.number}>
                  <span>{prefixText}</span>
                  <CountUp start={number - 30 > 0 ? number - 30 : 0} end={number} duration={1.5} />
                  <span className={styles.numberSuffix}>{suffixText}</span>
                </div>
              )}
              <span className={styles.title}>{titleText}</span>
            </OnVisible>
          );
        })}
      </div>
    </Section>
  );
};

export default Stats;
