import React from 'react';
import { Logo, Section } from 'components';
import { ReactComponent as Tick } from '../../../images/tick.svg';
import * as styles from './styles.module.scss';

const AppFooter = () => {
  const ticksArr = ['We handle all the paperwork', 'Mortgages from over 49 lenders', 'Access to over 1,216 mortgages'];
  return (
    <Section as="footer" className={styles.footer} containerClassName={styles.container}>
      <div className={styles.logoContainer}>
        <Logo icon />
        <span>Why use YouBroker</span>
      </div>
      <div className={styles.ticks}>
        {ticksArr.map((tick) => (
          <span className={styles.tickContainer} key={tick}>
            <Tick />
            <span>{tick}</span>
          </span>
        ))}
      </div>
    </Section>
  );
};

export default AppFooter;
