import React from 'react';
import { navigate } from 'gatsby';
import { CalculatorTemplate } from 'components';
import { fieldsDefault } from './fields';
import { SERVER } from '../../../utils/server';
import { isBrowser } from '../../../utils/isBrowser';

const ScenarioCalculator = (props) => {
  // CALCULATOR FORM DATA
  const calculatorEndpoint = `${SERVER}/calculators/purchase-scenario`;

  const defaultValues = {
    purchaseAmount: 860000,
    state: 'NSW',
    depositAmount: 150000,
    loanAmount: 780000,
    establishmentFee: 10000,
    legalCost: 5000,
    dischargeFee: 5000,
    otherFees: 2000,
    firstHomeGrant: 1000,
  };

  const defaultCalculationData = {
    fundsSurplus: 0,
    totalFundsAvailable: 0,
    totalFundsRequired: 0,
    stampDuty: {
      stampDuty: 0,
    },
    lmi: 0,
    lvr: 0,
  };

  // START APPLICATION
  const handleStartApplication = (data) => {
    if (isBrowser) {
      const storedLeadFlowData = JSON.parse(localStorage.getItem('yb-data'));

      const { loanAmount, depositAmount, purchaseAmount } = data;

      localStorage.setItem(
        'yb-data',
        JSON.stringify({
          ...storedLeadFlowData,
          loanAmount,
          depositAmount,
          expectedPurchasePrice: purchaseAmount,
        })
      );

      navigate('/get-started');
    }
  };

  // DISPLAY COUNT
  const controlsBlockConfigDefault = (data) => ({
    displayCountConfig: [
      {
        displayType: 'primary',
        title: 'Funds surplus',
        endCount: data?.fundsSurplus,
        className: data?.fundsSurplus < 0 ? 'negative-result' : '',
      },
      {
        displayType: 'secondary',
        title: 'Total funds available',
        endCount: data?.totalFundsAvailable,
      },
      {
        displayType: 'secondary',
        title: 'Total funds required',
        endCount: data?.totalFundsRequired,
      },
      [
        {
          displayType: 'tertiary',
          title: 'LVR',
          countType: 'rate',
          endCount: data?.totalLvr,
        },
        {
          displayType: 'tertiary',
          title: 'LMI',
          endCount: data?.lmi,
        },
        {
          displayType: 'tertiary',
          title: 'Stamp Duty',
          endCount: data?.stampDuty?.stampDuty,
        },
      ],
    ],
  });

  return (
    <CalculatorTemplate
      {...props}
      defaultValues={defaultValues}
      onHandleStartApplication={handleStartApplication}
      controlsBlockConfigDefault={controlsBlockConfigDefault}
      fieldsDefault={fieldsDefault}
      defaultCalculationData={defaultCalculationData}
      calculatorEndpoint={calculatorEndpoint}
      allowNegativeResults
    />
  );
};

export default ScenarioCalculator;
