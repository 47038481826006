import React from 'react';
import { Section, Image, Link, PostDetails } from 'components';

import './styles.scss';

const BlogPostHeader = (props) => {
  const { title, image, category, author, timestamp, dataString } = props;
  const { category_name: categoryName } = category?.document?.data;
  // content checks
  const titleHtml = title?.html;
  const categoryText = categoryName?.text;

  return (
    <>
      <Section className="blog-post-header">
        {categoryText && (
          <Link className="blog-post-category" to={`/articles/${category.uid}`}>
            {categoryText}
          </Link>
        )}
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className="blog-post-title" />}
        <PostDetails author={author} timestamp={timestamp} dataString={dataString} />
      </Section>
      <Section className="blog-post-hero-image">
        <Image image={image} />
      </Section>
    </>
  );
};

export default BlogPostHeader;
