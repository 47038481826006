export const defaultOnSubmit = async ({ formData, apolloClient, extraData }) => {
  const { apolloConfig } = extraData;
  const { fragment, id, responseDataName, updateMutation: mutation } = apolloConfig;

  const variables = {
    id,
    ...formData,
  };

  const response = await apolloClient.mutate({
    mutation,
    variables,
  });

  if (process.env.NODE_ENV === 'development') {
    console.log(`%c@Questions - Submission Server Response`, 'color:blue', response?.data?.[responseDataName]);
  }

  await apolloClient.cache.writeFragment({
    id,
    fragment,
    data: {
      ...response?.data?.[responseDataName],
    },
  });
};
