import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { FormField, CtaArrow } from 'components';
import './styles.scss';

const ContactForm = ({ content, location }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({ mode: 'onChange' });

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  useEffect(
    () =>
      // Set submitting to false in clean up function
      () => {
        setSubmitting(false);
      },
    []
  );

  const fields = [
    {
      type: 'text',
      name: 'firstName',
      label: 'What is your first name?*',
      className: 'split-left',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
    },
    {
      type: 'text',
      name: 'lastName',
      label: 'What is your last name?*',
      className: 'split-right',
      validation: { required: true },
      validationMessage: 'Please enter a your last name',
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Best contact number*',
      className: 'split-left',
      validation: {
        required: true,
        pattern: /^04(\s?[0-9]{2}\s?)([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})$/i,
      },
      validationMessage: 'Please enter your phone number',
    },
    {
      type: 'email',
      name: 'email',
      label: 'Enter your email*',
      className: 'split-right',
      validation: {
        required: true,
        pattern: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      },
      validationMessage: 'Please enter a valid email',
    },
    {
      type: 'textarea',
      name: 'message',
      label: 'Comments',
    },
  ];

  const onSubmit = async (values) => {
    setSubmitting(true);
    try {
      const url = 'https://submit-form.com/AYzOU3sS';
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: values,
      };
      await fetch(url, config);
      return navigate('/thank-you/');
      //
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionError('Opps something went wrong, please try again');
      setSubmitting(false);
    }
  };

  return (
    <section className="contact-form">
      {submissionError && <p>{submissionError}</p>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <button type="submit" disabled aria-hidden="true" style={{ display: 'none' }} />
        {fields.map((field) => {
          const hasError = dirtyFields[field.name] && errors[field.name];
          return (
            <div key={field.label} className={`contact-form-field ${field.className || ''}`}>
              <span className="contact-form-label">{field.label}</span>
              <FormField {...field} register={register} />
              <div className={`validation-error ${hasError ? 'active' : 'inactive'}`}>
                {hasError && <span>{field.validationMessage || 'This field is required'}</span>}
              </div>
            </div>
          );
        })}
        <button type="submit" className="button" disabled={submitting} onClick={() => setSubmitted(true)}>
          {submitting ? 'Submitting' : 'Submit'}
          <CtaArrow />
        </button>
      </form>
    </section>
  );
};

export default ContactForm;
