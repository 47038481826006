import React, { useEffect } from 'react';
import { FormFlow } from 'components';
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/client';
import { CREATE_APPROVAL, UPDATE_USER } from '../../../apollo/mutations/mutations_misc';
import { formatMortgageApprovalSpeed, formatChosenBanks, formatLoanPreferences, formatPropertyAddress } from './utils';
import { formatAddress } from '../../../utils/formatAddress';
import { useDataLayer } from '../../../hooks/useDataLayer';
import * as styles from './styles.module.scss';

const GetApproved = (props) => {
  const { extra } = props;
  const applicantCoreData = extra?.applicantCoreData;
  const application = applicantCoreData?.currentUser?.application;
  const purchaseOrRefinance = application?.isRefinance ? 'refinance' : 'purchase';
  const mortgageShortlist = application?.MortgageShortlist || [];
  const mortgageShortlistSorted = [...mortgageShortlist]?.sort((a, b) => a?.favourite - b?.favourite);
  const chosenBanks = mortgageShortlistSorted?.map((shortlistedProduct) => shortlistedProduct?.bank);
  const mortgageKeyPriorities = application?.MortgagePreference?.keyPriorities;
  const mortgageKeyPrioritiesSorted = [...mortgageKeyPriorities]?.sort((a, b) => a?.order - b?.order);
  const mortgageApprovalSpeed = application?.MortgagePreference?.mortgageQuickOffer;
  const mortgageSettlementDate = application?.MortgagePreference?.settlementDate;
  const propertyAddress = formatAddress({ addressData: application?.Property });
  const allApplicants = application?.Users;
  const primaryApplicant = application?.Users?.find((user) => user?.primaryApplicant);
  const primaryUserId = primaryApplicant?.id;
  const userId = applicantCoreData?.currentUser?.id;
  const showTermsAndConditions = primaryUserId === userId;
  const hasAcceptedTermsAndConditions = application?.Approval?.termsConditionsAccepted;

  const [createApproval] = useMutation(CREATE_APPROVAL);

  const [updateCurrentFlowStep, { client }] = useMutation(UPDATE_USER, {
    variables: {
      id: userId,
    },
  });

  const updateAllApplicantsFlowStepAfterApproval = async ({ formData, applicantData }) => {
    await updateCurrentFlowStep({
      variables: {
        id: primaryUserId,
        currentFlowStep: 6,
        targetId: applicantData?.id !== primaryUserId ? applicantData?.id : null,
      },
      update: (cache, { data }) => {
        const cacheObjToModify = cache.identify({ id: primaryUserId, __typename: 'User' });
        cache.modify({
          broadcast: false,
          id: cacheObjToModify,
          fields: {
            currentFlowStep() {
              return 6;
            },
          },
        });
      },
    });
  };

  const handleAllApplicantsSubmission = (formData) => {
    allApplicants?.forEach((applicantData) => {
      updateAllApplicantsFlowStepAfterApproval({ formData, applicantData });
    });

    navigate('/thank-you');
  };

  const dataLayer = useDataLayer();

  const onSubmit = async ({ formData: { formData } }) => {
    await createApproval({
      variables: {
        id: applicantCoreData?.currentUser?.application?.id,
        applicationId: applicantCoreData?.currentUser?.application?.id,
        additionalQuestions: formData?.additionalQuestions,
        termsConditionsAccepted: formData?.termsConditionsAccepted?.includes(
          'I/we accept the Credit Guide, Privacy Statement and Terms'
        ),
        extraOptions: Array.isArray(formData?.extraOptions) ? formData?.extraOptions : '',
        primaryUserId,
      },
      update: (cache, { data }) => {
        const cacheObjToModify = cache.identify({
          id: applicantCoreData?.currentUser?.application?.id,
          __typename: 'Application',
        });

        cache.modify({
          broadcast: false,
          id: cacheObjToModify,
          fields: {
            Approval() {
              return { __ref: `Approval:${data?.createApproval?.id}` };
            },
          },
        });
      },
    });

    await Promise.allSettled(handleAllApplicantsSubmission(formData));

    dataLayer?.track('Awaiting Approval');
  };

  const fields = [
    {
      type: 'group',
      name: 'getApproved',
      showGroupValidations: false,
      onSubmit,
      goPrev: () => navigate('/fact-find'),
      subfields: [
        {
          type: 'textarea',
          name: 'additionalQuestions',
          label: 'Let us know any other questions or items to take care of for you',
          validationMessage: 'Please enter details',
        },
        {
          type: 'checkbox',
          name: 'extraOptions',
          label: 'Promotions',
          options: [
            { text: 'Get me a free Insurance Quote from Allianz' },
            { text: 'Get me a trusted Conveyancer for my purchase' },
          ],
          validation: {
            required: {
              value: false,
            },
          },
        },
        {
          type: 'checkbox',
          name: 'termsConditionsAccepted',
          label: 'Terms and conditions',
          options: [{ text: 'I/we accept the Credit Guide, Privacy Statement and Terms', linkTo: '/privacy' }],
          validation: ({ formData }) => ({
            required: {
              value: true,
              message: 'Please accept terms and conditions',
            },
          }),
        },
      ],
    },
  ];

  useEffect(() => {
    const appLayoutBody = document.querySelector('.app-layout-body');
    appLayoutBody.style.maxWidth = '530px';

    return () => {
      appLayoutBody.style.maxWidth = 'none';
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <p className={styles.infoTitle}>Here's what we have got:</p>
        <ul className={styles.infoList}>
          <li>
            <span>You want to {purchaseOrRefinance}</span>
          </li>
          <li>
            <span>
              In terms of timeframe, you are keen on{' '}
              {formatMortgageApprovalSpeed(mortgageApprovalSpeed, mortgageSettlementDate)}
            </span>
          </li>
          <li>
            <span>
              {formatPropertyAddress(propertyAddress)} (note: in a purchase, the address is flexible within the approved
              budget limits)
            </span>
          </li>
        </ul>
      </div>
      <div className={styles.infoContainer}>
        <p className={styles.infoTitle}>Lending and loans:</p>
        <ul className={styles.infoList}>
          <li>
            <span>
              You are most interested in loans {formatLoanPreferences(mortgageKeyPrioritiesSorted?.[0]?.value, 1)}, with
              your next preference being {formatLoanPreferences(mortgageKeyPrioritiesSorted?.[1]?.value, 2)} followed by{' '}
              {formatLoanPreferences(mortgageKeyPrioritiesSorted?.[2]?.value, 3)}
            </span>
          </li>
          <li>
            <span>
              We'll cross-check and make a preliminary assessment on your lender shortlist, which includes{' '}
              {formatChosenBanks(chosenBanks, mortgageShortlistSorted)}
            </span>
          </li>
        </ul>
      </div>
      <h4 className={styles.ctaTitle}>
        {showTermsAndConditions && !hasAcceptedTermsAndConditions
          ? "We've got you! Our Best Interests Duty standard means we look out for you at all times, and our friendly team is here to help."
          : 'Thanks! We will now prepare and pre-assess your application to make the process as smooth as possible. We will be in touch usually within 2-4 hours.'}
      </h4>
      {showTermsAndConditions && !hasAcceptedTermsAndConditions && <FormFlow fields={fields} className={styles.form} />}
    </div>
  );
};

export default GetApproved;
