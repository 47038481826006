import React, { useState, useEffect } from 'react';
import { Link, Image } from 'components';

import './styles.scss';

const PostDetails = (props) => {
  const [minsToRead, setMinsToRead] = useState(0);
  const { timestamp, author, dataString } = props;
  useEffect(() => {
    const wordsPerMinute = 200;
    const textLength = dataString && dataString.split(' ').length; // Split by words
    if (textLength > 0) {
      setMinsToRead(Math.ceil(textLength / wordsPerMinute));
    }
  }, [dataString]);

  return (
    <div className="post-details">
      {author?.text && <span className="post-details-item post-details-author">{author?.text}</span>}
      {timestamp && minsToRead && (
        <span className="post-details-item">
          {timestamp} • {minsToRead} min read
        </span>
      )}
    </div>
  );
};

export default PostDetails;
