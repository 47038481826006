import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, FormError } from 'components';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useApolloClient } from '@apollo/client';
import FormFieldNew from '../../FormFieldNew';
import FieldArrayButtonAdd from '../_FieldCommon/FieldArrayButtonAdd';
import FieldArrayButtonRemove from '../_FieldCommon/FieldArrayButtonRemove';
import FieldOrder from '../FieldOrder';
import * as styles from './styles.module.scss';

const FieldArrayAccordion = (props) => {
  const {
    name,
    addButtonLabel,
    toggleLabel,
    removeButtonLabel,
    hideAddButton,
    onRemove,
    validation,
    subfields,
    register,
    getValues,
    setValue,
    className,
    validationErrors,
    setAppendControls,
    showGroupValidations,
    watch,
    expandLast = true,
    allowMultipleExpand = true,
    ...extraProps
  } = props;

  const extraData = extraProps?.extraData;
  const isSubmitting = extraProps?.isSubmitting;

  // -------------------------
  // Form Management
  const { fields, append, remove, swap, move } = useFieldArray({ name, keyName: 'reactHookFormId' });
  const fieldArrayData = useWatch({ name });
  const fieldsCount = fields.length;

  // -------------------------
  // Accordion Management
  const accordionShowLast = fieldsCount === 0 ? [] : [Math.max(fieldsCount - 1, 0)];
  const accordionState = allowMultipleExpand ? [...Array(fieldsCount).keys()] : [];
  const [activeIndexes, setActiveIndexes] = useState(accordionState);
  const [isFieldArrayInitialised, setFieldArrayInitialised] = useState(false);

  // -------------------------
  // Set Default values
  useEffect(() => {
    register(name);
    setFieldArrayInitialised(true);
    if (allowMultipleExpand) setActiveIndexes(accordionState);
    else if (expandLast) setActiveIndexes(accordionShowLast);
  }, [register]);

  // -------------------------
  // Set Default values
  useEffect(() => {
    if (isFieldArrayInitialised && allowMultipleExpand) setActiveIndexes(accordionState);
  }, [isFieldArrayInitialised, allowMultipleExpand]);

  // -------------------------
  // Show Accordion Handler
  const accordionShowHandler = (index) => {
    const isAlreadyOpen = activeIndexes.includes(index);
    if (isAlreadyOpen) return;
    if (!allowMultipleExpand) setActiveIndexes([index]);
    if (allowMultipleExpand) setActiveIndexes((state) => [...state, index]);
  };

  // -------------------------
  // Show Accordion Handler
  const accordionHideHandler = (index) => {
    const activeIndex = activeIndexes.findIndex((itemIndex) => itemIndex === index);
    const isAlreadyClosed = activeIndex < 0;
    if (isAlreadyClosed) return;
    if (!allowMultipleExpand) setActiveIndexes([]);
    if (allowMultipleExpand) setActiveIndexes((state) => state.splice(activeIndex, 1));
  };

  const accordionToggleHandler = (index) => {
    let state = [...activeIndexes];
    const activeIndex = state.findIndex((itemIndex) => itemIndex === index);
    const isActive = activeIndex > -1;
    if (!allowMultipleExpand) {
      if (isActive) state = [];
      else state = [index];
    }
    if (allowMultipleExpand) {
      if (isActive) state.splice(activeIndex, 1);
      else state.push(index);
    }
    setActiveIndexes(state);
  };

  // -------------------------
  // Array Controls + Reorder field on initial load
  // useEffect(() => {
  //   fields.forEach((field, prevIndex) => {
  //     const { order: nextIndex } = field;
  //     if (prevIndex !== nextIndex) move(nextIndex, prevIndex);
  //   });
  // }, []);

  // -------------------------
  // Add Item Handler
  const onAppendHandler = () => {
    const defaultValues = subfields?.reduce(
      (acc, subfield) => ({
        ...acc,
        [subfield.name]: subfield.defaultValue || '',
      }),
      {}
    );
    const firstFieldId = subfields?.[0].name;
    const firstFieldName = `${name}.${fieldsCount}.${firstFieldId}`;
    append(defaultValues, { focusName: firstFieldName });
    accordionShowHandler(fieldsCount);
  };

  // -------------------------
  // Append Controls (used with Multi array)
  useEffect(() => {
    if (setAppendControls) setAppendControls(name, onAppendHandler);
  }, [fields]);

  // -------------------------
  // Remove Item Handler
  const apolloClient = useApolloClient();
  const onRemoveHandler = async ({ field, index }) => {
    accordionHideHandler(index);
    if (onRemove) await onRemove({ field, apolloClient, table: name, extraData });
    return remove(index);
  };

  // -------------------------
  // Validation Management
  useEffect(() => {
    if (fieldsCount < validation?.min) onAppendHandler();
    if (fieldsCount > validation?.max) {
      remove(fieldsCount - 1);
      accordionHideHandler(fieldsCount - 1);
    }
  }, [validation, fieldsCount]);

  // -------------------------
  // Title (with dynamic label)
  const AccordionTitle = (titleProps) => {
    const { index } = titleProps;
    const formattedTitle = toggleLabel && toggleLabel({ fieldData: fieldArrayData?.[index], index });
    const hasError = validationErrors?.[index];
    return (
      <div className={styles.header}>
        <span className={`${styles.errorTooltip} ${hasError ? styles.active : ''}`} />
        {formattedTitle}
      </div>
    );
  };

  return (
    <div className={`${styles.container}`}>
      {fields?.map((field, index) => (
        <Accordion.Item
          key={field.reactHookFormId}
          active={activeIndexes.includes(index)}
          onClick={() => accordionToggleHandler(index)}
          title={<AccordionTitle index={index} />}
        >
          <FieldOrder order={index} name={`${name}.${index}.order`} register={register} />
          <div id={`${name}.${index}`} className={`${styles.subfieldsContainer} ${className || ''}`}>
            {subfields.map((subfield) => {
              const { name: subfieldName } = subfield;
              const fieldName = `${name}.${index}.${subfieldName}`;
              const subfieldValidationErrors = validationErrors?.[index]?.[subfieldName];
              return (
                <FormFieldNew
                  {...extraProps}
                  {...subfield}
                  key={fieldName}
                  name={fieldName}
                  setValue={setValue}
                  getValues={getValues}
                  watch={watch}
                  register={register}
                  index={index}
                  validationErrors={subfieldValidationErrors}
                  showGroupValidations={showGroupValidations}
                />
              );
            })}
            <FieldArrayButtonRemove
              hide={index < validation?.min || validation?.required}
              label={removeButtonLabel}
              onClick={() => onRemoveHandler({ field, index })}
              disabled={isSubmitting}
            />
          </div>
          <FormError errors={validationErrors?.[index]} showGroupValidations={showGroupValidations} />
        </Accordion.Item>
      ))}
      {!hideAddButton && (
        <FieldArrayButtonAdd label={addButtonLabel} onClick={onAppendHandler} disabled={isSubmitting} />
      )}
    </div>
  );
};

FieldArrayAccordion.propTypes = {
  name: PropTypes.string.isRequired,
  addButtonLabel: PropTypes.string,
  hideAddButton: PropTypes.bool,
  removeButtonLabel: PropTypes.string,
  removeButtonIcon: PropTypes.bool,
  onRemove: PropTypes.func,
  className: PropTypes.string,
  subfieldLabel: PropTypes.func,
  showGroupValidations: PropTypes.bool,
  validation: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
};

FieldArrayAccordion.defaultProps = {
  className: '',
  addButtonLabel: 'Add an item',
  removeButtonLabel: 'Remove an item',
  removeButtonIcon: true,
  onRemove: null,
  showGroupValidations: true,
  hideAddButton: false,
  subfieldLabel: null,
  validation: {
    min: 0,
    max: 50,
  },
};

export default FieldArrayAccordion;
