import React from 'react';
import * as styles from './styles.module.scss';

const AdditionalBlock = (props) => {
  const { additionalBlockConfig, className = '' } = props;

  const title = additionalBlockConfig?.title;
  const content = additionalBlockConfig?.content;

  if (!additionalBlockConfig) return null;

  return (
    <div className={`${styles.container} ${className}`}>
      {title && <div className={styles.title}>{title}</div>}
      {content && <div>{content}</div>}
    </div>
  );
};

export default AdditionalBlock;
