import React from 'react';
import { ReactComponent as Star } from '../../../images/iconography/star.svg';
import { ReactComponent as TakeFunds } from '../../../images/iconography/take-funds.svg';
import { ReactComponent as DollarCoin } from '../../../images/iconography/dollar-coin.svg';
import { multiplierLookupDefault, currencyValidation, currencyValidationMessage } from '../_common/utils';
import { formatCurrency } from '../../../utils/formatCurrency';
import { approvedLenders } from '../../../slices/Questions/QuestionsCatalogue/LeadFlow/approvedLenders';
import * as styles from './styles.module.scss';

export const controlsBlockFieldsDefault = (submitHandler) => [
  {
    name: 'termOfLoan',
    label: 'Loan over',
    type: 'select',
    options: [...Array(31).keys()]?.slice(10)?.map((number) => `${number} years`),
    className: styles.termOfLoan,
    onChange: (data) => submitHandler(data),
  },
];

export const fieldsDefault = (submitHandler) => [
  {
    title: 'Your profile',
    titleIcon: <Star className="title-icon" />,
    fields: [
      {
        name: 'numberOfApplicants',
        label: 'Who is the loan for?',
        type: 'select',
        options: ['Just me', 'My partner and I', 'A group of investors'],
        className: styles.splitLeft,
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'loanPurpose',
        label: 'What is the purpose?',
        type: 'select',
        options: ['To live in', "It's an investment"],
        className: styles.splitRight,
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'purchaseOrRefinance',
        label: 'Purchasing or refinancing?',
        type: 'select',
        options: ['Purchase', 'Refinance'],
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'dependants',
        label: 'Dependants?',
        type: 'select',
        options: ['0', '1', '2', '3', '4', '5', '6', '7', '7+'],
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'lender',
        label: 'Preferred lender',
        type: 'select',
        onChange: (data) => submitHandler(data),
        options: approvedLenders,
        reactSelect: true,
        selectLabel: true,
        conditional: ({ isLoggedIn }) => isLoggedIn,
      },
      {
        name: 'termOfLoan',
        label: 'Term of loan',
        type: 'select',
        options: [...Array(31).keys()]?.slice(10)?.map((number) => `${number} years`),
        className: styles.termOfLoanMobile,
        onChange: (data) => submitHandler(data),
      },
    ],
  },
  {
    title: 'Your income',
    titleIcon: <TakeFunds className="title-icon" />,
    fields: [
      {
        name: 'combinedIncome',
        label: 'Combined salary (before tax, excluding super)*',
        altLabel: "You and if applicable, your partner's income",
        type: 'currency',
        className: styles.col1Span4,
        validation: currencyValidation('Income - please enter valid amount'),
        onBlur: (data) => submitHandler(data),
      },
      {
        name: 'incomePeriod',
        type: 'select',
        options: ['/year', '/month', '/week'],
        className: `${styles.col5Span2} ${styles.incomePeriod}`,
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'otherIncome',
        type: 'array',
        subfieldLabel: () => 'Other income',
        className: styles.otherIncome,
        addButtonLabel: 'Add other income  -  e.g. commission, bonuses, etc.',
        onRemove: (data) => submitHandler(data?.formData),
        submitThenRemoveFromArray: false,
        subfields: [
          {
            name: 'income',
            type: 'currency',
            className: styles.col1Span4,
            validation: currencyValidation(),
            onBlur: (data) => submitHandler(data),
          },
          {
            name: 'incomePeriod',
            type: 'select',
            options: ['/year', '/month', '/week'],
            defaultValue: '/year',
            className: `${styles.col5Span2} ${styles.incomePeriod}`,
            onChange: (data) => submitHandler(data),
          },
        ],
      },
      {
        name: 'rentalIncome',
        type: 'array',
        subfieldLabel: () => 'Rental income',
        className: styles.otherIncome,
        addButtonLabel: 'Add rental income  -  e.g. income you receive from any investment property',
        onRemove: (data) => submitHandler(data?.formData),
        submitThenRemoveFromArray: false,
        subfields: [
          {
            name: 'income',
            type: 'currency',
            className: styles.col1Span4,
            validation: currencyValidation(),
            onBlur: (data) => submitHandler(data),
          },
          {
            name: 'incomePeriod',
            type: 'select',
            options: ['/year', '/month', '/week'],
            defaultValue: '/year',
            className: `${styles.col5Span2} ${styles.incomePeriod}`,
            onChange: (data) => submitHandler(data),
          },
        ],
      },
      {
        name: 'totalIncome',
        type: 'total',
        className: 'field-total',
        helper: ({ formData, extraData }) => {
          const { combinedIncome, incomePeriod, rentalIncome, otherIncome } = formData;

          const multiplierLookup = multiplierLookupDefault(1);

          const sum = (arr) =>
            arr?.reduce((acc, val) => {
              if (!val) return acc;
              return acc + parseFloat(val);
            }, 0);

          const multiplierSum = (arr, key, multiplierKey) =>
            arr?.reduce((acc, val, index) => {
              if (!val[key]) return acc;

              return acc + parseFloat(val[key]) * multiplierLookup?.[arr?.[index]?.[multiplierKey]];
            }, 0);

          const totalIncome = {
            combinedIncome: combinedIncome * multiplierLookup?.[incomePeriod],
            otherIncome: multiplierSum(otherIncome, 'income', 'incomePeriod'),
            rentalIncome: multiplierSum(rentalIncome, 'income', 'incomePeriod'),
          };

          const sumTotal = sum(Object.values(totalIncome));
          const sumCurrency = formatCurrency(sumTotal);

          return `
            <div>
              Total income (annual): ${sumCurrency}
            </div>
          `;
        },
      },
    ],
  },
  {
    title: 'Your expenses',
    titleIcon: <DollarCoin className="title-icon" />,
    fields: [
      {
        name: 'creditCardLimits',
        label: 'Credit card limits',
        altLabel: 'Total of all your credit card limits including store cards',
        type: 'currency',
        validation: currencyValidation('Credit card limits - please enter valid amount'),
        onBlur: (data) => submitHandler(data),
      },
      {
        name: 'vehicleLoanRepayments',
        label: 'Vehicle loan repayments',
        type: 'currency',
        className: styles.col1Span4,
        validation: currencyValidation('Vehicle loan repayments - please enter valid amount'),
        onBlur: (data) => submitHandler(data),
      },
      {
        name: 'periodVehicleLoanRepayments',
        type: 'select',
        options: ['/year', '/month', '/week'],
        className: `${styles.col5Span2} ${styles.incomePeriod}`,
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'householdExpenses',
        label: 'Household expenses',
        type: 'currency',
        className: styles.col1Span4,
        validation: currencyValidation('Household expenses - please enter valid amount'),
        onBlur: (data) => submitHandler(data),
      },
      {
        name: 'periodHouseholdExpenses',
        type: 'select',
        options: ['/year', '/month', '/week'],
        className: `${styles.col5Span2} ${styles.incomePeriod}`,
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'otherMortgages',
        type: 'array',
        subfieldLabel: () => 'Mortgage repayment',
        className: styles.otherIncome,
        addButtonLabel: 'Add mortgage repayments - e.g. repayment details for any mortgages you hold',
        onRemove: (data) => submitHandler(data?.formData),
        submitThenRemoveFromArray: false,
        subfields: [
          {
            name: 'balance',
            type: 'currency',
            className: styles.col1Span4,
            placeholder: 'Balance owing',
            validation: currencyValidation(),
            onBlur: (data) => submitHandler(data),
          },
          {
            name: 'remainingYears',
            type: 'number',
            defaultValue: 30,
            placeholder: 'Years left',
            className: `${styles.col5Span2} ${styles.incomePeriod}`,
            validation: {
              min: {
                value: 1,
                message: 'Please enter valid year',
              },
              max: {
                value: 30,
                message: 'Please enter year below 30',
              },
              required: {
                value: true,
                message: 'Please enter valid year',
              },
            },
            onChange: (data) => submitHandler(data),
          },
        ],
      },
      {
        name: 'totalExpenses',
        type: 'total',
        className: 'field-total',
        helper: ({ formData }) => {
          const {
            creditCardLimits,
            vehicleLoanRepayments,
            periodVehicleLoanRepayments,
            otherMortgages,
            householdExpenses,
            periodHouseholdExpenses,
          } = formData;

          const multiplierLookup = multiplierLookupDefault(12);

          const multiplierSum = (arr, key, multiplierKey) =>
            arr?.reduce((prev, current, index) => {
              if (!current[key]) return prev;

              return prev + Math.floor(current[key] / arr?.[index]?.[multiplierKey]);
            }, 0);

          const totalExpenses = {
            creditCardLimits: Math.floor(12 * (creditCardLimits * 0.035)),
            vehicleLoanRepayments: vehicleLoanRepayments * multiplierLookup?.[periodVehicleLoanRepayments],
            householdExpenses: householdExpenses * multiplierLookup?.[periodHouseholdExpenses],
            otherMortgages: multiplierSum(otherMortgages, 'balance', 'remainingYears'),
          };

          const sum = Object.values(totalExpenses).reduce((acc, val) => {
            if (!val) return acc;
            return acc + parseFloat(val);
          }, 0);

          const sumCurrency = formatCurrency(sum);

          return `
            <div>
              Total expenses (annual): ${sumCurrency}
            </div>
          `;
        },
      },
    ],
  },
];
