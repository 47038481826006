import React, { useEffect, useState } from 'react';
import { FormFlow } from 'components';
import { useApolloClient } from '@apollo/client';
import queryString from 'query-string';
import slugify from 'slugify';
import Cookies from 'js-cookie';
import { navigate } from 'gatsby';
import { useSetFlowStep } from '../../hooks/useSetFlowStep';
import populateFields from './utils/populateFields';
import { apolloLookup } from './utils/apollo';
import { REACTIVE_VARS } from '../../apollo/config/reactiveVars';
import { FACT_FIND_FRAGMENTS } from '../../apollo/fragments/fragments_ff';
import { defaultOnRemove } from './utils/defaultOnRemove';
import { handleCurrentProgress } from './utils/handleCurrentProgress';

const Questions = (props) => {
  const {
    pageContext: { uid, isLeadFlow },
    data,
    extra,
  } = props;
  const { location, applicantCoreData, isLoggedIn, refetchApplicantCoreData } = extra;
  const { items: questions } = data;

  // Applicant data
  const allApplicantsRaw = applicantCoreData?.currentUser?.application?.Users;
  const allApplicants =
    (allApplicantsRaw?.length > 0 &&
      [...allApplicantsRaw]?.sort((a, b) => b?.primaryApplicant - a?.primaryApplicant)) ||
    [];

  const loggedInUser = allApplicants?.find((applicant) => applicant?.email === Cookies.get('yb-email'));

  const isRefinancing = !!applicantCoreData?.currentUser?.application?.isRefinance;

  const currentUserIdKey = 'yb-current-user-id';
  const storedCurrentUserId = localStorage.getItem(currentUserIdKey);

  // Get Applicant Ids and Data
  const [currentUserId, setCurrentUserId] = useState(storedCurrentUserId || allApplicants?.[0]?.id);

  const apolloClient = useApolloClient();

  // Check current step in URL params
  const queryStrings = queryString.parse(location.search);
  const {
    question: questionSlug,
    next_applicant: fetchNextApplicant,
    primary_applicant: fetchPrimaryApplicant,
  } = queryStrings;

  // Keep track of current applicant in local storage
  useEffect(() => {
    if (currentUserId) {
      localStorage.setItem(currentUserIdKey, currentUserId);
    }
  }, [currentUserId]);

  const currentApplicantIndex = allApplicants?.findIndex((applicant, index) => applicant?.id === currentUserId);
  const currentApplicant = allApplicants?.[currentApplicantIndex];
  const nextApplicant = allApplicants?.[currentApplicantIndex + 1];
  const prevApplicant = currentApplicantIndex > 0 ? allApplicants?.[currentApplicantIndex - 1] : null;

  useEffect(() => {
    if (fetchNextApplicant && currentUserId !== nextApplicant?.id) {
      return setCurrentUserId(nextApplicant?.id);
    }

    if (fetchPrimaryApplicant) {
      return setCurrentUserId(allApplicants?.[0]?.id);
    }
  }, [fetchNextApplicant, fetchPrimaryApplicant]);

  // Set applicant name in header
  REACTIVE_VARS.currentApplicantVar({
    ...REACTIVE_VARS.currentApplicantVar(),
    firstName: currentApplicant?.name,
  });

  // Check whether the current question is a refinance flow question
  const refinanceFlowQuestion = questions?.find((question) => {
    if (typeof question.question === 'string') {
      return slugify(question?.question)?.toLowerCase() === questionSlug;
    }

    return question;
  })?.refinance_flow;

  //---------------------------------------
  // Apollo config
  const fragment = FACT_FIND_FRAGMENTS?.[uid]?.[questionSlug];
  const apollo = apolloLookup({
    isRefinancing,
    uid,
    applicantCoreData,
    refinanceFlowQuestion,
    currentApplicant,
    question: questionSlug,
  });
  const apolloCacheTypename = apollo?.cacheTypename;
  const apolloId = apollo?.id;
  const cacheId = `${apolloCacheTypename}:${apolloId}`;

  const storedCachedData = fragment && apolloClient?.cache?.readFragment({ id: cacheId, fragment });

  const factFindUids = ['about-you', 'property', 'mortgage', 'expenses'];
  const isFactFind = factFindUids?.includes(uid);
  const currentFactFindProgress = handleCurrentProgress({ currentApplicant, applicantCoreData });

  const isFactFindSectionLocked = factFindUids?.reduce((prev, current, index, arr) => {
    const prevStepUid = arr?.[index - 1];
    const prevStepProgress = currentFactFindProgress?.[prevStepUid];
    const locked = prevStepProgress && prevStepProgress < 100;

    return {
      ...prev,
      [current]: {
        locked: !!locked,
      },
    };
  }, {});

  const factFindComplete = !Object.values(isFactFindSectionLocked)?.find((lockedStatus) => lockedStatus?.locked);

  const targetIdUpdateCurrentFlowStep = loggedInUser?.id !== currentApplicant?.id ? currentApplicant?.id : null;

  useSetFlowStep({
    newFlowStep: 5,
    userId: loggedInUser?.id,
    condition: factFindComplete,
    targetId: targetIdUpdateCurrentFlowStep,
  });

  if (isFactFindSectionLocked?.[uid]?.locked && process.env.NODE_ENV !== 'development') {
    navigate('/fact-find');
    return null;
  }

  const extraData = {
    isLoggedIn,
    applicantCoreData,
    Users: allApplicants,
    isRefinancing,
    apolloConfig: { ...apollo, fragment, cacheId, apolloClient },
    currentApplicant,
    prevApplicant,
    nextApplicant,
    setCurrentUserId,
    isLeadFlow,
    isFactFind,
    questionSlug,
    uid,
    refetchApplicantCoreData,
  };

  const fields = populateFields({
    questions,
    defaultOnRemove,
    storedCachedData,
    isRefinancing,
    uid,
    isFactFind,
  });

  return (
    <FormFlow
      defaultValues={isFactFind && storedCachedData}
      fields={fields}
      extraData={extraData}
      formHeaderAlignCenter={isLeadFlow}
    />
  );
};

export default Questions;
