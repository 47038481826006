import { navigate } from 'gatsby';
import { REACTIVE_VARS } from '../../../../../apollo/config/reactiveVars';

const { nextApplicantVar } = REACTIVE_VARS;

export default [
  {
    type: 'quickMessage',
    name: 'message',
    namePrismic: 'Quick Message',
    slug: 'quick-message',
    className: 'transition-message',
    preventDefaultNavigation: true,
    conditional: ({ extraData }) => extraData?.Users?.length > 1,
    onMount: ({ extraData }) => {
      const { nextApplicant, currentApplicant } = extraData;

      if (!nextApplicant) {
        nextApplicantVar({
          ...nextApplicantVar(),
          customTitle: 'Please add details about your collective assets and liabilities',
        });
      }
    },
    onSubmit: ({ extraData }) =>
      new Promise((resolve, reject) => {
        const { nextApplicant } = extraData;
        if (nextApplicant) {
          navigate('?question=about-details&next_applicant=true');
          resolve();
        }

        if (!nextApplicant) {
          navigate('?question=about-assets&primary_applicant=true');
          resolve();
        }
      }),
  },
];
