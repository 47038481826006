import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import { onSubmit } from './onSubmit';

// Init to caclulate date from now
dayjs.extend(relativeTime);
dayjs.extend(duration);

export default [
  {
    type: 'arrayAccordion',
    name: 'employmentHistory',
    namePrismic: 'About - Work',
    slug: 'about-work',
    onSubmit,
    addButtonLabel: 'Add employment',
    removeButtonLabel: 'Delete employment',
    validation: () => ({
      min: 1,
    }),
    toggleLabel: ({ fieldData, index }) => {
      const formatDate = () => {
        const pluralize = (count, noun) => `${count} ${noun}${count !== 1 ? 's' : ''}`;
        if (!fieldData?.startDate || (!fieldData?.endDate && index > 0)) return null;
        const startDate = dayjs(fieldData?.startDate);
        const endDate = fieldData?.endDate ? dayjs(fieldData?.endDate) : dayjs();
        const years = endDate?.diff(startDate, 'y');
        const months = endDate?.diff(startDate, 'M');
        const yearsLabel = years > 0 && years < 100 ? pluralize(years, 'year') : null;
        const monthsLabel = years < 100 && months % 12 > 0 ? pluralize(months % 12, 'month') : null;
        const dateLabel = yearsLabel || monthsLabel ? [` - (`, yearsLabel, monthsLabel, ')'].join(' ') : null;
        return dateLabel;
      };
      return [index > 0 ? `Employment #${index + 1}` : `Current Employment`, formatDate()].join(' ');
    },
    subfields: [
      {
        type: 'select',
        name: 'employmentType',
        placeholder: 'Select one',
        label: 'Employment type',
        options: ['Employee', 'Self-employed', 'Full Time', 'Part Time', 'Casual'],
        validationMessage: 'Please select employment type',
      },
      {
        type: 'text',
        name: 'jobTitle',
        label: 'Job title',
        validationMessage: 'Please enter job title',
      },
      {
        type: 'text',
        name: 'company',
        label: 'Company / Organisation',
        validationMessage: 'Please enter company or organisation',
      },
      {
        type: 'date',
        name: 'startDate',
        label: 'Start date',
        className: ({ index }) => (index === 0 ? '' : 'split-left'),
        validation: ({ formData, index }) => ({
          required: {
            value: true,
            message: 'Please enter job start date',
          },
          validate: (v) => {
            const twoYearsDifference = dayjs.duration({ years: 2 }).$ms;
            const employmentHistory = formData?.employmentHistory;

            const allStartDates = employmentHistory?.map((employmentHistoryData) => employmentHistoryData?.startDate);
            const allStartDatesSorted = allStartDates?.sort((a, b) => {
              const aDate = dayjs(a);
              const bDate = dayjs(b);
              if (aDate && bDate) {
                return aDate?.diff(bDate) - bDate?.diff(aDate);
              }
              return a - b;
            });
            const oldestJobStartDate = dayjs(allStartDatesSorted?.[0]);
            const TODAY = dayjs();

            const oldestJobProvidedStartDateGreaterThanTwoYears = TODAY.diff(oldestJobStartDate) > twoYearsDifference;

            if (!oldestJobProvidedStartDateGreaterThanTwoYears) {
              return 'Please include at least two years employment history';
            }

            const selectedStartDate = dayjs(v);

            return TODAY.diff(selectedStartDate) > 0 || 'Please enter valid start date';
          },
        }),
      },
      {
        type: 'date',
        name: 'endDate',
        label: 'End date',
        className: ({ index }) => `${index < 1 ? 'hide-fields' : 'split-right'}`,
        validation: ({ formData, index }) => ({
          required: {
            value: true,
            message: 'Please enter job end date',
          },
          validate: (v) => {
            const selectedStartDate = dayjs(formData?.employmentHistory?.[index]?.startDate);
            const selectedEndDate = dayjs(v);
            const TODAY = dayjs();

            if (selectedEndDate.diff(selectedStartDate) < 0 || TODAY.diff(selectedEndDate) < 0)
              return 'Please enter valid job end date';

            return true;
          },
        }),
        conditional: ({ index }) => index !== 0,
        validationMessage: 'Please enter job end date',
      },
    ],
  },
];
