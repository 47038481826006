import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './styles.module.scss';

const FieldCurrencyInfoHelper = (props) => {
  const { helper, value, watch } = props;
  const formData = watch();
  if (!helper) return null;
  const fieldData = helper(value || `$0`, formData);
  return <div className={`${styles.container} ${value ? styles.active : ''}`}>{fieldData}</div>;
};

FieldCurrencyInfoHelper.propTypes = {
  helper: PropTypes.func,
  value: PropTypes.string,
};

FieldCurrencyInfoHelper.defaultProps = {
  helper: null,
  value: null,
};

export default FieldCurrencyInfoHelper;
