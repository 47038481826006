import React, { useState, useEffect, useRef } from 'react';
import AccordionItem from './AccordionItem';
import * as styles from './styles.module.scss';

const Accordion = (props) => {
  const { children, className, initialState = [0], allowMultipleExpand = false, allowExpandNew = true } = props;
  const initState = allowMultipleExpand ? [...Array(children.length).keys()] : initialState;
  const [activeIndexes, setActiveIndexes] = useState(initState);

  // Show or Hide items
  const onSetActiveIndexHandler = (index) => {
    let state = [...activeIndexes];
    const activeIndex = state.findIndex((itemIndex) => itemIndex === index);
    const isActive = activeIndex > -1;

    if (!allowMultipleExpand) {
      if (isActive) state = [];
      else state = [index];
    }

    if (allowMultipleExpand) {
      if (isActive) state.splice(activeIndex, 1);
      else state.push(index);
    }
    setActiveIndexes(state);
  };

  // Manage appended items
  const prevChildrenCount = useRef(null);
  useEffect(() => {
    const childrenCount = children.length;
    // if (allowMultipleExpand && !prevChildrenCount.current) {
    //   const showAllIndexes = [...Array(childrenCount).keys()];
    //   setActiveIndexes(showAllIndexes);
    // }
    if (allowExpandNew && prevChildrenCount !== childrenCount) onSetActiveIndexHandler(Math.max(childrenCount - 1, 0));
    prevChildrenCount.current = childrenCount;
  }, [children.length]);

  return (
    <div className={`${styles.accordion} ${className || ''}`}>
      {React.Children.map(children, (child, index) => {
        const key = child?.key || String(index);
        return React.cloneElement(child, {
          ...child.props,
          key,
          active: activeIndexes.includes(index),
          onClick: () => onSetActiveIndexHandler(index),
        });
      })}
    </div>
  );
};

Accordion.Item = AccordionItem;

export default Accordion;
