import { navigate } from 'gatsby';
import { UPDATE_APPLICATION } from '../../apollo/mutations/mutations_misc';
import { sendInvite } from '../../utils/sendCode';

export const handleSubmit = async ({ apolloClient, formData: { formData }, extraData, setError, dataLayer }) => {
  try {
    const { primaryApplicant, currentInvitedApplicant } = extraData;
    await sendInvite({
      inviteDetails: {
        primaryUserName: primaryApplicant?.name,
        primaryApplicationId: primaryApplicant?.application?.id,
        additionalApplicantFirstName: currentInvitedApplicant?.name,
        additionalApplicantNumber: formData?.invitePhoneNumber,
        additionalApplicantEmail: formData?.inviteEmail,
      },
    });

    await apolloClient.mutate({
      mutation: UPDATE_APPLICATION,
      variables: {
        id: primaryApplicant?.application?.id,
        additionalUsers: [
          {
            id: currentInvitedApplicant?.id,
            inviteSent: true,
          },
        ],
      },
    });

    dataLayer?.track('Applicant Invite Sent');

    return navigate(`/invite-other-applicants/`);
  } catch (error) {
    setError('invitePhoneNumber', {
      type: 'required',
      message: error.response.data.fail || 'Something went wrong. Please contact support@youbroker.com',
    });
  }
};
