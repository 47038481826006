import React, { useEffect } from 'react';
import { Header, AppFooter, AppSidebar, SEO, WmAscii } from 'components';
import { use100vh } from 'react-div-100vh';
import { useDataLayer } from '../../../hooks/useDataLayer';
import '@fontsource/inter';
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';
import '../../../sass/global/styles.scss';
import * as styles from './styles.module.scss';

const AppLayout = (props) => {
  const { children, customSEO, seo, location, showTrustFactors, stepIndex, hideSidebar, className, extra } = props;

  const dataLayer = useDataLayer();

  useEffect(() => {
    dataLayer?.init();
    dataLayer?.setup();
  }, []);

  const height = use100vh();

  return (
    <div className={`${styles.section} ${className || ''}`} id="app-layout" style={{ height }}>
      <WmAscii />
      <Header location={location} appUi />
      <main className={styles.main}>
        {!customSEO && <SEO {...seo} location={location} />}
        <div className={styles.pattern} />
        <div className={`${styles.container} app-layout-container`}>
          {!hideSidebar ? <AppSidebar extra={extra} stepIndex={stepIndex || 0} /> : <div />}
          <div className={`${styles.body} app-layout-body`}>{children}</div>
        </div>
        <div id="portal-footer" className={styles.portal} />
      </main>
      {showTrustFactors && <AppFooter />}
    </div>
  );
};

export default AppLayout;
