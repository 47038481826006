import React from 'react';
import { Modal, CtaArrow } from 'components';

const FieldModalNav = (props) => {
  const { goBack, handleSubmit } = props;
  return (
    <div className="modal-button-container">
      <button type="submit" className="form-back-button" onClick={goBack}>
        back
      </button>
      <button type="submit" className="form-next button" onClick={handleSubmit}>
        next
        <CtaArrow />
      </button>
    </div>
  );
};

const FieldModal = (props) => {
  const {
    title,
    name,
    closeMenu,
    register,
    validation,
    placeholder,
    defaultValue,
    autoFocus,
    index = 9,
    goBack,
    handleSubmit,
    setStepIndex,
  } = props;

  return (
    <Modal active>
      <div className="modal-contents">
        <img src={closeMenu} className="modal-close-button" alt="close modal" onClick={() => setStepIndex(index)} />
        <p className="modal-title">{title[name]}</p>
        {name !== 'firstName' && (
          <div className="modal-foot">
            <p className="modal-more-info">We need a little more information</p>
          </div>
        )}
        <input
          className="modal-input"
          {...register(name, validation)}
          name={name}
          id={name}
          placeholder={placeholder || 'type your answer here'}
          defaultValue={defaultValue}
          autoFocus={autoFocus}
        />
      </div>

      <FieldModalNav goBack={goBack} handleSubmit={handleSubmit} />
    </Modal>
  );
};

export default FieldModal;
