import React from 'react';
import PostGrid from '../PostGrid/index.jsx';
import './styles.scss';

const LatestPosts = props => {
  const { posts, title } = props;

  return (
    <section className="latest-posts">
      <PostGrid posts={posts} title={title} />
    </section>
  );
};

export default LatestPosts;
