import React, { useState, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import FieldCurrencyInfoHelper from './FieldCurrencyInfoHelper';
import { formatCurrency } from './formatCurrency';
import * as styles from './styles.module.scss';

const FieldCurrency = (props) => {
  const {
    name,
    placeholder,
    defaultValue,
    helper,
    validation,
    autoFocus,
    setValue,
    isSubmitting,
    defaultValues,
    onChange,
    onBlur,
    watch,
    control,
  } = props;

  const { methods } = useFormContext();

  const [isEditing, setIsEditing] = useState(false);
  const value = watch(name);
  const currency = formatCurrency(value) || value;

  const { field } = useController({
    name,
    rules: validation,
    defaultValue: defaultValue || '',
    control: methods?.control,
  });

  useEffect(() => {
    if (defaultValue && !defaultValues?.[name]) {
      // SET VALUE AFTER REF HAS BEEN REGISTERED
      setTimeout(() => {
        setValue(name, defaultValue);
      }, 1);
    }
  }, [defaultValue, name, defaultValues]);

  const onBlurHandler = (e) => {
    if (onBlur) onBlur(watch());
    field.onBlur(e);
    setIsEditing(false);
  };

  const onChangeHandler = (e) => {
    if (onChange) onChange(e);
    const inputValue = e?.target?.value;
    const parsedValue = inputValue ? parseInt(inputValue, 10) : '';
    field?.onChange(parsedValue);
  };

  const onFocusHandler = () => setIsEditing(true);

  return (
    <>
      {isEditing ? (
        <input
          {...field}
          className={styles.number}
          type="number"
          onChange={onChangeHandler}
          id={name}
          key={name}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onBlur={onBlurHandler}
          readOnly={isSubmitting}
          onKeyDown={(e) => {
            if (e?.key !== 'Backspace' && (e?.key === 'e' || e?.key === 'E' || e?.target?.value?.length > 9))
              return e.preventDefault();
          }}
        />
      ) : (
        <input
          type="text"
          key={`$${name}_formatted`}
          name={`$${name}_formatted`}
          value={currency || ''}
          placeholder={placeholder}
          onFocus={onFocusHandler}
          readOnly
        />
      )}
      <FieldCurrencyInfoHelper name={name} helper={helper} value={currency} watch={watch} />
    </>
  );
};

export default FieldCurrency;
