import React from 'react';
import { ReactComponent as WarningGauge } from '../../../images/lvr-warning-gauge.svg';
import * as styles from './styles.module.scss';

const Placeholder = (props) => {
  const { label, className = '', content, lvr } = props;

  return (
    <div className={`${styles.container} ${className}`}>
      {label && <h4>{label}</h4>}
      {lvr > 100 && <WarningGauge className={styles.warningGauge} />}
      {content && lvr > 100 && <div className={styles.content}>{content}</div>}
    </div>
  );
};

export default Placeholder;
