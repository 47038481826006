import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useForm, FormProvider } from 'react-hook-form';
import { useApolloClient } from '@apollo/client';
import { DevTool } from '@hookform/devtools';
import { FormFieldNew, FormError, FormHeader, FormNav, ProgressBar } from 'components';
import { useDataLayer } from '../../../hooks/useDataLayer';
import { isDev } from '../../../utils/misc';
import persistForm from '../helpers/persistForm';
import GlobalContext from '../../../contexts/GlobalContext';
import * as styles from './styles.module.scss';

const FormFlow = (props) => {
  const {
    fields,
    extraData,
    defaultValues,
    localStorageKey,
    hideProgressBar,
    isLogin,
    className,
    formHeaderAlignCenter,
  } = props;

  const dataLayer = useDataLayer();

  const { setLeadFlowStepProgress } = useContext(GlobalContext) || {};

  // -----------------------------
  // Get default form values (from localStorage or from AppolloClient)
  const parsedPersistedFormValues =
    (typeof window !== 'undefined' && JSON.parse(localStorage.getItem(localStorageKey))) || {};

  // -----------------------------
  // Initialise apolloClient
  const apolloClient = useApolloClient();

  // -----------------------------
  // Initialise Form
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || parsedPersistedFormValues,
  });

  const { register, handleSubmit, formState, getValues, setValue, clearErrors, control, watch, setError, reset } =
    methods;
  const getValuesWithExtra = () => ({ formData: getValues(), extraData });

  // -----------------------------
  // Conditionally filter fields
  const filteredFields = fields?.filter((field) => {
    const conditionalDisplay = field?.question?.conditional || field?.conditional; // Check if field has a conditional parameter (function)
    if (conditionalDisplay) return conditionalDisplay(getValuesWithExtra()); // Return true or false
    return true;
  });

  const uid = extraData?.uid;
  const questionSlug = extraData?.questionSlug;
  const isFactFind = extraData?.isFactFind;
  const nextApplicant = extraData?.nextApplicant;

  // FACT FIND SPECIFIC
  const questionsCount = filteredFields?.length;
  const questionIndex = filteredFields?.findIndex(({ slug }) => slug === questionSlug);
  const questionProgress = (questionIndex / questionsCount) * 100;
  const question = filteredFields?.[questionIndex];
  const questionInitSlug = filteredFields?.[0]?.slug;

  const prevQuestionIndex = questionIndex - 1;
  const prevQuestion = filteredFields?.[prevQuestionIndex];
  const prevQuestionSlug = prevQuestion?.question?.slug;
  const nextQuestionIndex = Math.min(questionsCount - 1, questionIndex + 1);
  const nextQuestion = filteredFields?.[nextQuestionIndex];
  const nextQuestionSlug = nextQuestion?.question?.slug;
  const nextPath = nextQuestionSlug === questionSlug ? '/fact-find' : `?question=${nextQuestionSlug}`;

  // -----------------------------
  // Form wizard step handling
  const [stepIndex, setStepIndex] = useState(0);
  const currentStep = !isFactFind ? filteredFields[stepIndex] : question;
  const stepCount = !isFactFind ? filteredFields?.length : questionsCount;
  const stepProgress = !isFactFind ? (stepIndex / stepCount) * 100 : questionProgress;
  const currentStepsProperties = !isFactFind ? currentStep : question;

  useEffect(() => {
    setLeadFlowStepProgress(stepProgress);
  }, [stepProgress]);

  const goPrev = currentStepsProperties?.goPrev;
  const goNext = currentStepsProperties?.goNext;
  const onSubmit = currentStepsProperties?.onSubmit;
  const prevButtonLabel = currentStepsProperties?.prevButtonLabel;
  const nextButtonLabel = currentStepsProperties?.nextButtonLabel;
  const skipSubmitDefault = currentStepsProperties?.skipSubmit;
  const preventDefaultNavigation = currentStepsProperties?.preventDefaultNavigation;

  // -----------------------------
  // Submit form handlers
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState();

  const onSubmitHandler = async (formData) => {
    const skipSubmit =
      typeof skipSubmitDefault === 'function' ? skipSubmitDefault({ extraData, formData }) : skipSubmitDefault;

    try {
      setSubmitting(true);
      if (onSubmit && !skipSubmit) await onSubmit({ formData, extraData, apolloClient, setError, dataLayer });
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setSubmitError(submitError);
    }
  };
  const onSubmitErrorHandler = (error) => {
    setSubmitError(error?.message);
    console.error('@Form - Submit errors', error);
  };

  // -----------------------------
  // Animate mounting
  const [isMounting, setIsMounting] = useState(false);
  const formClasses = `${styles.form} ${isMounting ? styles.hidden : styles.visible} form-flow`;
  useEffect(
    () => () => {
      setTimeout(() => {
        setIsMounting(false);
      }, 200);
    },
    [isMounting]
  );

  // -----------------------------
  // Navigation handlers
  const goPrevHandlerDefault = () => {
    if (!isFactFind) {
      clearErrors();
      if (goPrev) goPrev();
      return setStepIndex((state) => Math.max(state - 1, 0));
    }

    if (isFactFind) {
      clearErrors();
      if (prevQuestionIndex < 0) return navigate('/fact-find');
      return navigate(`/fact-find/${uid}/?question=${prevQuestionSlug}`);
    }
  };

  const goNextHandlerDefault = async (validatedFormData) => {
    const skipSubmit =
      typeof skipSubmitDefault === 'function'
        ? skipSubmitDefault({ extraData, formData: validatedFormData, dataLayer })
        : skipSubmitDefault;

    if (!isFactFind) {
      persistForm({ localStorageKey, getValues }); // Persist form to local storage
      if (onSubmit && !skipSubmit)
        await onSubmitHandler({ formData: validatedFormData, extraData, apolloClient, setError, dataLayer });
      else {
        setStepIndex((state) => Math.min(state + 1, stepCount - 1));
        setIsMounting(true);
        if (goNext) goNext({ formData: validatedFormData, extraData, apolloClient, setError, dataLayer });
      }
    }

    if (isFactFind) {
      try {
        setSubmitting(true);
        if (process.env.NODE_ENV === 'development') {
          console.log(`%c@Questions - Submission Start`, 'color:orange', validatedFormData);
        }

        if (onSubmit) {
          await onSubmit({ formData: validatedFormData, extraData, apolloClient, dataLayer });
          if (process.env.NODE_ENV === 'development') {
            console.log(`%c@Questions - Submission Success`, 'color:green', `Next Path > ${nextPath}`);
          }
        }
        setIsMounting(true);
        setSubmitting(false);
        if (goNext) return goNext({ formData: validatedFormData, extraData });

        if (!preventDefaultNavigation) navigate(nextPath);
      } catch (error) {
        setSubmitting(false);
        setSubmitError(submitError);

        if (process.env.NODE_ENV === 'development') {
          if (error?.graphQLErrors?.length > 0) {
            console.error('@Question - Submission GraphQL Error', error?.graphQLErrors);
          } else if (error?.networkError) {
            const errors = error?.networkError?.result?.errors;
            const errorsMessage = errors?.map((err) => err.message);
            console.error('@Question - Submission Server Error', errorsMessage);
          } else console.error('@Question - Submission Error', error);
        }
      }
    }
  };

  const goNextHandlerWithValidation = handleSubmit(goNextHandlerDefault, onSubmitErrorHandler);
  const goPrevHandler = goPrevHandlerDefault;

  // Reset form with default values
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (isFactFind) {
      // Redirect to start if question is not found
      if (question === undefined || questionIndex < 0) {
        navigate(`/fact-find/${uid}/?question=${questionInitSlug}`);
        return null;
      }
    }
  }, []);

  return (
    <section className={className || ''}>
      <FormProvider {...methods}>
        <form className={formClasses} onSubmit={goNextHandlerWithValidation}>
          <FormError message={submitError} />
          {filteredFields?.map((field, index) => {
            if (isFactFind && index !== questionIndex) return null;

            if (!isFactFind && index !== stepIndex) return null;

            const { name } = field;
            const validationErrors = formState?.errors?.[name] || formState?.errors;

            return (
              <div key={name} data-step={name.toUpperCase()} className={styles.container}>
                <FormHeader
                  title={field?.title}
                  description={field?.description}
                  alignCenter={formHeaderAlignCenter}
                  nextApplicant={nextApplicant}
                />
                <FormFieldNew
                  {...field}
                  key={name}
                  validationErrors={validationErrors}
                  register={register}
                  setValue={setValue}
                  getValues={getValuesWithExtra}
                  goNext={goNextHandlerWithValidation}
                  goPrev={goPrevHandler}
                  apolloClient={apolloClient}
                  watch={watch}
                  clearErrors={clearErrors}
                  defaultValues={defaultValues}
                  reset={reset}
                  setError={setError}
                  extraData={extraData}
                  isSubmitting={isSubmitting}
                />
                <FormNav
                  isSubmitting={isSubmitting}
                  goPrev={goPrevHandler}
                  goNext={goNextHandlerWithValidation}
                  isLogin={isLogin}
                  prevButtonLabel={prevButtonLabel}
                  nextButtonLabel={nextButtonLabel}
                  preventEnterKeyListener={field?.question?.preventEnterKeyListener}
                />
              </div>
            );
          })}
          {!hideProgressBar && <ProgressBar progress={stepProgress} />}
          {isDev && <DevTool control={control} />}
        </form>
      </FormProvider>
    </section>
  );
};

FormFlow.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  localStorageKey: PropTypes.string,
};

FormFlow.defaultProps = {
  localStorageKey: `yb-data`,
};

export default FormFlow;
