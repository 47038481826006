import React from 'react';
import { ProgressBar } from 'components';
import classNames from 'classnames';
import ApplicationFlowButton from '../ApplicationFlowCommon/ApplicationFlowButton';
import * as styles from '../styles.module.scss';

const ApplicationFlowDefault = (props) => {
  const { applicationFlows, progresses } = props;

  return (
    <div className={styles.section}>
      {applicationFlows.map((applicationFlow, index) => {
        const { title, subtitle, duration, link, tooltipLabel, tooltipContent } = applicationFlow;
        const { uid } = link;
        const progress = progresses?.[uid];

        const prevFlowStepUid = applicationFlows[index - 1]?.link?.uid;
        const prevStepProgress = progresses?.[prevFlowStepUid];
        const locked = prevStepProgress && prevStepProgress < 100;

        const classes = classNames(styles.container, locked && styles.locked);

        const progressStatus = () => {
          if (locked) return 'locked';
          if (progress === 100) return 'complete';
          if (progress > 0) return 'to-complete';
          return null;
        };

        const adjustedProgress = 50 + progresses?.[link?.uid] / 2;

        return (
          <div className={classes} key={title?.text}>
            <div className={`${styles.topContainer} ${styles.topContainerDefault}`}>
              <div>
                <div className={styles.title}>{title?.text}</div>
                <div className={styles.duration}>{duration?.text}</div>
              </div>
              <div>
                <ApplicationFlowButton link={{ to: `/fact-find/${uid}` }} status={progressStatus()} />
              </div>
            </div>
            <ProgressBar progress={adjustedProgress} className={styles.progress} />
          </div>
        );
      })}
    </div>
  );
};

export default ApplicationFlowDefault;
