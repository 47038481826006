export default [
  {
    type: 'radio',
    name: 'investmentOrLiveIn',
    namePrismic: 'Property - Use',
    slug: 'property-use',
    goNextOnClick: true,
    options: [{ text: 'To live in' }, { text: "It's an investment" }],
  },
];
