import React from 'react';

const FieldEmail = (props) => {
  const { name, className, register, validation, placeholder, defaultValue, autoFocus, isSubmitting } = props;
  return (
    <input
      {...register(name, validation)}
      type="email"
      className={className}
      name={name}
      id={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      readOnly={isSubmitting}
    />
  );
};

export default FieldEmail;
