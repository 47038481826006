import Cookies from 'js-cookie';
import { DELETE_RECORD } from '../../../apollo/mutations/mutations_misc';

export const defaultOnRemove = ({ field, apolloClient, table, extraData }) => {
  const Users = extraData?.Users;
  const currentApplicant = extraData?.currentApplicant;
  const loggedInApplicant = Users?.find((user) => user?.email === Cookies.get('yb-email'));

  // NEEDED FOR MULTI-APPLICANT FLOW PERMISSIONS
  const submitAdditionalIds = currentApplicant?.id !== loggedInApplicant?.id;

  const cacheObjectId = apolloClient.cache.identify({
    id: field?.id,
    __typename: field?.__typename,
  });

  if (cacheObjectId) {
    try {
      apolloClient.mutate({
        mutation: DELETE_RECORD,
        variables: {
          id: field?.id,
          table,
          userId: submitAdditionalIds ? loggedInApplicant?.id : null,
          targetId: submitAdditionalIds ? currentApplicant?.id : null,
        },
      });
      apolloClient.cache.evict({ id: cacheObjectId });
      apolloClient.cache.gc();
    } catch (error) {
      return console.log('ERROR: ', error);
    }
  }
};
