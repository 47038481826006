import Cookies from 'js-cookie';
import { REACTIVE_VARS } from './reactiveVars';

export const typePolicies = {
  Query: {
    fields: {
      isLoggedIn: {
        read() {
          return !!Cookies.get('yb-token');
        },
      },
      currentApplicant: {
        read() {
          return REACTIVE_VARS.currentApplicantVar();
        },
      },
      nextApplicant: {
        read() {
          return REACTIVE_VARS.nextApplicantVar();
        },
      },
    },
  },
  MortgageShortlist: {
    keyFields: ['productId'],
  },
};
