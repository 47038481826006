import React from 'react';
import { Section, GetStarted, OnVisible } from 'components';
import { ReactComponent as RocketIcon } from '../../images/rocket.svg';
import { ReactComponent as YbIcon } from '../../images/logo-icon.svg';
import { ReactComponent as YbIconWhite } from '../../images/logo-icon-light.svg';
import * as styles from './styles.module.scss';

const Cta = (props) => {
  const { data } = props;
  const { primary } = data;
  const { variant, icon, title, content, cta, highlight_refinance: highlightRefinance } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;

  const getIconComponent = {
    'YB Icon': variant === 'Dark Blue' ? YbIconWhite : YbIcon,
    Rocket: RocketIcon,
  };

  const IconComponent = getIconComponent[icon];

  const getVariantStyles = {
    White: styles.whiteBg,
    'Light Blue': styles.lightBlueBg,
    'Dark Blue': styles.darkBlueBg,
    Narrow: styles.narrow,
  };

  const variantStyles = getVariantStyles[variant] || styles.whiteBg;

  return (
    <Section className={`${styles.section} ${variantStyles}`} containerClassName={styles.container}>
      {/* top graphic */}
      {(titleHtml || IconComponent || contentHtml) && (
        <div className={styles.titleContainer}>
          {IconComponent && (
            <OnVisible className={styles.icon} visibleClassName={styles.iconVisible}>
              <IconComponent />
            </OnVisible>
          )}
          {titleHtml && (
            <OnVisible
              className={styles.title}
              visibleClassName={styles.titleVisible}
              dangerouslySetInnerHTML={{ __html: titleHtml }}
            />
          )}
          {contentHtml && (
            <OnVisible
              className={styles.content}
              visibleClassName={styles.contentVisible}
              dangerouslySetInnerHTML={{ __html: contentHtml }}
            />
          )}
        </div>
      )}
      {cta && (
        <OnVisible className={styles.cta} visibleClassName={styles.ctaVisible}>
          <GetStarted showRefinance={cta === 'Purchase or Refinance'} highlightRefinance={highlightRefinance} />
        </OnVisible>
      )}
    </Section>
  );
};

export default Cta;
