import { useQuery } from '@apollo/client';
import { GET_CURRENT_APPLICANT } from '../apollo/queries/queries_misc';

export const useFormatDescriptionWithApplicantData = ({ description, nextApplicant }) => {
  const { data: currentApplicantData } = useQuery(GET_CURRENT_APPLICANT);
  const nextApplicantQuery = currentApplicantData?.nextApplicant;

  if (!description?.includes('NEXT_APPLICANT')) return description;

  if (!nextApplicant?.name) return nextApplicantQuery?.customTitle;

  return description.replace(/NEXT_APPLICANT/g, nextApplicant?.name);
};
