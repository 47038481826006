import { defaultOnSubmit } from '../../../utils/defaultOnSubmit';

export const onSubmit = async ({ formData, apolloClient, extraData }) => {
  const addressNotKnown = formData?.addressNotKnown === 'No';

  const variables = {
    addressNotKnown,
    addressOne: addressNotKnown ? '' : formData?.addressOne,
    addressTwo: addressNotKnown ? '' : formData?.addressTwo,
    city: addressNotKnown ? '' : formData?.city,
    postCode: addressNotKnown ? '' : formData?.postCode,
    state: addressNotKnown ? '' : formData?.state,
    country: addressNotKnown ? '' : formData?.country,
  };

  return defaultOnSubmit({ formData: variables, apolloClient, extraData });
};
