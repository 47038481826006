import React from 'react';
import { ReactComponent as Star } from '../../../images/iconography/star.svg';

export const fieldsDefault = (submitHandler) => [
  {
    title: 'Your home loan details',
    titleIcon: <Star className="title-icon" />,
    fields: [
      {
        name: 'state',
        type: 'toggleButtons',
        className: 'stamp-duty-state',
        label: 'Select your state',
        options: [
          { buttonText: 'NSW' },
          { buttonText: 'VIC' },
          { buttonText: 'QLD' },
          { buttonText: 'WA' },
          { buttonText: 'ACT' },
          { buttonText: 'NT' },
          { buttonText: 'SA' },
          { buttonText: 'TAS' },
        ],
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'state',
        type: 'select',
        className: 'stamp-duty-state-mobile',
        label: 'Select your state',
        options: ['NSW', 'VIC', 'QLD', 'WA', 'ACT', 'NT', 'SA', 'TAS'],
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'propertyValue',
        label: 'Property value',
        type: 'currency',
        placeholder: '$',
        onBlur: (data) => submitHandler(data),
        validation: {
          required: {
            value: true,
            message: 'Please enter property value',
          },
          min: {
            value: 50000,
            message: 'Please enter an amount over $50,000',
          },
          max: {
            value: 999999999,
            message: 'Please enter an amount under $999,999,999',
          },
        },
      },
      {
        type: 'radio',
        name: 'firstHomeBuyer',
        label: 'First home buyers',
        options: [{ text: 'Yes' }, { text: 'No' }],
        onChange: (data) => submitHandler(data),
        conditional: ({ formData }) => !['NT', 'SA', 'TAS']?.includes(formData?.state),
      },
      {
        type: 'radio',
        name: 'foreignPurchaser',
        label: 'Foreign purchaser',
        options: [{ text: 'Yes' }, { text: 'No' }],
        onChange: (data) => submitHandler(data),
        conditional: ({ formData }) => !['ACT', 'NT']?.includes(formData?.state),
      },
      {
        type: 'radio',
        name: 'pensioner',
        label: 'Eligible pensioner',
        options: [{ text: 'Yes' }, { text: 'No' }],
        onChange: (data) => submitHandler(data),
        conditional: ({ formData }) => ['VIC', 'ACT', 'TAS']?.includes(formData?.state),
      },
      {
        type: 'select',
        name: 'vicEligiblePensioner',
        label: 'Duty exemption',
        options: ['First home buyer duty exemption, concession or reduction', 'Pensioner duty exemption or concession'],
        onChange: (data) => submitHandler(data),
        conditional: ({ formData }) => ['VIC']?.includes(formData?.state) && formData.pensioner === 'Yes',
      },
      {
        type: 'select',
        name: 'paymentMethod',
        label: 'Payment method',
        options: ['Electronic transaction', 'Paper transaction'],
        onChange: (data) => submitHandler(data),
        conditional: ({ formData }) => ['VIC']?.includes(formData?.state),
      },
      {
        name: 'numDependents',
        label: 'Number of Dependents',
        type: 'select',
        options: ['0', '1', '2', '3', '4', '5', '6', '7', '8'],
        onChange: (data) => submitHandler(data),
        conditional: ({ formData }) => ['ACT']?.includes(formData?.state),
      },
      {
        name: 'propertyKind',
        label: 'Property kind',
        type: 'select',
        options: ['Established property', 'Newly built property', 'Vacant land or construction'],
        onChange: (data) => submitHandler(data),
        conditional: ({ formData }) => !['NT', 'SA']?.includes(formData?.state),
      },
      {
        name: 'propertyUse',
        label: 'Property use',
        type: 'select',
        options: ['Primary residence', "It's an investment"],
        onChange: (data) => submitHandler(data),
        conditional: ({ formData }) => !['NT', 'SA']?.includes(formData?.state),
      },
    ],
  },
];
