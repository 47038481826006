import React from 'react';
import { CalculatorTemplate } from 'components';
import { navigate } from 'gatsby';
import { fieldsDefault, controlsBlockFieldsDefault } from './fields';
import { SERVER } from '../../../utils/server';
import { isBrowser } from '../../../utils/isBrowser';

const HomeLoanRepaymentCalculator = (props) => {
  // CALCULATOR FORM DATA
  const calculatorEndpoint = `${SERVER}/calculators/home-loan-repayments`;

  const defaultValues = {
    loanAmount: 600000,
    interestRate: 3,
    termOfLoan: '30 years',
    repaymentFrequency: 'Fortnightly',
  };

  const defaultCalculationData = {
    repayment: 0,
    totalPayments: 0,
    totalInterest: 0,
  };

  // START APPLICATION
  const handleStartApplication = (data) => {
    if (isBrowser) {
      const storedLeadFlowData = JSON.parse(localStorage.getItem('yb-data'));

      const { repaymentFrequency, termOfLoan, ...restOfData } = data;

      const formattedTermOfLoan = Number(termOfLoan?.slice(0, -6));

      localStorage.setItem(
        'yb-data',
        JSON.stringify({
          ...storedLeadFlowData,
          ...restOfData,
          termOfLoan: formattedTermOfLoan,
        })
      );

      navigate('/get-started');
    }
  };

  // DISPLAY COUNT
  const controlsBlockConfigDefault = (calculationData, formData) => ({
    displayCountConfig: [
      {
        displayType: 'primary',
        title: `Your ${formData?.repaymentFrequency?.toLowerCase()} payment`,
        endCount: calculationData?.repayment,
      },
      [
        {
          displayType: 'secondary',
          title: 'Total repayments',
          endCount: calculationData?.totalPayments,
        },
        {
          displayType: 'secondary',
          title: 'Total interest',
          endCount: calculationData?.totalInterest,
        },
      ],
    ],
    controlsBlockFieldsDefault,
  });

  return (
    <CalculatorTemplate
      {...props}
      defaultValues={defaultValues}
      onHandleStartApplication={handleStartApplication}
      controlsBlockConfigDefault={controlsBlockConfigDefault}
      fieldsDefault={fieldsDefault}
      defaultCalculationData={defaultCalculationData}
      calculatorEndpoint={calculatorEndpoint}
    />
  );
};

export default HomeLoanRepaymentCalculator;
