export default [
  {
    type: 'group',
    name: 'details',
    namePrismic: 'About - Details',
    slug: 'about-details',
    subfields: [
      {
        type: 'select',
        name: 'title',
        label: 'Title',
        placeholder: 'Select',
        className: 'grid-col-1',
        options: ['Mr', 'Ms', 'Mrs', 'Miss', 'Dr', 'Other'],
        validationMessage: 'Please select title',
      },
      {
        type: 'text',
        name: 'firstName',
        placeholder: '',
        label: 'First name',
        className: 'grid-col-2',
        validationMessage: 'Please enter first name',
      },
      {
        type: 'text',
        name: 'middleName',
        placeholder: '',
        label: 'Middle name(s)',
        className: 'grid-col-3',
        validation: {
          pattern: {
            value: /^[A-Za-z\s\-]+$/,
            message: 'Please enter a valid middle name',
          },
        },
      },
      {
        type: 'text',
        name: 'surname',
        placeholder: '',
        label: 'Surname',
        className: 'grid-col-4',
        validationMessage: 'Please enter surname',
      },
      {
        type: 'date',
        name: 'birthDate',
        placeholder: 'DD/MM/YYYY',
        label: 'Date of Birth',
        validation: {
          required: {
            value: true,
            message: 'Please enter your birthdate',
          },
        },
      },
      {
        type: 'text',
        name: 'phoneNumber',
        placeholder: '',
        label: 'Phone number',
      },
      {
        type: 'select',
        name: 'maritalStatus',
        placeholder: 'Select one',
        label: 'Marital status',
        options: ['Single', 'Married', 'De Facto', 'Other'],
        validationMessage: 'Please select marital status',
      },
      {
        type: 'text',
        name: 'retirementAge',
        placeholder: 'e.g. 68',
        label: 'Planned retirement age',
        validation: {
          required: {
            value: true,
            message: 'Please enter an age between 55 and 72',
          },
          min: {
            value: 35,
            message: 'Please enter an age between 55 and 72',
          },
          max: {
            value: 80,
            message: 'Please enter an age between 55 and 72',
          },
          pattern: {
            value: /^[0-9]*$/i,
            message: 'Please enter an age between 55 and 72',
          },
        },
      },
    ],
  },
];
