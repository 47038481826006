export default [
  {
    type: 'currency',
    name: 'purchasePrice',
    namePrismic: 'Property - Purchase Price',
    slug: 'property-purchase-price',
    placeholder: 'Enter an amount',
    label: 'Enter estimate purchase price',
    validationMessage: 'Please enter purchase price',
    helper: (value) => `${value} purchase price`,
  },
];
