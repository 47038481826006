import { defaultOnSubmit } from '../../../utils/defaultOnSubmit';

export const onSubmit = async ({ formData, apolloClient, extraData }) => {
  const formatData = ({ data }) => {
    if (data?.length === 0) return [];

    return data?.map((dataObj) => {
      delete dataObj?.__typename;

      if (dataObj?.refinance) {
        dataObj.refinance = dataObj.refinance === 'Yes';
      }

      return dataObj;
    });
  };

  const variables = {
    ...formData,
    liabilitiesCreditCardLenderList: formatData({ data: formData?.liabilitiesCreditCardLenderList }),
    liabilitiesPersonalLenderList: formatData({ data: formData?.liabilitiesPersonalLenderList }),
    liabilitiesStudentDebtList: formatData({ data: formData?.liabilitiesStudentDebtList }),
    liabilitiesHomeLoanLenderList: formatData({ data: formData?.liabilitiesHomeLoanLenderList }),
    liabilitiesVehicleLoanLenderList: formatData({ data: formData?.liabilitiesVehicleLoanLenderList }),
    liabilitiesSmsfLoanLenderList: formatData({ data: formData?.liabilitiesSmsfLoanLenderList }),
    storeCards: formatData({ data: formData?.storeCards }),
  };

  return defaultOnSubmit({ formData: variables, apolloClient, extraData });
};
