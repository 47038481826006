// import { useEffect } from 'react';
// const useFormPersist = (stepId, { localStorageKey = 'stored-data', getValues, exclude = [] }) => {
//   const storage = typeof window !== 'undefined' ? localStorage : null;
//
//   // @TODO setup field names to exclude from storage sensitive infos if any
//
//   // Save data to local storage on step change
//   useEffect(() => {
//     const values = getValues();
//     storage.setItem(localStorageKey, JSON.stringify(values));
//   }, [stepId]);
//
//   return {
//     clear: () => storage.removeItem(localStorageKey),
//   };
// };
//
// export default useFormPersist;

const persistForm = ({ localStorageKey = 'stored-data', getValues, exclude = [] }) => {
  const storage = typeof window !== 'undefined' ? localStorage : null;
  const values = getValues();
  storage.setItem(localStorageKey, JSON.stringify(values));
};

export default persistForm;
