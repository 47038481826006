import React from 'react';
import * as styles from './styles.module.scss';

const FieldTextarea = (props) => {
  const { name, placeholder, defaultValue, autoFocus, register, validation, isSubmitting } = props;
  return (
    <textarea
      {...register(name, validation)}
      className={styles.textarea}
      name={name}
      id={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      readOnly={isSubmitting}
    />
  );
};

export default FieldTextarea;
