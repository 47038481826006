import { onSubmit } from './onSubmit';

export default [
  {
    type: 'draggableMortgageShortlist',
    name: 'favouriteLoan',
    namePrismic: 'Mortgage - Favourite Loan',
    slug: 'mortgage-favourite-loan',
    onSubmit,
  },
];
