import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './styles.scss';

const LoanCard = (props) => {
  const rows = [
    { property: 'Interest Rate', value: '2.7%' },
    { property: 'Comparison Rate', value: '2.98%' },
    { property: 'Annual Fee', value: '$0.00' },
    { property: 'Monthly Fee', value: '$0.00' },
    { property: 'Upfront Fee', value: '$395.00' },
  ];

  return (
    <div className="loan-card">
      {/* PROFILE */}
      <div className="loan-card-profile">
        <StaticImage
          className="loan-card-profile-img"
          src="../../../images/avatar.jpeg"
          placeholder="none"
          alt="Get Started"
        />
        <div className="loan-card-profile-text">
          <span className="loan-card-profile-name">Sophia Marketi</span>
          <span className="loan-card-profile-role">Nurse (28 yrs)</span>
        </div>
      </div>
      {/* MONEY */}
      <div className="loan-card-money-info">
        <span className="loan-card-month">$1,080</span>
        <span className="loan-card-month-smaller">/month</span>
        <span className="loan-card-amount">Loan $271,000</span>
      </div>
      {/* TABLE */}
      <div className="loan-card-table">
        {rows.map(row => {
          const { property, value } = row;
          return (
            <div className="loan-card-table-row" key={property}>
              <span className="loan-card-property">{property}</span>
              <span className="loan-card-value">{value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LoanCard;
