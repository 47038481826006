import axios from 'axios';
import { isDev } from './misc';
import { SERVER } from './server';

const SKIP_VALIDATE = process.env.GATSBY_YOUBROKER_SERVER !== 'production';

export const validateUser = async ({ formData, skipCreate = false, isLeadFlow = true }) => {
  const validationResponse = await axios.post(`${SERVER}/validate`, {
    authKey: formData?.authKey,
    number: formData?.phoneNumber,
    email: formData?.emailAddress,
    firstName: formData?.additionalApplicants?.[0]?.firstName,
    primaryApplicant: true,
    skipCreate,
    skip2fa: SKIP_VALIDATE,
    isLeadFlow,
  });

  return validationResponse;
};
