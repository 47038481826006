import { draggableOptions, handleDefaultValsKeyPriorities } from './utils';
import { onSubmit } from './onSubmit';

export default [
  {
    type: 'group',
    slug: 'mortgage-key-priorities',
    name: 'mortgageKeyPrioritiesGroup',
    namePrismic: 'Mortgage - Key Priorities',
    onSubmit,
    subfields: [
      {
        type: 'draggableField',
        iconOptions: ({ isRefinancing }) => {
          const defaultIcons = ['🔥', '🥇', '🥈', '🥉'];

          if (!isRefinancing) return defaultIcons;

          defaultIcons?.push('👎');

          return defaultIcons;
        },
        options: ({ defaultValues, isRefinancing }) =>
          handleDefaultValsKeyPriorities({
            defaultValues,
            options: draggableOptions({ isRefinancing }),
          }),
        name: 'keyPriorities',
      },
      {
        type: 'text',
        name: 'keyPrioritiesMissed',
        placeholder: "Let us know if there's anything you want us to add?",
        label: "Anything we've missed?",
      },
    ],
  },
];
