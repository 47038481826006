import React from 'react';
import { Link } from 'components';
import * as styles from './styles.module.scss';

const Breadcrumbs = (props) => {
  const { breadcrumbs, currentPage } = props;

  if (!currentPage) return null;

  return (
    breadcrumbs?.length > 0 && (
      <div className={styles.breadcrumbsContainer}>
        {breadcrumbs?.map((breadcrumb, index) => {
          const title = breadcrumb?.title;
          const link = breadcrumb?.link;

          return (
            link?.url && (
              <div>
                <Link key={link?.url} to={link?.url} className={styles.breadcrumb}>
                  {title?.text}
                </Link>
                <span className={styles.arrow}>/</span>
              </div>
            )
          );
        })}
        <div>
          <a className={styles.currentPage}>{currentPage}</a>
        </div>
      </div>
    )
  );
};

export default Breadcrumbs;
