import React from 'react';
import * as styles from './styles.module.scss';

const FieldTotal = (props) => {
  const { helper, extraData, watch } = props;

  const formData = watch();
  if (!helper) return null;
  const content = helper({ formData, extraData });
  if (!content) return null;
  return <div className={styles.container} dangerouslySetInnerHTML={{ __html: content }} />;
};

export default FieldTotal;
