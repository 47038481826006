import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, Section, BlogPostHeader, BlogPostSideBar, LatestPosts, FooterCta } from 'components';
import * as Slices from '../slices';
import './blog_post.scss';

const Slice = ({ data, location, isBlog }) => {
  const { slice_type: sliceType } = data;

  const allSlices = { ...Slices };
  // Convert slice type from snake_case to TitleCase
  if (!sliceType) return null;
  const sliceName = sliceType
    .split('_')
    .map((item) => item.charAt(0).toUpperCase() + item.substring(1))
    .join('');

  const CustomSlice = allSlices[sliceName];
  return <CustomSlice data={data} location={location} isBlog={isBlog} />;
};

const BlogPost = ({ data, location }) => {
  const { prismicBlogPost = {}, allPrismicBlogPost = {}, prismicBlog } = data;
  const globalData = prismicBlog?.data || {};
  const { nodes: newPosts } = allPrismicBlogPost;
  const {
    data: pageData,
    dataRaw,
    uid,
    first_publication_date: publishedDate,
    last_publication_date: updatedDate,
  } = prismicBlogPost;

  const { sidebar_title: sidebarTitle, sidebar_content: sidebarContent } = globalData;

  const dataString = JSON.stringify(dataRaw);

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    title,
    excerpt,
    hero_image: heroImage,
    category,
    timestamp,
    author,
  } = pageData;

  const seo = {
    title: metaTitle?.text || title?.text,
    desc: metaDesc?.text || excerpt?.text,
    banner: ogImage?.url,
    article: true,
    publishedDate,
    updatedDate,
  };

  return (
    <Layout seo={seo} location={location}>
      <div className="blog-post">
        <BlogPostHeader
          image={heroImage}
          title={title}
          category={category}
          author={author}
          timestamp={timestamp}
          dataString={dataString}
        />
        <section className="blog-post-body">
          <div className="wrapper">
            <BlogPostSideBar title={sidebarTitle?.html} content={sidebarContent?.html} />
            <div className="blog-post-slice-content">
              {sliceData.map((slice) => (
                <Slice key={slice.id} data={{ ...slice, uid }} location={location} isBlog />
              ))}
            </div>
          </div>
        </section>
        <LatestPosts posts={newPosts} title="Recent posts" />
        <FooterCta />
      </div>
    </Layout>
  );
};

export default withPrismicPreview(BlogPost);

export const blogPostQuery = graphql`
  query BlogPostBySlug($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      _previewable
      id
      uid
      prismicId
      first_publication_date
      last_publication_date
      dataRaw
      data {
        meta_description {
          text
          html
        }
        meta_title {
          text
          html
        }
        open_graph_image {
          url
        }
        author {
          html
          text
        }
        category {
          uid
          document {
            ... on PrismicCategory {
              id
              data {
                category_name {
                  html
                  text
                }
              }
            }
          }
        }
        excerpt {
          html
          text
        }
        hero_image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 2800, aspectRatio: 1.77)
            }
          }
        }
        is_featured
        staging_only
        timestamp(formatString: "D MMMM YYYY")
        title {
          html
          text
        }
        body {
          ...WysiwygBlogFragment
          ...BlogPostBreakoutBox
          ...BlogPostBlockQuote
          ...ImageBlogPostFragment
          ...CalculatorBlogPostFragment
        }
      }
    }
    allPrismicBlogPost(
      limit: 3
      sort: { order: ASC, fields: last_publication_date }
      filter: { data: { staging_only: { ne: true } } }
    ) {
      nodes {
        uid
        dataRaw
        data {
          author {
            text
          }
          category {
            uid
            document {
              ... on PrismicCategory {
                id
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
          excerpt {
            text
            html
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
          is_featured
          staging_only
          timestamp(formatString: "D MMMM, YYYY")
          title {
            html
            text
          }
        }
      }
    }
    prismicBlog {
      data {
        sidebar_title {
          html
          text
        }
        sidebar_content {
          html
          text
        }
      }
    }
  }
`;
