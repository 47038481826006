import axios from 'axios';
import { SERVER } from './server';
import { isDev } from './misc';

const SKIP_2FA = process.env.GATSBY_YOUBROKER_SERVER !== 'production';
const IS_STAGING = process.env.GATSBY_IS_STAGING;

export const sendTwoFactor = async ({ number, userName }) => {
  const url = `${SERVER}/sendsms`;
  await axios.post(url, {
    number: number.toString(),
    userName,
    skip2fa: SKIP_2FA,
  });
};

export const sendInvite = async ({ inviteDetails }) => {
  const url = `${SERVER}/sendinvite`;

  // REQUIRED
  const {
    primaryUserName,
    primaryApplicationId,
    additionalApplicantFirstName,
    additionalApplicantNumber,
    additionalApplicantEmail,
  } = inviteDetails;

  await axios.post(url, {
    primaryUserName,
    primaryApplicationId,
    additionalApplicantFirstName,
    additionalApplicantNumber,
    additionalApplicantEmail,
  });
};
