import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { UPDATE_USER } from '../apollo/mutations/mutations_misc';

export const useSetFlowStep = ({ newFlowStep, userId, condition, targetId = null }) => {
  const [updateCurrentFlowStep, { client }] = useMutation(UPDATE_USER, {
    variables: {
      id: userId,
    },
  });

  const cachedCurrentFlowStep = client.cache.readFragment({
    id: `User:${userId}`,
    fragment: gql`
      fragment CurrentFlowStep on User {
        currentFlowStep
      }
    `,
  });

  const currentFlowStep = cachedCurrentFlowStep?.currentFlowStep;

  const updateCurrentFlowStepHandler = useCallback(async () => {
    try {
      await updateCurrentFlowStep({
        variables: {
          id: userId,
          currentFlowStep: newFlowStep,
          targetId,
        },
        update: (cache, { data }) => {
          const cacheObjToModify = cache.identify({ id: userId, __typename: 'User' });

          cache.modify({
            broadcast: false,
            id: cacheObjToModify,
            fields: {
              currentFlowStep() {
                return newFlowStep;
              },
            },
          });
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [userId, currentFlowStep, newFlowStep, targetId, condition]);

  if (newFlowStep > currentFlowStep && condition) {
    updateCurrentFlowStepHandler();
  }
};
