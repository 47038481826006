import React from 'react';
import { ReactComponent as Star } from '../../../images/iconography/star.svg';

export const fieldsDefault = (submitHandler) => [
  {
    title: 'Your home loan details',
    titleIcon: <Star className="title-icon" />,
    fields: [
      {
        type: 'currency',
        name: 'purchaseAmount',
        label: 'Purchase amount',
        onBlur: (data) => submitHandler(data),
        validation: {
          required: true,
          pattern: /^([5-9]\d{3,}|\d{4,})$/i,
          max: {
            value: 999999999,
            message: 'Please enter an amount under $999,999,999',
          },
        },
        validationMessage: 'Please enter purchase amount',
      },
      {
        name: 'state',
        type: 'select',
        label: 'Select your state',
        options: ['NSW', 'VIC', 'QLD', 'WA', 'ACT', 'NT', 'SA', 'TAS'],
        onChange: (data) => submitHandler(data),
      },
      {
        type: 'currency',
        name: 'depositAmount',
        label: 'Deposit amount',
        onBlur: (data) => submitHandler(data),
        validation: {
          required: true,
          pattern: /^([5-9]\d{3,}|\d{4,})$/i,
          max: {
            value: 999999999,
            message: 'Please enter an amount under $999,999,999',
          },
        },
        validationMessage: 'Please enter deposit amount',
      },
      {
        type: 'currency',
        name: 'loanAmount',
        label: 'Loan amount',
        onBlur: (data) => submitHandler(data),
        validation: {
          required: true,
          pattern: /^([5-9]\d{3,}|\d{4,})$/i,
          max: {
            value: 999999999,
            message: 'Please enter an amount under $999,999,999',
          },
        },
        validationMessage: 'Please enter loan amount',
      },
      {
        type: 'currency',
        name: 'establishmentFee',
        label: 'Establishment fee',
        onBlur: (data) => submitHandler(data),
        validation: {
          required: true,
          pattern: /^([5-9]\d{3,}|\d{4,})$/i,
          max: {
            value: 999999999,
            message: 'Please enter an amount under $999,999,999',
          },
        },
        validationMessage: 'Please enter establishment fee',
      },
      {
        type: 'currency',
        name: 'legalCost',
        label: 'Legal cost',
        onBlur: (data) => submitHandler(data),
        validation: {
          required: true,
          pattern: /^([5-9]\d{3,}|\d{4,})$/i,
          max: {
            value: 999999999,
            message: 'Please enter an amount under $999,999,999',
          },
        },
        validationMessage: 'Please enter legal cost',
      },
      {
        type: 'currency',
        name: 'dischargeFee',
        label: 'Discharge fee',
        onBlur: (data) => submitHandler(data),
        validation: {
          required: true,
          pattern: /^([5-9]\d{3,}|\d{4,})$/i,
          max: {
            value: 999999999,
            message: 'Please enter an amount under $999,999,999',
          },
        },
        validationMessage: 'Please enter discharge fee',
      },
      {
        type: 'currency',
        name: 'otherFees',
        label: 'Other/Sundries',
        onBlur: (data) => submitHandler(data),
        validation: {
          pattern: /^([5-9]\d{3,}|\d{4,})$/i,
          max: {
            value: 999999999,
            message: 'Please enter an amount under $999,999,999',
          },
        },
      },
      {
        type: 'currency',
        name: 'firstHomeGrant',
        label: 'First home grant',
        onBlur: (data) => submitHandler(data),
        validation: {
          pattern: /^([5-9]\d{3,}|\d{4,})$/i,
          max: {
            value: 999999999,
            message: 'Please enter an amount under $999,999,999',
          },
        },
      },
    ],
  },
];
