import React from 'react';
import { Section, RichText } from 'components';

const Wysiwyg = (props) => {
  const { data, isBlog } = props;
  const { primary } = data;
  const { wysiwyg } = primary;

  // content checks
  const wysiwygHtml = wysiwyg?.html;

  return (
    <Section className="wysiwyg" noWrapper={isBlog}>
      <RichText html={wysiwygHtml} />
    </Section>
  );
};

export default Wysiwyg;
