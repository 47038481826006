import React from 'react';
import { currencyValidation, currencyValidationMessage } from '../_common/utils';
import { ReactComponent as Star } from '../../../images/iconography/star.svg';

export const controlsBlockFieldsDefault = (submitHandler, calculationData, setCalculationData) => [
  {
    name: 'repaymentFrequency',
    label: 'Repayment frequency',
    type: 'toggleButtons',
    className: 'hide-mobile repayment-frequency-desktop',
    options: [{ buttonText: 'Weekly' }, { buttonText: 'Fortnightly' }, { buttonText: 'Monthly' }],
    onChange: (data) => submitHandler(data),
  },
];

export const fieldsDefault = (submitHandler) => [
  {
    title: 'Your home loan details',
    titleIcon: <Star className="title-icon" />,
    fields: [
      {
        name: 'loanAmount',
        label: 'Loan amount',
        type: 'currency',
        placeholder: '$',
        onBlur: (data) => submitHandler(data),
        validation: currencyValidation(),
        validationMessage: currencyValidationMessage,
      },
      {
        name: 'interestRate',
        label: 'Interest rate',
        type: 'number',
        step: 0.01,
        placeholder: '%',
        onBlur: (data) => submitHandler(data),
        validation: {
          min: {
            value: 0.01,
          },
          max: {
            value: 9.99,
          },
          required: {
            value: true,
          },
          validate: (v) => String(v)?.length < 5 || 'Please enter valid rate',
        },
        validationMessage: 'Please enter valid rate',
      },
      {
        name: 'termOfLoan',
        label: 'Term of loan',
        type: 'select',
        options: [...Array(31).keys()]?.slice(10)?.map((number) => `${number} years`),
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'repaymentFrequency',
        label: 'Repayment frequency',
        type: 'select',
        className: 'hide-desktop',
        options: ['Weekly', 'Fortnightly', 'Monthly'],
        onChange: (data) => submitHandler(data),
      },
    ],
  },
];
