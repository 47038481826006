import React, { useState, useEffect } from 'react';
import { Image, Section, Link, OnVisible } from 'components';
import { ReactComponent as Chevron } from '../../images/chevron.svg';

import './styles.scss';

const TwoColsAccordion = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, image_left: imageLeft, image, cta_text: ctaText, cta_link: ctaLink, content } = primary;

  // primary content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasCta = ctaLink?.url && ctaText?.text;
  const hasPrimaryImage = image?.url;
  const imageAlign = imageLeft ? 'left-aligned' : '';

  // state
  const [activeIndex, setActiveIndex] = useState(0);
  // if primary image exists, use primary image, else use accordion images
  const mainImage = hasPrimaryImage ? image : items?.[activeIndex]?.image || items[0]?.image;

  const handleOnClick = (event, index) => {
    event.preventDefault();
    setActiveIndex(index);
  };

  return (
    <Section className={`two-cols-accordion ${imageAlign}`}>
      {/* TITLE/CONTENT */}
      <div className="two-cols-acc-header">
        <div className="two-cols-acc-header-content">
          {titleHtml && <div className="two-cols-acc-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          {contentHtml && <div className="two-cols-acc-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        </div>
        {hasCta && (
          <Link className="button two-cols-acc-cta" to={ctaLink.url}>
            {ctaText.text}
          </Link>
        )}
      </div>
      <div className="two-cols-acc-container">
        <div className="two-cols-acc-text">
          {/* ACCORDION */}
          <div className="two-cols-acc-features">
            {items.map((item, index) => {
              const { content: cardContent, icon, title: cardTitle } = item;
              // content checks
              const cardContentHtml = cardContent?.html;
              const cardTitleHtml = cardTitle?.html;
              const hasIcon = icon?.url;
              const isActive = index === activeIndex;

              return (
                // BUTTON
                <OnVisible
                  className={`two-cols-acc-feature ${isActive ? 'active' : 'inactive'}`}
                  onClick={(event) => handleOnClick(event, index)}
                  key={cardTitle.text}
                >
                  <div className="two-cols-acc-button">
                    <div className="two-cols-acc-button-left">
                      {/* ICON */}
                      {hasIcon && <Image className="two-cols-acc-button-icon" image={icon} />}
                      {/* TITLE AND CHEVRON */}
                      {cardTitleHtml && (
                        <div
                          className="two-cols-acc-button-title"
                          dangerouslySetInnerHTML={{ __html: cardTitleHtml }}
                        />
                      )}
                    </div>
                    <Chevron className="two-cols-acc-button-chevron" />
                  </div>
                  {/* TEXT CONTENT */}
                  {cardContentHtml && (
                    <div
                      className="two-cols-acc-feature-description"
                      dangerouslySetInnerHTML={{ __html: cardContentHtml }}
                    />
                  )}
                </OnVisible>
              );
            })}
          </div>
        </div>
        {/* MAIN IMAGE */}
        {mainImage && (
          <div className="two-cols-acc-image-container">
            <Image image={mainImage} objectFit="contain" className="two-cols-acc-image" />
          </div>
        )}
      </div>
    </Section>
  );
};

export default TwoColsAccordion;
