import React from 'react';
import { Image, Section, Link, OnVisible } from 'components';
import './styles.scss';

const TwoColsText = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title } = primary;

  // content checks
  const titleHtml = title?.html;

  const isOdd = (num) => num % 2;

  return (
    <Section className="two-cols-text">
      {titleHtml && <div className="two-cols-text-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
      <div className="two-cols-text-cards">
        {items.map((item, index) => {
          const { content, cta_link: ctaLink, cta_text: ctaText, icon, title: cardTitle } = item;
          const buttonClass = isOdd(index) ? '' : 'secondary';
          return (
            <OnVisible className="two-cols-text-card">
              {icon?.url && (
                <div className="two-cols-text-card-icon">
                  <Image image={icon} />
                </div>
              )}
              {cardTitle?.html && (
                <div className="two-cols-text-card-title" dangerouslySetInnerHTML={{ __html: cardTitle.html }} />
              )}
              {content?.html && (
                <div className="two-cols-text-content" dangerouslySetInnerHTML={{ __html: content.html }} />
              )}
              {ctaLink?.url && (
                <Link className={`button ${buttonClass} two-cols-text-cta`} to={ctaLink.url}>
                  {ctaText?.text}
                </Link>
              )}
            </OnVisible>
          );
        })}
      </div>
    </Section>
  );
};

export default TwoColsText;
