// extracted by mini-css-extract-plugin
export var section = "Xwc4KSmV";
export var lightBlueBg = "mysm3Ewc";
export var container = "oladzFte";
export var title = "_13nA7dki";
export var accordionContainer = "Xc5f6fg4";
export var accordionItemContainer = "SyYw5sEL";
export var active = "yvZdlcer";
export var chevron = "E-YVuaYy";
export var accordionContent = "zthVxox8";
export var accordionTitle = "VxrzjaEF";