export default [
  {
    type: 'group',
    name: 'usageDetails',
    namePrismic: 'Property - Usage Details',
    slug: 'property-usage-details',
    subfields: [
      {
        type: 'currency',
        name: 'propertyValue',
        label: 'Current property value',
        validationMessage: 'Please enter property value',
      },
      {
        type: 'select',
        name: 'currentUse',
        placeholder: 'Select',
        label: 'Current use',
        options: ['Main Residence', 'Second Home', 'Renting Out', 'Holiday Let', 'Other'],
        validationMessage: 'Please select current use',
      },
      {
        type: 'radio',
        name: 'usageChange',
        placeholder: '',
        label: 'Are you changing how you use this property as part of this remortgage?',
        options: [{ text: 'Yes' }, { text: 'No' }],
      },
      {
        type: 'select',
        name: 'intendedUse',
        placeholder: 'Select',
        label: 'Intended use',
        options: ['Main Residence', 'Second Home', 'Renting Out', 'Holiday Let', 'Other'],
        conditional: ({ formData }) => formData?.usageChange === 'Yes',
        validationMessage: 'Please select intended use',
      },
    ],
  },
];
