import React from 'react';
import { STAGE_LOOKUP } from './stageLookup';

const FlowStage = (props) => {
  const { data } = props;
  const { primary } = data;
  const { flow_stage_component: flowStageComponent } = primary;

  const FlowStageComponent = STAGE_LOOKUP?.[flowStageComponent];

  return <FlowStageComponent {...props} />;
};

export default FlowStage;
