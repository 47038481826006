import QuestionsLibrary from '../QuestionsCatalogue';
import { defaultOnSubmit } from './defaultOnSubmit';

const populateFields = ({ questions, defaultOnRemove, isRefinancing, uid, isFactFind }) =>
  questions.reduce((accumulator, field) => {
    const { question: questionPrismicName, description, title, refinance_flow: refinanceFlow } = field;

    if (!questionPrismicName) return accumulator;
    const question = QuestionsLibrary.find((el) => el.namePrismic === questionPrismicName);

    if (!question) {
      console.error(`@populateFields - Question with Prismic name "${questionPrismicName}" could not be found.`, {
        field,
      });
      return accumulator;
    }

    const {
      // String
      type,
      name,
      placeholder,
      options,
      label,
      altLabel,
      defaultValue,
      validationMessage,
      buttonText,
      slug,

      // String or Function
      className,
      validation,
      helper,

      // Boolean
      goNextOnClick,
      autoFocus,
      preventDefaultNavigation,

      // Functions
      conditional,
      goPrev,
      goNext,
      onSubmit,
      onRemove,
      onMount,
      toggleLabel,
      skipSubmit,
      showGroupValidations,

      // Array fields
      subfieldLabel,
      subfields,
      addButtonLabel,
      removeButtonLabel,
    } = question;

    if (!type || !name) {
      console.error(`@populateFields - No type or name specificied for question "${questionPrismicName}"`);
    }

    const onSubmitSelector = () => {
      if (!isFactFind) return onSubmit;

      if (isFactFind && onSubmit) return onSubmit;

      return defaultOnSubmit;
    };

    accumulator.push({
      // String
      type,
      name, // camalise(question);
      placeholder: placeholder || '',
      label,
      altLabel,
      title,
      description: description?.text,
      defaultValue: defaultValue || '',
      validationMessage,
      buttonText,
      slug,

      // String or Function
      validation,
      className,

      // Array
      question,
      options,

      // Boolean
      autoFocus,
      goNextOnClick,
      refinanceFlow,
      preventDefaultNavigation,

      // Functions
      showGroupValidations,
      conditional: ({ extraData, formData, index }) => {
        if (conditional) {
          return conditional({ extraData, formData, index });
        }

        if (uid === 'property' && isRefinancing && !refinanceFlow) {
          return false;
        }

        if (isFactFind && refinanceFlow) {
          return isRefinancing;
        }

        return true;
      },
      helper,
      goPrev,
      goNext,
      onSubmit: onSubmitSelector(),
      onRemove: onRemove || defaultOnRemove,
      skipSubmit,
      onMount,
      toggleLabel,

      // Array fields
      subfieldLabel,
      subfields,
      addButtonLabel,
      removeButtonLabel,
    });
    return accumulator;
  }, []);
export default populateFields;
