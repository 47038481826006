import React from 'react';
import { Image, Section, Link, OnVisible } from 'components';
import './styles.scss';

const TwoColsFeatureList = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, image_left: imageLeft, image, content } = primary;

  // primary content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasImage = image?.url;
  const imageAlign = imageLeft ? 'left-aligned' : '';
  const noImageVariant = !hasImage ? 'no-image' : '';

  return (
    <Section className={`two-cols-feature-list ${imageAlign} ${noImageVariant}`}>
      {titleHtml && <div className="feature-list-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
      {contentHtml && <div className="feature-list-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
      <div className="feature-list-container">
        {/* TITLE/CONTENT */}
        <div className="feature-list-text">
          {/* LIST */}
          <div className="feature-list-features">
            {items.map((item) => {
              const { content: cardContent, icon, title: cardTitle } = item;
              // content checks
              const cardContentHtml = cardContent?.html;
              const cardTitleHtml = cardTitle?.html;
              const hasIcon = icon?.url;
              const bulletVariant = !cardContent?.text ? 'bullet-variant' : '';

              return (
                // LIST ITEM
                <OnVisible className={`feature-list-feature ${bulletVariant}`} key={cardTitle.text}>
                  {hasIcon && <Image className="feature-list-item-icon" image={icon} />}
                  <div className="feature-list-item-text">
                    {cardTitleHtml && (
                      <div className="feature-list-item-title" dangerouslySetInnerHTML={{ __html: cardTitleHtml }} />
                    )}
                    {cardContentHtml && (
                      <div
                        className="feature-list-item-description"
                        dangerouslySetInnerHTML={{ __html: cardContentHtml }}
                      />
                    )}
                  </div>
                </OnVisible>
              );
            })}
          </div>
        </div>
        {/* // FEATURE IMAGE */}
        <div className="feature-list-image">
          <Image image={image} />
        </div>
      </div>
    </Section>
  );
};

export default TwoColsFeatureList;
