const context = require.context('./', true, /\.\/[^]+index\.js$/);

// Import all questions
const fields = context.keys().reduce((accumulator, filePath) => {
  const questionData = context(filePath).default;
  if (!questionData) return accumulator;
  return [...accumulator, ...questionData];
}, []);

if (process.env.NODE_ENV === 'development') console.log(`%cExporting ${fields.length} Questions`, 'color:purple');

export default fields;
