import { gql } from '@apollo/client';
import { ABOUT_USER_FRAGMENTS } from './fragments_about_ff';
import { EXPENSES_FRAGMENTS } from './fragments_expenses_ff';
import { PROPERTY_FRAGMENTS } from './fragments_property_ff';
import { MORTGAGE_PREFERENCE_FRAGMENTS } from './fragments_mortgage_ff';
import { LEAD_APPLICATION_FRAGMENTS } from './fragments_lead_application';
import { MISC_FRAGMENTS } from './fragments_misc';

export const FACT_FIND_FRAGMENTS = {
  ...LEAD_APPLICATION_FRAGMENTS,
  ...ABOUT_USER_FRAGMENTS,
  ...EXPENSES_FRAGMENTS,
  ...PROPERTY_FRAGMENTS,
  ...MORTGAGE_PREFERENCE_FRAGMENTS,
  MortgageShortlist: gql`
    fragment ProductData on MortgageShortlist {
      id
      productId
      bank
      lender
      lenderLogoUrl
      displayName
      favourite
      featureSummary
      feeSummary
      feeSummaryComponents
      interestRate
      rateType
      comparisonRate
      additionalInfo
      purpose
      promotionSummary
      repaymentType
      repaymentRate
      lvrMin
      lvrMax
      description
      __typename
    }
  `,
  ...MISC_FRAGMENTS,
};
