import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Link, OnVisible, CtaArrow } from 'components';
import './styles.scss';

const CategoryGrid = ({ isResource, className = '' }) => {
  const data = useStaticQuery(graphql`
    query CategoryList {
      allPrismicCategory(sort: { order: ASC, fields: data___category_name___text }) {
        nodes {
          uid
          data {
            category_name {
              text
            }
          }
        }
      }
    }
  `);

  const [categoriesOpen, setCategoriesOpen] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setCategoriesOpen(!categoriesOpen);
  };

  const openCategories = categoriesOpen ? 'reveal-categories' : '';

  const { allPrismicCategory } = data;
  const { nodes: categories } = allPrismicCategory;

  return (
    <Section className={`category-grid ${className}`}>
      <OnVisible as="h4" className="category-grid-title">
        Categories
      </OnVisible>
      <div className={`category-grid-container ${openCategories}`}>
        {categories.map((category) => {
          const { data: categoryData, uid } = category;
          const { category_name: categoryName } = categoryData;

          const cardLink = isResource ? `/resources/${uid}` : `/articles/${uid}`;

          return (
            <OnVisible className="category-grid-link-container">
              <Link key={uid} to={cardLink} className="category-grid-link">
                <span>{categoryName.text}</span>
                <CtaArrow className="category-grid-link-arrow" />
              </Link>
            </OnVisible>
          );
        })}
      </div>
      {categories.length > 4 && (
        <a className="category-grid-view-all" onClick={handleClick} href="#view-all">
          {categoriesOpen ? 'View less' : `View all (+${categories.length - 4})`}
        </a>
      )}
    </Section>
  );
};

export default CategoryGrid;
