import { formatToggleLabelWithAdditionalFields } from '../../../../../utils/misc';
import { formatCurrency } from '../../../../../utils/formatCurrency';
import { onSubmit } from './onSubmit';

const ownership = {
  type: 'select',
  name: 'ownership',
  label: 'Ownership',
  className: 'grid-col-4 grid-span-2 ownership',
  placeholder: 'Please Select',
  validationMessage: 'Please select owner',
  options: ({ extraData }) => {
    const { Users } = extraData;
    return Users?.map((user) => user.name);
  },
};

const lender = {
  type: 'text',
  name: 'lender',
  label: 'Lender',
  placeholder: 'Lender name',
  defaultValue: '',
  validationMessage: 'Please enter lender',
  className: 'split-left lender',
};

const rate = {
  type: 'number',
  placeholder: '%',
  defaultValue: '',
  validation: {
    min: {
      value: 0,
      message: 'Please enter valid rate',
    },
    max: {
      value: 100,
      message: 'Please enter valid rate',
    },
  },
  name: 'rate',
  label: 'Rate',
  // step: 0.1,
  className: 'grid-col-6 rate',
};

const refinance = {
  type: 'select',
  placeholder: 'Yes',
  options: ['Yes', 'No'],
  name: 'refinance',
  label: 'Refinance',
  className: 'grid-col-6 refinance',
};

const defaultCurrency = {
  type: 'currency',
  placeholder: 'Enter an amount',
};

const balance = {
  ...defaultCurrency,
  name: 'balance',
  validationMessage: 'Please enter valid balance',
  label: 'Balance',
  placeholder: 'Enter an amount',
  className: 'balance',
};

const repayment = {
  ...defaultCurrency,
  validationMessage: 'Please enter valid repayment',
  name: 'repayment',
  placeholder: 'Enter an amount',
  label: 'Repayment (monthly)',
  className: 'split-left repayment',
};

const limit = {
  ...defaultCurrency,
  name: 'limit',
  label: 'Limit',
  placeholder: 'Enter an amount',
  className: 'limit',
  validationMessage: 'Please enter valid limit',
};

export default [
  {
    type: 'arrayMulti',
    name: 'liabilities',
    namePrismic: 'About - Liabilities',
    slug: 'about-liabilities',
    onSubmit,
    addButtonLabel: 'Add Liabilities',
    onMount: ({ extraData }) => {
      const primaryApplicantId = extraData?.Users?.find((user) => user?.primaryApplicant)?.id;
      localStorage.setItem('yb-current-user-id', primaryApplicantId);
    },
    subfields: [
      {
        type: 'arrayAccordion',
        name: 'liabilitiesCreditCardLenderList',
        hideAddButton: true,
        className: 'grid-cols-6 credit-card',
        addButtonLabel: 'Credit Card',
        removeButtonLabel: 'Delete liability',
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'Credit Card' }),
        subfields: [
          lender,
          ownership,
          refinance,
          {
            ...limit,
            className: `${limit.className} split-left`,
          },
          {
            ...balance,
            className: `${balance.className} split-right`,
          },
        ],
      },
      {
        type: 'arrayAccordion',
        name: 'liabilitiesHomeLoanLenderList',
        addButtonLabel: 'Home Loan',
        removeButtonLabel: 'Delete liability',
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'Home Loan' }),
        className: 'grid-cols-6 home-loan',
        hideAddButton: true,
        requiredFieldCheck: () => false,
        appendedFields: [
          {
            lender: null,
            ownership: 'Select One',
            refinance: null,
            repayment: null,
            balance: null,
            rate: null,
          },
        ],
        subfields: [
          lender,
          ownership,
          refinance,
          repayment,
          {
            ...balance,
            className: `${balance.className} grid-col-4 grid-span-2`,
          },
          rate,
        ],
      },
      {
        type: 'arrayAccordion',
        name: 'liabilitiesVehicleLoanLenderList',
        addButtonLabel: 'Vehicle Loan',
        removeButtonLabel: 'Delete liability',
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'Vehicle Loan' }),
        className: 'grid-cols-6 vehicle-loan',
        hideAddButton: true,
        subfields: [
          lender,
          ownership,
          refinance,
          repayment,
          {
            ...balance,
            className: `${balance.className} grid-col-4 grid-span-2`,
          },
          rate,
        ],
      },
      {
        type: 'arrayAccordion',
        name: 'storeCards',
        addButtonLabel: 'Buy Now Pay Later / Store Cards',
        removeButtonLabel: 'Delete liability',
        toggleLabel: ({ index }) =>
          formatToggleLabelWithAdditionalFields({ index, label: 'Buy Now Pay Later / Store Cards' }),
        hideAddButton: true,
        subfields: [
          {
            ...lender,
            className: 'split-left',
          },
          {
            ...ownership,
            className: 'split-right',
          },
          {
            ...limit,
            className: 'split-left',
          },
          {
            ...balance,
            className: 'split-right',
          },
        ],
      },
      {
        type: 'arrayAccordion',
        name: 'liabilitiesPersonalLenderList',
        addButtonLabel: 'Personal Lending',
        removeButtonLabel: 'Delete liability',
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'Personal Lending' }),
        className: 'grid-cols-6 personal-lending',
        hideAddButton: true,
        subfields: [
          {
            ...ownership,
            className: `ownership grid-col-1 grid-span-5`,
          },
          refinance,
          repayment,
          {
            ...balance,
            className: `${balance.className} split-right`,
          },
        ],
      },
      {
        type: 'arrayAccordion',
        name: 'liabilitiesSmsfLoanLenderList',
        addButtonLabel: 'SMSF Loan Lender',
        removeButtonLabel: 'Delete liability',
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'SMSF Loan Lender' }),
        className: 'grid-cols-6 smsf-loan-lender',
        hideAddButton: true,
        subfields: [
          lender,
          ownership,
          refinance,
          {
            ...repayment,
            className: `${repayment.className} split-left`,
          },
          {
            ...balance,
            className: `${balance.className} grid-col-4 grid-span-2`,
          },
          rate,
        ],
      },
      {
        type: 'arrayAccordion',
        name: 'liabilitiesStudentDebtList',
        addButtonLabel: 'HECS/HELP Debt',
        removeButtonLabel: 'Delete liability',
        toggleLabel: ({ index }) => formatToggleLabelWithAdditionalFields({ index, label: 'HECS/HELP Debt' }),
        className: 'grid-cols-3 hecs-help-debt',
        hideAddButton: true,
        subfields: [
          {
            ...lender,
            className: `grid-col-1`,
            defaultValue: 'ATO',
            readOnly: true,
          },
          {
            ...repayment,
            className: `grid-col-2`,
          },
          {
            ...balance,
            className: `grid-col-3`,
          },
        ],
      },
      {
        type: 'total',
        name: 'about-liabilities-total',
        removeFromDropdown: true,
        helper: ({ formData }) => {
          const {
            liabilitiesCreditCardLenderList,
            liabilitiesPersonalLenderList,
            liabilitiesStudentDebtList,
            liabilitiesHomeLoanLenderList,
            liabilitiesVehicleLoanLenderList,
            liabilitiesSmsfLoanLenderList,
            storeCards,
          } = formData || {};
          const liabilities = [
            liabilitiesCreditCardLenderList,
            liabilitiesPersonalLenderList,
            liabilitiesStudentDebtList,
            liabilitiesHomeLoanLenderList,
            liabilitiesVehicleLoanLenderList,
            liabilitiesSmsfLoanLenderList,
            storeCards,
          ];
          const sum = liabilities.reduce((acc, val) => {
            if (!val) return acc;
            const subtotal = val.reduce((a, b) => a + (parseFloat(b.balance) || 0), 0);
            return acc + subtotal;
          }, 0);
          const sumCurrency = formatCurrency(sum);
          return `
        <div class="inner-container flex-divider">
          💰 Total liabilities: <span>${sumCurrency}</span>
        </div>
      `;
        },
      },
    ],
  },
];
