import Cookies from 'js-cookie';
import { isBrowser } from '../utils/isBrowser';
import { useDataLayer } from './useDataLayer';

export const useLogout = () => {
  const ybStorage = {
    localStorage: [
      'yb-data-apollo-cache-persist',
      'yb-current-property-id',
      'yb-current-user-id',
      'yb-invited-applicant-id',
      'yb-data',
      'yb-current-flow-step',
      'yb-gcs-data',
    ],
    cookies: ['yb-token', 'yb-email', 'yb-app'],
  };

  const dataLayer = useDataLayer();

  const logout = () => {
    if (isBrowser) {
      dataLayer?.track('Logged Out');

      ybStorage.localStorage?.forEach((storageKey) => localStorage.removeItem(storageKey));

      ybStorage.cookies?.forEach((storageKey) => Cookies.remove(storageKey));
    }
  };

  return logout;
};
