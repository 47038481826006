import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './styles.module.scss';

const FieldContainer = (props) => {
  const { className, children } = props;
  return <div className={`${styles.container} ${className || ''}`}>{children}</div>;
};

export default FieldContainer;

FieldContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FieldContainer.defaultProps = {
  className: 'grid-col-full',
};
