import axios from 'axios';

const formatSubmissionData = (data) =>
  Object?.entries(data)?.reduce((prev, current) => {
    const [key, value] = current;

    if (key === 'termOfLoan' || key === 'remainingYears') return { ...prev, [key]: Number(data?.[key]?.slice(0, -6)) };

    if (value === 'Yes' || value === 'No') return { ...prev, [key]: value === 'Yes' };

    return {
      ...prev,
      [key]: value,
    };
  }, {});

export const submitHandlerDefault = async (
  data,
  errors,
  setCalculationData,
  url,
  defaultCalculationData,
  setError,
  clearErrors,
  allowNegativeResults = false,
  showCalculationError
) => {
  clearErrors('controlsBlock');

  const hasErrors = Object?.keys(errors)?.length > 0;

  if (hasErrors) return null;

  const formattedData = formatSubmissionData(data);

  const calculationDataFailFallback =
    typeof defaultCalculationData === 'number'
      ? 'N/A'
      : Object.keys(defaultCalculationData)?.reduce((acc, key) => {
        acc[key] = 'N/A';
        return acc;
      }, {});

  const isNegativeResult = (responseData) => {
    if (typeof responseData === 'string') {
      const dataAsNumber = Number(responseData);
      return dataAsNumber < 0;
    }

    if (typeof responseData === 'object') {
      const check = !!Object?.keys(responseData)?.find((dataKey) => responseData?.[dataKey] < 0);

      return check;
    }
  };

  const handleCalculationFail = () => {
    setCalculationData(calculationDataFailFallback);
    setError('controlsBlock', {
      type: 'custom',
      message: 'Calculation unsuccessful. Please try again with different values.',
    });
  };

  try {
    const response = await axios.post(url, formattedData);
    const responseCalculationData = response?.data?.calculation;

    const hasNegativeResult = isNegativeResult(responseCalculationData);

    if (hasNegativeResult && !allowNegativeResults) return handleCalculationFail();

    if (showCalculationError && showCalculationError(responseCalculationData)) {
      setError('controlsBlock', {
        type: 'custom',
        message:
          showCalculationError(responseCalculationData) ||
          'Calculation unsuccessful. Please try again with different values.',
      });
    }

    return setCalculationData(responseCalculationData);
  } catch (error) {
    console.error(error);
    handleCalculationFail();
  }
};

export const multiplierLookupDefault = (defaultValue = 1) => ({
  '': defaultValue,
  '/year': 1,
  '/month': 12,
  '/fortnight': 26,
  '/week': 52,
});

export const currencyValidation = (validationMessage) => ({
  min: {
    value: 0,
    message: validationMessage || 'Please enter valid amount',
  },
  max: {
    value: 99999999,
    message: validationMessage || 'Please enter valid amount',
  },
  required: {
    value: true,
    message: validationMessage || 'Please enter valid amount',
  },
});

export const currencyValidationMessage = 'Please enter valid amount';
