import { gql } from '@apollo/client';

export const MORTGAGE_PREFERENCE_FRAGMENTS = {
  mortgage: {
    'mortgage-whats-important-loan': gql`
      fragment WhatsImportantLoan on MortgagePreference {
        whatsImportantLoan
        whatsImportantLoanOther
      }
    `,
    'mortgage-whats-important-to-you': gql`
      fragment WhatsImportantToYou on MortgagePreference {
        whatsImportantToYou
        whatsImportantToYouOther
      }
    `,
    'mortgage-key-priorities': gql`
      fragment KeyPriorities on MortgagePreference {
        keyPrioritiesMissed
        keyPriorities {
          id
          order
          value
        }
      }
    `,
    'mortgage-approval-speed': gql`
      fragment ApprovalSpeed on MortgagePreference {
        mortgageQuickOffer
        settlementDate
      }
    `,
    flowProgress: gql`
      fragment FlowProgressMortgagePreference on MortgagePreference {
        complete
        totalFields
        completedFields
      }
    `,
  },
};
