import React, { Fragment, useRef, useEffect } from 'react';
import { TwoFactorField, Modal, CtaArrow } from 'components';
import slugify from 'slugify';
import closeMenu from '../../../images/close-menu.svg';
import './styles.scss';

const FormField = ({
  name,
  type,
  options,
  placeholder,
  defaultValue,
  helper,
  autoFocus,
  register,
  getValues,
  setValue,
  validation,
  phoneNumber,
  setStepIndex,
  goBack,
  reveal,
  handleSubmit,
  submitButtonRef,
  submitOnClick,
  submitForm,
  control,
  errors,
}) => {
  const inputRef = useRef();
  const fieldRef = register(name, validation);

  useEffect(() => {
    if (autoFocus && inputRef?.current && !defaultValue) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 300);
    }
  }, [autoFocus, inputRef, defaultValue]);

  // ////////// TEXT /////////////
  if (type === 'textarea') {
    return (
      <textarea
        {...fieldRef}
        ref={(ref) => {
          fieldRef.ref(ref);
          inputRef.current = ref;
        }}
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
      />
    );
  }

  // ////////// MODAL /////////////
  if (type === 'modal') {
    const title = {
      firstName: 'What is your name',
      phoneNumber: 'What is your phone number?',
    };

    return (
      <Modal active>
        <>
          <div className="modal-contents">
            <img src={closeMenu} className="modal-close-button" alt="close modal" onClick={() => setStepIndex(9)} />
            <p className="modal-title">{title[name]}</p>
            {name !== 'firstName' && (
              <div className="modal-foot">
                <p className="modal-more-info">We need a little more information</p>
              </div>
            )}
            <input
              className="modal-input"
              {...register(name, validation)}
              name={name}
              id={name}
              placeholder={placeholder || 'type your answer here'}
              defaultValue={defaultValue}
              autoFocus={autoFocus}
            />

            <div className="modal-button-container">
              <button type="submit" className="form-back-button" onClick={goBack}>
                back
              </button>
              <button type="submit" className="form-next button" onClick={handleSubmit}>
                next
                <CtaArrow />
              </button>
            </div>
          </div>
        </>
      </Modal>
    );
  }

  // ////////// Two Factor Modal /////////////

  if (type === 'twoFactorModal') {
    return (
      <TwoFactorField
        defaultValue={defaultValue}
        name={name}
        getValues={getValues}
        setValue={setValue}
        submitForm={submitForm}
      />
    );
  }

  // ////////// Number /////////////
  if (type === 'number') {
    return (
      <input
        {...fieldRef}
        ref={(ref) => {
          fieldRef.ref(ref);
          inputRef.current = ref;
        }}
        className="number-input"
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
      />
    );
  }

  // ////////// SELECT /////////////
  if (type === 'select') {
    return (
      <select name={name} {...fieldRef}>
        {options.map((option) => (
          <option value={option} key={option} defaultValue={defaultValue}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // ////////// RADIOS TEST /////////////
  if (type === 'radios') {
    return (
      <div>
        {options.map((option) => {
          const { text } = option;
          return (
            <div key={text} className="radio-checkbox">
              <input
                type="radio"
                id={text}
                name={name}
                value={text}
                defaultChecked={defaultValue === text}
                ref={fieldRef.ref}
                onChange={(e) => {
                  fieldRef.onChange(e); // method from hook form register
                  if (submitOnClick) {
                    setTimeout(() => {
                      submitButtonRef?.current?.click(); // onSubmit on radio selection
                    }, 300);
                  }
                }}
              />
              <label htmlFor={text}>{text}</label>
            </div>
          );
        })}
      </div>
    );
  }

  // ////////// RADIO AND CHECKBOX /////////////
  if (type === 'radio' || type === 'checkbox') {
    return (
      <div className={`radio-buttons ${slugify(name, { lower: true })}-screen`}>
        {options.map((option) => {
          const { text, IconComponent } = option;
          const hasImage = IconComponent;
          const id = slugify(text, { lower: true });
          return (
            <div role="img" key={text} className={`radio-checkbox ${hasImage ? 'has-image' : ''}`}>
              <input
                type={type}
                id={id}
                name={name}
                value={text}
                defaultChecked={defaultValue === text}
                ref={fieldRef.ref}
                onChange={(e) => {
                  fieldRef.onChange(e); // method from hook form register
                  if (submitOnClick) {
                    setTimeout(() => {
                      submitButtonRef?.current?.click(); // onSubmit on radio selection
                    }, 300);
                  }
                }}
              />
              <label htmlFor={id}>
                {hasImage && <IconComponent className="option-image" />}
                <span className="radio-buttons-checkbox" />
                <span className="button-text">{text}</span>
              </label>
            </div>
          );
        })}
      </div>
    );
  }

  // ////////// DEFAULT INPUT /////////////
  return (
    <input
      {...fieldRef}
      ref={(ref) => {
        fieldRef.ref(ref);
        inputRef.current = ref;
      }}
      type={type}
      name={name}
      id={name}
      placeholder={placeholder || ''}
      defaultValue={defaultValue || ''}
      autoFocus={autoFocus}
    />
  );
};

export default FormField;
