import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import * as styles from './styles.module.scss';

const FieldToggleButtons = (props) => {
  const { name, className, validation, options, watch, onChange } = props;

  const { methods } = useFormContext();

  const { setValue } = methods;

  const { field } = useController({
    name,
    rules: validation,
    control: methods?.control,
  });

  const onClickHandler = (value, optionClick) => {
    if (optionClick) optionClick(watch());
    return setValue(name, value);
  };

  const activeOption = watch(name);

  useEffect(() => {
    if (onChange) onChange(watch());
  }, [activeOption]);

  return (
    <div {...field} className={`${styles.container} ${className}`}>
      {options.map((option, index) => {
        const { buttonText = 'Click', optionClick } = option;

        return (
          <button
            type="button"
            className={`button white ${styles.toggleButton} ${activeOption === buttonText ? styles.activeButton : ''}`}
            onClick={() => onClickHandler(buttonText, optionClick)}
            key={buttonText}
          >
            {buttonText}
          </button>
        );
      })}
    </div>
  );
};

export default FieldToggleButtons;
