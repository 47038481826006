import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import { validateUser } from '../../../../utils/validateUser';
import { inThirtyMinutes, isDev } from '../../../../utils/misc';
import { CREATE_APPLICATION, UPDATE_APPLICATION } from '../../../../apollo/mutations/mutations_misc';
import { CREATE_PROPERTY_REFINANCE } from '../../../../apollo/mutations/mutations_property_refinance_ff';
import { sendEmail } from '../../../../utils/sendEmail';
import { isBrowser } from '../../../../utils/isBrowser';

const IS_STAGING = process.env.GATSBY_IS_STAGING;
const FORMSPARK_ID = IS_STAGING ? 'FOA5EalV' : 'HaSYyldB';
const SUBMIT_URL = `https://submit-form.com/${FORMSPARK_ID}`;

export const onSubmitNewApplicant = async ({ formData: { formData }, apolloClient, setError, dataLayer }) => {
  try {
    if (isDev) console.log('@onSubmit - LeadFlowForm Initiate', { formData });

    const validationResponse = await validateUser({ formData });

    // ------------------------
    // Get additional applicants data
    const applicants = formData?.additionalApplicants;
    const additionalApplicants = applicants?.slice(1);
    const additionalApplications = additionalApplicants?.map((applicantData) => ({
      name: applicantData?.firstName,
    }));
    if (isDev) console.log('@onSubmit - LeadFlowForm - Get applicants', { applicants });

    // ------------------------
    // Extract data from form
    const newApplicant = validationResponse?.data;
    const newApplicationToken = newApplicant?.token;
    const userId = newApplicant?.id;
    const firstName = newApplicant?.name;
    const emailAddress = newApplicant?.email;
    const phoneNumber = newApplicant?.phoneNumber;
    Cookies.set('yb-token', newApplicationToken, { expires: isDev ? 365 : inThirtyMinutes });
    Cookies.set('yb-email', newApplicant?.email, { expires: isDev ? 365 : inThirtyMinutes });

    // ------------------------
    // Create a new application
    const createApplicationVariables = () => {
      const clone = { ...formData };
      delete clone?.additionalApplicants;
      return clone;
    };

    const gcsData = isBrowser && JSON.parse(localStorage.getItem('yb-gcs-data'))?.gcsData;

    const newApplication = await apolloClient.mutate({
      mutation: CREATE_APPLICATION,
      variables: {
        ...createApplicationVariables(),
        userId,
        emailAddress,
        firstName,
        interestRate: parseFloat(createApplicationVariables()?.interestRate),
        primaryApplicant: true,
        additionalUsers: additionalApplications,
        isRefinance: createApplicationVariables()?.purchaseOrRefinance === 'Refinance',
        isGcs: !!gcsData?.id,
      },
    });

    const applicationId = newApplication?.data?.createApplication?.application?.id;

    Cookies.set('yb-app', applicationId, { expires: isDev ? 365 : inThirtyMinutes });

    if (createApplicationVariables()?.purchaseOrRefinance === 'Refinance') {
      await apolloClient.mutate({
        mutation: CREATE_PROPERTY_REFINANCE,
        variables: {
          propertyRefinance: [{ applicationId, propertyValue: createApplicationVariables()?.propertyValue }],
        },
      });
    }

    if (phoneNumber) {
      dataLayer?.identify(phoneNumber, {
        LogRocket: {
          name: firstName,
          email: emailAddress,
          phone: phoneNumber,
        },
        mixpanel: {
          $first_name: firstName,
          $email: emailAddress,
          $phone: phoneNumber,
        },
      });
    }

    if (gcsData?.id) {
      formData.gcsData = gcsData;
      await sendEmail(SUBMIT_URL, formData);
    }

    dataLayer?.track('Signed Up');

    // ------------------------
    // On success redirect user
    console.log('@onSubmitLeadFlow - GREAT SUCCESS!');
    navigate('/create-your-shortlist/');
  } catch (error) {
    const failureMessage = error?.response?.data?.fail;

    const userAlreadyExistsMsgs = [
      'This user exists, please login',
      'A user with that email address already exists',
      'A user with that phone number already exists',
    ];

    if (userAlreadyExistsMsgs?.includes(failureMessage)) {
      const loginUrl = `${process.env.GATSBY_SITE_URL}/login`;

      return setError('twoFactor', {
        type: 'manual',
        message: `Looks like you already have an account. <a target="_blank" href="${loginUrl}" class="account-exists-login-link">Please login here</a>`,
      });
    }

    setError('twoFactor', {
      type: 'manual',
      message: failureMessage || 'Something went wrong. Please contact support@youbroker.com',
    });
    console.error('@onSubmitLeadFlow', error);
    return error.message;
  }
};

export const onSubmitExistingApplicant = async ({ formData: { formData }, apolloClient, extraData, setError }) => {
  const isLoggedIn = extraData?.isLoggedIn;
  const refetchApplicantCoreData = extraData?.refetchApplicantCoreData;

  if (!isLoggedIn) return null;

  try {
    const { applicantCoreData } = extraData;
    const { numberOfApplicants, additionalApplicants, phoneNumber, authKey, ...updateApplicationVariables } = formData;

    await apolloClient.mutate({
      mutation: UPDATE_APPLICATION,
      variables: {
        id: applicantCoreData?.currentUser?.application?.id,
        ...updateApplicationVariables,
        termOfLoan: Number(updateApplicationVariables?.termOfLoan?.slice(0, 2)),
      },
    });

    await refetchApplicantCoreData();

    // ------------------------
    // On success redirect user
    console.log('@onSubmitLeadFlow - GREAT SUCCESS!');
    return navigate('/create-your-shortlist/');
  } catch (error) {
    setError('twoFactor', {
      type: 'manual',
      message: error.response.data.fail || 'Something went wrong. Please contact support@youbroker.com',
    });
    console.error('@onSubmitLeadFlow', error);
    return error.message;
  }
};
