import React from 'react';
import { GetStarted } from 'components';
import { ReactComponent as RocketIcon } from '../../../images/rocket.svg';
import './styles.scss';

const BlogPostSideBar = ({ title, content }) => (
  <div className="blog-post-side-bar">
    <div className="blog-post-side-bar-sticky">
      <div className="blog-post-side-bar-cta">
        <RocketIcon className="blog-post-side-bar-cta-icon" />
        <span className="blog-post-side-bar-cta-title" dangerouslySetInnerHTML={{ __html: title }} />
        <span className="blog-post-side-bar-cta-content" dangerouslySetInnerHTML={{ __html: content }} />
        <GetStarted />
      </div>
    </div>
  </div>
);

export default BlogPostSideBar;
