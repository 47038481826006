import React, { useState } from 'react';
import { Section, Image, OnVisible, Link } from 'components';
import * as styles from './styles.module.scss';

const Partners = (props) => {
  const [showLogos, setShowLogos] = useState(false);
  const { data } = props;
  const { primary, items } = data;
  const { title, content } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;

  const showFade = items.length > 20;

  return (
    <Section className={styles.section} containerClassName={styles.container}>
      {/* top graphic */}
      {titleHtml && (
        <div className={styles.titleContainer}>
          {titleHtml && (
            <OnVisible
              onChange={setShowLogos}
              className={styles.title}
              visibleClassName={styles.titleVisible}
              dangerouslySetInnerHTML={{ __html: titleHtml }}
            />
          )}
          {contentHtml && (
            <OnVisible
              className={styles.content}
              visibleClassName={styles.contentVisible}
              dangerouslySetInnerHTML={{ __html: contentHtml }}
            />
          )}
        </div>
      )}
      {showLogos && (
        <>
          {/* logos */}
          <div className={`${styles.logos} ${showFade ? styles.withFade : ''}`}>
            {items.map((item) => {
              const { logo, name } = item;
              const nameText = name?.text;
              return (
                <OnVisible key={nameText || logo?.url} className={styles.logo} visibleClassName={styles.logoVisible}>
                  <Image image={logo} alt={nameText} />
                </OnVisible>
              );
            })}
          </div>
          <OnVisible className={styles.cta} visibleClassName={styles.ctaVisible}>
            Plus many more... <Link to="/get-started/">Get qualified in minutes</Link>
          </OnVisible>
        </>
      )}
    </Section>
  );
};

export default Partners;
