import { onSubmit } from './onSubmit';

export default [
  {
    type: 'fieldGroup',
    name: 'propertyAddress',
    namePrismic: 'Property - Address',
    slug: 'property-address',
    preventEnterKeyListener: true,
    onSubmit,
    subfields: [
      {
        type: 'radio',
        name: 'addressNotKnown',
        label: 'Do you know the property address or suburb?',
        reverseYesNoDefault: true,
        options: [{ text: 'Yes' }, { text: 'No' }],
      },
      {
        type: 'addressSearch',
        conditional: ({ formData }) => formData?.addressNotKnown === 'Yes',
      },
    ],
  },
];
