import { UPDATE_APPLICATION } from '../../../apollo/mutations/mutations_misc';
import { UPDATE_ABOUT_USER } from '../../../apollo/mutations/mutations_about_ff';
import { UPDATE_EXPENSES } from '../../../apollo/mutations/mutations_expenses_ff';
import { UPDATE_MORTGAGE } from '../../../apollo/mutations/mutations_mortgage_ff';
import { UPDATE_MORTGAGE_REFINANCE } from '../../../apollo/mutations/mutations_mortgage_refinance_ff';
import { UPDATE_PROPERTY } from '../../../apollo/mutations/mutations_property_ff';
import { UPDATE_PROPERTY_REFINANCE } from '../../../apollo/mutations/mutations_property_refinance_ff';
import { isBrowser } from '../../../utils/isBrowser';

export const apolloLookup = ({ uid, applicantCoreData, refinanceFlowQuestion, currentApplicant, question }) => {
  const primaryApplicantApplication = applicantCoreData?.currentUser?.application;
  const users = primaryApplicantApplication?.Users;
  const primaryApplicant = users?.find((user) => user?.primaryApplicant);
  const currentpropertyId =
    (isBrowser && localStorage.getItem('yb-current-property-id')) ||
    applicantCoreData?.currentUser?.application?.PropertyRefinance?.[0]?.id;

  const updateApplicationConfig = {
    cacheTypename: 'Application',
    id: currentApplicant?.applicationId,
    responseDataName: 'updateApplication',
    updateMutation: UPDATE_APPLICATION,
  };

  const questionLookup = {
    'property-refinance': updateApplicationConfig,
    'mortgage-favourite-loan': updateApplicationConfig,
  };

  const uidLookup = {
    'about-you': {
      cacheTypename: 'AboutUser',
      id: currentApplicant?.aboutUser?.id,
      responseDataName: 'updateAboutUser',
      updateMutation: UPDATE_ABOUT_USER,
    },
    expenses: {
      cacheTypename: 'Expenses',
      id: primaryApplicant?.Expenses?.id,
      responseDataName: 'updateExpenses',
      updateMutation: UPDATE_EXPENSES,
    },
    property: {
      cacheTypename: refinanceFlowQuestion ? 'PropertyRefinance' : 'Property',
      id: refinanceFlowQuestion ? currentpropertyId : primaryApplicantApplication?.Property?.id,
      responseDataName: refinanceFlowQuestion ? 'updatePropertyRefinance' : 'updateProperty',
      updateMutation: refinanceFlowQuestion ? UPDATE_PROPERTY_REFINANCE : UPDATE_PROPERTY,
    },
    mortgage: {
      cacheTypename: refinanceFlowQuestion ? 'MortgageRefinance' : 'MortgagePreference',
      id: refinanceFlowQuestion
        ? primaryApplicantApplication?.MortgageRefinance?.id
        : primaryApplicantApplication?.MortgagePreference?.id,
      responseDataName: refinanceFlowQuestion ? 'updateMortgageRefinance' : 'updateMortgagePreference',
      updateMutation: refinanceFlowQuestion ? UPDATE_MORTGAGE_REFINANCE : UPDATE_MORTGAGE,
    },
  };

  return questionLookup?.[question] || uidLookup?.[uid];
};
