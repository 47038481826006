import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as Chevron } from '../../../../images/chevron-down.svg';
import * as styles from './styles.module.scss';

const AccordionItem = (props) => {
  const { children, title, className, active, onClick } = props;

  // Accordion height animation
  const content = useRef(null);
  const [maxHeight, setMaxHeight] = useState('0px');
  useEffect(() => {
    const scrollHeight = content?.current?.scrollHeight;
    setMaxHeight(active ? `${scrollHeight}px` : '0px');
  }, [active, children]);

  const classes = `${styles.accordionItem} ${active && styles.active} ${className || ''}`;

  return (
    <div className={classes}>
      <div className={styles.header} onClick={onClick} role="button">
        <div className={styles.title}>{title}</div>
        <div className={styles.indicator}>
          <Chevron />
        </div>
      </div>
      <div className={styles.content} ref={content} style={{ maxHeight }}>
        {children}
      </div>
    </div>
  );
};

export default AccordionItem;
