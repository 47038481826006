import React, { useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormSubmitButton, Pattern } from 'components';
import { navigate } from 'gatsby';
import FormBlock from '../../Calculators/_common/FormBlock';
import { isBrowser } from '../../../utils/isBrowser';
import * as styles from './styles.module.scss';

const GetCreditScoreDataInput = (props) => {
  const localStorageData = isBrowser && JSON.parse(localStorage.getItem('yb-data'));

  // Initialise Form
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      purchaseOrRefinance: localStorageData?.purchaseOrRefinance || 'Refinance',
      loanAmount: localStorageData?.loanAmount,
      householdIncome: localStorageData?.householdIncome,
      expectedPurchasePrice: localStorageData?.expectedPurchasePrice,
    },
  });

  const submitRef = useRef();

  const fieldsets = [
    {
      title: 'Home loan matcher',
      fields: [
        {
          name: 'purchaseOrRefinance',
          type: 'toggleButtons',
          options: [
            {
              buttonText: 'Purchase',
            },
            {
              buttonText: 'Refinance',
            },
          ],
        },
        {
          name: 'loanAmount',
          label: 'Amount needed',
          type: 'currency',
          placeholder: '$',
          className: styles.loanAmount,
          validation: ({ formData }) => ({
            required: {
              value: true,
              message: 'Please enter loan amount amount',
            },
            min: {
              value: 100000,
              message: 'Please enter loan amount amount over $100,000',
            },
            max: {
              value: 999999999,
              message: 'Please enter loan amount amount under $999,999,999',
            },
            validate: (v) => {
              const { propertyValue } = formData;
              if (!propertyValue || !v) return true;
              const depositPercentage = Math.round((v / propertyValue) * 100);
              return depositPercentage < 90 || `Max deposit percantage is 90%`;
            },
          }),
        },
        {
          name: 'householdIncome',
          label: 'Household income',
          type: 'currency',
          placeholder: '$',
          className: styles.householdIncome,
          validation: ({ formData }) => ({
            required: {
              value: true,
              message: 'Please enter annual household income',
            },
            max: {
              value: 999999999,
              message: 'Please enter annual household income under $999,999,999',
            },
          }),
        },
        {
          name: 'expectedPurchasePrice',
          label: 'Property value',
          type: 'currency',
          placeholder: '$',
          validation: {
            required: {
              value: true,
              message: 'Please enter property value',
            },
            min: {
              value: 50000,
              message: 'Please enter a property value over $50,000',
            },
            max: {
              value: 999999999,
              message: 'Please enter an property value under $999,999,999',
            },
          },
        },
      ],
    },
  ];

  const handleSubmit = (data) => {
    if (isBrowser) {
      localStorage.setItem(
        'yb-data',
        JSON?.stringify({
          ...localStorageData,
          ...data,
          propertyValue: data?.expectedPurchasePrice,
          depositAmount: data?.expectedPurchasePrice - data?.loanAmount,
        })
      );
    }

    if (methods?.watch('purchaseOrRefinance') === 'Purchase') return navigate('/purchase/');

    return navigate('/refinance/');
  };

  return (
    <FormProvider methods={methods}>
      <div className={styles.container}>
        <Pattern className={styles.pattern} />
        <FormBlock
          methods={methods}
          fieldsets={fieldsets}
          submitRef={submitRef}
          showGroupValidations
          className={styles.gcsDataInput}
          onSubmit={handleSubmit}
        />
        <FormSubmitButton
          label="See my matches"
          className={styles.formSubmitButton}
          onClick={() => submitRef?.current?.click()}
        />
      </div>
    </FormProvider>
  );
};

export default GetCreditScoreDataInput;
