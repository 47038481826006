import { navigate } from 'gatsby';
import { onSubmit } from './onSubmit';

export default [
  {
    type: 'radio',
    name: 'ownOtherProperties',
    namePrismic: 'Property - Other Properties',
    onSubmit,
    goNext: ({ formData }) => {
      if (formData?.ownOtherProperties === 'Yes') {
        return navigate('?question=property-refinance-address');
      }

      return navigate('?question=property-review-details');
    },
    slug: 'property-other-properties',
    options: [{ text: 'Yes' }, { text: 'No' }],
  },
];
