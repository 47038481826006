import { gql } from '@apollo/client';
import { FACT_FIND_FRAGMENTS } from '../fragments/fragments_ff';

const { expenses } = FACT_FIND_FRAGMENTS;

export const UPDATE_EXPENSES = gql`
  mutation updateExpenses(
    $id: String
    $basicHousingAndPropertyExpenses: Int
    $educationAndChildcare: Int
    $communicationsAndMedia: Int
    $insurance: Int
    $foodAndGroceries: Int
    $otherExpenses: Int
    $clothingAndToiletries: Int
    $essentialTravel: Int
    $leisureExpenses: Int
    $medical: Int
  ) {
    updateExpenses(
      id: $id
      basicHousingAndPropertyExpenses: $basicHousingAndPropertyExpenses
      educationAndChildcare: $educationAndChildcare
      communicationsAndMedia: $communicationsAndMedia
      insurance: $insurance
      foodAndGroceries: $foodAndGroceries
      otherExpenses: $otherExpenses
      clothingAndToiletries: $clothingAndToiletries
      essentialTravel: $essentialTravel
      leisureExpenses: $leisureExpenses
      medical: $medical
    ) {
      ...ExpensesTotal
      ...FlowProgressExpenses
    }
  }
  ${expenses?.['expenses-monthly-expenses']}
  ${expenses?.flowProgress}
`;
