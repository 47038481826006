import React from 'react';
import { Section } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import PostGrid from '../../components/Blog/PostGrid';
import * as styles from './styles.module.scss';

const BlogFeed = (props) => {
  const { data } = props;
  const primary = data?.primary;

  const titleText = primary?.title_text;

  const hasLightBlueBg = primary?.variant === 'Light Blue Background';

  const backgroundClass = hasLightBlueBg ? styles.lightBlueBg : '';

  const { blogPostData } = useStaticQuery(blogFeedQuery);
  const posts = blogPostData?.nodes;

  if (!posts || posts?.length < 1) return null;

  return (
    <Section className={`${styles.section} ${backgroundClass}`} containerClassName={styles.container}>
      <PostGrid posts={posts} title={titleText?.text || 'From our blog'} titleLink="/articles/" />
    </Section>
  );
};

export default BlogFeed;

export const blogFeedQuery = graphql`
  query BlogFeedQuery {
    blogPostData: allPrismicBlogPost(
      limit: 3
      sort: { order: ASC, fields: last_publication_date }
      filter: { data: { staging_only: { ne: true } } }
    ) {
      nodes {
        uid
        dataRaw
        data {
          author {
            text
          }
          category {
            uid
            document {
              ... on PrismicCategory {
                id
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
          excerpt {
            text
            html
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
          is_featured
          staging_only
          timestamp(formatString: "D MMMM, YYYY")
          title {
            html
            text
          }
        }
      }
    }
  }
`;
