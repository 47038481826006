import { gql } from '@apollo/client';

export const LEAD_APPLICATION_FRAGMENTS = {
  additionalUsers: gql`
    fragment AdditionalUsers on User {
      leadApplication {
        additionalUsers {
          id
          name
          inviteSent
          leadApplicationId
        }
      }
    }
  `,
  leadApplication: gql`
    fragment LeadApplication on User {
      leadApplication {
        additionalUsers {
          id
          name
          inviteSent
          leadApplicationId
        }
        creditHistory
        depositAmount
        emailAddress
        employment
        expectedPurchasePrice
        firstName
        grantEligibility
        homeBuyingSituation
        importantValues
        loanAmount
        numberOfApplicants
        numberOfProperties
        phoneNumber
        propertyKind
        propertyUse
        termOfLoan
        whatStage
      }
    }
  `,
};
