import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useScript from '../../../../hooks/useScript';
import { FRANKIE_CONFIG } from './frankieWidgetConfig';
import { SERVER } from '../../../../utils/server';
import './styles.scss';

const FrankieOneWidget = (props) => {
  const { applicantData, onChange, reset } = props;
  const [frankieConfig, setFrankieConfig] = useState({});

  // Frankie One Change Handler
  const onChangeHandler = (event) => {
    if (onChange) onChange(event);
  };

  // Load Frankie One Script
  const script = useScript({ src: 'https://assets.frankiefinancial.io/onboarding/v4/ff-onboarding-widget.umd.min.js' });
  const scriptReady = script === 'ready';

  // Fetch Frankie One Customer Token
  useEffect(async () => {
    if (applicantData?.id && applicantData?.id !== frankieConfig?.id && reset) {
      const frankieCustomerTokenResponse = await axios.post(`${SERVER}/frankieToken`, {
        customerReference: applicantData?.id,
      });
      const token = frankieCustomerTokenResponse?.data;
      if (!token) return;
      setFrankieConfig({
        id: applicantData?.id,
        token,
      });
    }
  }, [applicantData?.id, reset, frankieConfig]);

  // Initialise Widget (once script and token are loaded)
  useEffect(async () => {
    try {
      if (scriptReady && frankieConfig?.id && frankieConfig?.token) {
        const initFrankie = {
          applicantReference: frankieConfig?.id, // This will be your applicants reference code
          config: FRANKIE_CONFIG,
          // ffToken,
          ffToken: frankieConfig?.token,
          width: `${window.innerWidth * 0.5}px`,
          height: `${window.innerHeight / 1.4}px`,
        };
        window.frankieFinancial.initialiseOnboardingWidget(initFrankie);
      }
      window.addEventListener('FF_CHECK_RESULT', onChangeHandler);
      return () => {
        window.removeEventListener('FF_CHECK_RESULT', onChangeHandler);
      };
    } catch (err) {
      console.log('ERROR: ', err);
    }
  }, [scriptReady, frankieConfig]);

  return (
    <div id="smart-ui-styles">
      <ff-onboarding-widget />
    </div>
  );
};

export default FrankieOneWidget;
