import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { DraggableRateCards, FormFieldNavigation } from 'components';
import { useMutation } from '@apollo/client';
import { UPDATE_APPLICATION } from '../../../apollo/mutations/mutations_misc';
import { useSetFlowStep } from '../../../hooks/useSetFlowStep';
import { sendEmail } from '../../../utils/sendEmail';
import { isDev } from '../../../utils/misc';
import { useDataLayer } from '../../../hooks/useDataLayer';

const IS_STAGING = process.env.GATSBY_IS_STAGING;
const FORMSPARK_ID = IS_STAGING ? 'FOA5EalV' : 'HaSYyldB';
const SUBMIT_URL = `https://submit-form.com/${FORMSPARK_ID}`;

const PrioritiseShortlist = (props) => {
  const { extra } = props;
  const { applicantCoreData } = extra;

  const [shortlistedProducts, setShortlistedProducts] = useState([]);
  const [updateCache, setUpdateCache] = useState(false);

  const applicationId = applicantCoreData?.currentUser?.application?.id;
  const hasAdditionalApplicants = applicantCoreData?.currentUser?.leadApplication?.additionalUsers?.length > 0;
  const storedDbShortlistedProducts = applicantCoreData?.currentUser?.application?.MortgageShortlist;

  const storedLeadFlowData = JSON.parse(localStorage.getItem('yb-data'));
  const storedLocallyShortlistedProducts = storedLeadFlowData?.shortlistedProducts;
  const formattedWithIdStoredLocallyShorlistedProducts =
    storedDbShortlistedProducts &&
    storedLocallyShortlistedProducts?.map((shortlistedProduct, index) => ({
      ...shortlistedProduct,
      id: storedDbShortlistedProducts?.[index]?.id,
    }));

  // -----------------------------
  // Submit form handlers
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    // Ensure user has completed creating shortlist
    if (storedDbShortlistedProducts?.length < 3 && storedLocallyShortlistedProducts?.length < 3) {
      return navigate('/create-your-shortlist');
    }

    if (storedLocallyShortlistedProducts?.length > 0) {
      return setShortlistedProducts(formattedWithIdStoredLocallyShorlistedProducts || storedLocallyShortlistedProducts);
    }

    return setShortlistedProducts(storedDbShortlistedProducts);
  }, []);

  useEffect(() => {
    // Keep in LS for account creation
    localStorage.setItem(
      'yb-data',
      JSON.stringify({
        ...storedLeadFlowData,
        shortlistedProducts,
      })
    );
  }, [shortlistedProducts]);

  const [updateMortgageShortlist, { data: apolloData, loading: apolloLoading, client }] = useMutation(
    UPDATE_APPLICATION,
    {
      variables: {
        id: applicantCoreData?.currentUser?.application?.id,
        mortgageShortlist: shortlistedProducts,
      },
      // Keep to update Application cahce object
      update: (cache, { data }) => {
        const cacheObjToModify = cache.identify({ id: applicationId, __typename: 'Application' });
        const { updateApplication } = data;
        const { MortgageShortlist: DbMortgageShortlist } = updateApplication;

        cache.modify({
          broadcast: false,
          id: cacheObjToModify,
          fields: {
            MortgageShortlist() {
              return DbMortgageShortlist?.map((shortlistData) => ({
                __ref: `MortgageShortlist:{"productId":"${shortlistData?.productId}"}`,
              }));
            },
          },
        });
      },
    }
  );

  useSetFlowStep({
    newFlowStep: hasAdditionalApplicants ? 2 : 3,
    userId: applicantCoreData?.currentUser?.id,
    condition: updateCache,
  });

  const emailDataToSubmit = {
    LeadApplication: applicantCoreData?.currentUser?.leadApplication,
    MortgageShortlist: applicantCoreData?.currentUser?.application?.MortgageShortlist,
  };

  const dataLayer = useDataLayer();

  const handleSubmit = async () => {
    if (storedDbShortlistedProducts?.length === 0 && !isDev) {
      await sendEmail(SUBMIT_URL, emailDataToSubmit);
      dataLayer?.track('Shortlist Created');
    }

    await setSubmitting(true);
    await setShortlistedProducts((prevState) =>
      prevState?.map((prev, index) => {
        const { __typename, ...prevRest } = prev;

        return {
          ...prevRest,
          favourite: index + 1,
          feeSummaryComponents: prevRest?.feeSummaryComponents ? prevRest?.feeSummaryComponents : [],
        };
      })
    );
    setUpdateCache(true);
    await updateMortgageShortlist();
    navigate(hasAdditionalApplicants ? `/invite-other-applicants` : '/verify-your-details');
    await setSubmitting(false);
    return null;
  };

  return (
    <>
      <DraggableRateCards
        shortlistedProducts={shortlistedProducts}
        setShortlistedProducts={setShortlistedProducts}
        disabled={isSubmitting}
      />
      <FormFieldNavigation
        handleSubmit={handleSubmit}
        submitButtonText="Confirm your shortlist"
        onBack={() => navigate('/create-your-shortlist')}
      />
    </>
  );
};

export default PrioritiseShortlist;
