export const defaultFields = [
  {
    type: 'text',
    name: 'firstName',
    placeholder: 'Pepe',
    label: 'First name',
    className: 'split-left',
    defaultValue: '',
    validation: { required: true },
    validationMessage: 'Please enter your first name',
  },
  {
    type: 'text',
    name: 'lastName',
    placeholder: 'Silvia',
    label: 'Last name',
    className: 'split-right',
    defaultValue: '',
    validation: { required: true },
    validationMessage: 'Please enter your last name',
  },
  {
    type: 'tel',
    name: 'phone',
    placeholder: '0422 123 456',
    label: 'Best Phone number',
    defaultValue: '',
    validation: { required: true, minLength: 8 },
    validationMessage: 'Please enter a valid number',
  },
  {
    type: 'email',
    name: 'email',
    placeholder: 'carol@hr.com',
    label: 'Email address',
    defaultValue: '',
    validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
    validationMessage: 'Please enter a valid email',
  },
  {
    type: 'textarea',
    name: 'message',
    placeholder: "What's on your mind?",
    label: 'Enter a message',
    defaultValue: '',
  },
];
