module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Be your own broker | YouBroker', // Default Site Title used for SEO & PWA
  description:
    "At YouBroker, we help you find your best home loan, faster! Compare mortgages, apply online and get approved easily. We have mortgage rates and offers from Australia's leading home loan lenders and banks. Refinance your home or research how to buy your first home with YouBroker.", // Default Site Decription used for SEO
  siteName: 'YouBroker', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/open-graph.jpg', // Default OpenGraph image
  ogLanguage: 'en_US', // Facebook Language
  socialLinks: [''], // Array of social links (facebook, insta, etc)
  // JSONLD / Manifest
  icon: 'src/images/icon.png', // Used for manifest favicon, splash screen, and icon generation
  shortName: 'PWA Name', // shortname for manifest. MUST be shorter than 12 characters
  author: 'PWA Author', // Author for schemaORGJSONLD
  themeColor: '#FFFFFF', // PWA Icon background & address bar colour if installed on desktop
  backgroundColor: '#FFFFFF', // PWA colour shown before styles and content loads, should match the background-color CSS property in the site’s stylesheet

  twitter: '', // Twitter Username
  googleTagManagerId: '',
};
