import React from 'react';
import { ReactComponent as Star } from '../../../images/iconography/star.svg';

export const fieldsDefault = (submitHandler) => [
  {
    title: 'Enter details',
    titleIcon: <Star className="title-icon" />,
    fields: [
      {
        name: 'propertyValue',
        label: 'Estimated property value($)',
        type: 'currency',
        placeholder: '$',
        onBlur: (data) => submitHandler(data),
        validation: {
          required: {
            value: true,
            message: 'Please enter property value',
          },
          min: {
            value: 50000,
            message: 'Please enter an amount over $50,000',
          },
          max: {
            value: 999999999,
            message: 'Please enter an amount under $999,999,999',
          },
        },
      },
      {
        name: 'depositAmount',
        label: 'Deposit amount',
        type: 'currency',
        placeholder: '$',
        onBlur: (data) => submitHandler(data),
        validation: ({ formData }) => ({
          required: {
            value: true,
            message: 'Please enter an amount',
          },
          min: {
            value: 0,
            message: 'Please enter an amount',
          },
          validate: (v) => {
            const { propertyValue } = formData;
            if (!propertyValue || !v) return true;
            const depositPercentage = Math.round((v / propertyValue) * 100);
            return depositPercentage < 90 || `Max deposit percantage is 90%`;
          },
        }),
      },
      {
        name: 'state',
        label: 'State',
        type: 'select',
        options: ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'NT', 'ACT'],
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'homeBuyingSituation',
        label: 'First time buyer?',
        type: 'select',
        options: ['Yes', 'No'],
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'termOfLoan',
        label: 'Term of loan',
        type: 'select',
        options: [...Array(31).keys()]?.slice(10)?.map((number) => `${number} years`),
        onChange: (data) => submitHandler(data),
      },
      {
        name: 'propertyUse',
        label: 'Occupancy type',
        type: 'select',
        options: ['Primary residence', "It's an investment"],
        onChange: (data) => submitHandler(data),
      },
    ],
  },
];
