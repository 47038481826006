import React, { useState, useEffect } from 'react';
import { FormFieldNew as FormField } from 'components';
import { useWatch, useFormContext } from 'react-hook-form';
import { Loader } from '@googlemaps/js-api-loader';
import { formatAddress } from './formatAddress';
import FieldLabel from '../../_FieldCommon/FieldLabel';
import * as styles from './styles.module.scss';

const FieldAddressSearch = (props) => {
  const {
    onChange,
    initialValue,
    showSearch,
    setShowSearch,
    setValue,
    clearErrors,
    register,
    setError,
    getValues: getValuesWithExtra,
    reset,
    fieldsResetConfig,
    addressArray,
    hideManualAddress,
    hideDefaultLabel,
    name,
    addressArrayNamePrefix,
  } = props;

  const registeredName = !addressArray ? 'addressSearch' : name;
  const registeredHiddenName = !addressArray ? 'hiddenAddressSearch' : `${addressArrayNamePrefix}.hiddenAddressSearch`;

  const { getValues, getFieldState } = useFormContext();

  // Hide Search if address field is already complete
  const addressField = useWatch({ name: 'addressOne' });
  useEffect(() => {
    if (showSearch && addressField && !addressArray) {
      setShowSearch(false);
    }
  }, [addressField]);

  useEffect(() => {
    if (initialValue) {
      setValue(registeredHiddenName, initialValue);
    }
  }, [initialValue]);

  const handleManualAddress = () => {
    reset(fieldsResetConfig, { keepValues: false, keepErrors: false, keepDefaultValues: false });
    clearErrors();
  };

  const onChangeHandler = (e) => {
    clearErrors([registeredName, registeredHiddenName]);
    setValue(registeredHiddenName, '');
  };

  const onPlaceChanged = (autoComplete) => {
    const autoCompleteData = autoComplete?.getPlace();
    const { formatted_address: formattedAddress, address_components: addressComponents } = autoCompleteData;

    const searchBySuburb = !addressComponents?.[0]?.types?.includes('street_number');

    const data = {
      formattedAddress,
      addressComponents: formatAddress(addressComponents, searchBySuburb),
      searchBySuburb,
    };

    if (!data?.formattedAddress) {
      return setError(registeredName, {
        type: 'pattern',
        message: 'Please enter valid address',
      });
    }

    if (onChange) onChange(data);

    setValue(registeredName, formattedAddress);
    setValue(registeredHiddenName, formattedAddress);

    if (!addressArray) {
      clearErrors();
    }
  };

  // Load Google Maps library
  const fieldId = registeredName;
  useEffect(() => {
    const additionalLoaderOptions = { libraries: ['places'] };
    const loader = new Loader({
      apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      ...additionalLoaderOptions,
    });
    loader.load().then(() => {
      const input = document.getElementById(fieldId);
      const autoComplete = new window.google.maps.places.Autocomplete(input, {
        types: ['address', ['(regions)']],
        componentRestrictions: { country: ['AU'] },
        fields: ['formatted_address', 'address_components'],
      });
      autoComplete.addListener('place_changed', () => onPlaceChanged(autoComplete));
    });
  }, []);

  const addressSearchValidation = {
    required: {
      value: addressArray ? true : showSearch,
      message: 'Please enter address',
    },
    validate: (v) => {
      if (v !== getValues(registeredHiddenName)) {
        return 'Please enter valid address';
      }

      return true;
    },
  };

  const hasError = getFieldState(registeredName)?.invalid;

  return (
    <div className={`${styles.container} ${showSearch ? 'show-fields' : 'hide-fields'}`}>
      {!hideDefaultLabel && <FieldLabel name={fieldId} label="Search for address or suburb" />}
      <input
        {...register(registeredName, addressSearchValidation)}
        placeholder="Enter an address"
        id={registeredName}
        type="text"
        name={registeredName}
        onChange={onChangeHandler}
        className={hasError ? 'google-address-input-error' : ''}
        defaultValue={initialValue}
      />
      <FormField name={registeredHiddenName} type="hidden" register={register} />
      {!hideManualAddress && (
        <button
          type="button"
          className={styles.button}
          onClick={() => {
            handleManualAddress();
            setShowSearch(false);
          }}
        >
          Can't find your address?
        </button>
      )}
    </div>
  );
};

export default FieldAddressSearch;
