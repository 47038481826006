export const draggableOptions = ({ isRefinancing }) => {
  const defaultOptions = [
    {
      text: 'Getting the best mix of value, borrowing power, and approval speed',
      value: 'best mix',
      order: 0,
    },
    {
      text: 'Being able to pay more into my loan or offset',
      value: 'pay more',
      order: 1,
    },
    {
      text: 'Getting the fastest response / Meeting my target settlement date',
      value: 'fastest response',
      order: 2,
    },
    {
      text: `Getting the most borrowing power`,
      value: 'borrowing power',
      order: isRefinancing ? 4 : 3,
    },
  ];

  if (!isRefinancing) return defaultOptions;

  const refinancingOption = {
    text: 'Getting my highest home value',
    value: 'home value',
    order: 3,
  };

  if (isRefinancing) {
    defaultOptions?.splice(3, 0, refinancingOption);
    return defaultOptions;
  }
};

export const handleDefaultValsKeyPriorities = ({ options, defaultValues }) => {
  if (defaultValues?.keyPriorities?.length === 0) return options;

  const matchWithDefaultOptions = ({ keyPriority }) => options?.find((option) => option?.value === keyPriority?.value);

  return defaultValues?.keyPriorities
    ?.map((keyPriority) => ({
      ...keyPriority,
      text: matchWithDefaultOptions({ keyPriority })?.text,
    }))
    ?.sort(function (a, b) {
      return a?.order - b?.order;
    });
};
