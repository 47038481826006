import React from 'react';
import * as styles from './styles.module.scss';

const ToggleButtons = (props) => {
  const { allOptionsOnClick, options = [], className = '', activeButton = 1, setActiveButton } = props;

  return (
    <div className={`${styles.container} ${className}`}>
      {options.map((option, index) => {
        const { optionOnClick, buttonText = 'Click' } = option;

        const onClickHandler = (e) => {
          setActiveButton(index);

          if (allOptionsOnClick) return allOptionsOnClick(e, option, index);

          if (optionOnClick) return optionOnClick();

          return null;
        };

        return (
          <button
            type="button"
            className={`button white ${styles.toggleButton} ${index === activeButton ? styles.activeButton : ''}`}
            onClick={onClickHandler}
            key={buttonText}
          >
            {buttonText}
          </button>
        );
      })}
    </div>
  );
};

export default ToggleButtons;
