import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { DraggableRateCards } from 'components';

const FieldDraggableMortgageShortlist = (props) => {
  const { extraData, setValue, isSubmitting } = props;
  const { applicantCoreData } = extraData;

  const savedMortgageShortlist = applicantCoreData?.currentUser?.application?.MortgageShortlist;

  const sortedMortgageShortlistByFavourite =
    Array?.isArray(savedMortgageShortlist) &&
    [...savedMortgageShortlist]?.sort((a, b) => {
      if (a?.favourite && b?.favourite) {
        return a?.favourite - b?.favourite;
      }

      return a;
    });

  const [shortlistedProducts, setShortlistedProducts] = useState(sortedMortgageShortlistByFavourite);

  useController({
    name: 'mortgageShortlist',
    defaultValue: shortlistedProducts,
  });

  useEffect(() => {
    if (shortlistedProducts?.length > 0) {
      setValue('mortgageShortlist', shortlistedProducts);
    }
  }, [shortlistedProducts]);

  useEffect(() => {
    const formFlow = document.querySelector('.form-flow');
    formFlow.style.maxWidth = 'none';

    return () => {
      formFlow.style.maxWidth = '530px';
    };
  }, []);

  return (
    <DraggableRateCards
      shortlistedProducts={shortlistedProducts}
      setShortlistedProducts={setShortlistedProducts}
      disabled={isSubmitting}
    />
  );
};

export default FieldDraggableMortgageShortlist;
