export default [
  {
    type: 'group',
    name: 'whatsImportantToYouGroup',
    namePrismic: 'Mortgage - Whats Important To You',
    slug: 'mortgage-whats-important-to-you',
    showGroupValidations: false,
    subfields: [
      {
        type: 'checkbox',
        name: 'whatsImportantToYou',
        namePrismic: 'Mortgage - Whats Important To You',
        slug: 'mortgage-whats-important-to-you',
        placeholder: 'Select one',
        label: '',
        options: [
          { text: 'Loans with an offset account' },
          { text: 'Loans that allow Parental Guarantee' },
          { text: 'Package deals with linked cards and accounts' },
          { text: 'Interest Only investment lending' },
          { text: 'Loans that allow higher LVR lending' },
          { text: 'Other' },
        ],
      },
      {
        type: 'text', // conditional field
        name: 'whatsImportantToYouOther',
        placeholder: "What's important in your loan selection?",
        label: 'Please specify',
        conditional: ({ formData }) => {
          if (formData?.whatsImportantToYou?.includes('Other')) return true;

          return false;
        },
        validationMessage: 'Please enter details',
      },
    ],
  },
];
