import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Section, OnVisible } from 'components';
import './styles.scss';

const EmailCapture = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title, description, submit_text: submitText } = primary;

  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(
    () =>
      // Set submitting to false in clean up function
      () => {
        setSubmitting(false);
      },
    []
  );

  const handleChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const encode = (formData) =>
    Object.keys(formData)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`)
      .join('&');

  const onSubmit = async (event) => {
    event.preventDefault();
    // get data
    const formData = new FormData(event.target);
    const values = {};
    for (const [key, value] of formData.entries()) {
      values[key] = value;
    }
    console.log('VALUES', values);
    // submission logic
    setSubmitting(true);
    try {
      const url = 'https://aeqni6fq2h.execute-api.ap-southeast-2.amazonaws.com/prod?format=json';
      const config = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: encode({
          ...values,
          _to: 'dbb0e9790894c5461332c7c31b722e5d9e133e03', // YB
          _bcc: 'c2bee1791c9add490e29c4881d392e5a', // sam
          _sender: 'YouBroker',
          _formname: 'Email Capture from YouBroker',
          _replyTo: email,
        }),
      };
      const response = await fetch(url, config);
      if (response.ok) {
        return navigate('/thank-you');
      }
      //
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmitting(false);
    }
  };

  // content checks
  const titleHtml = title?.html;
  const descriptionHtml = description?.html;

  return (
    <Section className="email-capture">
      {titleHtml && <OnVisible className="email-capture-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
      {descriptionHtml && (
        <OnVisible className="email-capture-content" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
      )}
      <OnVisible as="form" className="email-capture-form" name="email-capture" onSubmit={onSubmit}>
        <input
          type="email"
          id="email"
          name="email"
          className="email-capture-input"
          placeholder="Your email here"
          value={email}
          onChange={(event) => handleChange(event)}
          required
        />
        <button type="submit" className="button secondary email-capture-submit" disabled={submitting}>
          {submitText?.text || 'Submit'}
        </button>
      </OnVisible>
    </Section>
  );
};

export default EmailCapture;
