export const handleCurrentProgress = ({ currentApplicant, applicantCoreData }) => {
  if (!applicantCoreData) return {};

  const application = applicantCoreData?.currentUser?.application;

  const isRefinancing = application?.isRefinance;

  const Property = application?.Property;
  const PropertyRefinance = application?.PropertyRefinance?.[0];
  const handlePropertyRefinanceProgress = () => {
    const totalFields = 4;
    const hasPropertyValue = !!PropertyRefinance?.propertyValue;
    const hasAddress = !!PropertyRefinance?.addressOne;
    const hasCurrentUse = !!PropertyRefinance?.currentUse;
    const hasUsageChange = !!PropertyRefinance?.usageChange;
    const requiredFields = [hasPropertyValue, hasAddress, hasCurrentUse, hasUsageChange];
    const requiredFieldsTotal = requiredFields?.filter((val) => val)?.length;

    return (requiredFieldsTotal / totalFields) * 100;
  };

  const MortgagePreference = application?.MortgagePreference;

  const allApplicants = application?.Users;

  const applicantsCount = allApplicants?.length || 1;
  const Expenses = currentApplicant?.Expenses;

  if (!currentApplicant || !applicantCoreData) return {};

  const calculateAboutUserProgress = () =>
    allApplicants?.reduce(
      (prev, current) =>
        prev + ((current?.aboutUser?.completedFields / current?.aboutUser?.totalFields) * 100) / applicantsCount,
      0
    );

  const progresses = {
    'about-you': calculateAboutUserProgress(),
    property: isRefinancing
      ? handlePropertyRefinanceProgress()
      : (Property?.completedFields / Property?.totalFields) * 100,
    mortgage: (MortgagePreference?.completedFields / MortgagePreference?.totalFields) * 100,
    expenses: (Expenses?.completedFields / Expenses?.totalFields) * 100,
    verificationProgress: (applicant) =>
      (applicant?.aboutUser?.idVerified ? 50 : 0) + (applicant?.aboutUser?.bankStatementsReceived ? 50 : 0),
    verificationSteps: (applicant) =>
      (applicant?.aboutUser?.idVerified ? 1 : 0) + (applicant?.aboutUser?.bankStatementsReceived ? 1 : 0),
    'enter-number': (applicants) => {
      const numberOfApplicants = applicants?.length;
      const countIncrement = 100 / numberOfApplicants;
      let progressBar = 0;
      let stepsComplete = 0;

      applicants?.forEach((applicant) => {
        if (applicant?.inviteSent) {
          progressBar += countIncrement;
          stepsComplete += 1;
        }
      });

      return {
        progressBar: Math.round(progressBar),
        stepsComplete,
        stepsCompleteProgress: (stepsComplete / numberOfApplicants) * 100,
      };
    },
  };

  return progresses;
};
