import React, { useState, useRef, useEffect } from 'react';
import CodeInput from './CodeInput';
import { sendTwoFactor } from '../../../../utils/sendCode';
import * as styles from './styles.module.scss';

const FieldTwoFactors = (props) => {
  const { getValues, setValue, clearErrors, defaultValue, register, goNext } = props;

  const formData = getValues()?.formData;

  const phoneNumber = formData?.phoneNumber || defaultValue?.phoneNumber;
  const applicantName = formData?.additionalApplicants?.[0]?.firstName;

  const [disabled, setDisabled] = useState(false);
  const enteredCode = useRef(null);

  const onChangeHandler = () => {
    if (goNext)
      setTimeout(async () => {
        await goNext();
        setDisabled(false);
      }, 300);
  };

  useEffect(() => {
    sendTwoFactor({ userName: applicantName, number: phoneNumber });
  }, []);

  const authKeyValidation = {
    required: {
      value: true,
      message: 'Please enter valid code',
    },
    validate: (v) => String(v)?.length === 6 || 'Please enter valid code',
  };

  return (
    <div className={styles.twoFactorModal}>
      <CodeInput
        length={6}
        onChange={(v) => {
          enteredCode.current = v;
          setValue('authKey', Number(v));

          if (v.length === 6) {
            setDisabled(true);
            return onChangeHandler();
          }
          if (v.length < 6) {
            clearErrors();
          }
        }}
        disabled={disabled}
      />
      <input type="hidden" {...register('authKey', authKeyValidation)} />
    </div>
  );
};

export default FieldTwoFactors;
